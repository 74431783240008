import FixrateLogo from '#app/pages/menu/FixrateLogo'
import ButtonRow from '#components/ButtonRow/ButtonRow'
import { Button, DialogActions, DialogContent, DialogTitle, IconButton, useMediaQuery, useTheme } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import AsyncButton from '../Button/AsyncButton'
import styles from './Modal.module.scss'

type Props = {
    header?
    headerDescription?
    onCancel?
    cancel?
    onSubmit?
    submitButtonText?
    submitButtonId?
    submitButtonDisabled?
    submitButtonCy?
    width?
    className?
    children?
    paddingTop?
    paddingBottom?
    showLogo?
    alignButtons?
    visibleOverflow?
    actionButton?
    cancelButtonText?
}

export default function Modal({
    header,
    headerDescription,
    onCancel,
    cancel,
    onSubmit,
    submitButtonText,
    submitButtonId,
    submitButtonDisabled,
    submitButtonCy,
    width,
    className,
    children,
    paddingTop,
    paddingBottom,
    showLogo,
    alignButtons,
    visibleOverflow,
    actionButton,
    cancelButtonText,
}: Props) {
    const { t } = useTranslation()
    const open = true
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
    const navigate = useNavigate()

    function onCancelButton() {
        if (cancel) {
            navigate(cancel + '#!')
        } else if (onCancel) {
            onCancel()
        }
    }

    function handleClose() {
        if (cancel) {
            navigate(cancel + '#!')
        } else if (onCancel) {
            onCancel()
        }
    }

    const headerLogo = showLogo && <FixrateLogo className={styles.fixrateHeaderLogo} />

    const buttonRow = onSubmit && (
        <ButtonRow align={alignButtons ? alignButtons : 'right'} className={styles.modal__buttonrow}>
            {(cancel || onCancel) && (
                <Button id="modalCancelButton" onClick={onCancelButton} variant={'text'}>
                    {cancelButtonText || t('common.cancel')}
                </Button>
            )}
            <AsyncButton
                id={submitButtonId || 'modalConfirmButton'}
                onClick={onSubmit}
                variant={'contained'}
                data-cy={submitButtonCy}
                disabled={submitButtonDisabled}
            >
                {submitButtonText || t('common.ok')}
            </AsyncButton>
        </ButtonRow>
    )

    const dialog = (
        <Dialog
            transitionDuration={0}
            fullScreen={fullScreen}
            onClose={handleClose}
            maxWidth={'md'}
            open={open}
            sx={{
                '& .MuiDialog-container .MuiPaper-root': {
                    overflowY: visibleOverflow ? 'visible' : null,
                },
            }}
        >
            {(header || onCancel || headerLogo || cancel) && (
                <DialogTitle
                    sx={{
                        maxWidth: '100%',
                        width: width ? width : null,
                    }}
                >
                    {headerLogo}
                    {header}
                    {headerDescription && <span className={styles.headerDescription}>{headerDescription}</span>}
                    {(onCancel || cancel) && (
                        <IconButton
                            aria-label="close"
                            onClick={onCancelButton}
                            sx={{
                                position: 'absolute',
                                right: 16,
                            }}
                        >
                            <i className="ri-close-line" />
                        </IconButton>
                    )}
                </DialogTitle>
            )}
            <DialogContent
                dividers={header || actionButton ? true : false}
                className={className}
                sx={{
                    maxWidth: '100%',
                    width: width ? width : null,
                    paddingTop: paddingTop ? paddingTop : null,
                    paddingBottom: paddingBottom ? paddingBottom : null,
                    overflowY: visibleOverflow ? 'visible' : null,
                }}
            >
                {children}
                {buttonRow}
            </DialogContent>
            {actionButton && <DialogActions>{actionButton}</DialogActions>}
        </Dialog>
    )

    return createPortal(dialog, document.querySelector('#modalPortal'))
}
