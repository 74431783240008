import { Children, cloneElement } from 'react'
import { AnimatePresence } from 'motion/react'
import styles from './Table.module.scss'
import TableRow from './TableRow'
import TableHeader from './TableHeader'
import TableFooter from './TableFooter'
import classNames from 'classnames'

/**
 * @param {object} props
 * @param props.children
 * @param [props.sort]
 * @param [props.className]
 * @param [props.dataCy]
 * @return {JSX.Element}
 */
export default function Table({ children = [], sort = null, className, dataCy = '' }) {
    // The HTML syntax for tables have many levels (i.e. table -> thead -> tr -> th)
    // This component accepts a simpler structure, and will re-organize the children into the correct structure
    // The childs are first copied into arrays, and then re-organized into a HTML compliant structure when rendered
    let rows = []
    let headers = []
    let footers = []

    // Copies children to the correct array
    Children.forEach(children, (child) => {
        if (child?.type === TableRow || child?.props?.isTableRow) {
            rows.push(cloneElement(child))
        }
        if (child?.type === TableHeader || child?.props?.isTableHeader) {
            headers.push(cloneElement(child, { sort }))
        }
        if (child?.type === TableFooter || child?.props?.isTableFooter) {
            footers.push(cloneElement(child))
        }
    })
    if (headers.length === 1) {
        // Unpacks the element from the array to avoid the need for a key when there is only one element
        headers = headers[0]
    }
    if (footers.length === 1) {
        // Unpacks the element from the array to avoid the need for a key when there is only one element
        footers = footers[0]
    }

    return (
        <div style={{ width: '100%' }}>
            <table className={classNames(styles.table, className)} data-cy={dataCy}>
                <thead>{headers}</thead>
                <tbody>
                    <AnimatePresence>{rows}</AnimatePresence>
                </tbody>
                <tfoot>{footers}</tfoot>
            </table>
        </div>
    )
}
