import POASigners from '#app/pages/organizations/OrganizationDetail/UsersAndRoles/views/PowerOfAttorney/POASigners'
import { DepositorDto } from '@fixrate/fixrate-query'
import { Stack } from '@mui/material'
import POAList from '#pages/organizations/OrganizationDetail/UsersAndRoles/views/PowerOfAttorney/POAList'
import NeedsAuthorization from '#app/pages/organizations/OrganizationDetail/UsersAndRoles/views/PowerOfAttorney/NeedsAuthorization'

const AccountOpenerAuthorization = ({ depositor }: { depositor: DepositorDto }) => {
    return <NeedsAuthorization depositor={depositor} />
}

export { AccountOpenerAuthorization }
