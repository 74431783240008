import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { FundInventoryReport } from '@fixrate/fixrate-report'
import { Box, Stack, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { fundInventoryReturnPeriodTMap } from '#services/enumTranslationKeyMapping'
import { InterestBadge } from '#components/InterestBadge/InterestBadge'
import { useCurrencyOutputWithCurrency } from '#app/components/CurrencyOutput/useCurrencyOutput'
import { Currency } from '@fixrate/fixrate-query'

type ReturnType = keyof FundInventoryReport & ('returnRates' | 'returnValues')

type Props = {
    report: FundInventoryReport
    type: ReturnType
    currency: Currency
}

export default function ReturnsTable({ report, type, currency }: Props) {
    const { t } = useTranslation()
    const Currency = useCurrencyOutputWithCurrency(currency)

    let alignment
    let formatFn: (value: number | null | undefined) => string | React.ReactNode

    switch (type) {
        case 'returnRates':
            alignment = 'center'
            formatFn = function valueAsInterest(value) {
                return <InterestBadge interest={value} />
            }
            break
        case 'returnValues':
            alignment = 'right'
            formatFn = (value) => (value != null ? Currency(value) : '–')
            break
        default:
            alignment = 'left'
            formatFn = (value) => value?.toString() ?? ''
    }

    const filteredEntries = report.fundPlacementInventoryEntries.filter((entry) => {
        // Only include entries with at least one non-null value.
        return Object.values(entry[type]).some((value) => value != null && value != 0)
    })

    return (
        <Table size={'small'} sx={{ breakInside: 'avoid', pageBreakInside: 'avoid', borderCollapse: 'collapse' }}>
            <TableHead>
                <TableRow>
                    <TableCell>{t('pages-fund-reports-returns-table.rowFund')}</TableCell>
                    {Object.keys(report[type]).map((period) => (
                        <TableCell key={period} align={alignment}>
                            {t(fundInventoryReturnPeriodTMap[period])}
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow>
                    <TableCell>
                        <Box component="span" sx={{ fontWeight: 600 }}>
                            {t('pages-fund-reports-returns-table.rowPortfolio')}
                        </Box>
                    </TableCell>
                    {Object.entries(report[type]).map(([period, value]) => (
                        <TableCell key={period} align={alignment}>
                            {formatFn(value)}
                        </TableCell>
                    ))}
                </TableRow>
                {filteredEntries.map((entry) => (
                    <TableRow key={entry.fundId}>
                        <TableCell>
                            <Stack>
                                <Box component="span" sx={{ fontWeight: 600 }}>
                                    {entry.fundName}
                                </Box>
                                <Box component="span" sx={{ color: '#6F7271' }}>
                                    {entry.fundIsin}
                                </Box>
                            </Stack>
                        </TableCell>
                        {Object.entries(entry[type]).map(([period, value]) => (
                            <TableCell key={period} align={alignment}>
                                {formatFn(value)}
                            </TableCell>
                        ))}
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    )
}
