import { COMPANY_COLORS, PURPLE, SUNSET_ORANGE } from '#app/colors/colors'
import config from '#app/config'
import { getSupportArticleForPathname } from '#app/layers/PayloadSupportPane/utils/getSupportArticleForPathname'
import AddNewOrganization from '#app/pages/organizations/AddNewOrganization/AddNewOrganization'
import { switchOrganisation } from '#app/services/thunks/session'
import useCurrentDepositor from '#app/services/useCurrentDepositor'
import { isFixrateUser } from '#app/state/selectors'
import { useSelector } from '#app/state/useSelector'
import useSupportPane from '#services/useSupportPane'
import { useTracking } from '#services/useTracking'
import { OrganisationType } from '@fixrate/fixrate-query'
import { AssociationDto } from '@fixrate/fixrate-security'
import {
    Avatar,
    Backdrop,
    Box,
    Button,
    CircularProgress,
    Hidden,
    IconButton,
    Stack,
    Typography,
    useMediaQuery,
} from '@mui/material'
import classNames from 'classnames'
import * as React from 'react'
import { ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { BankCurrencySelection } from './components/CurrencySelection/BankCurrencySelection'
import { DepositorPortfolioSelection } from './components/PortfolioSelection/DepositorPortfolioSelection'
import { selectButtonStyles } from './components/MenuComponents'
import { OrganizationSelection } from './components/OrganizationSelection'
import { PartnerSelectCustomer } from './components/PartnerSelectCustomer'
import { PartnerSelectionMenu } from './components/PartnerSelectionMenu'
import { ProfileMenu } from './components/ProfileMenu'
import { NewBankAdButton } from './NewBankAdButton'
import styles from './PageHeader.module.scss'
import { SwitchOrgAnimation, SWITCH_ORG_DURATION } from '../SwitchOrgAnimation/SwitchOrgAnimation'

const ORGANISATION_TYPE_SORT_ORDER: OrganisationType[] = [
    'FIXRATE',
    'FUND_INTERMEDIARY',
    'PARTNER',
    'BANK',
    'DEPOSITOR',
]
export type OrganisationSelectorOption = {
    key: string
    organisationType: OrganisationType
    name: string
    iconColor: string
    iconName: string[]
    organisationId: string
    selected: boolean
}

export type MenuLink = {
    icon: string
    text: string
    link: string
    visible?: boolean
    warning?: boolean
    adminOnly?: boolean
    dataCy?: string
}

export function getIconText(name: string) {
    if (!name) {
        return null
    }

    return name
        .replace(' & ', ' ')
        .replace(/ AS$/, '')
        .replace('A/S', '')
        .split(' ')
        .map((i, index) => {
            if (index < 2) {
                return i.charAt(0)
            }
            return ''
        })
}

type Props = {
    title?: string | ReactElement
    subtitle?: string
    backToLink?: string
    useBackButton?: boolean
    children?: React.ReactNode
    shadow?: boolean
    icon?: string
}

export default function PageHeader({
    title,
    backToLink,
    subtitle,
    useBackButton = false,
    children,
    shadow = true,
    icon,
}: Props) {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()

    const supportPane = useSupportPane()
    const { trackEvent } = useTracking({ page: 'SupportPane' })
    const sessionId = useSelector((state) => state.session?.id)
    const fixrateUser = useSelector((state) => isFixrateUser(state))
    const authenticated = useSelector((state) => state.session.authenticated)
    const association = useSelector((state) => state.session?.association)
    const associations = useSelector((state) => state.session?.associations)
    const [registerOrganizationVisible, setRegisterOrganizationVisible] = useState(false)
    const organisationSelectorOptions = getOrganisationSelectorOptions(associations, association)
    const depositor = useCurrentDepositor()
    const isMobile = useMediaQuery('(max-width: 800px)')
    const [partnerSwitchAnimation, setPartnerSwitchAnimation] = useState(false)
    const [prevOrg, setPrevOrg] = useState<OrganisationSelectorOption | null>(null)
    const [newOrg, setNewOrg] = useState<OrganisationSelectorOption | null>()

    const darkMode = association?.organisationType === 'PARTNER'

    const selectedOrganisation = organisationSelectorOptions?.find((o) => o.selected)
    const partnerSession = selectedOrganisation?.organisationType === 'PARTNER'
    const bankSession = selectedOrganisation?.organisationType === 'BANK'
    const depositorSession = selectedOrganisation?.organisationType === 'DEPOSITOR'
    const isPartnerAtDepositor = depositor?.users
        ?.find((u) => u.id === sessionId)
        ?.roles?.find(
            (r) =>
                r === 'DEPOSITOR_PARTNER_ACCOUNTANT' ||
                r === 'DEPOSITOR_PARTNER_SUPPORT' ||
                r === 'DEPOSITOR_PARTNER_ADVISOR'
        )

    function navigateToPartner() {
        setPartnerSwitchAnimation(true)
        setNewOrg(organisationSelectorOptions.find((o) => o.organisationType === 'PARTNER'))
        setPrevOrg(organisationSelectorOptions.find((o) => o.selected))
        setTimeout(() => {
            const orgId = organisationSelectorOptions.find((o) => o.organisationType === 'PARTNER')?.organisationId
            dispatch(switchOrganisation('PARTNER', orgId, () => navigate('/customer')))
            setPartnerSwitchAnimation(false)
            setPrevOrg(null)
            setNewOrg(null)
        }, SWITCH_ORG_DURATION)
    }

    const openHelpMenu = () => {
        trackEvent({ event: 'open' })
        const relevantArticle = getSupportArticleForPathname(location.pathname)
        if (relevantArticle) {
            supportPane.show(relevantArticle)
        } else {
            supportPane.open()
        }
    }

    function backButtonClick() {
        if (!backToLink || useBackButton) {
            navigate(-1)
        } else {
            navigate(backToLink)
        }
    }

    const titleIcon = icon && <i className={classNames(icon, styles.titleIcon)} />

    const titleText = !!title && (
        <Typography
            component="h2"
            fontWeight={600}
            alignItems="center"
            sx={{ display: 'flex', alignItems: 'center' }}
            color={darkMode ? PURPLE[25] : PURPLE[900]}
        >
            {titleIcon}
            <Stack component={'span'}>
                {title}
                {subtitle && (
                    <Typography component="span" variant="caption">
                        {subtitle}
                    </Typography>
                )}
            </Stack>
        </Typography>
    )

    const backButton = (!!backToLink || useBackButton) && (
        <IconButton
            color="primary"
            sx={{ '& i': { color: darkMode ? 'white' : PURPLE[500] } }}
            onClick={backButtonClick}
            data-cy="backLink"
        >
            <i className={'ri-arrow-left-line'} />
        </IconButton>
    )

    return (
        <Stack
            component="header"
            width="100%"
            direction="row"
            justifyContent="space-between"
            id="page-header"
            alignItems="center"
            sx={{
                backgroundColor: darkMode ? PURPLE[900] : 'white',
                boxShadow: shadow ? '1px 1px 3px 0 rgba(0, 0, 0, 0.2);' : 'none',
                pl: {
                    xs: 3,
                    md: 6.4,
                },
                zIndex: 2,
            }}
        >
            <Stack direction="row" alignItems="center" spacing={1}>
                {backButton}
                <Stack direction={{ lg: 'row' }} alignItems={{ lg: 'center' }}>
                    {titleText}
                </Stack>
            </Stack>
            {children ? children : null}
            <div className={styles.helpOrgWrapper}>
                {authenticated && (
                    <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                        {isPartnerAtDepositor && (
                            <Hidden lgDown>
                                <Button
                                    onClick={navigateToPartner}
                                    size="small"
                                    sx={{ mr: 1 }}
                                    variant={'outlined'}
                                    startIcon={<i className="ri-logout-box-line" />}
                                >
                                    {t('common.goToPartnerPortal')}
                                </Button>
                            </Hidden>
                        )}
                        {!partnerSession && organisationSelectorOptions.length === 0 && (
                            <Button
                                onClick={() => setRegisterOrganizationVisible(true)}
                                variant={'contained'}
                                sx={{ fontSize: '1.4rem' }}
                                startIcon={<i className={'ri-add-circle-line'} />}
                            >
                                {t('common.registerCompany')}
                            </Button>
                        )}
                        <AddNewOrganization
                            visible={registerOrganizationVisible}
                            setShowNewOrganization={() => setRegisterOrganizationVisible(false)}
                        />
                        {bankSession && !isMobile && <NewBankAdButton />}
                        {partnerSession && (
                            <React.Fragment>
                                <PartnerSelectCustomer organisationOptions={organisationSelectorOptions} />
                                <PartnerSelectionMenu
                                    selectedOrganisation={selectedOrganisation}
                                    organisationOptions={organisationSelectorOptions}
                                />
                            </React.Fragment>
                        )}
                        {!partnerSession && (
                            <OrganizationSelection
                                selectedOrganisation={selectedOrganisation}
                                organisationOptions={organisationSelectorOptions}
                            />
                        )}
                        {bankSession && <BankCurrencySelection />}
                        {depositorSession && <DepositorPortfolioSelection />}
                        <ProfileMenu />
                    </Box>
                )}
                <Stack direction={'row'}>
                    <Button
                        color={darkMode ? 'darkMode' : 'secondary'}
                        sx={{
                            ...selectButtonStyles,
                            minWidth: 'auto',
                            borderLeftColor: darkMode ? 'rgba(250,250,250,0.3)' : undefined,
                        }}
                        onClick={openHelpMenu}
                        startIcon={
                            <i
                                style={{ fontSize: '1.8rem', color: darkMode ? SUNSET_ORANGE[500] : PURPLE[500] }}
                                className="ri-lifebuoy-line"
                            />
                        }
                    >
                        {!isMobile && t('common.help')}
                    </Button>
                    {fixrateUser && (
                        <Button
                            color={darkMode ? 'darkMode' : 'secondary'}
                            sx={{
                                ...selectButtonStyles,
                                minWidth: 'auto',
                                borderLeftColor: darkMode ? 'rgba(250,250,250,0.3)' : undefined,
                            }}
                            onClick={() => window.location.replace(config().homePageUrl)}
                            startIcon={
                                <i
                                    style={{ fontSize: '1.8rem', color: darkMode ? SUNSET_ORANGE[500] : PURPLE[500] }}
                                    className="ri-global-line"
                                />
                            }
                        >
                            {!isMobile && config().homePageUrl.substring(8)}
                        </Button>
                    )}
                </Stack>
            </div>
            {partnerSwitchAnimation && <SwitchOrgAnimation prevOrg={prevOrg} newOrg={newOrg} />}
        </Stack>
    )
}

export function getOrganisationSelectorOptions(
    associations: AssociationDto[],
    association: AssociationDto
): OrganisationSelectorOption[] {
    const allAssociations = associations ?? []
    return allAssociations
        .slice()
        .sort((a, b) => {
            const organisationSortOrder =
                ORGANISATION_TYPE_SORT_ORDER.indexOf(a?.organisationType) -
                ORGANISATION_TYPE_SORT_ORDER.indexOf(b?.organisationType)
            if (organisationSortOrder === 0) {
                return a.name.localeCompare(b.name)
            } else {
                return organisationSortOrder
            }
        })
        .map(
            (a, index): OrganisationSelectorOption => ({
                key: a.organisationType + ':' + a.organisation?.id,
                organisationType: a.organisationType,
                name: a.name,
                iconName: getIconText(a.name),
                organisationId: a.organisation?.id,
                selected: a.organisation.id === association?.organisation.id,
                iconColor: COMPANY_COLORS[index],
            })
        )
}
