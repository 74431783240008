import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
    name: 'ads',
    initialState: [],
    reducers: {
        updateAds(state, action) {
            return action.payload
        },
        updateAd(state, action) {
            const key = state.findIndex((ad) => ad.id === action.payload.id)
            return state.splice(key, 1, action.payload)
        },
    },
})

const { actions, reducer } = slice
export const { updateAds, updateAd } = actions
export default reducer
