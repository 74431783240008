import { DepositorDto, OrganisationalPersonDto, OrganisationalRole, PersonMatchDto } from '@fixrate/fixrate-query'
import { useTranslation } from 'react-i18next'
import { useSelector } from '#state/useSelector'
import { useCommand } from '#command'
import { DateOutput } from '#components'
import {
    TableHead,
    TableBody,
    Table,
    TableCell,
    TableRow,
    Box,
    Button,
    Alert,
    Card,
    CardContent,
    Grid,
    Typography,
} from '@mui/material'
import { organisationalRoleTMap } from '#services/enumTranslationKeyMapping'
import BrregFixer from '../components/BrregInformation/BrregFixer'
import BrregNewPerson from '../components/BrregInformation/BrregNewPerson'
import BrregModifyPerson from '../components/BrregInformation/BrregModifyPerson'
import { useState } from 'react'
import BrregComparisonLabel from '../components/BrregInformation/BrregComparisonLabel'

export enum MatchType {
    EXACT = 'EXACT',
    POSSIBLE = 'POSSIBLE',
    LIKELY = 'LIKELY',
    NONE = 'NONE',
}

export enum RolesMatchType {
    EXACT = 'EXACT',
    ROLES_DIFFER = 'ROLES_DIFFER',
    MISSING_IN_PROFF = 'MISSING_IN_PROFF',
    MISSING_IN_FIXRATE = 'MISSING_IN_FIXRATE',
}

interface Props {
    depositor: DepositorDto
}

export function getRoleMatch(proffRole: string | null, fixrateRole: string | null) {
    if (proffRole === fixrateRole) {
        return RolesMatchType.EXACT
    } else if (proffRole === null) {
        return RolesMatchType.MISSING_IN_PROFF
    } else if (fixrateRole === null) {
        return RolesMatchType.MISSING_IN_FIXRATE
    } else {
        return RolesMatchType.ROLES_DIFFER
    }
}

export const formatPerson = (name, birthDate) => {
    if (!name) return ''
    const formattedDate = birthDate ? `(${DateOutput.formatDate(birthDate)})` : ''
    return `${name} ${formattedDate}`
}

export default function BrregBoard({ depositor }: Props) {
    const { t } = useTranslation()
    const [newUserModalOpen, setNewUserModalOpen] = useState(false)
    const [modifyUserModalOpen, setModifyUserModalOpen] = useState(false)
    const [organisationalRole, setOrganisationalRole] = useState<OrganisationalRole | null>(null)
    const [newPerson, setNewPerson] = useState<{
        lastName: string
        firstName: string
        birthDate: string
        address: string
    }>(null)
    const [modifyPerson, setModifyPerson] = useState<OrganisationalPersonDto>(null)
    const [brregPerson, setBrregPerson] = useState<PersonMatchDto>(null)

    const { setPersonOrganisationalRoles: setPersonOrganisationalRolesCommand, acknowledgeImportedPeopleChanges } =
        useCommand()

    const companyPublicInformation = useSelector((state) =>
        state.companyPublicInformation.find((c) => c.nationalIdentity === depositor.nationalIdentity)
    )
    const boardPersonMatches = depositor.peopleChanges.boardPersonMatches
    const hasBoardChanges = depositor.peopleChanges.hasBoardChanges

    if (!companyPublicInformation) return null
    const brregBoard = companyPublicInformation.people

    const boardPersons: PersonMatchDto[] = boardPersonMatches
        .filter((p) => p.boardRole.fixrateRole !== null || p.boardRole.proffRole !== null)
        .sort((a, b) => {
            const isAChairman =
                a.boardRole.fixrateRole === 'BOARD_CHAIRMAN' || a.boardRole.proffRole === 'BOARD_CHAIRMAN'
            const isBChairman =
                b.boardRole.fixrateRole === 'BOARD_CHAIRMAN' || b.boardRole.proffRole === 'BOARD_CHAIRMAN'

            if (isAChairman && !isBChairman) {
                return -1
            } else if (!isAChairman && isBChairman) {
                return 1
            } else {
                const nameA = a.personName ?? a.proffName ?? ''
                const nameB = b.personName ?? b.proffName ?? ''
                return nameA.localeCompare(nameB, depositor.language ? depositor.language : 'nb', {
                    sensitivity: 'base',
                })
            }
        })

    const ceoPersons: PersonMatchDto[] = boardPersonMatches
        .map((match) => match)
        .filter((p) => p.managementRole.fixrateRole === 'CEO' || p.managementRole.proffRole === 'CEO')

    const onAddPerson = async (person: PersonMatchDto, newRole: OrganisationalRole[]) => {
        if (person.personId) {
            const fixratePersonExistingRoles: OrganisationalRole[] = depositor.people.find(
                (p) => p.personId === person.personId
            ).organisationalRoles
            const updatedRoles: OrganisationalRole[] = [...fixratePersonExistingRoles, ...newRole]

            const { waitForCommand } = await setPersonOrganisationalRolesCommand(
                depositor.id,
                person.personId,
                updatedRoles
            )
            const success = await waitForCommand()
            if (!success) {
                console.error('Failed to update roles', updatedRoles)
            }
        } else {
            const nameParts = person.proffName.split(' ')
            const initialLastName = nameParts.pop()
            const initialFirstName = nameParts.join(' ')
            const brregPerson = brregBoard.find(
                (p) =>
                    p.name.includes(initialFirstName) &&
                    p.name.includes(initialLastName) &&
                    p.birthDate === person.birthDate
            )
            const address = `${brregPerson?.address}\n${brregPerson?.postCode} ${brregPerson?.city}`

            setOrganisationalRole(newRole[0])
            setNewPerson({
                lastName: initialLastName,
                firstName: initialFirstName,
                birthDate: person.birthDate,
                address,
            })
            setNewUserModalOpen(true)
        }
    }

    const onRemovePerson = async (personId, role) => {
        const fixratePerson = depositor.people.find((p) => p.personId === personId)
        const updatedRoles = fixratePerson.organisationalRoles.filter((r) => r !== role)

        const { waitForCommand } = await setPersonOrganisationalRolesCommand(
            depositor.id,
            fixratePerson.personId,
            updatedRoles
        )
        const success = await waitForCommand()
        if (!success) {
            console.error('Failed to update roles', updatedRoles)
        }
    }

    const onSwitchRole = async (personId, roleToRemove, roleToAdd) => {
        const fixratePerson = depositor.people.find((p) => p.personId === personId)
        let updatedRoles: OrganisationalRole[] = fixratePerson.organisationalRoles.filter((r) => r !== roleToRemove)
        updatedRoles = [...updatedRoles, roleToAdd]

        const { waitForCommand } = await setPersonOrganisationalRolesCommand(
            depositor.id,
            fixratePerson.personId,
            updatedRoles
        )
        const success = await waitForCommand()
        if (!success) {
            console.error('Failed to update roles', updatedRoles)
        }
    }

    const onModifyPerson = async (person) => {
        const fixratePerson = depositor.people.find((p) => p.personId === person.personId)
        setModifyPerson(fixratePerson)
        setBrregPerson(person)
        setModifyUserModalOpen(true)
    }

    const confirmChanges = async () => {
        const changedRoles = ['ROLES', 'MANAGEMENT']
        for (const role of changedRoles) {
            try {
                await acknowledgeImportedPeopleChanges(depositor.id, role)
                console.log(`Changes for ${role} acknowledged`)
            } catch (err) {
                console.error(`Failed to acknowledge changes for ${role}`, err)
            }
        }
    }

    const renderPersonRow = ({
        person,
        proffRole,
        fixrateRole,
        t,
        organisationalRoleTMap,
        formatPerson,
        onAddPerson,
        onRemovePerson,
        onModifyPerson,
        onSwitchRole,
    }) => {
        const fixratePerson = depositor.people.find((p) => p.personId === person.personId)
        return (
            <TableRow key={person.personId + person.birthDate}>
                <TableCell>
                    {(fixrateRole ?? proffRole) ? t(organisationalRoleTMap[fixrateRole ?? proffRole]) : ''}
                </TableCell>
                <TableCell>{formatPerson(person.proffName, person.birthDate)}</TableCell>
                <TableCell>
                    <BrregFixer
                        fixratePerson={fixratePerson}
                        personMatch={person}
                        proffRole={proffRole}
                        fixrateRole={fixrateRole}
                        onAddPerson={() => onAddPerson(person, [proffRole] as OrganisationalRole[])}
                        onRemovePerson={() => onRemovePerson(person.personId, fixrateRole)}
                        onModifyPerson={() => onModifyPerson(person)}
                        onSwitchRole={() => onSwitchRole(person.personId, fixrateRole, proffRole)}
                    />
                </TableCell>
                <TableCell>
                    <BrregComparisonLabel person={person} proffRole={proffRole} fixrateRole={fixrateRole} />
                </TableCell>
            </TableRow>
        )
    }

    const renderPersonCard = ({
        person,
        proffRole,
        fixrateRole,
        t,
        organisationalRoleTMap,
        formatPerson,
        onAddPerson,
        onRemovePerson,
        onModifyPerson,
        onSwitchRole,
    }) => {
        const fixratePerson = depositor.people.find((p) => p.personId === person.personId)
        return (
            <Card key={person.personId + person.birthDate} sx={{ marginBottom: '1rem' }}>
                <CardContent>
                    <Grid container spacing={0.5} direction="column">
                        <Grid item>
                            <Typography variant="body2">
                                {t(organisationalRoleTMap[fixrateRole ?? proffRole])}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="h6">{formatPerson(person.proffName, person.birthDate)}</Typography>
                        </Grid>
                        <Grid item>
                            {person.matchType !== 'EXACT' && (
                                <Box>
                                    <BrregFixer
                                        fixratePerson={fixratePerson}
                                        personMatch={person}
                                        proffRole={proffRole}
                                        fixrateRole={fixrateRole}
                                        onAddPerson={() => onAddPerson(person, [proffRole] as OrganisationalRole[])}
                                        onRemovePerson={() => onRemovePerson(person.personId, fixrateRole)}
                                        onModifyPerson={() => onModifyPerson(person)}
                                        onSwitchRole={() => onSwitchRole(person.personId, fixrateRole, proffRole)}
                                    />
                                </Box>
                            )}
                        </Grid>
                        <Grid item>
                            <BrregComparisonLabel person={person} proffRole={proffRole} fixrateRole={fixrateRole} />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        )
    }

    return (
        <>
            <Box sx={{ padding: '2rem 3rem', backgroundColor: 'white' }}>
                <Grid container spacing={2}>
                    {/* This grid is for mobile view */}
                    <Grid item xs={12} lg={12} sx={{ display: { xs: 'block', lg: 'none' } }}>
                        {boardPersons.map((person) => {
                            const proffRole = person.boardRole.proffRole
                            const fixrateRole = person.boardRole.fixrateRole
                            return renderPersonCard({
                                person,
                                proffRole,
                                fixrateRole,
                                t,
                                organisationalRoleTMap,
                                formatPerson,
                                onAddPerson,
                                onRemovePerson,
                                onModifyPerson,
                                onSwitchRole,
                            })
                        })}
                        {ceoPersons.map((person) => {
                            const proffRole = person.managementRole.proffRole
                            const fixrateRole = person.managementRole.fixrateRole
                            return renderPersonCard({
                                person,
                                proffRole,
                                fixrateRole,
                                t,
                                organisationalRoleTMap,
                                formatPerson,
                                onAddPerson,
                                onRemovePerson,
                                onModifyPerson,
                                onSwitchRole,
                            })
                        })}
                    </Grid>
                    {/* This grid is for desktop view */}
                    <Grid item xs={12} lg={12} sx={{ display: { xs: 'none', lg: 'block' } }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>{t('pages-organizations.boardTableHeaderRole')}</TableCell>
                                    <TableCell>{t('pages-organizations.boardTableHeaderBrreg')}</TableCell>
                                    <TableCell>{t('pages-organizations.boardTableHeaderFixrate')}</TableCell>
                                    <TableCell>{t('pages-organizations.boardTableHeaderStatus')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {boardPersons.map((person) => {
                                    const proffRole = person.boardRole.proffRole
                                    const fixrateRole = person.boardRole.fixrateRole
                                    return renderPersonRow({
                                        person,
                                        proffRole,
                                        fixrateRole,
                                        t,
                                        organisationalRoleTMap,
                                        formatPerson,
                                        onAddPerson,
                                        onRemovePerson,
                                        onModifyPerson,
                                        onSwitchRole,
                                    })
                                })}
                                {ceoPersons.map((person) => {
                                    const proffRole = person.managementRole.proffRole
                                    const fixrateRole = person.managementRole.fixrateRole
                                    return renderPersonRow({
                                        person,
                                        proffRole,
                                        fixrateRole,
                                        t,
                                        organisationalRoleTMap,
                                        formatPerson,
                                        onAddPerson,
                                        onRemovePerson,
                                        onModifyPerson,
                                        onSwitchRole,
                                    })
                                })}
                            </TableBody>
                        </Table>
                    </Grid>
                </Grid>
                {hasBoardChanges && (
                    <>
                        <Alert severity="info" sx={{ fontSize: '1.2rem', mt: '2rem' }}>
                            {t('pages-organizations.confirmBoardChangesDesc')}
                        </Alert>

                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: '1rem' }}>
                            <Button variant="outlined" onClick={confirmChanges} data-cy="confirm">
                                {t('pages-organizations.confirmBoardChanges')}
                            </Button>
                        </Box>
                    </>
                )}
            </Box>
            {newUserModalOpen && (
                <BrregNewPerson
                    securityRoles={[]}
                    organisationalRoles={organisationalRole ? [organisationalRole] : []}
                    close={() => {
                        setNewUserModalOpen(false)
                    }}
                    initialFirstName={newPerson.firstName}
                    initialLastName={newPerson.lastName}
                    initialBirthDate={newPerson.birthDate}
                    initialAddress={newPerson.address}
                />
            )}
            {modifyUserModalOpen && (
                <BrregModifyPerson
                    close={() => setModifyUserModalOpen(false)}
                    person={brregPerson}
                    fixratePerson={modifyPerson}
                />
            )}
        </>
    )
}
