import AsyncButton from '#app/components/Button/AsyncButton'
import { LanguageSelect } from '#app/components/LanguageSelect/LanguageSelect'
import { useCommand } from '#app/services/beta'
import { AvailableLanguage, supportedLanguages } from '#app/services/i18n'
import useCurrentDepositor from '#app/services/useCurrentDepositor'
import { useSelector } from '#app/state/useSelector'
import { nameWithRoles } from '#app/utilities/nameWithRoles'
import InviteDataModal from '#pages/organizations/Authorization/InviteDataModal'
import { PersonValidator } from '#services/PersonValidator'
import { DateAsString } from '@fixrate/fixrate-query'
import {
    Box,
    Button,
    Checkbox,
    Divider,
    FormControlLabel,
    InputLabel,
    List,
    ListItem,
    MenuItem,
    Select,
    Stack,
    Typography,
} from '@mui/material'
import { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Proxy } from '../../OrganizationDetail/UsersAndRoles/views/PowerOfAttorney/PowerOfAttorney'
import { Signatories } from '../../OrganizationDetail/UsersAndRoles/views/PowerOfAttorney/Signatories'
import { Attorney, getPrincipalCandidates } from '../Authorization'
import RequestAuthorizationContainer from './RequestAuthorizationContainer'

export type PrincipalCandidate = {
    personId: string
    birthDate: DateAsString
    email: string
    name: string
}

type Props = {
    attorney: Attorney | Proxy
    principalPersonIds: string[]
    setPrincipalPersonIds: (ids: string[]) => void
    inline?: boolean
    onClose?: () => void
}
export default function RequestAuthorization({
    attorney,
    principalPersonIds,
    setPrincipalPersonIds,
    inline,
    onClose,
}: Props) {
    const { t } = useTranslation()
    const { generateAuthorizationDocument: generateAuthorizationDocumentCommand } = useCommand()

    const [birthDateModal, setBirthDateModal] = useState(null)
    const [showError, setShowError] = useState(false)
    const depositor = useCurrentDepositor()
    const principalCandidates: PrincipalCandidate[] = depositor ? getPrincipalCandidates(depositor) : []
    const [isWorking, setWorking] = useState(false)

    const [notifiedUserId, setNotifiedUserId] = useState('nobody')
    const [language, setLanguage] = useState(depositor?.language)
    const organisation = useSelector((state) => state.session.association.organisation)

    if (!depositor || !attorney) {
        return null
    }

    let error = null
    if (principalCandidates.length === 0) {
        error = t('pages-organizations.noPrincipalCandidates')
    } else if (principalPersonIds.length === 0) {
        error = t('pages-organizations.selectAtLeastOnePrincipal')
    }

    const modal = birthDateModal && (
        <InviteDataModal depositorId={depositor.id} personId={birthDateModal} onClose={() => setBirthDateModal(null)} />
    )

    const attorneyPersonId = attorney?.personId
    const userId = depositor?.people.find((p) => attorneyPersonId === p.personId)?.associatedUserId || attorney?.id
    const inviteId = depositor?.people.find((p) => attorneyPersonId === p.personId)?.associatedInviteId

    const selectedPrincipalMissingInfo = principalCandidates
        .filter((p) => principalPersonIds.some((id) => id === p.personId))
        .some((principal) => PersonValidator.isCompleteForAuthorizationCreation(principal) === false)

    async function generateAuthorizationDocument() {
        try {
            setWorking(true)
            const { waitForCommand } = await generateAuthorizationDocumentCommand(
                userId,
                inviteId,
                principalPersonIds,
                organisation.id,
                notifiedUserId === 'nobody' ? null : notifiedUserId,
                language
            )

            await waitForCommand()
            setWorking(false)
            if (onClose) {
                onClose()
            }
        } catch (err) {
            console.error(err)
            setWorking(false)
        }
    }

    const principalInputs = principalCandidates.map((principalCandidate, index) => {
        const isComplete = PersonValidator.isCompleteForAuthorizationCreation(principalCandidate)
        const roleAndName = nameWithRoles(
            depositor.people.find((p) => principalCandidate.personId === p.personId),
            depositor,
            t
        )
        return (
            <Fragment key={principalCandidate.personId}>
                <ListItem sx={{ justifyContent: 'space-between', py: 2 }} data-cy="authorizationSigner">
                    <FormControlLabel
                        label={
                            <Stack ml={0.5}>
                                <Typography>{roleAndName.name}</Typography>
                                <Typography variant="body2">{roleAndName.orgRoles}</Typography>
                            </Stack>
                        }
                        sx={{
                            maxWidth: !isComplete ? 'calc(100% - 20rem)' : 'none',
                        }}
                        control={
                            <Checkbox
                                edge="start"
                                checked={principalPersonIds.includes(principalCandidate.personId)}
                                id={principalCandidate.personId}
                                className="authorizationSignerCheckbox"
                                onChange={(e) => {
                                    setPrincipalPersonIds(
                                        e.target.checked
                                            ? [...principalPersonIds, principalCandidate.personId]
                                            : principalPersonIds.filter((id) => id !== principalCandidate.personId)
                                    )
                                }}
                            />
                        }
                    />
                    {!isComplete && (
                        <Button
                            sx={{ ml: 2, whiteSpace: 'nowrap' }}
                            size="small"
                            variant="outlined"
                            color="error"
                            data-cy="editPersonLink"
                            onClick={() => setBirthDateModal(principalCandidate.personId)}
                        >
                            {t('pages-organizations.missingInformation')}
                        </Button>
                    )}
                </ListItem>
                {index < principalCandidates.length - 1 && <Divider component="li" />}
            </Fragment>
        )
    })

    function getAuthorizationLanguages(regulatoryRegion: string) {
        const languageMap: Record<string, Partial<typeof supportedLanguages>> = {
            NO: { en: supportedLanguages.en, nb: supportedLanguages.nb },
            SE: { en: supportedLanguages.en, sv: supportedLanguages.sv },
        }

        return languageMap[regulatoryRegion] || supportedLanguages
    }

    return (
        <RequestAuthorizationContainer onClose={onClose} modal={inline}>
            <Fragment>
                <Stack spacing={3}>
                    {!inline && (
                        <Stack spacing={1}>
                            <Typography>
                                {t('pages-organizations.userNeedsAuthorization', {
                                    userName: `${attorney?.firstName} ${attorney?.lastName}`,
                                })}
                            </Typography>
                            <Typography>{t('pages-organizations.selectPrincipalsMessagePart1')}</Typography>
                            <Typography>{t('pages-organizations.selectPrincipalsMessagePart2')}</Typography>
                        </Stack>
                    )}
                    <Signatories nationalIdentity={depositor.nationalIdentity} />
                    <Stack>
                        <InputLabel sx={{ mb: 0 }}>{t('pages-organizations.selectPrincipals')}</InputLabel>
                        <List disablePadding>{principalInputs}</List>
                        <p className="field-error-message">
                            {error}
                            {principalCandidates.length === 0 && (
                                <>
                                    <br />
                                    <Link to={`/organizations/${depositor.id}/users/power-of-attorney`}>
                                        {t('pages-organizations.addPrincipalCandidates')}
                                    </Link>
                                </>
                            )}
                        </p>
                        {selectedPrincipalMissingInfo && showError && (
                            <p className="field-error-message">{t('pages-organizations.principalHasWarnings')}</p>
                        )}
                    </Stack>
                    <Stack>
                        <InputLabel>{t('pages-organizations.selectNotifiedUser')}</InputLabel>
                        <Select value={notifiedUserId} onChange={(e) => setNotifiedUserId(e.target.value)}>
                            <MenuItem value={'nobody'}>{t('common.nobody')}</MenuItem>
                            {depositor?.users.map((user) => (
                                <MenuItem
                                    key={user.id}
                                    value={user.id}
                                >{`${user.firstName} ${user.lastName}`}</MenuItem>
                            ))}
                        </Select>
                    </Stack>
                    <Stack>
                        <InputLabel>{t('pages-organizations.selectLanguage')}</InputLabel>
                        <LanguageSelect
                            language={language as AvailableLanguage}
                            setLanguage={setLanguage}
                            supportedLanguages={getAuthorizationLanguages(depositor.regulatoryRegion)}
                        />
                    </Stack>
                    <Box>
                        <AsyncButton
                            disabled={isWorking || error}
                            id="createAuthorizationDocumentButton"
                            loading={isWorking}
                            onClick={() => {
                                if (!selectedPrincipalMissingInfo) {
                                    generateAuthorizationDocument()
                                } else {
                                    setShowError(true)
                                }
                            }}
                        >
                            {t('pages-organizations.createAuthorization')}
                        </AsyncButton>
                    </Box>
                </Stack>
                {modal}
            </Fragment>
        </RequestAuthorizationContainer>
    )
}
