import { DepositorDto, DepositorWarning, DepositorWarningType, PartnerCustomerDto } from '@fixrate/fixrate-query'
import { useMemo } from 'react'

export interface OrganizationWarnings {
    customerDeclaration: DepositorWarning[]
    terms: DepositorWarning[]
    accounts: DepositorWarning[]
    users: DepositorWarning[]
    organisationalRoles: DepositorWarning[]
    integrations: DepositorWarning[]
    onboarding: DepositorWarning[]
}

type WarningCategory =
    | 'ORGANISATIONAL_ROLES'
    | 'CUSTOMER_DECLARATION'
    | 'TERMS'
    | 'SETTLEMENT_ACCOUNTS'
    | 'USERS'
    | 'ONBOARDING'

type WarningMapCategoryType = {
    [key in DepositorWarningType]: WarningCategory
}

const warningMap: WarningMapCategoryType = {
    MISSING_SECURITY_ROLES: 'ORGANISATIONAL_ROLES',
    MISSING_ORGANISATIONAL_ROLES: 'ORGANISATIONAL_ROLES',

    MISSING_SETTLEMENT_ACCOUNT: 'SETTLEMENT_ACCOUNTS',
    TERMS_NOT_ACCEPTED: 'TERMS',

    MISSING_OWNERS_DECLARATION: 'CUSTOMER_DECLARATION',
    CUSTOMER_DECLARATION_PERSON_GALLERY_CHANGED: 'CUSTOMER_DECLARATION',
    MISSING_CUSTOMER_DECLARATION: 'CUSTOMER_DECLARATION',
    NEED_INFO_FOR_CUSTOMER_DECLARATION: 'CUSTOMER_DECLARATION',

    MISSING_BENEFICIAL_OWNERS: 'USERS',
    AUTHORIZATION_DOCUMENT_NOT_SIGNED: 'USERS',
    AUTHORIZATION_DOCUMENT_MISSING: 'USERS',
    PERSON_NEEDS_INVITE: 'USERS',
    PERSON_NEEDS_INFO_FOR_INVITE: 'USERS',
    AUTHORIZATION_SIGNER_NEEDS_INFO_FOR_INVITE: 'USERS',
    INVITE_HAS_EXPIRED: 'USERS',
    INVITE_IS_NOT_SENT: 'USERS',
    MISSING_AUTHORIZATION_DOCUMENT: 'USERS',
    MISSING_IDENTIFICATION_DOCUMENT: 'USERS',
    AUTHORIZATION_SIGNER_NEEDS_INVITE: 'USERS',
    IMPORTED_PEOPLE_CHANGES_NOT_ACKNOWLEDGED: 'USERS',
    MISSING_AUTHORIZATION_SIGNERS: 'USERS',

    SECURITY_ROLES_NOT_CONFIRMED: 'ONBOARDING',
    ORGANISATIONAL_ROLES_NOT_CONFIRMED: 'ONBOARDING',
    BENEFICIAL_OWNERS_NOT_CONFIRMED: 'ONBOARDING',
}

export default function useOrganizationWarnings(depositor: DepositorDto | PartnerCustomerDto): OrganizationWarnings {
    const DepositorWarningCategory = (warning: DepositorWarningType): string => {
        return warningMap[warning]
    }

    return useMemo(() => {
        if (!depositor) {
            return {
                customerDeclaration: [],
                terms: [],
                accounts: [],
                users: [],
                organisationalRoles: [],
                integrations: [],
                onboarding: [],
            }
        }

        const getWarnings = (type: WarningCategory) => {
            return depositor.warnings.filter((warning) => DepositorWarningCategory(warning.type) === type)
        }

        return {
            customerDeclaration: getWarnings('CUSTOMER_DECLARATION'),
            terms: getWarnings('TERMS'),
            accounts: getWarnings('SETTLEMENT_ACCOUNTS'),
            users: getWarnings('USERS'),
            organisationalRoles: getWarnings('ORGANISATIONAL_ROLES'),
            integrations: [],
            onboarding: getWarnings('ONBOARDING'),
        }
    }, [depositor])
}
