import { bring } from '../network/external-api-connection'
import command from '../command/command'
import * as depositorActions from '#state/reducers/depositor'
import * as depositorsActions from '#state/reducers/depositors'
import { setPostalCodeSearchData } from '#state/reducers/postalCodeSearchData'

export const setCustomerDeclarationValue = (depositorId, path, value) => (dispatch) => {
    dispatch(depositorActions.setCustomerDeclarationValue({ depositorId, path, value }))
    dispatch(depositorsActions.setCustomerDeclarationValue({ depositorId, path, value }))
    return command(dispatch).setCustomerDeclarationValue(depositorId, path, value)
}

export const createCustomerDeclarationDraft = (depositorId) => (dispatch) => {
    return command(dispatch).createCustomerDeclarationDraft(depositorId)
}

export const addCustomerDeclarationValue = (depositorId, path, value) => (dispatch) => {
    dispatch(depositorActions.setCustomerDeclarationValue({ depositorId, path, value }))
    dispatch(depositorsActions.setCustomerDeclarationValue({ depositorId, path, value }))
}

export const deleteCustomerDeclarationValues = (depositorId, paths) => (dispatch) => {
    dispatch(depositorActions.deleteCustomerDeclarationValues({ depositorId, paths }))
    dispatch(depositorsActions.deleteCustomerDeclarationValues({ depositorId, paths }))
    return command(dispatch).deleteCustomerDeclarationValues(depositorId, paths)
}

export const setPinCodeAuthorization = (organisationId, organisationType, allow) => (dispatch) => {
    return command(dispatch).setPinCodeAuthorization(organisationId, organisationType, allow)
}

export const getPostalCode = (postalCode, countryCode) => (dispatch) => {
    let pattern
    switch (countryCode) {
        case 'NO':
            pattern = /\d{4}/
            break
        case 'SE':
            pattern = /\d{3}\s?\d{2}/
            break
    }
    if (!postalCode || !postalCode.match(pattern)) {
        dispatch(
            setPostalCodeSearchData({
                valid: false,
                result: undefined,
            })
        )
        return
    }

    return bring(dispatch)
        .getPostalCode(postalCode, countryCode)
        .then((result) => dispatch(setPostalCodeSearchData(result)))
}
