import styles from './ButtonRow.module.css'
import classNames from 'classnames'

type Props = {
    className?: string
    children: React.ReactNode
    center?: boolean
    align?: 'left' | 'center' | 'right'
}

export default function ButtonRow({ className, children = null, center = false, align }: Props) {
    return (
        <div
            className={classNames(
                styles.body,
                className,
                { [styles.center]: center || align === 'center' },
                { [styles.left]: align === 'left' },
                { [styles.right]: align === 'right' }
            )}
        >
            {children}
        </div>
    )
}
