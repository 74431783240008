import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
    name: 'documents',
    initialState: [],
    reducers: {
        setDocuments(state, action) {
            return action.payload
        },
        setDocument(state, action) {
            state[action?.payload.id] = action.payload
        },
        removeDocument(state, action) {
            if (state[action?.payload]) {
                // Document found, remove it
                console.log(`Document ${action.payload} found, remove it`)
                delete state[action.payload]
            }
        },
    },
})

const { actions, reducer } = slice
export const { setDocuments, setDocument, removeDocument } = actions
export default reducer
