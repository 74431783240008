import React from 'react'
import { Avatar } from '@mui/material'
import { COMPANY_COLORS } from '#app/colors/colors'

interface AvatarContentProps {
    hasDifferentOrgTypes: boolean
    hasPartner: boolean
    name: string
    type: string
    colorIndex?: number
    iconColor?: string
    isList?: boolean
}

const getInitials = (name: string): string => {
    return name
        .replace(' & ', ' ')
        .replace(/ AS$/, '')
        .replace('A/S', '')
        .split(' ')
        .map((word, index) => (index < 2 ? word.charAt(0) : ''))
        .join('')
}

const AvatarContent: React.FC<AvatarContentProps> = ({
    hasDifferentOrgTypes,
    hasPartner,
    name,
    type,
    colorIndex,
    iconColor,
    isList,
}) => {
    const commonAvatarStyles = {
        width: { md: '10rem' },
        height: { md: '10rem' },
        backgroundColor: colorIndex ? COMPANY_COLORS[colorIndex] : iconColor,
        fontSize: { xs: '2rem', md: '4.5rem' },
        fontWeight: '200',
    }

    const listAvatarStyles = {
        fontSize: '1.4rem!important',
        width: { md: '3.6rem' },
        height: { md: '3.6rem' },
        fontWeight: '600',
    }

    const iconMapping: { [key: string]: JSX.Element } = {
        BANK: <i className="ri-bank-line" />,
        DEPOSITOR: <i className="ri-building-line" />,
        FUND_INTERMEDIARY: <i className="ri-funds-line" />,
    }

    if (hasDifferentOrgTypes && !hasPartner) {
        const icon = iconMapping[type]
        const avatarStyle = isList ? { ...commonAvatarStyles, ...listAvatarStyles } : commonAvatarStyles
        return <Avatar sx={avatarStyle}>{icon}</Avatar>
    }

    if (isList) {
        return <Avatar sx={{ ...commonAvatarStyles, ...listAvatarStyles }}>{getInitials(name)}</Avatar>
    }

    return (
        <Avatar
            sx={{
                ...commonAvatarStyles,
                fontSize: { xs: '1.6rem', md: '3rem' },
                fontWeight: '600',
            }}
        >
            {getInitials(name)}
        </Avatar>
    )
}

export default AvatarContent
