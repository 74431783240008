import { PURPLE } from '#app/colors/colors'
import { SupportCategoryNameType } from '#app/layers/PayloadSupportPane/utils/supportNameMappings'
import { useTranslation } from '#components/i18n'
import useSupportPane from '#services/useSupportPane'
import { useSelector } from '#state/useSelector'
import { Box, IconButton, SxProps } from '@mui/material'
import { useTracking } from 'react-tracking'

function HelpIcon({
    supportCommonName,
    inline = false,
    helpContextPage = undefined,
    inlineText = undefined,
    sx = undefined,
}: {
    supportCommonName: SupportCategoryNameType
    inline?: boolean
    helpContextPage?: string
    inlineText?: string
    sx?: SxProps
}) {
    const session = useSelector((state) => state.session)
    const { trackEvent } = useTracking({ page: helpContextPage, session })
    const supportPane = useSupportPane()
    const { t } = useTranslation('components-HelpIcon')

    const showSupportArticle = () => {
        trackEvent({ event: 'open-context-help' })
        supportPane.show(supportCommonName)
    }

    if (inline) {
        return (
            <Box
                component={'span'}
                sx={{ cursor: 'pointer', color: PURPLE[500], fontWeight: '600', ...sx }}
                onClick={showSupportArticle}
            >
                {!inlineText && t('readMore')}
                {inlineText && inlineText}
            </Box>
        )
    } else {
        return (
            <IconButton
                color="primary"
                onClick={showSupportArticle}
                sx={{ fontSize: '2.6rem', justifyContent: 'center', display: 'flex', ...sx }}
            >
                <i className="ri-question-line" style={{ padding: 0 }} />
            </IconButton>
        )
    }
}

export default HelpIcon
