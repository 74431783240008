import { canBuyFundsSelector } from '#app/state/selectors'
import { useSelector } from '#app/state/useSelector'

type FundStatus = {
    allFundDocumentsSigned: boolean
    canBuyFunds: boolean
    canEnableFund: boolean
    inFundOnboardingQueue: boolean
}
export default function useFundStatus(): FundStatus {
    const canBuyFunds = useSelector((state) => canBuyFundsSelector(state))
    const permissions = useSelector((state) => state.session?.association?.permissions)
    const canEnableFund =
        permissions?.includes('GENERAL__ORGANISATION__CONFIG') ||
        permissions?.includes('DEPOSITOR__ACCOUNT_AGREEMENT__SIGN')
    const fundContractDocumentId = useSelector(
        (state) => state.depositor?.contracts?.filter((c) => c.documentType === 'FUND_CONTRACT')?.[0]?.documentId
    )
    const fundContractAddendumDocumentId = useSelector(
        (state) => state.depositor?.contracts?.filter((c) => c.documentType === 'FUND_CONTRACT_ADDENDUM')?.[0]?.documentId
    )
    const fundGeneralTermsDocumentId = useSelector(
        (state) =>
            state.depositor?.contracts?.filter((c) => c.documentType === 'FUND_GENERAL_TERMS_AND_CONDITIONS')?.[0]
                ?.documentId
    )

    const fundContract = useSelector((state) => state.documents[fundContractDocumentId])
    const fundContractAddendum = useSelector((state) => state.documents[fundContractAddendumDocumentId])
    const fundGeneralTerms = useSelector((state) => state.documents[fundGeneralTermsDocumentId])
    const fundDocuments = [fundContract, fundGeneralTerms, fundContractAddendum].filter((d) => !!d)
    const allFundDocumentsSigned = fundDocuments.length > 0 && fundDocuments.every((d) => d?.signedByAll)
    const inFundOnboardingQueue = !canBuyFunds && allFundDocumentsSigned

    return {
        allFundDocumentsSigned,
        canBuyFunds,
        canEnableFund,
        inFundOnboardingQueue,
    }
}
