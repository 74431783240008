import NameOutput from '#app/pages/organizations/OrganizationDetail/UsersAndRoles/components/NameOutput'
import { DepositorDto, OrganisationalPersonDto } from '@fixrate/fixrate-query'
import { TFunction } from 'react-i18next'

type NameWithRoles = {
    name: string
    fixrateRoles: string
    orgRoles: string
    allRoles: string
    nameWithOrgRoles: string
    nameWithFixrateRoles: string
    nameWithAllRoles: string
}

export const nameWithRoles = (
    person: OrganisationalPersonDto,
    depositor: DepositorDto,
    t: TFunction
): NameWithRoles => {
    const relevantRoles = person.organisationalRoles
        .map((role) => role)
        .filter(
            (role) =>
                role === 'BOARD_CHAIRMAN' ||
                role === 'BOARD_MEMBER' ||
                role === 'CEO' ||
                role === 'BENEFICIAL_OWNER' ||
                'BOARD_DEPUTY_MEMBER'
        )
    const fixrateRoles = person.securityRoles.map((role) => role).map((role) => t(`enum-SecurityRole.${role}`)) || []
    const orgRoles = relevantRoles.map((role) => role).map((role) => t(`enum-OrganisationalRole.${role}`)) || []
    const allRoles = fixrateRoles.concat(orgRoles)
    const rolesString = allRoles.length > 0 ? allRoles.map((role) => role).join(', ') : ''
    return {
        name: NameOutput.withBirthYearOnDuplicates(person, depositor),
        fixrateRoles: fixrateRoles.length > 0 ? fixrateRoles.map((role) => role).join(', ') : '',
        orgRoles: orgRoles.length > 0 ? orgRoles.map((role) => role).join(', ') : '',
        allRoles: rolesString,
        nameWithOrgRoles:
            orgRoles.length > 0
                ? NameOutput.withBirthYearOnDuplicates(person, depositor) +
                  ' (' +
                  orgRoles.map((role) => role).join(', ') +
                  ')'
                : NameOutput.withBirthYearOnDuplicates(person, depositor),
        nameWithFixrateRoles:
            fixrateRoles.length > 0
                ? NameOutput.withBirthYearOnDuplicates(person, depositor) +
                  ' (' +
                  fixrateRoles.map((role) => role).join(', ') +
                  ')'
                : NameOutput.withBirthYearOnDuplicates(person, depositor),
        nameWithAllRoles: rolesString
            ? NameOutput.withBirthYearOnDuplicates(person, depositor) + ' (' + rolesString + ')'
            : NameOutput.withBirthYearOnDuplicates(person, depositor),
    }
}
