import AsyncButton from '#app/components/Button/AsyncButton'
import CountrySelect from '#app/components/CountrySelect/CountrySelect'
import { useCommand } from '#command'
import Paper from '#components/Paper'
import { SignableDocument } from '#components/SignableDocument/SignableDocument'
import { getPostalCode } from '#services/thunks/depositor'
import { startElectronicSignature } from '#services/thunks/documents'
import { useFieldState, useStableValidator } from '@fixrate/fieldstate'
import { validateNorwegianPostcode, validateSwedishPostcode } from '#services/validateFields'
import { removePostalCodeSearchData } from '#state/reducers/postalCodeSearchData'
import * as selectors from '#state/selectors'
import { useSelector } from '#state/useSelector'
import { Box, InputLabel, List, ListItem, ListItemText, Stack, TextField, Typography } from '@mui/material'
import { parsePhoneNumber } from 'awesome-phonenumber'
import classNames from 'classnames'
import countryList from 'country-list'
import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import styles from './IdentificationDocument.module.scss'
import styles2 from './Profile.module.scss'

export default function IdentificationDocument({
    inline = false,
    signContext,
    showAddress = true,
    showSign = true,
}: {
    inline?: boolean
    signContext?: string
    showAddress?: boolean
    showSign?: boolean
}) {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const {
        generateIdentificationDocument: generateIdentificationDocumentCommand,
        removeIdentificationDocument: removeIdentificationDocumentCommand,
    } = useCommand()

    const session = useSelector((state) => state.session)
    const profile = useSelector((state) => state.profile)
    const depositor = useSelector((state) => state.depositor)
    const identificationDocument = useSelector(selectors.identificationDocument)
    const lookupSignatureStatus = useSelector(selectors.lookupSignatureStatus)
    const postalCodeSearchData = useSelector((state) => state.postalCodeSearchData)

    const countryCode = useFieldState(profile.countryCode || depositor?.nationality || null)
    const addressWithPostcode = !(countryCode.value === 'NO' || countryCode.value === 'SE')
    const mustAddTin = !(countryCode.value === 'NO' || countryCode.value === 'SE')

    const addressValidator = useStableValidator('NOT_EMPTY_ON_EDIT', t('pages-profile.addressMissing'))
    const address = useFieldState<string>(profile.address || '', addressValidator)

    const postcodeValidator = useCallback(
        ({ value, isEditing }) => {
            if (addressWithPostcode) {
                return
            }
            if (!value) {
                return t('pages-profile.postcodeMissing')
            }

            if (!isEditing) {
                if (countryCode.value === 'SE' && !validateSwedishPostcode(value)) {
                    return t('pages-profile.swedishPostcodeValidation')
                }
                if (countryCode.value === 'NO' && !validateNorwegianPostcode(value)) {
                    return t('pages-profile.norwegianPostcodeValidation')
                }
            }
        },
        [addressWithPostcode, countryCode.value, t]
    )
    const postcode = useFieldState<string>(profile.postcode || '', postcodeValidator)

    const tinFieldValidator = useCallback(
        ({ value }) => {
            if (!mustAddTin) {
                return
            }
            if (!value) {
                return t('pages-profile.tinMissing')
            }
        },
        [mustAddTin, t]
    )
    const tinField = useFieldState<string>(profile.tin || '', tinFieldValidator)

    useEffect(() => {
        dispatch(getPostalCode(postcode.value, countryCode.value))
    }, [dispatch, postcode.value, countryCode.value])

    useEffect(() => {
        const country = countryCode.value
        const newPostcode = postcode.value
        if (
            (newPostcode.match(/^\d{3}\s?\d{2}$/) && country === 'SE') ||
            (newPostcode.match(/^\d{4}$/) && country === 'NO')
        ) {
            dispatch(getPostalCode(newPostcode, country))
        } else {
            dispatch(removePostalCodeSearchData(null))
        }
    }, [dispatch, postcode.value, countryCode.value])

    useEffect(() => {
        if (countryCode.touched) {
            postcode.validate()
        }
    }, [countryCode.touched, postcode])

    function onStartSignature() {
        const processId = uuidv4()
        const ctx = signContext ? signContext : '/profile'
        dispatch(startElectronicSignature(processId, 'SINGLE_DOCUMENT', identificationDocument.id))
        navigate(`/signature/${processId}?context=` + ctx)
    }

    async function onRemoveDocument() {
        const { waitForCommand } = await removeIdentificationDocumentCommand()
        await waitForCommand()
    }

    async function onGenerateDocument() {
        if (postcode.validate() && address.validate() && tinField.validate() && profile?.email && profile?.mobile) {
            const { waitForCommand } = await generateIdentificationDocumentCommand(
                countryCode.value,
                address.value,
                addressWithPostcode ? null : postcode.value,
                countryCode.value !== 'NO' ? tinField.value : null
            )
            await waitForCommand()
        }
    }

    // Bailout for users that should not see this section
    if (session.organisationType !== 'DEPOSITOR') return null

    const renderPhoneNumber = () => {
        if (!profile?.mobile) {
            return '-'
        }

        return (
            parsePhoneNumber(profile?.mobile)?.number?.international || parsePhoneNumber(profile?.mobile)?.number?.input
        )
    }

    const contactContent = (
        <>
            <Typography variant="h3" mt={3}>
                {t('pages-profile.contactInformation')}
            </Typography>
            <List sx={{ mb: 3, maxWidth: '60rem' }}>
                <ListItem disablePadding>
                    <ListItemText primary={t('pages-profile.phone')} secondary={renderPhoneNumber()} />
                    <ListItemText primary={t('pages-profile.email')} secondary={profile?.email || '-'} />
                </ListItem>
                <p className="field-error-message">{profile.mobile ? '' : t('pages-profile.phoneMissing')}</p>
                <p className="field-error-message">{profile.email ? '' : t('pages-profile.emailMissing')}</p>
            </List>
        </>
    )

    const addressContent = identificationDocument ? (
        <>
            <Typography variant="h3" mb={2}>
                {t('pages-organizations.personAddress')}
            </Typography>
            <dl>
                <dt>{t('pages-profile.countryOfResidence')}</dt>
                <dd>
                    <div>
                        <span>{countryList.getName(countryCode.value)}</span>
                    </div>
                </dd>
            </dl>
            <dl>
                <dt>{t('pages-profile.residentialAddress')}</dt>
                <dd>
                    <div>
                        <span className={classNames(styles2.profileSection__fieldInfoAddress)}>{address.value}</span>
                    </div>
                </dd>
            </dl>
            {!addressWithPostcode && (
                <dl>
                    <dt>{t('pages-profile.postcode')}</dt>
                    <dd>
                        <Stack direction="row" spacing={1}>
                            <span>{postcode.value}</span>
                            <span>{postalCodeSearchData && postalCodeSearchData.result}</span>
                        </Stack>
                    </dd>
                </dl>
            )}
            {mustAddTin && (
                <dl>
                    <dt>{t('pages-profile.tin')}</dt>
                    <dd>
                        <div>
                            <span>{tinField.value}</span>
                        </div>
                    </dd>
                </dl>
            )}
        </>
    ) : (
        <Stack spacing={2} mb={2}>
            <Typography variant="h3">{t('pages-organizations.personAddress')}</Typography>
            <Box>
                <InputLabel>{t('pages-profile.countryOfResidence')}</InputLabel>
                <CountrySelect
                    data-cy="countryCodeSelect"
                    selectedCountry={countryCode.value}
                    setSelectedCountry={countryCode.setValue}
                />
                <p className="field-error-message">{countryCode.errorMessage}</p>
            </Box>
            <Box>
                <InputLabel>{t('pages-profile.residentialAddress')}</InputLabel>
                <TextField
                    data-cy="addressInput"
                    name="address"
                    multiline
                    minRows={4}
                    value={address.value}
                    onChange={(e) => address.setValue(e.target.value)}
                    required
                    onBlur={address.onBlur}
                />
                <p className="field-error-message">{address.errorMessage}</p>
            </Box>

            {addressWithPostcode || (
                <Box>
                    <InputLabel>{t('pages-profile.postcode')}</InputLabel>
                    <Stack direction={'row'} alignItems="center" spacing={1}>
                        <TextField
                            value={postcode.value}
                            type="tel"
                            className={styles.postcodeInput}
                            data-cy="postcodeInput"
                            name="postcode"
                            onBlur={postcode.onBlur}
                            onChange={(e) => postcode.setValue(e.target.value)}
                            required
                        />
                        <Typography component={'span'}>
                            {postcode.value && postalCodeSearchData && postalCodeSearchData.result}
                        </Typography>
                    </Stack>

                    <p className="field-error-message">{postcode.errorMessage}</p>
                </Box>
            )}
            {mustAddTin && (
                <Box>
                    <InputLabel>{t('pages-profile.tin')}</InputLabel>
                    <TextField
                        value={tinField.value}
                        type="text"
                        data-cy="tinInput"
                        name="tin"
                        onBlur={tinField.onBlur}
                        onChange={(e) => tinField.setValue(e.target.value)}
                        required
                    />
                    <p className="field-error-message">{tinField.errorMessage}</p>
                </Box>
            )}
        </Stack>
    )

    const generateButton = !identificationDocument && (
        <AsyncButton id="createIdDocumentButton" onClick={onGenerateDocument}>
            {t('pages-profile.createIdDocument')}
        </AsyncButton>
    )

    const signElement = identificationDocument && (
        <div>
            <SignableDocument
                isCheckingSignatureStatus={lookupSignatureStatus(identificationDocument.id)}
                linkText={t('pages-profile.identificationDocument')}
                document={identificationDocument}
                onStartSignature={onStartSignature}
                onRemoveDocument={onRemoveDocument}
                showSigningError={!identificationDocument.signedByUser}
            />
        </div>
    )

    if (inline) {
        return (
            <>
                <Typography mb={2}>{t('pages-profile.identificationDocumentDescription')}</Typography>
                {showAddress && addressContent}
                {showAddress && contactContent}
                {showAddress && generateButton}
                {showSign && signElement}
            </>
        )
    }

    return (
        <Paper
            id="id-document"
            title={t('pages-profile.accountHolderIdentification')}
            needsAction={!identificationDocument || !identificationDocument.signedByUser}
            supportCommonName="depositorAccountOpener"
        >
            <p>{t('pages-profile.accountHolderIdentificationExplanation')}</p>
            {addressContent}
            {contactContent}
            {generateButton}
            {signElement}
        </Paper>
    )
}
