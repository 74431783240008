import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
    name: 'profile',
    initialState: null,
    reducers: {
        setProfile(state, action) {
            return action.payload
        },
    },
})

const { actions, reducer } = slice
export const { setProfile } = actions
export default reducer
