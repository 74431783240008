import {useEffect, useRef, useState} from 'react'
import { Chart } from 'chart.js'
import 'chartjs-adapter-date-fns'
import { InterestOutput } from '#components'
import { FundInventoryReport } from '@fixrate/fixrate-report'
import { CHART_COLORS } from '#app/colors/colors'
import { Box, Stack, Typography } from '@mui/material'
import LegendSquare, { LegendCircle } from '#pages/portfolio-depositor/Reports/FundReports/InventoryReport/LegendSquare'
import format from 'date-fns/format'
import subDays from 'date-fns/subDays'
import { useTranslation } from 'react-i18next'

const COLORS = {
    FOREST_GREEN: '#21362C',
    SILVER_GRAY: '#6F7271',
    PURPLE: '#4D2A98',
    SUNSET_ORANGE: '#FFA621',
    BURNT_ORANGE: '#B44A06',
}

type Props = {
    report: FundInventoryReport
    pdfMode?: boolean
}

type LineChart = Chart<'line', string[] | number[]>

export default function FundsDevelopmentChart({ report, pdfMode = false }: Props) {
    const { t } = useTranslation()
    const chartRef = useRef<LineChart | null>(null)
    const reportIdRef = useRef<string | null>(null)

    const filteredEntries = report.fundPlacementInventoryEntries.filter((entry) => {
        // Only include entries with a non-null and non-zero proportion.
        return entry.proportionOfPortfolio != null && entry.proportionOfPortfolio != 0
    })

    const referenceReturnRates: { [index: string]: { [index: string]: number | null } } | null =
        report.referenceReturnRates || { 'NIBOR 3M': report.niborReturnRates }
    const labels = Object.values(referenceReturnRates).flatMap((data) => Object.keys(data))
    const firstEntriesOfReturnRates: { [label: string]: string } = Object.entries(referenceReturnRates)
        .map(([label, data]) => ({ [label]: Object.keys(data)[0] }))
        .reduce((acc, val) => ({ ...acc, ...val }), {})
    const stitchedReferenceReturnRates: { [index: string]: { [index: string]: number | null } } | null = Object.entries(
        referenceReturnRates
    )
        .map(([label, data]) => {
            const lastDay = Object.keys(data)[Object.keys(data).length - 1]
            const lastDayPlusOne = format(subDays(new Date(lastDay), -1), 'yyyy-MM-dd')
            const lastDayPlusOneValue = Object.entries(referenceReturnRates).find(
                ([label, data]) => data[lastDayPlusOne] !== undefined
            )?.[1][lastDayPlusOne]
            if (lastDayPlusOneValue === undefined) {
                return { [label]: { ...data, [lastDayPlusOne]: null } }
            }
            return { [label]: { ...data, [lastDayPlusOne]: lastDayPlusOneValue } }
        })
        .reduce((acc, val) => ({ ...acc, ...val }), {})
    const hasSeveralReferenceRates = Object.keys(referenceReturnRates).length > 1

    const canvasCallback = (canvas: HTMLCanvasElement | null) => {
        const ctx = canvas?.getContext('2d')
        if (reportIdRef.current !== report.id) {
            reportIdRef.current = report.id
            if (chartRef.current) {
                chartRef.current.destroy()
                chartRef.current = null
            }
        }
        if (ctx && !chartRef.current) {
            chartRef.current = new Chart(ctx, {
                type: 'line',
                data: {
                    labels: labels,
                    datasets: [
                        ...Object.entries(referenceReturnRates).map(([label, data], i) => ({
                            label: 'referenceRate',
                            data: labels.map((date) => {
                                if (
                                    !hasSeveralReferenceRates ||
                                    !Object.values(firstEntriesOfReturnRates).includes(date)
                                ) {
                                    return null
                                }
                                return data[date]
                            }),
                            borderColor: COLORS.PURPLE,
                            backgroundColor: COLORS.PURPLE,
                            borderWidth: 2,
                            pointRadius: 6,
                            pointStyle: 'circle',
                            pointBackgroundColor: COLORS.PURPLE,
                            pointBorderColor: 'transparent',
                            spanGaps: true,
                            tension: 0.1,
                        })),
                        ...Object.entries(stitchedReferenceReturnRates).map(([label, data], i) => ({
                            label: label,
                            data: labels.map((date: string) => {
                                if (data[date] === undefined) {
                                    return null
                                }
                                return data[date]
                            }),
                            borderColor: COLORS.PURPLE,
                            backgroundColor: COLORS.PURPLE,
                            borderWidth: 2,
                            pointRadius: 0,
                            borderDash: [8, 4],
                            pointBackgroundColor: COLORS.PURPLE,
                            pointBorderColor: 'transparent',
                            spanGaps: true,
                            tension: 0.1,
                        })),
                        ...filteredEntries.map((entry, i) => ({
                            label: entry.fundIsin + ' – ' + entry.fundName,
                            data: Object.values(entry.fundReturnRates),
                            borderColor: CHART_COLORS[i],
                            backgroundColor: CHART_COLORS[i],
                            borderWidth: 2,
                            pointRadius: 0,
                            pointBackgroundColor: CHART_COLORS[i],
                            pointBorderColor: 'transparent',
                            spanGaps: true,
                            tension: 0.1,
                        })),
                    ],
                },
                options: {
                    color: COLORS.SILVER_GRAY,
                    aspectRatio: 5 / 2,
                    maintainAspectRatio: !pdfMode,
                    responsive: !pdfMode,
                    animation: pdfMode ? false : { duration: 500 },
                    hover: {
                        mode: 'nearest',
                        intersect: true,
                    },
                    scales: {
                        x: {
                            display: true,
                            type: 'time',
                            ticks: {
                                font: {
                                    size: 10,
                                    family: "'Montserrat'",
                                    weight: '500',
                                },
                                color: COLORS.SILVER_GRAY,
                            },
                            grid: {
                                drawBorder: false,
                                display: false,
                            },
                            time: {
                                displayFormats: {
                                    day: 'dd. MMM',
                                    month: 'MMM yy',
                                },
                                tooltipFormat: 'dd. MMMM yyyy',
                            },
                        },
                        y: {
                            ticks: {
                                display: true,
                                font: {
                                    family: "'Montserrat'",
                                    weight: '500',
                                },
                                color: COLORS.SILVER_GRAY,
                                callback: (value) => InterestOutput.format(value),
                            },
                            grid: {
                                drawBorder: false,
                                borderDash: [4, 4],
                                color: (context) => (context.tick.value === 0 ? '#00000025' : '#00000000'),
                            },
                        },
                    },
                    plugins: {
                        legend: {
                            display: false,
                        },
                        tooltip: {
                            enabled: true,
                            axis: 'x',
                            mode: 'nearest',
                            intersect: false,
                            callbacks: {
                                label: (tooltipItem) => {
                                    const dataset = chartRef.current.data.datasets[tooltipItem.datasetIndex]
                                    const value = dataset.data[tooltipItem.dataIndex]
                                    if (dataset.label === 'referenceRate') {
                                        return ''
                                    }
                                    if (value !== undefined && value !== null) {
                                        // Find x value (date) of the tooltip
                                        const date = chartRef.current.data.labels[tooltipItem.dataIndex] as string
                                        if (Object.values(firstEntriesOfReturnRates).includes(date)) {
                                            const firstDateForLabel = firstEntriesOfReturnRates[dataset.label]
                                            if (firstDateForLabel && firstDateForLabel !== date) {
                                                return ''
                                            }
                                        }
                                        return `${dataset.label}: ${InterestOutput.format(value)}`
                                    }
                                    return ''
                                },
                            },
                        },
                    },
                },
            })
        }
    }

    const webStyle = {
        maxWidth: '100%',
    }
    const pdfStyle = {
        width: '80rem',
        height: '32rem',
    }
    const canvasStyle = pdfMode ? pdfStyle : webStyle

    return (
        <Stack direction={'column'} spacing={2}>
            <Stack direction={{ xs: 'column', lg: 'row' }} spacing={2}>
                <Box sx={{ maxWidth: '100%', width: '98rem' }}>
                    <canvas ref={canvasCallback} style={canvasStyle} id={'funds-development-chart-canvas'} />
                </Box>
                <Stack spacing={1} direction={pdfMode ? 'row' : 'column'} sx={{ flexWrap: 'wrap' }}>
                    {filteredEntries.map((entry, i) => (
                        <Stack key={entry.fundId} direction={'row'} alignItems={'start'} spacing={1}>
                            <LegendSquare color={CHART_COLORS[i]} />
                            <Stack width={'calc(100% - 4.2rem)'}>
                                <Typography fontSize={12} fontWeight={600}>
                                    {entry.fundName}
                                </Typography>
                                <Typography variant={'caption'} color={'text.secondary'}>
                                    {entry.fundIsin}
                                </Typography>
                            </Stack>
                        </Stack>
                    ))}
                    <Stack key={'referenceInterestRate'} direction={'row'} alignItems={'start'} spacing={1}>
                        <LegendSquare color={COLORS.PURPLE} dashed={true} />
                        <Stack width={'calc(100% - 4.2rem)'}>
                            <Box component="span" sx={{ fontSize: 12, fontWeight: 600 }}>
                                {t('pages-fund-reports-funds-development-chart.referenceInterestRate')}
                            </Box>
                            {Object.entries(referenceReturnRates)
                                .filter(([, data]) => Object.keys(data).length > 0)
                                .map(([label, data], i) => (
                                    <Stack key={`${label}-${i}`} direction={'row'} alignItems={'start'} spacing={1}>
                                        {hasSeveralReferenceRates && (
                                            <Stack direction={'row'} alignItems={'flex-start'} spacing={0.5} mt={0.5}>
                                                <LegendCircle color={COLORS.PURPLE} />
                                                <Stack>
                                                    <Typography fontSize={12} fontWeight={600}>
                                                        {format(new Date(Object.keys(data)[0]), 'dd. MMM')}
                                                    </Typography>
                                                    <Typography variant={'caption'} color={'text.secondary'}>
                                                        {label}
                                                    </Typography>
                                                </Stack>
                                            </Stack>
                                        )}
                                        {!hasSeveralReferenceRates && (
                                            <Typography variant={'caption'} color={'text.secondary'}>
                                                {label}
                                            </Typography>
                                        )}
                                    </Stack>
                                ))}
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    )
}
