import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
    name: 'defaultFundPlatformFees',
    initialState: {},
    reducers: {
        setDefaultFundPlatformFees(state, action) {
            return action.payload
        },
    },
})

const { actions, reducer } = slice
export const { setDefaultFundPlatformFees } = actions
export default reducer
