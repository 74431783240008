import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
    name: 'timeoutCountdown',
    initialState: 0,
    reducers: {
        setTimeoutCountdown(state, action) {
            return action.payload || 0
        },
    },
})

const { actions, reducer } = slice
export const { setTimeoutCountdown } = actions
export default reducer
