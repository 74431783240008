import { useCommand } from '#command'
import classNames from 'classnames'
import { useFieldState } from '@fixrate/fieldstate'
import styles from './InviteDataModal.module.scss'
import useCurrentDepositor from '#app/services/useCurrentDepositor'
import { useTranslation } from 'react-i18next'
import { Box, Button, InputLabel, Stack, TextField, Typography } from '@mui/material'
import DateInput from '#app/components/DateInput'
import { validateEmailAddress } from '#services/validateFields'
import FxDialog from '#app/components/FxDialog/FxDialog'
import FxDialogTitle from '#app/components/FxDialog/FxDialogTitle'
import FxDialogContent from '#app/components/FxDialog/FxDialogContent'
import FxDialogActions from '#app/components/FxDialog/FxDialogActions'

interface Props {
    depositorId: string
    personId: string
    onClose: () => void
}

export default function InviteDataModal({ depositorId, personId, onClose }: Props) {
    const { t } = useTranslation()

    const { updatePerson } = useCommand()
    const depositor = useCurrentDepositor()
    const person = depositor?.people.find((p) => p.personId === personId)

    const birthDate = useFieldState(person.birthDate ? new Date(person.birthDate) : null, ({ value }) => {
        return !value && t('common.mandatoryField')
    })
    const email = useFieldState(person.email, ({ value }) => {
        if (!value) {
            return t('common.mandatoryField')
        }
        if (!validateEmailAddress(value)) {
            return t('common.invalidEmail')
        }
    })

    async function submit() {
        if (birthDate.validate() && email.validate()) {
            const { waitForCommand } = await updatePerson(
                depositorId,
                personId,
                person.firstName?.trim(),
                person.lastName?.trim(),
                birthDate.value,
                email.value?.trim(),
                person.address?.trim(),
                person.taxResidencies,
                person.americanCitizenship,
                person.pep,
                person.pepPosition,
                person.pepInvestingPrivateFunds,
                person.controlShare,
                person.keyPersonRole
            )
            const success = await waitForCommand()
            if (success) {
                onClose()
            }
        }
    }

    return (
        <FxDialog open={true} maxWidth="sm" fullWidth onClose={onClose}>
            <FxDialogTitle onClose={onClose}>
                {t('pages-organizations.birthDateEmailFor', { firstName: person.firstName, lastName: person.lastName })}
            </FxDialogTitle>
            <FxDialogContent>
                <Box component={'form'}>
                    <Stack spacing={2} maxWidth="40rem">
                        <Typography>{t('pages-organizations.registerBirthdateInstructions')}</Typography>
                        <Box>
                            <InputLabel htmlFor="emailField">{t('common.email')}</InputLabel>
                            <TextField
                                id="emailField"
                                sx={{ width: '30rem', maxWidth: '100%' }}
                                onBlur={email.onBlur}
                                onChange={(e) => email.setValue(e.target.value)}
                                type="email"
                                value={email.value}
                            />
                            <p className="field-error-message">{email.errorMessage}</p>
                        </Box>
                        <Box>
                            <InputLabel htmlFor="birthDateDatePicker">
                                {t('pages-organizations.personBirthDate')}
                            </InputLabel>
                            <DateInput
                                className={classNames(styles.datepicker)}
                                id="birthDateDatePicker"
                                onChange={(date) => birthDate.setValue(date)}
                                value={birthDate.value}
                            />
                            <p className="field-error-message">{birthDate.errorMessage}</p>
                        </Box>
                    </Stack>
                </Box>
            </FxDialogContent>
            <FxDialogActions>
                <Button onClick={onClose}>{t('common.cancel')}</Button>
                <Button variant="contained" onClick={submit} data-cy="saveButton">
                    {t('common.save')}
                </Button>
            </FxDialogActions>
        </FxDialog>
    )
}
