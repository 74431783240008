import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
    name: 'shoppingCart',
    initialState: null,
    reducers: {
        setShoppingCart(state, action) {
            console.log('Reducer: setShoppingCart', action.payload)
            return action.payload
        },
    },
})

const { actions, reducer } = slice
export const { setShoppingCart } = actions
export default reducer
