import { Box, SxProps } from '@mui/material'
import { CSSProperties, Fragment } from 'react'
import ColumnSplitter from './ColumnSplitter'
import styles from './PageLayout.module.scss'

const PageLayout = ({
    children = null,
    className = '',
    style = {},
    sx,
    noStyles,
}: {
    children?: JSX.Element | JSX.Element[]
    className?: string | Array<string>
    style?: CSSProperties
    sx?: SxProps
    noStyles?: boolean
}) => {
    /**
     * Splits a single array of children in separate arrays for each column, updated January 2020 - Simen :)
     */
    children = Array.isArray(children) ? children : Array.of(children)

    let i = 0
    const content = children
        ? children
              .reduce(
                  (columns, column) => {
                      if (!column) return columns
                      if (column.type === ColumnSplitter) {
                          i += 1
                          columns[i] = []
                          return columns
                      }
                      columns[i] = [...columns[i], column]
                      return columns
                  },
                  [[]]
              )
              .map((children, i) =>
                  noStyles ? (
                      <Fragment key={i}>{children}</Fragment>
                  ) : (
                      <div key={`column-${i}`} className={noStyles ? '' : styles.column}>
                          {children}
                      </div>
                  )
              )
        : null

    return (
        <Box sx={sx} id={'pageLayout'} style={style} className={[styles.layoutPadding, className].join(' ')}>
            {noStyles ? content : <div className={styles.columnContainer}>{content}</div>}
        </Box>
    )
}

export default PageLayout
