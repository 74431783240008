import { DialogContent, DialogContentProps } from '@mui/material'

type Props = DialogContentProps & {
    disablePadding?: boolean
}

export default function FxDialogContent(props: Props) {
    return (
        <DialogContent {...props} sx={{ ...props.sx }}>
            {props.children}
        </DialogContent>
    )
}
