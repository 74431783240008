import { useDispatch } from 'react-redux'
import { syncSessionState } from '#services/thunks/session'
import { useMemo } from 'react'

let sessionBroadcast

export function sendLogoutMessage() {
    if (!window.BroadcastChannel) return
    sessionBroadcast.postMessage('logout')
}

export function sendLoginMessage() {
    if (!window.BroadcastChannel) return
    sessionBroadcast.postMessage('login')
}

export function sendSwitchOrganisationMessage() {
    if (!window.BroadcastChannel) return
    sessionBroadcast.postMessage('switch')
}

export function useSessionBroadcast() {
    const dispatch = useDispatch()

    return useMemo(
        () => ({
            init: () => {
                if (!window.BroadcastChannel) return
                sessionBroadcast = new BroadcastChannel('session')

                sessionBroadcast.onmessage = (event) => {
                    switch (event.data) {
                        case 'logout':
                            console.log('Logout message received')
                            dispatch(syncSessionState())
                            break
                        case 'login':
                            console.log('Login message received')
                            dispatch(syncSessionState())
                            break
                        case 'switch':
                            console.log('Switch organisation message received')
                            dispatch(syncSessionState())
                            break
                        default:
                            console.log('Unknown message received: ' + event.data)
                    }
                }
            },
            sendLogoutMessage,
            sendLoginMessage,
        }),
        [dispatch]
    )
}
