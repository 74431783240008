import PropTypes from 'prop-types'

const ChevronCircledLeft = (props) => (
    <svg
        className={props.className}
        id="Chevron_circled_left"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 3.81 6.74"
    >
        <title>venstre</title>
        <path
            d="M11.3,6.78a.5.5,0,0,0-.71,0L7.78,9.64a.51.51,0,0,0,0,.72l2.81,2.86a.5.5,0,0,0,.71,0,.51.51,0,0,0,0-.72L9,10l2.3-2.51A.51.51,0,0,0,11.3,6.78Z"
            transform="translate(-7.64 -6.63)"
        />
    </svg>
)

ChevronCircledLeft.propTypes = {
    className: PropTypes.string,
}

export default ChevronCircledLeft
