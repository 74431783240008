import i18n from '#services/i18n'
import { sendLogoutMessage, sendSwitchOrganisationMessage } from '#services/sessionBroadcast'
import { OrganisationType, SessionDto } from '@fixrate/fixrate-security'
import { clearReduxStoreIdentityData } from '#state/reducers'
import { clearAllPings } from '#state/reducers/ping'
import { setSession } from '#state/reducers/session'
import * as timeoutCountdownFn from '#state/reducers/timeoutCountdown'
import { GetState } from '#state/types'
import { NavigateFunction } from 'react-router-dom'
import { loginToHubspot, logoutFromHubspot } from '../hubspot'
import IdleDetector from '../IdleDetector'
import { navigateToCalculatedRoot } from '../navigate'
import syncSession from '../network/sync-session'
import * as websocket from '../network/websocket-connection'
import { startPingThread, stopPingThread } from '../ping'
import restEndpoint from '../rest/rest-endpoint'
import { clearSentryUser, setSentryUser } from '../sentry'
import { handleWebsocketMessage } from '../session/websocketMessageHandler'
import { Dispatch } from 'redux'

export const registerLanding = (url: string, referrer: string) => (dispatch) => {
    return restEndpoint(dispatch).registerLanding(url, referrer)
}

export const loginWithToken = (token: string, url: URL) => {
    fetch('/api/session/token', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: token,
        credentials: 'include',
    })
        .then((response) => {
            if (!response.ok) {
                throw new Error('Failed to consume token')
            }
            // Remove the token parameter from the URL
            url.searchParams.delete('token')
            window.history.replaceState({}, document.title, url.toString())
            // Reload the application
            window.location.reload()
        })
        .catch((error) => {
            console.error('Error consuming token:', error)
        })
}

export const logout = (navigate: NavigateFunction, url?: string) => (dispatch) => {
    return restEndpoint(dispatch)
        .logout()
        .then(() => {
            dispatch(syncSessionState())
            sendLogoutMessage()
            navigate(url ?? '/marketplace')
        })
        .catch((ex) => {
            // Navigates to application root. This will start a hard reload of the application
            console.error('Failed while logging out', ex)
            navigateToCalculatedRoot()
        })
}

const logoutAndReset = () => (dispatch) => {
    return restEndpoint(dispatch)
        .logout()
        .then(() => {
            dispatch(syncSessionState())
            sendLogoutMessage()
            window.location.href = '/marketplace?login'
        })
        .catch((ex) => {
            // Navigates to application root. This will start a hard reload of the application
            console.error('Failed while logging out', ex)
            navigateToCalculatedRoot()
        })
}

// Used for forget me option
export const hardLogout = (navigate: NavigateFunction) => (dispatch) => {
    return restEndpoint(dispatch)
        .hardLogout()
        .then(() => {
            dispatch(syncSessionState())
            navigate('/marketplace')
        })
        .catch((ex) => {
            // Navigates to application root. This will start a hard reload of the application
            console.error('Failed while logging out', ex)
            navigateToCalculatedRoot()
        })
}

export const unlockSession = (pin: string) => (dispatch, getState: GetState) => {
    const previousOrganistionType = getState().session?.organisationType
    const previousFocusedDepositorId = getState().session?.association?.organisation.id
    return restEndpoint(dispatch)
        .unlockSession(pin)
        .then((session: SessionDto) => {
            dispatch(setSession(session))
            updateApplicationState(session, previousOrganistionType, previousFocusedDepositorId, dispatch, getState)
        })
        .catch((ex) => {
            // Navigates to application root. This will start a hard reload of the application
            console.error('Failed while unlocking session', ex)
            navigateToCalculatedRoot()
        })
}

//
// Synchronize client session state with session state on server
// Call this when the app starts, after login, logout or if session state may be out of sync for some reason
// The function is idempotent. It can be called multiple times, over and over again.
//
export const syncSessionState = () => (dispatch, getState: GetState) => {
    const previousOrganisationType = getState().session?.organisationType
    const previousFocusedDepositorId = getState().session?.association?.organisation.id
    dispatch(clearAllPings())
    return restEndpoint(dispatch)
        .getSession()
        .then((session) => {
            dispatch(setSession(session))
            console.log('Sync Session: authLevel=' + session.authenticationLevel)
            if (session.language && session.language !== i18n.language) {
                i18n.changeLanguage(session.language)
            }
            updateApplicationState(session, previousOrganisationType, previousFocusedDepositorId, dispatch, getState)
            return session
        })
}

export const switchOrganisation =
    (organisationType: OrganisationType, organisationId: string | null, onSwitched?: () => void) => (dispatch) => {
        return restEndpoint(dispatch)
            .organisation(organisationType, organisationId)
            .then(() => {
                dispatch(clearReduxStoreIdentityData())
                websocket.closeWebsocket(websocket.websockets.MARKETPLACE_DATA)
                dispatch(syncSessionState())
                sendSwitchOrganisationMessage()
                if (onSwitched) {
                    onSwitched()
                }
            })
    }

// Updates application state after a potential change of authenticationLevel
function updateApplicationState(
    session: SessionDto,
    previousOrganisationType: string,
    previousFocusedDepositorId: string,
    dispatch: Dispatch<any>,
    getState: GetState
) {
    if (session.authenticationLevel === 'AUTHENTICATED') {
        // Close websocket if the user has changed organisation type
        if (
            previousOrganisationType !== session.organisationType ||
            previousFocusedDepositorId !== session.association?.organisation.id
        ) {
            dispatch(clearReduxStoreIdentityData())
            websocket.closeWebsocket(websocket.websockets.MARKETPLACE_DATA)
        }

        // Opens websocket if it is not open already
        websocket.openWebsocket(websocket.websockets.OPEN_DATA, (message) =>
            handleWebsocketMessage(dispatch, getState, message, syncSessionState)
        )
        websocket.openWebsocket(websocket.websockets.MARKETPLACE_DATA, (message) =>
            handleWebsocketMessage(dispatch, getState, message, syncSessionState)
        )

        // Starts process that will timeout user on inactivity and synchronize inactivity with other processes
        // using the same login session
        const setTimeoutCountdown = (counter) => dispatch(timeoutCountdownFn.setTimeoutCountdown(counter))
        const syncLastSeen = (lastSeen) => syncSession(dispatch).syncLastSeen(lastSeen)
        const onSessionTimeout = () => dispatch(logoutAndReset())

        IdleDetector.init(setTimeoutCountdown, syncLastSeen, onSessionTimeout)

        // Register users data in raven context
        setSentryUser(session.id)

        // Starts timer that sends ping commands to server
        startPingThread(dispatch, getState)

        // Start HubSpot conversation with token
        loginToHubspot(getState)
    } else {
        // Stops timer that makes lastSeen calls to server
        IdleDetector.cleanup()

        // If authLevel is not AUTHENTICATED, then we should ensure that all identity related information is removed and
        // all identity related services are disconnected
        logoutFromHubspot()

        // Remove any user id's from raven context
        clearSentryUser()

        // Close any open websockets
        websocket.closeWebsocket(websocket.websockets.OPEN_DATA)
        websocket.closeWebsocket(websocket.websockets.MARKETPLACE_DATA)

        // Stops timer that sends ping commands to server
        stopPingThread()

        // Clear all identity related data from redux
        dispatch(clearReduxStoreIdentityData())

        //reopen open data
        websocket.openWebsocket(websocket.websockets.OPEN_DATA, (message) =>
            handleWebsocketMessage(dispatch, getState, message, syncSessionState)
        )
    }
}
