import { FileUpload, LoadingSpinner } from '#app/components'
import { AccountNumberInput } from '#app/components/AccountNumberInput/AccountNumberInput'
import FxDialog from '#app/components/FxDialog/FxDialog'
import FxDialogContent from '#app/components/FxDialog/FxDialogContent'
import FxDialogTitle from '#app/components/FxDialog/FxDialogTitle'
import useCurrentCountryCode from '#app/services/useCurrentCountryCode'
import usePortfolio from '#app/services/usePortfolio'
import { useCommand, useEndpoint } from '#command'
import { useFieldState } from '@fixrate/fieldstate'
import { validateAccountNumber, validateIbanNumber } from '#services/validateFields'
import * as selectors from '#state/selectors'
import { useSelector } from '#state/useSelector'
import { Currency } from '@fixrate/fixrate-query'
import {
    Box,
    Button,
    FormControl,
    FormControlLabel,
    FormLabel,
    Link,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    Stack,
    Step,
    StepButton,
    Stepper,
    TextField,
    Typography,
} from '@mui/material'
import { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

export default function NewAccount({
    depositorId,
    inline = false,
    showDescription = false,
}: {
    depositorId: string
    inline?: boolean
    showDescription?: boolean
}) {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const location = useLocation()

    const { addSettlementAccount, uploadSettlementAccountCertificate } = useCommand()
    const { getBankName } = useEndpoint()
    const depositor = useSelector((state) => state.depositor)
    const portfolio = usePortfolio()
    const organisationCountry = useCurrentCountryCode()
    const portfolios = depositor?.portfolios
    const currencies = portfolios?.map((p) => p.currency)
    const hasMultipleCurrencies = currencies.length > 1
    const defaultCurrency = portfolio?.currency
    const settlementAccounts = useSelector((state) => state.depositor.settlementAccounts)

    const [loading, setLoading] = useState(false)
    const [visible, setVisible] = useState(true)
    const [currency, setCurrency] = useState(defaultCurrency)
    const [activeStep, setActiveStep] = useState(0)

    const accounts = useSelector((state) => selectors.depositorSettlementAccounts(state)[depositorId])
    const [uploadingAccountCertificate, setUploadingAccountCertificate] = useState(false)

    const acceptsIban = organisationCountry === 'NO'
    const hasAccountCertificateStep = organisationCountry === 'SE'

    const accountCertificate = useFieldState(null, ({ value }: { value: { blob: Blob; name: string } | null }) => {
        if (!value && organisationCountry === 'SE') {
            return t('pages-organizations.missingAccountCertificate')
        }
    })

    const accountNumber = useFieldState('', ({ value, isEditing }) => {
        if (!value) {
            return t('pages-organizations.accountNumberMissing')
        }
        if (!isEditing && !validateAccountNumber(value, organisationCountry)) {
            return t('pages-organizations.accountNumberInvalid')
        }
    })

    const accountName = useFieldState('', ({ value, isEditing }) => {
        if (!value || value.trim().length === 0) {
            return t('pages-organizations.accountNameMissing')
        }

        if (hasMultipleCurrencies) {
            if (!isEditing && accounts?.some((ac) => ac.name === value && ac.currency === currency)) {
                return t('pages-organizations.AccountNameInvalidCurrency')
            }
        } else {
            if (!isEditing && accounts.map((ac) => ac.name).includes(value)) {
                return t('pages-organizations.accountNameInvalid')
            }
        }
    })

    const ibanNumber = useFieldState('', ({ value, isEditing }) => {
        if (!value) {
            return t('pages-organizations.ibanNumberMissing')
        }
        if (!isEditing && !validateIbanNumber(value)) {
            return t('pages-organizations.ibanNumberInvalid')
        }
    })
    const bic = useFieldState('', ({ value }) => {
        if (!value || value.length === 0) {
            return t('pages-organizations.swiftNumberMissing')
        }
    })
    const [iban, setIban] = useState(false)

    const onClose = () => {
        resetFields()
        if (location.pathname.includes('new-account')) {
            navigate(`/organizations/${depositorId}/accounts#!`)
        } else {
            setVisible(false)
        }
    }

    async function onSubmit() {
        if (iban) {
            await onSubmitIbanNumber()
        } else {
            await onSubmitAccountNumber()
        }
    }

    async function uploadAccountCertificate(certificate: Blob | null) {
        const settlementAccount = settlementAccounts?.find((a) => a.account === accountNumber.value)
        if (!settlementAccount || !accountCertificate.validate()) {
            return
        }
        setLoading(true)
        await uploadSettlementAccountCertificate({
            depositorId: depositorId,
            settlementAccountId: settlementAccount?.id,
            content: certificate,
            fileName: 'Kontointyg-' + settlementAccount?.id,
        })
        onClose()
        return
    }

    async function onSubmitAccountNumber() {
        const isValidAccountNumber = accountNumber.validate()
        const isValidAccountName = accountName.validate()

        if (isValidAccountNumber && isValidAccountName) {
            const cleanedAccountNumber = accountNumber.value.toString().replace(/[.\s]/g, '').replace('-', '')
            const cleanedAccountName = accountName.value.trim()

            // Validate bank mapping
            const bankIdentifier = cleanedAccountNumber.substring(0, 4)
            const bankName = await getBankName(bankIdentifier, organisationCountry)
            if (!bankName) {
                console.error('Invalid bank identifier', bankIdentifier)
                accountNumber.setErrorMessage(t('pages-organizations.accountNumberInvalidBank'))
            } else {
                setLoading(true)
                // Bank mapping was successful
                await callAddSettlementAccount({
                    cleanedAccountName: cleanedAccountName,
                    cleanedAccountNumber: cleanedAccountNumber,
                })
            }
        }
    }

    async function onSubmitIbanNumber() {
        const isValidIbanNumber = ibanNumber.validate()
        const isValidBic = bic.validate()
        const isValidAccountName = accountName.validate()

        const cleanedIban = ibanNumber.value.toString().replace(/[.\s]/g, '')
        const cleanedAccountName = accountName.value.trim()

        if (isValidAccountName && isValidIbanNumber && isValidBic) {
            setLoading(true)
            await callAddSettlementAccount({
                cleanedAccountName: cleanedAccountName,
                cleanedIbanNumber: cleanedIban,
                cleanedBic: bic.value,
            })
        }
    }

    async function callAddSettlementAccount({
        cleanedAccountName,
        cleanedAccountNumber,
        cleanedIbanNumber,
        cleanedBic,
    }: {
        cleanedAccountName: string
        cleanedAccountNumber?: string
        cleanedIbanNumber?: string
        cleanedBic?: string
    }) {
        const { waitForCommand } = await addSettlementAccount(
            depositorId,
            cleanedAccountName,
            cleanedAccountNumber,
            cleanedIbanNumber,
            cleanedBic,
            currency,
            organisationCountry
        )
        const success = await waitForCommand()
        if (success) {
            if (hasAccountCertificateStep) {
                setActiveStep(1)
                setLoading(false)
            } else {
                onClose()
            }
        }
    }

    function resetFields() {
        accountNumber.reset()
        accountName.reset()
        ibanNumber.reset()
        bic.reset()
        accountCertificate.reset()
    }

    const nationalAccountLabel = () => {
        if (organisationCountry === 'NO') {
            return t('pages-organizations.accountTypeNorwegian')
        }
        if (organisationCountry === 'SE') {
            return t('pages-organizations.accountTypeSwedish')
        }
        return t('pages-organizations.accountTypeNorwegian')
    }

    const onFileDrop = async (files) => {
        setUploadingAccountCertificate(true)
        if (files.length > 0) {
            const file = files[0]
            const reader = new FileReader()
            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = () => {
                console.log(file?.name)
                accountCertificate.setValue({ blob: new Blob([reader.result], { type: file.type }), name: file?.name })
            }
            reader.readAsArrayBuffer(file)
        }
        setUploadingAccountCertificate(false)
    }
    const stepper = (
        <Stepper
            sx={{ mb: 2, '& .MuiStepLabel-labelContainer .MuiStepLabel-label': { fontSize: '1.2rem' } }}
            activeStep={activeStep}
        >
            <Step disabled>
                <StepButton>{t('pages-organizations.addAccount')}</StepButton>
            </Step>
            <Step disabled>
                <StepButton>{t('pages-organizations.uploadAccountCertificate')}</StepButton>
            </Step>
        </Stepper>
    )

    const content = (
        <Fragment>
            {loading && <LoadingSpinner />}
            {!loading && activeStep === 0 && (
                <Stack spacing={1}>
                    {showDescription && (
                        <Typography pb={2}>{t('pages-organizations.settlementAccountMessage')}</Typography>
                    )}
                    {acceptsIban && (
                        <FormControl>
                            <FormLabel>{t('pages-organizations.accountType')}</FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                onChange={(e) => setIban(e.target.value !== 'NATIONAL')}
                            >
                                <FormControlLabel
                                    value="NATIONAL"
                                    name="accountType"
                                    checked={!iban}
                                    control={<Radio />}
                                    label={nationalAccountLabel()}
                                />
                                <FormControlLabel
                                    value="FOREIGN"
                                    name="accountType"
                                    checked={iban}
                                    control={<Radio />}
                                    label={t('pages-organizations.accountTypeForeign')}
                                />
                            </RadioGroup>
                        </FormControl>
                    )}
                    <FormControl>
                        <FormLabel>{t('pages-organizations.accountName')}</FormLabel>
                        <TextField
                            value={accountName.value}
                            onChange={(e) => accountName.setValue(e.target.value)}
                            onBlur={accountName.onBlur}
                            autoComplete="off"
                            data-cy="accountNameInput"
                            placeholder={t('pages-organizations.accountNameExample')}
                        />
                        <p className="field-error-message">{!accountName.valid && accountName.errorMessage}</p>
                    </FormControl>
                    {hasMultipleCurrencies && (
                        <FormControl>
                            <FormLabel>{t('pages-organizations.currency')}</FormLabel>
                            <Select
                                data-cy="currencySelector"
                                value={currency}
                                onBlur={accountName.onBlur}
                                onChange={(e) => setCurrency(e.target.value as Currency)}
                            >
                                {currencies.map((c) => (
                                    <MenuItem key={c} value={c}>
                                        {c}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}
                    {!iban && <AccountNumberInput accountNumber={accountNumber} />}
                    {iban && (
                        <Fragment>
                            <FormControl>
                                <FormLabel>{t('pages-organizations.ibanNumber')}</FormLabel>
                                <TextField
                                    data-cy="ibanNumberInput"
                                    name="ibanNumber"
                                    autoComplete="off"
                                    value={ibanNumber.value}
                                    onChange={(e) => ibanNumber.setValue(e.target.value)}
                                    onBlur={ibanNumber.onBlur}
                                />
                                <p className="field-error-message">{!ibanNumber.valid && ibanNumber.errorMessage}</p>
                            </FormControl>
                            <FormControl>
                                <FormLabel>{t('pages-organizations.swiftNumber')}</FormLabel>
                                <TextField
                                    data-cy="swiftNumberInput"
                                    name="swiftNumber"
                                    autoComplete="off"
                                    value={bic.value}
                                    onChange={(e) => bic.setValue(e.target.value)}
                                    onBlur={bic.onBlur}
                                />
                                <p className="field-error-message">{!bic.valid && bic.errorMessage}</p>
                            </FormControl>
                        </Fragment>
                    )}
                    <Stack alignItems={'center'} direction="row" my={2} spacing={1}>
                        <Button data-cy="addAccountButton" variant={'contained'} onClick={onSubmit}>
                            {t('pages-organizations.addAccountSingular')}
                        </Button>
                        {!inline && (
                            <Button variant={'text'} onClick={onClose}>
                                {t('common.cancel')}
                            </Button>
                        )}
                    </Stack>
                </Stack>
            )}
            {!loading && activeStep === 1 && (
                <FormControl>
                    <FormLabel>{t('pages-organizations.accountCertificate')}</FormLabel>
                    {!accountCertificate.value?.blob ? (
                        <FileUpload
                            uploadFiles={onFileDrop}
                            isUploadingDocument={uploadingAccountCertificate}
                            multiple={false}
                        />
                    ) : (
                        <>
                            <List>
                                <ListItem sx={{ px: 0 }}>
                                    <ListItemIcon>
                                        <i className="ri-file-line" />
                                    </ListItemIcon>
                                    <ListItemText
                                        sx={{
                                            pr: 2,
                                            '& .MuiLink-root': {
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                fontSize: '1.4rem',
                                                width: '20rem',
                                                display: 'block',
                                            },
                                        }}
                                    >
                                        <Link
                                            onClick={() =>
                                                window.open(URL.createObjectURL(accountCertificate.value?.blob))
                                            }
                                        >
                                            {accountCertificate.value.name}
                                        </Link>
                                    </ListItemText>
                                    <Button
                                        variant={'outlined'}
                                        color="error"
                                        size="small"
                                        onClick={() => accountCertificate.setValue(null)}
                                    >
                                        {t('components-FileUpload.remove')}
                                    </Button>
                                </ListItem>
                            </List>
                        </>
                    )}
                    <p className="field-error-message">
                        {!accountCertificate.valid && accountCertificate.errorMessage}
                    </p>
                    <Stack alignItems={'center'} direction="row" my={2} spacing={1}>
                        <Button
                            data-cy="addAccountCertificateButton"
                            variant={'contained'}
                            onClick={() => uploadAccountCertificate(accountCertificate.value?.blob)}
                        >
                            {t('pages-organizations.uploadAccountCertificate')}
                        </Button>
                        {!inline && (
                            <Button variant={'text'} onClick={onClose}>
                                {t('common.cancel')}
                            </Button>
                        )}
                    </Stack>
                </FormControl>
            )}
        </Fragment>
    )

    if (inline) {
        return (
            <>
                {hasAccountCertificateStep && stepper}
                {content}
            </>
        )
    }

    return (
        <FxDialog onClose={onClose} maxWidth="lg" open={visible}>
            <FxDialogTitle onClose={onClose}>{t('pages-organizations.addAccountHeading')}</FxDialogTitle>
            <Box pt={2} px={2}>
                {hasAccountCertificateStep && stepper}
            </Box>
            <FxDialogContent sx={{ pt: 0, width: '44rem' }}>{content}</FxDialogContent>
        </FxDialog>
    )
}
