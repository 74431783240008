import WomanOfficeWorkingDefault from './assets/woman-office-working/woman-office-working=Default.svg?url'
import WomanOfficeWorkingMultiColor from './assets/woman-office-working/woman-office-working=Default.svg?url'
import WomanOfficeWorkingPurple from './assets/woman-office-working/woman-office-working=Purple.svg?url'
import WomanOfficeWorkingOrange from './assets/woman-office-working/woman-office-working=Orange.svg?url'

import MeetingDiscussionDefault from './assets/meeting-discussion/meeting-discussion=Default.svg?url'
import MeetingDiscussionMulticolor from './assets/meeting-discussion/meeting-discussion=Multicolor.svg?url'
import MeetingDiscussionPurple from './assets/meeting-discussion/meeting-discussion=Purple.svg?url'
import MeetingDiscussionOrange from './assets/meeting-discussion/meeting-discussion=Orange.svg?url'

import ManShowingReportDefault from './assets/man-showing-report/man-showing-report=Default.svg?url'
import ManShowingReportMulticolor from './assets/man-showing-report/man-showing-report=Multicolor.svg?url'
import ManShowingReportPurple from './assets/man-showing-report/man-showing-report=Purple.svg?url'
import ManShowingReportOrange from './assets/man-showing-report/man-showing-report=Orange.svg?url'

import FixrateEcosystemDefault from './assets/fixrate-ecosystem/fixrate-ecosystem=Default.svg?url'
import FixrateEcosystemMulticolor from './assets/fixrate-ecosystem/fixrate-ecosystem=Multicolor.svg?url'
import FixrateEcosystemPurple from './assets/fixrate-ecosystem/fixrate-ecosystem=Purple.svg?url'
import FixrateEcosystemOrange from './assets/fixrate-ecosystem/fixrate-ecosystem=Orange.svg?url'

import EmailSentDefault from './assets/email-sent/email-sent=Default.svg?url'
import EmailSentMulticolor from './assets/email-sent/email-sent=Multicolor.svg?url'
import EmailSentPurple from './assets/email-sent/email-sent=Purple.svg?url'
import EmailSentOrange from './assets/email-sent/email-sent=Orange.svg?url'

import ManAnalyzingDefault from './assets/man-analyzing/man-analyzing=Default.svg?url'
import ManAnalyzingMulticolor from './assets/man-analyzing/man-analyzing=Multicolor.svg?url'
import ManAnalyzingPurple from './assets/man-analyzing/man-analyzing=Purple.svg?url'
import ManAnalyzingOrange from './assets/man-analyzing/man-analyzing=Orange.svg?url'

import ManCoffeeDocumentDefault from './assets/man-coffee-documents/man-coffee-documents=Default.svg?url'
import ManCoffeeDocumentMulticolor from './assets/man-coffee-documents/man-coffee-documents=Multicolor.svg?url'
import ManCoffeeDocumentPurple from './assets/man-coffee-documents/man-coffee-documents=Purple.svg?url'
import ManCoffeeDocumentOrange from './assets/man-coffee-documents/man-coffee-documents=Orange.svg?url'

import PortfolioDefault from './assets/empty-state-portfolio/empty-state-portfolio=Default.svg?url'
import PortfolioMulticolor from './assets/empty-state-portfolio/empty-state-portfolio=Multicolor.svg?url'
import PortfolioPurple from './assets/empty-state-portfolio/empty-state-portfolio=Purple.svg?url'
import PortfolioOrange from './assets/empty-state-portfolio/empty-state-portfolio=Orange.svg?url'

import CurrencyNOK from './assets/selected-currencies/nok-selected=Default.svg?url'
import CurrencyEUR from './assets/selected-currencies/eur-selected=Default.svg?url'
import CurrencyUSD from './assets/selected-currencies/usd-selected=Default.svg?url'
import CurrencySEK from './assets/selected-currencies/sek-selected=Default.svg?url'

import CakeDefault from './assets/cake/cake=Default.svg?url'

import BrokenLinkDefault from './assets/broken-link/broken-link=Default.svg?url'
import StatusError from './assets/status/Status=error.svg?url'
import StatusSucessCircle from './assets/status/Status=success-circle.svg?url'
import StatusSucessConfetti from './assets/status/Status=success-confetti.svg?url'
import StatusEmailSent from './assets/status/Status=email-sent.svg?url'

import ErrorMissingInfo from './assets/error/error-missing-info.svg?url'
import ErrorProblem from './assets/error/error-problem.svg?url'
import ErrorQuestion from './assets/error/error-question.svg?url'
import ErrorThinking from './assets/error/error-thinking.svg?url'
import ErrorWarning from './assets/error/error-warning.svg?url'
import ErrorWhat from './assets/error/error-what.svg?url'

interface Illustration {
    default: string
    orange?: string
    purple?: string
    multiColor?: string
}

const ILLUSTRATION_MAP = {
    womanOfficeWorking: {
        default: WomanOfficeWorkingDefault,
        orange: WomanOfficeWorkingOrange,
        purple: WomanOfficeWorkingPurple,
        multiColor: WomanOfficeWorkingMultiColor,
    },
    meetingDiscussion: {
        default: MeetingDiscussionDefault,
        orange: MeetingDiscussionOrange,
        purple: MeetingDiscussionPurple,
        multiColor: MeetingDiscussionMulticolor,
    },
    manShowingReport: {
        default: ManShowingReportDefault,
        orange: ManShowingReportOrange,
        purple: ManShowingReportPurple,
        multiColor: ManShowingReportMulticolor,
    },
    fixrateEcosystem: {
        default: FixrateEcosystemDefault,
        orange: FixrateEcosystemOrange,
        purple: FixrateEcosystemPurple,
        multiColor: FixrateEcosystemMulticolor,
    },
    emailSent: {
        default: EmailSentDefault,
        orange: EmailSentOrange,
        purple: EmailSentPurple,
        multiColor: EmailSentMulticolor,
    },
    manAnalyzing: {
        default: ManAnalyzingDefault,
        orange: ManAnalyzingOrange,
        purple: ManAnalyzingPurple,
        multiColor: ManAnalyzingMulticolor,
    },
    manCoffeeDocument: {
        default: ManCoffeeDocumentDefault,
        orange: ManCoffeeDocumentOrange,
        purple: ManCoffeeDocumentPurple,
        multiColor: ManCoffeeDocumentMulticolor,
    },
    cake: {
        default: CakeDefault,
    },
    portfolio: {
        default: PortfolioDefault,
        orange: PortfolioOrange,
        purple: PortfolioPurple,
        multiColor: PortfolioMulticolor,
    },
    currencyNOK: {
        default: CurrencyNOK,
    },
    currencyEUR: {
        default: CurrencyEUR,
    },
    currencyUSD: {
        default: CurrencyUSD,
    },
    currencySEK: {
        default: CurrencySEK,
    },
    brokenLink: {
        default: BrokenLinkDefault,
    },
    statusError: {
        default: StatusError,
    },
    statusSuccessCircle: {
        default: StatusSucessCircle,
    },
    statusSuccessConfetti: {
        default: StatusSucessConfetti,
    },
    statusEmailSent: {
        default: StatusEmailSent,
    },
    errorMissingInfo: {
        default: ErrorMissingInfo,
    },
    errorProblem: {
        default: ErrorProblem,
    },
    errorQuestion: {
        default: ErrorQuestion,
    },
    errorThinking: {
        default: ErrorThinking,
    },
    errorWarning: {
        default: ErrorWarning,
    },
    errorWhat: {
        default: ErrorWhat,
    },
}

export type IllustrationName = keyof typeof ILLUSTRATION_MAP
export type IllustrationColor = keyof Illustration

type Props = {
    name: IllustrationName
    color: IllustrationColor
    width?: string
}

export default function FixrateIllustration({ name, color, width = 'auto' }: Props): JSX.Element | null {
    const illustration: Illustration = ILLUSTRATION_MAP[name]

    return illustration ? <img style={{ maxWidth: '100%', width: width }} src={illustration[color]} alt="" /> : null
}
