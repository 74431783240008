import update from 'immutability-helper'
import { removeField, setField } from '#app/services/immutable'
import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
    name: 'depositors',
    initialState: [],
    reducers: {
        setDepositors(state, action) {
            return action.payload
        },
        updateDepositor(state, action) {
            const index = state.findIndex((depositor) => depositor.id === action.payload.id)
            if (index !== -1) {
                return update(state, { $splice: [[index, 1, action.payload]] })
            } else {
                return update(state, { $push: [action.payload] })
            }
        },
        setCustomerDeclarationValue(state, action) {
            // Find matching depositor
            const index = state.findIndex((depositor) => depositor.id === action.payload.depositorId)
            const customerDeclaration = state[index].customerDeclaration

            // Change field in customer declaration
            const newCustomerDeclaration = setField(customerDeclaration, action.payload.path, action.payload.value)

            // Create new state
            const newDepositor = update(state[index], { customerDeclaration: { $set: newCustomerDeclaration } })
            return update(state, { $splice: [[index, 1, newDepositor]] })
        },
        deleteCustomerDeclarationValues(state, action) {
            // Find matching depositor
            const index = state.findIndex((depositor) => depositor.id === action.payload.depositorId)
            let customerDeclaration = state[index].customerDeclaration

            // Remove field in customer declaration
            action.payload.paths.forEach((path) => {
                customerDeclaration = removeField(customerDeclaration, path)
            })

            // Create new state
            const newDepositor = update(state[index], { customerDeclaration: { $set: customerDeclaration } })
            return update(state, { $splice: [[index, 1, newDepositor]] })
        },
    },
})

const { actions, reducer } = slice
export const { setDepositors, updateDepositor, setCustomerDeclarationValue, deleteCustomerDeclarationValues } = actions
export default reducer
