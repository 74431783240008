import { DateOutput, LoadingSpinner } from '#app/components'
import FxDialog from '#app/components/FxDialog/FxDialog'
import FxDialogContent from '#app/components/FxDialog/FxDialogContent'
import FxDialogTitle from '#app/components/FxDialog/FxDialogTitle'
import { useSelector } from '#app/state/useSelector'
import { PersonValidator } from '#services/PersonValidator'
import { DepositorWarningType, OrganisationalPersonDto } from '@fixrate/fixrate-query'
import { Box, Button, Stack, Tab, Tabs, Typography } from '@mui/material'
import { isAfter } from 'date-fns'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { WarningElement } from '../../views/PersonWarnings'
import ResendUserInvite from '../DepositorPersonList/ResendUserInvite'
import InviteModal from '../InviteModal'
import Contact from './Contact/Contact'
import Nationality from './Nationality/Nationality'
import Ownership from './Ownership/Ownership'
import PEP from './PEP/PEP'
import UpdateStatus from './UpdateStatus'

export interface NationalIdentity {
    nationality: string
    value: string
}

interface Props {
    person: OrganisationalPersonDto
    onClose: () => void
    open: boolean
    hideInvitationAlert?: boolean
    wizardMode?: boolean
}

type TabCategory = 'contact' | 'nationality' | 'ownership' | 'pep'
type TabStep = {
    name: TabCategory
    visible: boolean
}
export default function PersonalInformation({ person, onClose, open, hideInvitationAlert, wizardMode }: Props) {
    const { t } = useTranslation()
    const depositor = useSelector((state) => state.depositor)
    const [sendInviteVisible, setSendInviteVisible] = useState(false)
    const [resendInviteVisible, setResendInviteVisible] = useState(false)
    const [loadingContactInfo, setLoadingContactInfo] = useState(false)
    const [currentTab, setCurrentTab] = useState<TabCategory>('contact')
    const [loading, setLoading] = useState(false)
    const [updateDate, setUpdateDate] = useState<Date | undefined>(undefined)
    const isInCustomerDeclaration = PersonValidator.isCustomerDeclarationPerson(person)
    const isBeneficialOwner = PersonValidator.isBeneficialOwner(person)
    const userInvite = depositor.userInvites.find((ui) => ui.id === person.associatedInviteId)
    const inviteExpired = userInvite && isAfter(new Date(), new Date(userInvite.expirationDate))
    const inviteInvalid = userInvite?.invalid
    const tabSteps: TabStep[] = [
        {
            name: 'contact',
            visible: true,
        },
        {
            name: 'nationality',
            visible: isInCustomerDeclaration,
        },
        {
            name: 'pep',
            visible: isInCustomerDeclaration,
        },
        {
            name: 'ownership',
            visible: isBeneficialOwner,
        },
    ]

    const visibleSteps = tabSteps.filter((step) => step.visible)
    const currentStep = visibleSteps.find((step) => step.name === currentTab)
    const currentStepNumber = visibleSteps.findIndex((step) => step.name === currentStep.name)

    const hasWarning = (type: DepositorWarningType): boolean =>
        depositor.warnings.some(
            (warning) => warning.type === type && (!warning.personId || warning.personId === person.personId)
        )

    const hasAnyWarnings = () => {
        return (
            hasWarning('PERSON_NEEDS_INVITE') ||
            hasWarning('AUTHORIZATION_SIGNER_NEEDS_INVITE') ||
            inviteExpired ||
            inviteInvalid
        )
    }

    const nextStep = () => {
        setCurrentTab(visibleSteps[currentStepNumber + 1].name)
    }

    const previousStep = () => {
        setCurrentTab(visibleSteps[currentStepNumber - 1].name)
    }

    const categoryHasWarnings = (category: TabCategory): boolean => {
        if (category === 'contact' && PersonValidator.ContactInformationIsValid(person)) {
            return false
        }

        if (category === 'nationality' && PersonValidator.NationalityInformationIsValid(person)) {
            return false
        }

        if (category === 'pep' && PersonValidator.PEPInformationIsValid(person)) {
            return false
        }

        if (category === 'ownership' && PersonValidator.OwnershipInformationIsValid(person)) {
            return false
        }

        return true
    }

    function categoryWarningIcon(category: TabCategory): JSX.Element | undefined {
        return categoryHasWarnings(category) ? <i className="ri-error-warning-line red" /> : undefined
    }

    function setUpdating(value: boolean) {
        setLoading(value)
        if (loading === false) {
            setUpdateDate(new Date())
        }
    }

    // This function is used to force the update of the information in the modal when information is updated through the invitation modal
    function forceUpdatedInformation() {
        setLoadingContactInfo(true)

        setTimeout(() => {
            setLoadingContactInfo(false)
        }, 1000)
    }

    return (
        <FxDialog open={open} maxWidth={'md'} onClose={onClose}>
            <FxDialogTitle onClose={onClose}>
                {person.firstName + ' ' + person.lastName}
                <Typography component="span" ml={0.5}>
                    {person.birthDate && '(f. ' + DateOutput.formatDate(person.birthDate) + ')'}
                </Typography>
            </FxDialogTitle>
            <FxDialogContent sx={{ pt: 0 }} data-cy="personalInformationModal">
                <Stack width={'80rem'} minHeight={'55rem'} maxWidth="100%">
                    {isInCustomerDeclaration && (
                        <Tabs value={currentTab} onChange={(e, val: TabCategory) => setCurrentTab(val)}>
                            <Tab
                                data-cy="contactTab"
                                icon={categoryWarningIcon('contact')}
                                label={t('pages-organizations.general')}
                                value="contact"
                            />
                            <Tab
                                data-cy="nationalityTab"
                                icon={categoryWarningIcon('nationality')}
                                label={t('pages-organizations.personInfo')}
                                value="nationality"
                            />
                            <Tab
                                data-cy="pepTab"
                                icon={categoryWarningIcon('pep')}
                                label={t('pages-organizations.pep')}
                                value="pep"
                            />
                            {isBeneficialOwner && (
                                <Tab
                                    data-cy="ownershipTab"
                                    icon={categoryWarningIcon('ownership')}
                                    label={t('pages-organizations.ownership')}
                                    value="ownership"
                                />
                            )}
                        </Tabs>
                    )}
                    <Box mt={2}>
                        {currentTab === 'contact' &&
                            (loadingContactInfo ? (
                                <LoadingSpinner />
                            ) : (
                                <Contact person={person} setUpdating={setUpdating} />
                            ))}
                        {currentTab === 'nationality' && isInCustomerDeclaration && (
                            <Nationality region="NO" person={person} setUpdating={setUpdating} />
                        )}
                        {currentTab === 'pep' && isInCustomerDeclaration && (
                            <PEP person={person} setUpdating={setUpdating} />
                        )}
                        {currentTab === 'ownership' && isInCustomerDeclaration && (
                            <Ownership person={person} setUpdating={setUpdating} />
                        )}
                    </Box>
                </Stack>
                {hasAnyWarnings && !hideInvitationAlert && (
                    <Stack mb={2}>
                        {hasWarning('PERSON_NEEDS_INVITE') && (
                            <WarningElement
                                warningText={t('blocks-UsersList.warningNeedsInvite')}
                                linkText={t('blocks-UsersList.menuItemInviteNow')}
                                dataCy="inviteNowLink"
                                onSendInvite={() => setSendInviteVisible(true)}
                                showRedLeftBorder={false}
                            />
                        )}

                        {hasWarning('AUTHORIZATION_SIGNER_NEEDS_INVITE') && (
                            <WarningElement
                                warningText={t('blocks-UsersList.warningNeedsInviteForAuthorizations')}
                                linkText={t('blocks-UsersList.menuItemInviteNow')}
                                dataCy="inviteNowLink"
                                onSendInvite={() => setSendInviteVisible(true)}
                                showRedLeftBorder={false}
                            />
                        )}
                        {inviteExpired && (
                            <WarningElement
                                warningText={t('blocks-UsersList.warningInviteExpired')}
                                linkText={t('blocks-UsersList.menuItemResendInvite')}
                                dataCy="resendNowLink"
                                onSendInvite={() => setResendInviteVisible(true)}
                                showRedLeftBorder={false}
                            />
                        )}

                        {inviteInvalid && !inviteExpired && (
                            <WarningElement
                                warningText={t('blocks-UsersList.warningInviteInvalid')}
                                showRedLeftBorder={false}
                            />
                        )}
                    </Stack>
                )}

                {sendInviteVisible && (
                    <InviteModal
                        personId={person.personId}
                        close={() => {
                            forceUpdatedInformation()
                            setSendInviteVisible(false)
                        }}
                    />
                )}

                {resendInviteVisible && (
                    <ResendUserInvite
                        depositorId={depositor.id}
                        inviteId={userInvite.id}
                        inviteEmail={userInvite.email}
                        close={() => {
                            forceUpdatedInformation()
                            setResendInviteVisible(false)
                        }}
                        returnTo={''}
                    />
                )}
                {wizardMode && isInCustomerDeclaration ? (
                    <Stack direction="row" justifyContent={'space-between'} alignItems="flex-start" mt={2}>
                        <Stack direction="row" gap={2} alignItems="flex-start">
                            {currentTab !== 'contact' && (
                                <Button
                                    startIcon={<i className="ri-arrow-left-line" />}
                                    variant="outlined"
                                    onClick={() => previousStep()}
                                >
                                    {t('common.back')}
                                </Button>
                            )}
                            <UpdateStatus loading={loading} lastUpdated={updateDate} sx={{ mt: 1 }} />
                        </Stack>
                        {currentStep.name !== visibleSteps[visibleSteps.length - 1].name ? (
                            <Button
                                endIcon={<i className="ri-arrow-right-line" />}
                                variant="contained"
                                data-cy="goToNext"
                                onClick={() => nextStep()}
                            >
                                {t('common.goToNext')}
                            </Button>
                        ) : (
                            <Button variant="contained" data-cy="close" onClick={onClose}>
                                {t('common.close')}
                            </Button>
                        )}
                    </Stack>
                ) : (
                    <Stack direction="row" justifyContent={'space-between'} alignItems="center" mt={2}>
                        <UpdateStatus loading={loading} lastUpdated={updateDate} />
                        <Button variant="contained" onClick={onClose}>
                            {t('common.close')}
                        </Button>
                    </Stack>
                )}
            </FxDialogContent>
        </FxDialog>
    )
}
