import { PURPLE, SILVER_GRAY } from '#app/colors/colors'
import { SxProps, Typography, Box, TextField, Autocomplete, Stack, Chip } from '@mui/material'
import { SyntheticEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { countries, CountryType } from './countryTypes'
import 'flag-icons/css/flag-icons.min.css'

export default function CountryMultiSelect({
    selectedCountries,
    setSelectedCountries,
    dataCy,
    sx,
}: {
    selectedCountries: string[]
    setSelectedCountries: (countries: string[]) => void
    dataCy?: string
    sx?: SxProps
}) {
    const { t } = useTranslation()
    const handleChange = (event: SyntheticEvent, countries: CountryType[]) => {
        setSelectedCountries(countries.map((country) => country.code))
    }

    const sortedCountries = [...countries].sort((a, b) => {
        if (a.suggested && !b.suggested) {
            return -1
        } else if (!a.suggested && b.suggested) {
            return 1
        } else {
            return 0
        }
    })

    return (
        <Autocomplete
            data-cy={dataCy ?? 'country-select'}
            id="fixrate-country-select"
            value={sortedCountries.filter((country) => selectedCountries?.includes(country.code)) ?? []}
            options={sortedCountries}
            sx={sx}
            autoHighlight
            multiple
            groupBy={(option) =>
                option.suggested ? t('components-CountrySelect.suggestions') : t('components-CountrySelect.all')
            }
            autoComplete={false}
            getOptionLabel={(option: CountryType) => option.label}
            onChange={handleChange}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <Chip
                        component="li"
                        icon={<Box width={'2rem'} height={'2rem'} className={'fi fi-' + option.code.toLowerCase()} />}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            padding: 0.5,
                            margin: 0.5,
                            backgroundColor: SILVER_GRAY[50],
                            borderRadius: '0.4rem',
                        }}
                        {...getTagProps({ index })}
                        key={option.code}
                        label={option.label}
                    />
                ))
            }
            renderGroup={(params) => (
                <Box key={params.group}>
                    <Typography
                        fontSize={'1.2rem'}
                        fontWeight={600}
                        sx={{
                            position: 'sticky',
                            top: '-0.8rem',
                            py: 0.5,
                            px: 1,
                            backgroundColor: SILVER_GRAY[50],
                            color: PURPLE[900],
                        }}
                    >
                        {params.group}
                    </Typography>
                    <Box>{params.children}</Box>
                </Box>
            )}
            renderOption={(props, option) => {
                // Remove the key prop from the props object, necessary to avoid key being spread warning
                const p = { ...props }
                delete p['key']
                return (
                    <Box
                        component="li"
                        data-cy={'country-select-option-' + option.code}
                        sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                        {...p}
                        key={option.code}
                    >
                        <Stack direction="row">
                            <Box
                                width={'2rem'}
                                height={'2rem'}
                                className={'fi fi-' + option.code.toLowerCase()}
                                mr={1}
                            />
                            <span>
                                {option.label} ({option.code})
                            </span>
                        </Stack>
                    </Box>
                )
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: 'off', // disable autocomplete and autofill
                    }}
                />
            )}
        />
    )
}
