import { Fragment, useState } from 'react'
import config from '#app/config'
import useCurrentDepositor from '#app/services/useCurrentDepositor'
import { useTranslation } from 'react-i18next'
import {
    Button,
    Checkbox,
    FormControlLabel,
    FormGroup,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
} from '@mui/material'
import { useSelector } from '#app/state/useSelector'
import { useAuthorization } from '#app/services/authorization'
import { useCommand } from '#app/services/beta'
import AsyncButton from '#app/components/Button/AsyncButton'

const API_BASE_URL = config().apiUrl

const GeneralTerms = () => {
    const depositor = useCurrentDepositor()
    const auth = useAuthorization()
    const [acceptedTermsOfUse, setAcceptedTermsOfUse] = useState(false)
    const { acceptDepositorTerms: acceptDepositorTermsCommand } = useCommand()
    const generalTermsDocumentId = useSelector(
        (state) => state.depositor.contracts.filter((c) => c.documentType === 'DEPOSITOR_TERMS')?.[0]?.documentId
    )
    const { t } = useTranslation()
    const generalTermsUrl = `${API_BASE_URL}/api/document/id/${generalTermsDocumentId}/data`

    async function acceptDepositorTerms() {
        try {
            await acceptDepositorTermsCommand(depositor.id, generalTermsDocumentId)
        } catch (err) {
            console.error(err)
        }
    }

    return (
        <>
            {!depositor.termsAccepted && (
                <p>{t('pages-organizations.termsOfServiceMessage', { depositorName: depositor.name })}</p>
            )}
            {!depositor.termsAccepted && (
                <Fragment>
                    <FormGroup>
                        <FormControlLabel
                            data-cy="acceptTermsCheckbox"
                            control={
                                <Checkbox
                                    disabled={!auth.depositor.hasAdminRole && !auth.depositor.hasPartnerAccountantRole}
                                    checked={acceptedTermsOfUse}
                                    onChange={(e) => setAcceptedTermsOfUse(e.target.checked)}
                                />
                            }
                            label={
                                <span>
                                    <span data-cy="acceptTermsLabel">
                                        {t('pages-organizations.acceptTermsOfService', {
                                            depositorName: depositor.name,
                                        })}{' '}
                                    </span>
                                    <a href={generalTermsUrl} rel="noopener noreferrer" target="_blank">
                                        {t('pages-organizations.readTerms')}
                                    </a>
                                </span>
                            }
                        />
                    </FormGroup>
                    {!auth.depositor.hasAdminRole && !auth.depositor.hasPartnerAccountantRole && (
                        <p className="field-info-message">{t('pages-organizations.mustBeAcceptedByAdmin')}</p>
                    )}
                    <AsyncButton
                        disabled={!acceptedTermsOfUse}
                        onClick={acceptDepositorTerms}
                        data-cy="acceptTermsButton"
                        variant={'contained'}
                        sx={{ mt: 2 }}
                    >
                        {t('pages-organizations.acceptTerms')}
                    </AsyncButton>
                </Fragment>
            )}
            {depositor.termsAccepted && (
                <List sx={{ maxWidth: '60rem' }}>
                    <ListItem sx={{ paddingLeft: '0' }}>
                        <ListItemIcon sx={{ backgroundColor: '#40a25120' }}>
                            <i className="ri-check-line green" />
                        </ListItemIcon>
                        <ListItemText
                            primary={t('pages-organizations.accepted')}
                            secondary={t('pages-organizations.acceptedDescription')}
                        />
                        <Button
                            sx={{ minWidth: '10rem', marginLeft: '1rem' }}
                            size={'small'}
                            variant={'outlined'}
                            href={generalTermsUrl}
                            target={'_blank'}
                            rel="noopener noreferrer"
                        >
                            {t('pages-organizations.termsOfServiceLink')}
                        </Button>
                    </ListItem>
                </List>
            )}
        </>
    )
}

export default GeneralTerms
