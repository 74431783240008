import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
    name: 'commandIds',
    initialState: {},
    reducers: {
        setCommandId(state, { payload }) {
            const [key, value] = payload
            return {
                ...state,
                [key]: {
                    pending: state?.[key]?.pending ?? true,
                    value,
                },
            }
        },
        removeCommandId(state, { payload }) {
            return Object.entries(state).reduce((newState, [key, value]) => {
                if (key === payload) {
                    return newState
                }
                newState[key] = value
                return newState
            }, {})
        },
        updateCommandId(state, { payload }) {
            const oldObjectReference = state[payload] ?? {}
            return {
                ...state,
                [payload]: {
                    ...oldObjectReference,
                    pending: false,
                },
            }
        },
    },
})

const { actions, reducer } = slice
export const { updateCommandId } = actions
export default reducer
