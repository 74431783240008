import {useQuery} from "react-query";
import {FundAccountingReport, FundInventoryReport} from "@fixrate/fixrate-report";

export function useFundAccountingReport(depositorId: string, portfolioId: string, period: string, version: string) {
    const versionSuffix = version ? `&version=${version}` : ''

    const {
        data: report,
        isSuccess,
        isLoading,
        isError,
    } = useQuery({
        enabled: !!depositorId && !!period && !!portfolioId,
        retry: 3,
        refetchOnWindowFocus: false,
        queryFn: async () => {
            if (depositorId && period) {
                const data = await fetch(
                    `/api/report/fund-accounting?depositorId=${depositorId}&portfolioId=${portfolioId}&period=${period}${versionSuffix}`
                )
                if (data.status === 404) {
                    return null
                }
                return (await data.json()) as FundAccountingReport
            }
        },
        queryKey: ['fund-accounting-report', depositorId, period, portfolioId, version],
    })

    return {report, isSuccess, isLoading, isError}
}

export function useFundInventoryReport(depositorId: string, portfolioId: string, period: string, version: string) {
    const versionSuffix = version ? `&version=${version}` : ''
    const {
        data: report,
        isSuccess,
        isLoading,
        isError,
    } = useQuery({
        enabled: !!depositorId && !!period && !!portfolioId,
        refetchOnWindowFocus: false,
        retry: 3,
        queryFn: async () => {
            if (depositorId && period) {
                const data = await fetch(`/api/report/fund-inventory?depositorId=${depositorId}&portfolioId=${portfolioId}&period=${period}${versionSuffix}`)
                if (data.status === 404) {
                    return null
                }
                return (await data.json()) as FundInventoryReport
            }
        },
        queryKey: ['fund-inventory-report', depositorId, period, portfolioId, version],
    })

    return {report, isSuccess, isLoading, isError}
}

export function useFundReportVersions( reportType: "ACCOUNTING" | "INVENTORY", depositorId: string, portfolioId: string, period: string, currentVersion?: number | string | undefined,) {
    const url = reportType === "ACCOUNTING"
        ? `/api/report/fund-accounting/available-versions?depositorId=${depositorId}&portfolioId=${portfolioId}&period=${period}`
        : `/api/report/fund-inventory/available-versions?depositorId=${depositorId}&portfolioId=${portfolioId}&period=${period}`
    const queryKey = reportType === "ACCOUNTING"
        ? ['fund-accounting-report-versions', depositorId, portfolioId, period, currentVersion]
        : ['fund-inventory-report-versions', depositorId, portfolioId, period, currentVersion]
    const {
        data: availableVersions,
        isSuccess,
        isLoading,
        isError,
    } = useQuery({
        enabled: !!depositorId && !!period && !!portfolioId,
        retry: 3,
        refetchOnWindowFocus: false,
        queryFn: async () => {
            if (depositorId && period) {
                const data = await fetch(url)
                if (data.status === 404) {
                    return {}
                }
                return ((await data.json()) as {[v: string]: string})
            }
        },
        queryKey: queryKey,
    })

    return {availableVersions, isSuccess, isLoading, isError}
}
