import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
    name: 'fundBuyOrders',
    initialState: [],
    reducers: {
        setFundBuyOrders(state, action) {
            return action.payload
        },
    },
})

const { actions, reducer } = slice
export const { setFundBuyOrders } = actions
export default reducer
