import { Box, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { useSelector } from '#state/useSelector'
import { useTranslation } from 'react-i18next'
import { fundCategoryTMap } from '#services/enumTranslationKeyMapping'
import { CurrencyOutput, InterestOutput } from '#components'
import { FundCategory } from '@fixrate/fixrate-query'
import { PURPLE } from '#app/colors/colors'
import usePlatformFeeVirtualValuta from '#services/platformFeeVirtualValuta'
import usePortfolio from '#services/usePortfolio'
import { sendSentryMessage } from '#services/sentry'

type Props = {
    order?: {
        type: 'BUY' | 'SELL'
        amount: number
    }
}

export default function FundPlatformFeeTable({ order }: Props) {
    const { t } = useTranslation()
    const { convertToVirtual, convertFromVirtual } = usePlatformFeeVirtualValuta()
    const portfolio = usePortfolio()

    const feeModel = useSelector((state) => {
        return state.depositor?.fundData?.feeModel
    })

    if (!feeModel || !portfolio) {
        return null
    }
    if (order != null && order.amount < 0) {
        sendSentryMessage(
            'Got negative order amount in FundPlatformFeeTable; order amounts should be absolute values.',
            { amount: order.amount },
            'error'
        )
        return null
    }
    const orderType = order?.type
    const volumeChange = orderType === 'BUY' ? order.amount : orderType === 'SELL' ? -order.amount : 0

    const allSteps = Object.keys(feeModel.platformFeeSteps)
        .map((step) => +step)
        .sort((a, b) => a - b)

    const selectedStep = [...allSteps]
        .reverse()
        .find(
            (step) =>
                +step * 1e6 <= feeModel.marketValueVirtualValuta + convertToVirtual(portfolio.currency, volumeChange)
        )

    const newMarketValue = {
        ...feeModel.marketValue,
        [portfolio.currency]: (feeModel.marketValue[portfolio.currency] ?? 0) + volumeChange,
    }
    const totalValueStr = Object.keys(newMarketValue)
        .map((currency) => `${CurrencyOutput.formatMillion(newMarketValue[currency])} ${currency}`)
        .join(', ')

    const platformFeeCategories = Object.keys(feeModel.platformFeeSteps[0])

    return (
        <Table size={'small'}>
            <TableHead>
                <TableRow>
                    <TableCell align={'left'}></TableCell>
                    <TableCell align={'left'}>{t('components.FundPlatformFeeTable.entryVolume')}</TableCell>
                    <TableCell align={'left'}>
                        {orderType === 'BUY'
                            ? t('components.FundPlatformFeeTable.volumeAfterPurchase')
                            : orderType === 'SELL'
                              ? t('components.FundPlatformFeeTable.volumeAfterSale')
                              : t('components.FundPlatformFeeTable.yourVolume')}
                    </TableCell>
                    {platformFeeCategories.map((category) => (
                        <TableCell key={category} align={'right'}>
                            {t(fundCategoryTMap[category])}
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {allSteps.map((volumeStep) => {
                    const isSelected = volumeStep === selectedStep
                    return (
                        <TableRow
                            key={volumeStep}
                            sx={
                                isSelected
                                    ? { backgroundColor: PURPLE[50], '& .MuiTableCell-root': { fontWeight: 700 } }
                                    : {}
                            }
                        >
                            <TableCell sx={{ pr: 1, pl: 1 }} align={'left'}>
                                {isSelected && (
                                    <Box>
                                        <i className="ri-arrow-right-line" />
                                    </Box>
                                )}
                            </TableCell>
                            <TableCell align={'left'}>
                                {CurrencyOutput.formatMillion(
                                    convertFromVirtual(portfolio.currency, +volumeStep * 1e6)
                                )}
                            </TableCell>
                            <TableCell align={'left'}>{isSelected ? totalValueStr : null}</TableCell>
                            {Object.keys(feeModel.platformFeeSteps[volumeStep]).map((category: FundCategory) => {
                                const value = feeModel.platformFeeSteps[volumeStep][category]
                                return (
                                    <TableCell
                                        key={category}
                                        align={'right'}
                                        sx={{ fontVariantNumeric: 'tabular-nums' }}
                                    >
                                        {value ? (
                                            InterestOutput.formatWithDecimals(value, 3)
                                        ) : (
                                            <i className="ri-arrow-up-line" />
                                        )}
                                    </TableCell>
                                )
                            })}
                        </TableRow>
                    )
                })}
            </TableBody>
        </Table>
    )
}
