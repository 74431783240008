/*
    General functions for handling date and time
 */

import format from 'date-fns/format'
import dateFnsIsBefore from 'date-fns/isBefore'
import dateFnsIsAfter from 'date-fns/isAfter'
import dateFnsIsEqual from 'date-fns/isEqual'
import { DateAsString } from '@fixrate/fixrate-query'
import isValid from 'date-fns/isValid'
import parse from 'date-fns/parse'

let skew = 0

// Records time skew
export const setCorrectDate = (serversDate: Date) => {
    skew = serversDate.getTime() - new Date().getTime()
    console.log('Time skew changed to ' + skew)
}

// Returns the date, corrected for time skew
export const correctDate = (date?: Date) => {
    if (!date) date = new Date()
    return new Date(date.getTime() + skew)
}

export const convertStringToDate = (value: string | Date): Date => {
    if (!value) {
        return null
    }
    if ((value as Date)?.getTime) {
        // If the provided value already is a Date, returns the date
        return value as Date
    }
    const dateAsString = value as string
    if (dateAsString.lastIndexOf('Z') === dateAsString.length - 1) {
        return new Date(dateAsString)
    }
    const [year, month, date, hours, minutes, seconds] = dateAsString.split(/[^0-9]/)
    return new Date(+year, +month - 1, +date, +hours || 0, +minutes || 0, +seconds || 0)
}

export const sortDatesAscending = (date1, date2) =>
    format(new Date(date1), 'yyyy-MM-dd HH:mm').localeCompare(format(new Date(date2), 'yyyy-MM-dd HH:mm'))
export const sortDatesDescending = (date1, date2) =>
    format(new Date(date2), 'yyyy-MM-dd HH:mm').localeCompare(format(new Date(date1), 'yyyy-MM-dd HH:mm'))

export function isBefore(a: DateAsString | Date, b: DateAsString | Date) {
    return dateFnsIsBefore(toDate(a), toDate(b))
}

export function isEqual(a: DateAsString | Date, b: DateAsString | Date) {
    return dateFnsIsEqual(toDate(a), toDate(b))
}

export function isAfter(a: DateAsString | Date, b: DateAsString | Date) {
    return dateFnsIsAfter(toDate(a), toDate(b))
}

export function toDate(stringOrDate: DateAsString | Date | null) {
    if (stringOrDate === null) return null
    if (typeof stringOrDate === 'string' || stringOrDate instanceof String) {
        return new Date(stringOrDate)
    }
    return stringOrDate
}

export function isValidDate(value: string | Date): boolean {
    return isValid(new Date(value))
}

export function parseNorwegianDateFormat(value: string): Date {
    return parse(value, 'dd.MM.yyyy', new Date())
}
