import { Fragment } from 'react'
import {
    DepositorDto,
    DepositorUserDto,
    OrganisationalPersonDto,
    OrganisationalRole,
    OrganisationUserInviteDto,
    SecurityRole,
} from '@fixrate/fixrate-query'
import PersonBadge from '#pages/organizations/OrganizationDetail/UsersAndRoles/components/PersonBadge'
import styles from './BadgeList.module.scss'
import AddUserButton from '#pages/organizations/OrganizationDetail/UsersAndRoles/components/AddUserButton'
import classNames from 'classnames'
import { useTranslation } from '#components/i18n'
import { useAuthorization } from '#services/authorization'
import { Stack } from '@mui/material'

interface Props {
    organisationalRole?: OrganisationalRole | null
    securityRole?: SecurityRole | null
    maxNoInRole?: number
    getHeader?: (person: OrganisationalPersonDto) => React.ReactNode
    selectedOpenRole: SecurityRole | OrganisationalRole | null
    setSelectedOpenRole: (role: SecurityRole | OrganisationalRole | null) => void
    depositor: DepositorDto
    people: OrganisationalPersonDto[]
    partnerAccountantHasAccess?: boolean
    row?: boolean
    missingRoleFeedback?: string
    onlyInvitesCoverRoleFeedback?: string
    showPersonalWarnings?: boolean
    namesWithRoles?: boolean
    centered?: boolean
}

export default function BadgeList({
    organisationalRole,
    securityRole,
    maxNoInRole,
    getHeader,
    selectedOpenRole,
    setSelectedOpenRole,
    depositor,
    people,
    partnerAccountantHasAccess,
    row,
    missingRoleFeedback,
    onlyInvitesCoverRoleFeedback,
    showPersonalWarnings = true,
    namesWithRoles,
    centered,
}: Props) {
    const { t } = useTranslation('pages-organizations')
    const isSecurityRolebadge = !!securityRole
    const auth = useAuthorization(depositor.id)
    const hasHeader = !!getHeader

    const canEdit =
        auth.depositor.hasAdminRole ||
        auth.depositor.hasPartnerRole ||
        (auth.depositor.hasAccountHolderRole && !isSecurityRolebadge)

    const filteredPeople = people.filter(
        (person) =>
            (!securityRole && !organisationalRole) ||
            (partnerAccountantHasAccess && person.securityRoles.includes('DEPOSITOR_PARTNER_ACCOUNTANT')) ||
            (securityRole && person.securityRoles.includes(securityRole)) ||
            (organisationalRole && person.organisationalRoles.includes(organisationalRole))
    )

    const userBadgeList = filteredPeople.map((person) => (
        <PersonBadge
            key={person.personId}
            depositor={depositor}
            person={person}
            role={organisationalRole}
            getHeader={getHeader}
            securityRole={securityRole}
            personalWarningsVisible={showPersonalWarnings}
            namesWithRoles={namesWithRoles}
            canDelete={
                (canEdit &&
                    person.securityRoles.includes('DEPOSITOR_PARTNER_ACCOUNTANT') &&
                    !partnerAccountantHasAccess) ||
                (canEdit && !person.securityRoles.includes('DEPOSITOR_PARTNER_ACCOUNTANT'))
            }
        />
    ))

    const missingRole = !!missingRoleFeedback && userBadgeList.length === 0
    const onlyInvitesCoverRole =
        !!onlyInvitesCoverRoleFeedback && filteredPeople.length > 0 && filteredPeople.every(isInvite)

    return (
        <Fragment>
            <Stack
                component="ul"
                spacing={1.5}
                flexWrap="wrap"
                alignItems={centered ? 'center' : 'flex-start'}
                justifyContent={centered ? 'center' : 'flex-start'}
                direction={row ? 'row' : 'column'}
            >
                {userBadgeList}
                {setSelectedOpenRole && (!maxNoInRole || maxNoInRole > filteredPeople.length) && (
                    <AddUserButton
                        data-cy={securityRole || organisationalRole}
                        organisationalRole={organisationalRole}
                        securityRole={securityRole}
                        selectedOpenRole={selectedOpenRole}
                        setSelectedOpenRole={setSelectedOpenRole}
                        depositorId={depositor.id}
                        people={people}
                        canAddRole={canEdit}
                        highlight={missingRole}
                        hasHeader={hasHeader && userBadgeList.length > 0}
                        namesWithRoles={namesWithRoles}
                        text={userBadgeList.length ? t('addMore') : t('add')}
                    />
                )}
            </Stack>

            {missingRole && row && (
                <p className={classNames(styles.errorMessageNewMargin, 'field-error-message')}>{missingRoleFeedback}</p>
            )}

            {missingRole && !row && (
                <div className={styles.roleMissingWrapper}>
                    <p className={styles.roleMissing}>{missingRoleFeedback}</p>
                </div>
            )}

            {onlyInvitesCoverRole && (
                <div className={styles.roleMissingWrapper}>
                    <p className={styles.roleMissing}>{onlyInvitesCoverRoleFeedback}</p>
                </div>
            )}
        </Fragment>
    )
}

function isInvite(userOrInvite: DepositorUserDto | OrganisationUserInviteDto | OrganisationalPersonDto): boolean {
    return Object.hasOwn(userOrInvite, 'expirationDate')
}
