import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
    name: 'bankDocuments',
    initialState: [],
    reducers: {
        setBankDocuments(state, action) {
            return action.payload
        },
    },
})

const { actions, reducer } = slice
export const { setBankDocuments } = actions
export default reducer
