import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
    name: 'history',
    initialState: [],
    reducers: {
        setHistory(state, action) {
            return action.payload
        },
    },
})

const { actions, reducer } = slice
export const { setHistory } = actions
export default reducer
