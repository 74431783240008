import { configureStore } from '@reduxjs/toolkit'
import thunk from 'redux-thunk'
import reducers from './reducers'
import config from '../config'

const middlewares = [thunk]

const store = configureStore({
    reducer: reducers(),
    middleware: middlewares,
    devTools: config().enableReduxTools,
})

export default store
