import { PURPLE } from '#app/colors/colors'
import { DocumentDto } from '@fixrate/fixrate-query'
import { Button, Chip, CircularProgress, Link, Stack, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import { Fragment, useState } from 'react'
import { Accept, useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import config from '../../config'
import LoadingSpinner from '../LoadingSpinner'

const API_BASE_URL = config().apiUrl

type SigningActionProps = {
    isCheckingSignatureStatus: boolean
    isSigned: boolean
    userCanSign: boolean
    onStartSignature: () => void
}

type FileUploadProps = {
    document?: DocumentDto
    isUploadingDocument: boolean
    acceptedType?: Accept
    maxFileSize?: number
    uploadFiles: (files: File[]) => void
    onStartSignature?: () => void
    removeFile?: (id: string) => void
    isCheckingSignatureStatus?: boolean
    multiple: boolean
}

const SigningAction = ({ isCheckingSignatureStatus, isSigned, userCanSign, onStartSignature }: SigningActionProps) => {
    const { t } = useTranslation()

    return (
        <Stack alignItems="center" justifyContent="center">
            {isCheckingSignatureStatus ? (
                <Stack direction={'row'} spacing={1}>
                    <CircularProgress size={20} />
                    <Typography>{t('components-FileUpload.checkingStatus')}</Typography>
                </Stack>
            ) : (
                <>
                    {isSigned && (
                        <Chip
                            color="success"
                            variant="outlined"
                            icon={<i className="ri-check-line" />}
                            label={t('components-FileUpload.signed')}
                            data-cy="signedStamp"
                        />
                    )}
                    {!isSigned && userCanSign && (
                        <Button
                            size="small"
                            startIcon={<i className="ri-pencil-line" />}
                            onClick={onStartSignature}
                            data-cy="signButton"
                            variant="contained"
                        >
                            {t('components-FileUpload.signDocument')}
                        </Button>
                    )}
                </>
            )}
        </Stack>
    )
}

const FileUpload = ({
    document,
    isUploadingDocument,
    acceptedType = { 'application/pdf': ['.pdf'] },
    maxFileSize = 6,
    isCheckingSignatureStatus,
    uploadFiles,
    onStartSignature,
    removeFile,
    multiple,
}: FileUploadProps) => {
    const { t } = useTranslation()

    const [uploadError, setUploadError] = useState('')

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: acceptedType,
        multiple: multiple,
        onDrop,
    })

    function onDrop(acceptedFiles, rejectedFiles) {
        let files = acceptedFiles
        if (files.length === 0) {
            // On IE11, even pdf files may be rejected for some reason. This seems to be a bug in dropzone,
            // but we have to assume that even rejected files may be legit.
            // The file selector will guide the user to only select pdf files, but the user may override this.
            if (rejectedFiles.length > 0 && rejectedFiles[0].name.endsWith('.pdf')) {
                files = rejectedFiles
            } else {
                setUploadError(t('components-FileUpload.illegalFileFormat'))
                return
            }
        }

        const fileSize = files[0].size / 1e6 // B to MB
        if (fileSize > maxFileSize) {
            setUploadError(t('components-FileUpload.fileIsTooBig', { fileSize: fileSize.toFixed(1), maxFileSize }))
        } else {
            setUploadError('')
            uploadFiles(files)
        }
    }

    const calculateDocumentLink = (document) => {
        if (!document) return
        return `${API_BASE_URL}${document.link}?inline=true`
    }

    if (document) {
        return (
            <Stack direction={'row'} justifyContent="space-between" p={2} border={'0.1rem solid ' + PURPLE[50]}>
                <Stack direction={'row'} spacing={2} alignItems="center">
                    <Link
                        sx={{ fontWeight: 'normal' }}
                        href={calculateDocumentLink(document)}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <i className="ri-file-line" />
                        <Typography>{document.name}</Typography>
                    </Link>
                    {removeFile !== undefined ? (
                        <Button color="error" variant={'outlined'} size="small" onClick={() => removeFile(document.id)}>
                            {t('components-FileUpload.remove')}
                        </Button>
                    ) : (
                        <></>
                    )}
                </Stack>
                {!!onStartSignature && (
                    <SigningAction
                        isCheckingSignatureStatus={isCheckingSignatureStatus}
                        isSigned={document.signedByAll}
                        userCanSign={document.userCanSign}
                        onStartSignature={onStartSignature}
                    />
                )}
            </Stack>
        )
    }

    return (
        <Fragment>
            <Stack
                sx={{
                    transition: '0.3s',
                    width: '100%',
                    backgroundColor: isDragActive ? PURPLE[100] : PURPLE[50] + '50',
                    border: '0.1rem dashed ' + PURPLE[500],
                }}
                {...getRootProps({})}
            >
                <Stack py={6} alignItems="center" justifyContent="center" width={'100%'}>
                    {!isUploadingDocument ? (
                        <>
                            <input {...getInputProps()} />
                            <Stack alignItems={'center'} justifyContent="center" height={'5rem'}>
                                {!isDragActive ? (
                                    <Button variant="outlined">{t('components-FileUpload.uploadFile')}</Button>
                                ) : (
                                    <Typography fontWeight={600} color={PURPLE[900]}>
                                        {t('components-FileUpload.dropFilesToUpload')}
                                    </Typography>
                                )}
                            </Stack>
                        </>
                    ) : (
                        <LoadingSpinner text={t('components-FileUpload.uploadingDocument')}></LoadingSpinner>
                    )}
                </Stack>
            </Stack>
            {uploadError && <p className="field-error-message">{uploadError}</p>}
        </Fragment>
    )
}

FileUpload.propTypes = {
    document: PropTypes.object,
    acceptedType: PropTypes.string,
    maxFileSize: PropTypes.number,
    uploadFiles: PropTypes.func.isRequired,
    removeFile: PropTypes.func,
    isUploadingDocument: PropTypes.bool.isRequired,
    onStartSignature: PropTypes.func,
    lookupSignatureStatus: PropTypes.func,
    isCheckingSignatureStatus: PropTypes.bool,
}

export default FileUpload
