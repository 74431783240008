import { motion } from 'motion/react'
import * as React from 'react'

type Props = {
    children?: React.ReactNode
    align?: 'left' | 'right' | 'center'
    className?: string
    onClick?: () => void
    style?: React.CSSProperties
    colSpan?: number
}

export default function TableCell({ children, align, className, onClick, style, colSpan }: Props) {
    return (
        <motion.td
            className={className}
            onClick={onClick}
            style={{ textAlign: align ?? 'left', cursor: onClick ? 'pointer' : 'auto', ...style }}
            colSpan={colSpan}
        >
            {children}
        </motion.td>
    )
}
