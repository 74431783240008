import HelpPopup from '#app/components/HelpPopup'
import { useCommand } from '#app/services/beta'
import { UpdatePersonOwnership } from '#app/services/beta/command/useCommand.types'
import { PersonValidator } from '#app/services/PersonValidator'
import useCurrentDepositor from '#app/services/useCurrentDepositor'
import { useFieldState } from '@fixrate/fieldstate'
import { OrganisationalPersonDto } from '@fixrate/fixrate-query'
import { Box, Checkbox, FormControlLabel, FormGroup, InputLabel, Stack, TextField, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

export default function Ownership({
    person,
    setUpdating,
}: {
    person: OrganisationalPersonDto
    setUpdating: (value: boolean) => void
}) {
    const { t } = useTranslation()
    const { updatePersonOwnership } = useCommand()
    const depositor = useCurrentDepositor()
    const isBeneficialOwner = PersonValidator.isBeneficialOwner(person)

    const controlShare = useFieldState(
        person.controlShare,
        ({ value, isEditing, isSet }) => {
            if (!isSet && isBeneficialOwner) {
                return t('pages-organizations.personFormMustBeGivenForCustomerDeclaration')
            }
        },
        { validateInitially: true },
        (value) => updateOwnership({ controlShare: value })
    )

    const controlByShares = useFieldState(person.control.ownsShares, null, { validateInitially: true }, (value) =>
        updateOwnership({ controlByShares: value })
    )

    const controlWithRelative = useFieldState(
        person.control.controlsWithRelatives,
        null,
        { validateInitially: true },
        (value) => updateOwnership({ controlWithRelative: value })
    )

    const controlByOther = useFieldState(person.control.other, null, { validateInitially: true }, (value) =>
        updateOwnership({ controlByOther: value })
    )

    async function updateOwnership(updatedFields: Partial<UpdatePersonOwnership>) {
        setUpdating(true)
        const { waitForCommand } = await updatePersonOwnership({
            depositorId: depositor?.id,
            personId: person.personId,
            controlByShares: !!controlByShares.value,
            controlWithRelative: !!controlWithRelative.value,
            controlByOther: !!controlByOther.value,
            controlShare: controlShare.value,
            ...updatedFields,
        })
        await waitForCommand()
        setUpdating(false)
    }

    return (
        <Stack spacing={2}>
            <Box>
                <InputLabel
                    sx={{
                        mb: 0,
                        whiteSpace: 'unset',
                    }}
                >
                    {t('pages-organizations.ownership-controllingHeader')}
                </InputLabel>
                <Typography variant="caption">{t('pages-organizations.ownership-controllingDescription')}</Typography>
                <FormGroup>
                    <FormControlLabel
                        label={t('pages-organizations.ownership-owningShares')}
                        control={
                            <Checkbox
                                name="direct-owner"
                                data-cy="owner-direct"
                                checked={!!controlByShares.value}
                                onChange={(e) => controlByShares.submitValue(e.target.checked)}
                            />
                        }
                    />
                    <FormControlLabel
                        label={t('pages-organizations.ownership-ownerWithFamily')}
                        control={
                            <Checkbox
                                name="owner-with-others"
                                data-cy="owner-with-others"
                                checked={!!controlWithRelative.value}
                                onChange={(e) => controlWithRelative.submitValue(e.target.checked)}
                            />
                        }
                    />
                    <FormControlLabel
                        label={t('pages-organizations.ownership-ownerOther')}
                        control={
                            <Checkbox
                                name="owner-other"
                                data-cy="owner-other"
                                checked={!!controlByOther.value}
                                onChange={(e) => controlByOther.submitValue(e.target.checked)}
                            />
                        }
                    />
                </FormGroup>
                {!controlByShares.value && !controlWithRelative.value && !controlByOther.value && (
                    <p className="field-error-message">{t('pages-organizations.error-selectOneOption')}</p>
                )}
            </Box>
            <Box>
                <InputLabel sx={{ whiteSpace: 'unset', maxWidth: '60rem' }}>
                    <HelpPopup
                        text={t('pages-organizations.personInfoControlShareDescription')}
                        supportCommonName="ownershipKeyPeople"
                    >
                        {t('pages-organizations.personInfoControlShare')}
                    </HelpPopup>
                </InputLabel>
                <TextField
                    data-cy="controlShareField"
                    required
                    sx={{ width: '100%', maxWidth: '70rem' }}
                    type={'text'}
                    value={controlShare.value || ''}
                    onChange={(e) => controlShare.setValue(e.target.value)}
                    onBlur={(e) => controlShare.submitValue(e.target.value)}
                    multiline
                    rows={4}
                />
                <p className="field-error-message">{controlShare.errorMessage}</p>
            </Box>
        </Stack>
    )
}
