import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
    name: 'messages',
    initialState: [],
    reducers: {
        setMessages(state, action) {
            return action.payload
        },
    },
})

const { actions, reducer } = slice
export const { setMessages } = actions
export default reducer
