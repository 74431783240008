import { AccountOpenerAuthorization } from '#app/pages/organizations/AddNewOrganization/OrganizationWizard/steps/AccountOpenerAuthorization'
import InvitationsStep, {
    peopleWithTasks,
} from '#app/pages/organizations/AddNewOrganization/OrganizationWizard/steps/InvitationsStep'
import MissingInformationStep from '#app/pages/organizations/AddNewOrganization/OrganizationWizard/steps/MissingInformationStep'
import StatusStep from '#app/pages/organizations/AddNewOrganization/OrganizationWizard/steps/StatusStep'
import Accounts from '#app/pages/organizations/OrganizationDetail/Accounts/Accounts'
import NewAccount from '#app/pages/organizations/OrganizationDetail/Accounts/NewAccount'
import CustomerDeclarationInfo from '#app/pages/organizations/OrganizationDetail/CustomerDeclarationInfo/CustomerDeclarationInfo'
import AcceptTerms from '#app/pages/organizations/OrganizationDetail/TermsAndConditions/AcceptTerms'
import Board from '#app/pages/organizations/OrganizationDetail/UsersAndRoles/views/Board'
import OrderProcess from '#app/pages/organizations/OrganizationDetail/UsersAndRoles/views/OrderProcess'
import Owners from '#app/pages/organizations/OrganizationDetail/UsersAndRoles/views/Owners'
import POAList from '#app/pages/organizations/OrganizationDetail/UsersAndRoles/views/PowerOfAttorney/POAList'
import IdentificationDocument from '#app/pages/profile/IdentificationDocument'
import useOrganizationWarnings from '#app/services/useOrganizationWarnings'
import useUiSetting from '#app/services/useUiSetting'
import { identificationDocument, isLoggedIn } from '#app/state/selectors'
import { useSelector } from '#app/state/useSelector'
import { DepositorDto, PartnerCustomerDto } from '@fixrate/fixrate-query'
import { useRef } from 'react'
import { userIsAccountCreator } from './accountCreatorUtils'

import BankIcon from '#app/assets/wizard-icons/bank.svg'
import BusinessIcon from '#app/assets/wizard-icons/business.svg'
import CoinsIcon from '#app/assets/wizard-icons/coins.svg'
import VerifiedDocumentIcon from '#app/assets/wizard-icons/document-verified.svg'
import DoughnutPercentage from '#app/assets/wizard-icons/doughnut-percentage.svg'
import HandshakeIcon from '#app/assets/wizard-icons/handshake.svg'
import InviteIcon from '#app/assets/wizard-icons/invitations.svg'
import RolesIcon from '#app/assets/wizard-icons/roles.svg'
import UserVerifiedIcon from '#app/assets/wizard-icons/user-verified.svg'
import UserZoomIcon from '#app/assets/wizard-icons/user-zoom.svg'
import { SupportCategoryNameType } from '#app/layers/PayloadSupportPane/utils/supportNameMappings'
import { Paper, Stack, Typography } from '@mui/material'
import PartnerSendOffer from '#app/pages/organizations/AddNewOrganization/OrganizationWizard/steps/PartnerSendOffer'

export type OnboardingWizardStep = {
    name: StepName
    done: boolean
    visible: boolean
    icon: string | null
    supportarticleId?: SupportCategoryNameType | null
}

export type OnboardingWizardStatus = {
    steps: OnboardingWizardStep[]
    progress: number
    currentSlug: string
    wizardActive: boolean
    loaded: boolean
    fullyRegistered: boolean
    simplifiedOnboarding: boolean
    setSimplifiedOnboarding: (value: boolean) => void
}

export type StepName =
    | 'terms'
    | 'identificationDocument'
    | 'board'
    | 'accounts'
    | 'customerDeclaration'
    | 'owners'
    | 'orderProcess'
    | 'customerDeclarationInfo'
    | 'signingRights'
    | 'powerOfAttorney'
    | 'invitations'
    | 'status'
    | 'partnerSendOffer'

type Content = Record<StepName, JSX.Element>

const useOnboardingWizardContent = (depositor: DepositorDto): Content => {
    const IDDoc = useSelector(identificationDocument)
    return {
        terms: <AcceptTerms inline />,
        board: <Board inline depositor={depositor} />,
        orderProcess: <OrderProcess depositor={depositor} visibleItems={['orderProcess']} inline />,
        identificationDocument: (
            <IdentificationDocument
                signContext={'/organizations/onboarding/' + depositor?.id + '/identificationDocument'}
                inline
                showSign={IDDoc ? true : false}
                showAddress={IDDoc ? false : true}
            />
        ),
        owners: <Owners inline={true} depositor={depositor} />,
        signingRights: <AccountOpenerAuthorization depositor={depositor} />,
        powerOfAttorney: <POAList depositor={depositor} inline hideWarnings={true} />,
        accounts:
            depositor.settlementAccounts.length < 1 ? (
                <NewAccount inline={true} depositorId={depositor.id} showDescription={true} />
            ) : (
                <Accounts inline={true} allowMultipleAccounts={false} />
            ),
        customerDeclarationInfo: <MissingInformationStep depositor={depositor} />,
        customerDeclaration: <CustomerDeclarationInfo inline />,
        invitations: <InvitationsStep depositor={depositor} />,
        status: <StatusStep depositor={depositor} />,
        partnerSendOffer: <PartnerSendOffer depositor={depositor} />,
    }
}

const useOnboardingWizardStatus = (depositor: DepositorDto | PartnerCustomerDto): OnboardingWizardStatus => {
    const loggedIn = useSelector(isLoggedIn)
    const sessionId = useSelector((state) => state.session.id)
    const associations = useSelector((state) => state.session.associations)
    const depositorId = (depositor as DepositorDto)?.id || (depositor as PartnerCustomerDto)?.depositorId
    const isPartnerAdminView = useSelector((state) => state.session.organisationType === 'PARTNER')
    const orders = useSelector((state) => state.orders)
    const loaded = useSelector((state) => state.loaded)
    const documents = useSelector((state) => state.documents)
    const fundBuyOrders = useSelector((state) => state.fundBuyOrders)
    const hasDoneOrders = fundBuyOrders?.length > 0 || orders?.length > 0
    const authDocs = Object.keys(documents)
        .map((key) => documents[key])
        .filter((d) => d.documentType === 'AUTHORIZATION_DOCUMENT')
        .filter((d) => d.owner === depositorId)

    const showInvitationStatusStep =
        peopleWithTasks(depositor)?.filter((p) => p.associatedUserId !== sessionId)?.length > 0

    // TODO: This is a temporary hack because uiSettings is loaded after funds. Remove when suspense loaders is implemented
    const isLoaded = loaded.funds

    const hasSignedAuthDocs =
        authDocs.length > 0 ? authDocs.filter((d) => d.userCanSign).every((d) => d.signedByUser) : true

    const hasPartnerProposals = depositor?.partnerRelations?.some((pr) => pr.partnerProposals.length > 0)

    const isFullyRegistered = hasDoneOrders || (!!depositor?.fullyRegisteredAt && hasPartnerProposals)

    const isPartnerAccountant = depositor?.users
        ?.find((u) => u.id === sessionId)
        ?.roles?.find((r) => r === 'DEPOSITOR_PARTNER_ACCOUNTANT')
    const organizationWarnings = useOrganizationWarnings(depositor)
    const isSelfServiced = (depositor as DepositorDto)?.depositorSupportCategory === 'SELF_SERVICED' || false
    const isAccountHolder = userIsAccountCreator(depositor?.users.find((u) => u.id === sessionId))

    const defaultSimplifiedOnboarding = isSelfServiced || !!isPartnerAccountant
    const [simplifiedOnboarding, setSimplifiedOnboarding] = useUiSetting(
        'simplifiedOnboarding',
        defaultSimplifiedOnboarding
    )

    const canAcceptPartner = associations
        .find((a) => a.organisation?.id === depositorId)
        ?.permissions.includes('DEPOSITOR__PARTNER__ACCEPT')
    const hasPendingPartnerRequests = depositor?.partnerRelations?.some((pr) => !pr.accepted)

    const pendingRequestForPartner = canAcceptPartner && hasPendingPartnerRequests

    const IDDocument = useSelector(identificationDocument)
    // Avoid showing ID-document step if user has already signed it.
    // useRef so that we don't re-render and remove the step when the user signs
    const hasSignedIdDoc = useRef(IDDocument?.signedByUser ?? false)

    const showWizard =
        loggedIn && !pendingRequestForPartner && !isFullyRegistered && !!depositor && simplifiedOnboarding

    const steps: OnboardingWizardStep[] = [
        {
            name: 'terms',
            done: organizationWarnings?.terms.length === 0,
            visible: true,
            icon: HandshakeIcon,
            supportarticleId: 'generalTerms',
        },
        {
            name: 'board',
            done:
                !organizationWarnings?.organisationalRoles.some((w) => w.type === 'MISSING_ORGANISATIONAL_ROLES') &&
                !organizationWarnings.onboarding.some((w) => w.type === 'ORGANISATIONAL_ROLES_NOT_CONFIRMED'),
            visible: true,
            icon: RolesIcon,
            supportarticleId: null,
        },
        {
            name: 'orderProcess',
            done:
                !organizationWarnings.organisationalRoles.some((w) => w.type === 'MISSING_SECURITY_ROLES') &&
                !organizationWarnings.onboarding.some((w) => w.type === 'SECURITY_ROLES_NOT_CONFIRMED'),
            visible: !isSelfServiced && !isPartnerAccountant,
            icon: CoinsIcon,
            supportarticleId: 'rolesDepositor',
        },
        {
            name: 'identificationDocument',
            done: !organizationWarnings.users.some((w) => w.type === 'MISSING_IDENTIFICATION_DOCUMENT'),
            visible: isAccountHolder && !hasSignedIdDoc?.current,
            icon: UserVerifiedIcon,
            supportarticleId: 'depositorAccountOpener',
        },
        {
            name: 'owners',
            done: isSelfServiced
                ? true
                : !organizationWarnings.users.some((w) => w.type === 'MISSING_BENEFICIAL_OWNERS') &&
                  !organizationWarnings.onboarding.some((w) => w.type === 'BENEFICIAL_OWNERS_NOT_CONFIRMED'),
            visible: !isSelfServiced,
            icon: BusinessIcon,
            supportarticleId: 'ownershipKeyPeople',
        },
        {
            name: 'signingRights',
            done: isSelfServiced
                ? true
                : !organizationWarnings.users.some((w) => w.type === 'MISSING_AUTHORIZATION_SIGNERS'),
            visible: !isSelfServiced,
            icon: BusinessIcon,
            supportarticleId: 'authorizations',
        },
        {
            name: 'powerOfAttorney',
            done: isSelfServiced
                ? true
                : !organizationWarnings.users.some((w) => w.type === 'MISSING_AUTHORIZATION_DOCUMENT') &&
                  hasSignedAuthDocs,
            visible: !isSelfServiced,
            icon: BusinessIcon,
            supportarticleId: 'authorizations',
        },
        {
            name: 'accounts',
            done: organizationWarnings?.accounts.length === 0,
            visible: true,
            icon: BankIcon,
            supportarticleId: 'settlementAccounts',
        },
        {
            name: 'customerDeclarationInfo',
            done: !organizationWarnings?.customerDeclaration.some(
                (w) => w.type === 'NEED_INFO_FOR_CUSTOMER_DECLARATION'
            ),
            visible: true,
            icon: UserZoomIcon,
            supportarticleId: 'customerDeclaration',
        },
        {
            name: 'customerDeclaration',
            done: organizationWarnings?.customerDeclaration.length === 0,
            visible: true,
            icon: VerifiedDocumentIcon,
            supportarticleId: 'customerDeclaration',
        },
        {
            name: 'invitations',
            done: !organizationWarnings.users.some(
                (w) =>
                    w.type === 'PERSON_NEEDS_INFO_FOR_INVITE' ||
                    w.type === 'PERSON_NEEDS_INVITE' ||
                    w.type === 'AUTHORIZATION_SIGNER_NEEDS_INFO_FOR_INVITE' ||
                    w.type === 'AUTHORIZATION_SIGNER_NEEDS_INVITE' ||
                    w.type === 'INVITE_IS_NOT_SENT'
            ),
            visible: showInvitationStatusStep && !isSelfServiced && !isPartnerAccountant,
            icon: InviteIcon,
            supportarticleId: null,
        },
        {
            name: 'status',
            done: organizationWarnings?.users.length === 0,
            visible: showInvitationStatusStep && !isSelfServiced && !isPartnerAccountant,
            icon: DoughnutPercentage,
            supportarticleId: null,
        },
        {
            name: 'partnerSendOffer',
            done: true,
            visible: isPartnerAccountant && !isPartnerAdminView,
            icon: HandshakeIcon,
            supportarticleId: null,
        },
    ]

    const filteredSteps = steps.filter((s) => s.visible)
    const stepsDone = filteredSteps.filter((s) => s.done).length
    const allDone = filteredSteps.every((v) => v.done)
    const progress = allDone ? 100 : (stepsDone / filteredSteps.length) * 100

    const lastDoneItemSlug = allDone
        ? filteredSteps[filteredSteps.length - 1].name
        : filteredSteps.find((step) => !step.done).name

    return {
        loaded: isLoaded,
        steps: filteredSteps,
        progress,
        currentSlug: lastDoneItemSlug
            ? `/organizations/onboarding/${depositorId}/${lastDoneItemSlug}`
            : '/organizations/onboarding/terms',
        wizardActive: showWizard,
        fullyRegistered: isFullyRegistered,
        simplifiedOnboarding,
        setSimplifiedOnboarding,
    }
}

export { useOnboardingWizardStatus, useOnboardingWizardContent }
