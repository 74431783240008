/*

    Functions for handling connections to external api's

*/

import { addInfoMessage } from '#services/thunks/infoMessages'

function handleStatusCodeErrors(response) {
    if (!response.ok) {
        throw Error(response.statusText)
    }
    return response
}

function parseJson(response) {
    if (response.json) {
        return response.json()
    } else {
        return response
    }
}

function handleNetworkErrors(dispatch, ex, message, detail) {
    console.error('Network error', ex)
    dispatch(addInfoMessage('error', message, detail, ex.message))
}

export const bring = (dispatch) => ({
    handleNetworkErrors: (ex) =>
        handleNetworkErrors(dispatch, ex, 'Bring', 'Det oppsto et problem under kommunikasjon med Bring'),

    getPostalCode(postalCode, country) {
        const options = {
            headers: {
                Accept: 'application/json',
            },
        }
        return fetch(
            `https://api.bring.com/shippingguide/api/postalCode.json?pnr=${postalCode}&clientUrl=http%3A%2F%2Ffixrate.no&country=${country}`,
            options
        )
            .then(handleStatusCodeErrors)
            .then(parseJson)
            .then((response) => {
                if (response.valid) {
                    return response
                } else {
                    return {
                        valid: false,
                        result: undefined,
                    }
                }
            })
            .catch(this.handleNetworkErrors)
    },
})
