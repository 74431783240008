import config from '#app/config'
import useCurrentDepositor from '#app/services/useCurrentDepositor'
import { useCommand } from '#command'
import { PageHeader, PageLayout } from '#components'
import { useSelector } from '#state/useSelector'
import { DepositorDto, OrganisationalPersonDto } from '@fixrate/fixrate-query'
import { Alert, Button, Grid, Stack, Typography } from '@mui/material'
import format from 'date-fns/format'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import ActiveAuthorization from './ActiveAuthorization'
import './Authorization.scss'
import AuthorizationDocument from './AuthorizationDocument'
import AuthorizationDocumentPreview from './AuthorizationDocumentPreview'
import DeleteAuthorization from './DeleteAuthorization'
import RequestAuthorization, { PrincipalCandidate } from './RequestAuthorization/RequestAuthorization'
import SignAuthorization from './SignAuthorization/SignAuthorization'
import { formatOrganizationNumber } from '#services/formatnumber'

const API_BASE_URL = config().apiUrl

type Params = {
    userId?: string
    inviteId?: string
    organisationId?: string
}

export type Attorney = Pick<OrganisationalPersonDto, 'firstName' | 'lastName' | 'birthDate'> & {
    isUser: boolean
    id: string
    personId: string
    inviteId: string | null
    userId: string | null
    authorizationDocumentId: string | null
}

export function getPrincipalCandidates(depositor: DepositorDto): PrincipalCandidate[] {
    return depositor.people
        .filter((person) => person.securityRoles.includes('DEPOSITOR_SIGN_AUTHORIZATION'))
        .map((person) => ({
            personId: person.personId,
            birthDate: person.birthDate,
            email: person.email,
            name: person.firstName + ' ' + person.lastName,
        }))
}

export default function Authorization() {
    const { t } = useTranslation()
    const params = useParams<Params>()
    const navigate = useNavigate()

    const {
        generateAuthorizationDocument: generateAuthorizationDocumentCommand,
        deleteAuthorizationDocument: deleteAuthorizationDocumentCommand,
        startElectronicSignature: startElectronicSignatureCommand,
    } = useCommand()

    const documents = useSelector(({ documents }) => documents)
    const depositor = useCurrentDepositor()

    // Finds the user or invite that represents the attorney
    const attorney: Attorney = useMemo(() => {
        return params.userId
            ? {
                  ...depositor?.users?.find((user) => user.id === params.userId),
                  ...{
                      isUser: true,
                      userId: params.userId,
                      inviteId: null,
                      id: params.userId,
                      personId: depositor?.people.find((person) => person.associatedUserId === params.userId)?.personId,
                  },
              }
            : {
                  ...depositor?.userInvites?.find((invite) => invite.id === params.inviteId),
                  ...{
                      isUser: false,
                      userId: null,
                      inviteId: params.inviteId,
                      id: params.inviteId,
                      personId: depositor?.people.find((person) => person.associatedInviteId === params.inviteId)
                          ?.personId,
                  },
              }
    }, [depositor?.userInvites, depositor?.users, params.inviteId, params.userId, depositor?.people])

    const authorizationDocument = attorney?.authorizationDocumentId ? documents[attorney.authorizationDocumentId] : null

    const [isWorking, setWorking] = useState(false)
    const [principalPersonIds, setPrincipalPersonIds] = useState([])
    const [language, setLanguage] = useState(depositor?.language)
    const [regulatoryRegion, setRegulatoryRegion] = useState(depositor?.regulatoryRegion)

    useEffect(() => {
        if (!!depositor && (!attorney || !attorney.id)) {
            navigate(`/organizations/${depositor.id}/users/power-of-attorney`)
        }
    }, [attorney, depositor, navigate])

    async function deleteAuthorizationDocument() {
        try {
            setWorking(true)
            const { waitForCommand } = await deleteAuthorizationDocumentCommand(
                params.userId,
                params.inviteId,
                params.organisationId
            )
            await waitForCommand()
            setWorking(false)
        } catch (err) {
            console.error(err)
            setWorking(false)
        }
    }

    function generatePreviewLink() {
        const depositorNameEncoded = encodeURIComponent(depositor?.name)
        const depositorNationalIdentity = formatOrganizationNumber(depositor?.nationalIdentity, regulatoryRegion)

        const attorneyEncoded = encodeURIComponent(
            JSON.stringify({
                name: attorney.firstName + ' ' + attorney.lastName,
                birthDate: attorney.birthDate,
            })
        )

        const principalCandidates: PrincipalCandidate[] = depositor ? getPrincipalCandidates(depositor) : []
        const principalParameters = principalPersonIds.map((principalPersonId) => {
            const principal = principalCandidates.find((person) => person.personId === principalPersonId)
            return `&from-users=${encodeURIComponent(
                JSON.stringify({
                    name: principal.name,
                    birthDate: format(new Date(principal.birthDate), 'yyyy-MM-dd'),
                })
            )}`
        })

        return {
            inline: `${API_BASE_URL}/document/preview/authorization-document?inline=true&depositor-name=${depositorNameEncoded}&depositor-nationalidentity=${depositorNationalIdentity}&for-user=${attorneyEncoded}${principalParameters}&language=${language}&regulatoryRegion=${regulatoryRegion}`,
            attachment: `${API_BASE_URL}/document/preview/authorization-document?inline=false&depositor-name=${depositorNameEncoded}&depositor-nationalidentity=${depositorNationalIdentity}&for-user=${attorneyEncoded}${principalParameters}&language=${language}&regulatoryRegion=${regulatoryRegion}`,
        }
    }

    async function startElectronicSignature(processId, documentType, identity) {
        await startElectronicSignatureCommand(processId, documentType, identity)
    }

    if (!depositor) {
        return null
    }

    const previewLink = generatePreviewLink()

    const userInSessionHasNotSigned =
        authorizationDocument?.signedByUser || authorizationDocument?.userCanSign === false

    const showUserDoneMessage = userInSessionHasNotSigned

    return (
        <>
            <PageHeader
                title={t('pages-organizations.authorizationForUserHeading', {
                    userName: `${attorney.firstName} ${attorney.lastName}`,
                    organizationName: depositor.name,
                })}
                backToLink={`/organizations/${depositor.id}/users/power-of-attorney`}
            />
            {showUserDoneMessage && (
                <Alert icon={<></>} severity="success" sx={{ marginBottom: '1rem' }}>
                    <Stack spacing={1} direction="row" alignItems="center">
                        <Button
                            size="small"
                            variant="outlined"
                            data-cy="goBack"
                            color="inherit"
                            startIcon={<i className="ri-arrow-left-line" />}
                            onClick={() => navigate(`/organizations/${depositor.id}/users/power-of-attorney`)}
                            sx={{ marginLeft: '1rem' }}
                        >
                            {t('common.goBack')}
                        </Button>
                        <Typography fontSize={'1.4rem'}>{t('pages-organizations.authorizationDoneForUser')}</Typography>
                    </Stack>
                </Alert>
            )}
            <PageLayout>
                <Grid container spacing={2} maxWidth="150rem">
                    <Grid item xs={12} lg={8} xl={6}>
                        {!authorizationDocument && (
                            <RequestAuthorization
                                principalPersonIds={principalPersonIds}
                                setPrincipalPersonIds={setPrincipalPersonIds}
                                attorney={attorney as Attorney}
                            />
                        )}
                        {!authorizationDocument?.signedByAll && (
                            <SignAuthorization
                                authorizationDocument={authorizationDocument}
                                depositorId={params.organisationId}
                                forUser={attorney}
                                startElectronicSignature={startElectronicSignature}
                            />
                        )}
                        {authorizationDocument?.signedByAll && (
                            <ActiveAuthorization authorizationDocument={authorizationDocument} forUser={attorney} />
                        )}
                        {authorizationDocument && (
                            <DeleteAuthorization deleteAuthorizationDocument={deleteAuthorizationDocument} />
                        )}
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        {authorizationDocument && <AuthorizationDocument document={authorizationDocument} />}
                        {!authorizationDocument && <AuthorizationDocumentPreview previewLink={previewLink} />}
                    </Grid>
                </Grid>
            </PageLayout>
        </>
    )
}
