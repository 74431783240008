import { COLORS, SILVER_GRAY } from '#app/colors/colors'
import { DateOutput, LoadingSpinner, PageHeader, PageLayout } from '#app/components'
import { useCurrencyOutputWithCurrency } from '#components/CurrencyOutput/useCurrencyOutput'
import FixrateLogo from '#pages/menu/FixrateLogo'
import styles from '#pages/portfolio-depositor/Reports/FundReports/AccountingReport/AccountingReport.module.scss'
import { formatLongReportPeriod  } from '#pages/portfolio-depositor/Reports/ReportPeriods'
import { FundAccountingReport, FundAccountingReportPdfProps } from '@fixrate/fixrate-report'
import { useSelector } from '#state/useSelector'
import { Currency } from '@fixrate/fixrate-query'
import {
    Alert,
    AlertTitle,
    Box,
    Button,
    FormControlLabel,
    Stack,
    Switch,
    Tooltip,
    Typography,
    useMediaQuery,
} from '@mui/material'
import { Fragment, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {useParams, useSearchParams} from 'react-router-dom'
import BalanceDevelopmentChart from './BalanceDevelopmentChart'
import EmptyState from "#components/EmptyState/EmptyState";
import usePortfolio from "#services/usePortfolio";
import {ReportVersionSelect} from "#pages/portfolio-depositor/Reports/FundReports/components/ReportVersionSelect";
import {useFundAccountingReport} from "#pages/portfolio-depositor/Reports/FundReports/useFundReports";

type Params = {
    period?: string
}

export default function AccountingReportPage() {
    const { t } = useTranslation()
    const { period } = useParams<Params>()
    const {id: depositorId, name: depositorName} = useSelector((state) => state.depositor)
    const {id: portfolioId, name: portfolioName} = usePortfolio()
    const [searchParams, setSearchParams] = useSearchParams()
    const selectedVersion = searchParams.get('version')
    const {
        report,
        isSuccess: reportSuccess,
        isLoading: reportLoading,
        isError: reportError,
    } = useFundAccountingReport(depositorId, portfolioId, period, selectedVersion)
    const portfolioIdRef = useRef<string | null>(portfolioId)

    if (portfolioIdRef.current !== portfolioId) {
        portfolioIdRef.current = portfolioId
        setSearchParams({})
    }

    const versionSuffix = selectedVersion ? `&version=${selectedVersion}` : ''
    const downloadPdfLink = `/api/report/fund-accounting/pdf?depositorId=${depositorId}&period=${period}&portfolioId=${portfolioId}&download=attachment${versionSuffix}`
    const downloadExcelLink = `/api/report/fund-accounting/excel?depositorId=${depositorId}&period=${period}&portfolioId=${portfolioId}&download=attachment${versionSuffix}`

    function changeVersion(newVersion: string) {
        if (newVersion !== selectedVersion) {
            setSearchParams({version: newVersion})
        }
    }
    return (
        <Fragment>
            <PageHeader
                backToLink={'/reports/funds'}
                icon="ri-line-chart-line"
                title={t('pages-fund-reports.fundReports')}
            ></PageHeader>
            {reportLoading && <LoadingSpinner />}
            {reportError && <Alert severity="error">{t('pages-fund-reports.errorLoadingReport')}</Alert>}
            {reportSuccess && (
                <AccountingReport
                    report={report}
                    showBalanceFundPlacementRowsDefault={false}
                    showResultFundPlacementRowsDefault={false}
                    downloadPdfLink={downloadPdfLink}
                    downloadExcelLink={downloadExcelLink}
                    changeVersion={changeVersion}
                    period={period}
                    depositorName={depositorName}
                    portfolioName={portfolioName}
                />
            )}
        </Fragment>
    )
}

type AccountingReportPageProps = FundAccountingReportPdfProps & {
    pdfMode?: boolean
    downloadPdfLink?: string
    downloadExcelLink?: string
    changeVersion?: (newVersion: string) => void,
    period?: string
}

/**
 * NB: Do not add any session hooks to this component!
 * The component is used for PDF generation and cannot rely on dynamic session state.
 * Any session state should be passed as props.
 */
export function AccountingReport({
    report,
    showBalanceFundPlacementRowsDefault,
    showResultFundPlacementRowsDefault,
    depositorName,
    portfolioName,
    pdfMode = false,
    downloadPdfLink,
    downloadExcelLink,
    changeVersion,
    period,
}: AccountingReportPageProps) {
    const [showBalanceFundPlacementRows, setShowBalanceFundPlacementRows] = useState(
        showBalanceFundPlacementRowsDefault
    )
    const [showResultFundPlacementRows, setShowResultFundPlacementRows] = useState(showResultFundPlacementRowsDefault)
    const { t } = useTranslation()
    const isPrint = useMediaQuery('print') || pdfMode
    const periodIdentifier = report?.periodIdentifier || period
    const currency = report?.currency
    const version = report?.version ? `${report.version}` : undefined
    const timestamp = report?.updated || undefined
    const reportDescription = formatLongReportPeriod(periodIdentifier, t) + (isPrint ? ` – ${depositorName} – ${portfolioName}` : '')

    return (
        <PageLayout style={{ backgroundColor: 'white' }}>
            {isPrint && (
                <Box display={'flex'} justifyContent={'right'}>
                    <FixrateLogo className={styles.fixratePdfHeaderLogo} />
                </Box>
            )}
            <Stack spacing={4} sx={{ px: { xs: 2, md: 0 }, maxWidth: '130rem' }}>
                <Stack>
                    {periodIdentifier && (
                        <Box
                            sx={{
                                color: COLORS.PURPLE,
                                fontWeight: 600,
                                fontVariant: 'small-caps',
                                textTransform: 'uppercase',
                                mb: 1,
                            }}
                        >
                            {reportDescription}
                        </Box>
                    )}
                    <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                        <Typography variant={'h1'}>{t('pages-fund-reports.reconciliationReport')}</Typography>
                        {(version !== undefined && timestamp !== undefined) && (
                            <ReportVersionSelect
                                reportType={"ACCOUNTING"}
                                depositorId={report?.depositorId}
                                portfolioId={report?.portfolioId}
                                period={period}
                                currentVersion={version}
                                currentTimestamp={timestamp}
                                changeVersion={changeVersion}
                                isPrint={isPrint}
                            />
                        )}
                    </Stack>
                </Stack>
                {!report && (
                    <EmptyState
                        illustration={{ name: 'manAnalyzing', color: 'default' }}
                        title={t('pages-fund-reports.noReportForPeriod')}
                    />)}
                {report && (
                <>
                    <BalanceDevelopmentSection report={report} currency={currency} pdfMode={isPrint} />
                    <BalanceTableSection
                        report={report}
                        startDate={report.fromDate}
                        endDate={report.toDate}
                        showFundPlacementRows={showBalanceFundPlacementRows}
                        setShowFundPlacementRows={setShowBalanceFundPlacementRows}
                        currency={currency}
                    />
                    <ResultTableSection
                        report={report}
                        startDate={report.fromDate}
                        endDate={report.toDate}
                        showFundPlacementRows={showResultFundPlacementRows}
                        setShowFundPlacementRows={setShowResultFundPlacementRows}
                        currency={currency}
                    />
                    {!isPrint && downloadPdfLink && (
                        <Stack spacing={1} direction={'row'}>
                            <Box>
                                <Button
                                    href={
                                        downloadPdfLink +
                                        `&showBalanceFundPlacementRows=${showBalanceFundPlacementRows}&showResultFundPlacementRows=${showResultFundPlacementRows}`
                                    }
                                    startIcon={<i className="ri-file-pdf-2-line"></i>}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    variant={'contained'}
                                >
                                    {t('pages-fund-reconciliation-report.downloadAsPdf')}
                                </Button>
                            </Box>
                            {downloadExcelLink && (
                                <Box>
                                    <Button
                                        href={downloadExcelLink}
                                        startIcon={<i className="ri-file-excel-2-line"></i>}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        variant={'contained'}
                                    >
                                        {t('pages-fund-reconciliation-report.downloadAsSpreadsheet')}
                                    </Button>
                                </Box>
                            )}
                        </Stack>
                    )}
                    <Alert severity="info" sx={{ pageBreakInside: 'avoid' }}>
                        <AlertTitle>{t('pages-fund-reconciliation-report.alertTitle')}</AlertTitle>
                        {t('pages-fund-reconciliation-report.alertMessage')}
                    </Alert>
                </>
            )}
            </Stack>
        </PageLayout>
    )
}

type BalanceDevelopmentSectionProps = {
    report: FundAccountingReport
    currency: Currency
    pdfMode: boolean
}

/*
 For some reason using useMediaQuery('print') in this component or in BalanceDevelopmentChart makes the chart not render in PDF.
 Hence, we pass pdfMode as a prop.
 */
function BalanceDevelopmentSection({ report, currency, pdfMode }: BalanceDevelopmentSectionProps) {
    const { t } = useTranslation()
    return (
        <Stack spacing={4}>
            <Typography variant={'h2'}>{t('pages-fund-reconciliation-report.balanceDevelopment')}</Typography>
            <Typography variant={'subtitle2'}>
                {t('pages-fund-reconciliation-report.closingBalanceVisualization')}
            </Typography>
            <Box width={'100%'} maxWidth={'100rem'}>
                <BalanceDevelopmentChart report={report} currency={currency} pdfMode={pdfMode} />
            </Box>
        </Stack>
    )
}

type BalanceTableSectionProps = {
    report: FundAccountingReport
    startDate: string
    endDate: string
    showFundPlacementRows: boolean
    setShowFundPlacementRows: (value: boolean) => void
    currency: Currency
}

function BalanceTableSection({
    report,
    startDate,
    endDate,
    showFundPlacementRows,
    setShowFundPlacementRows,
    currency,
}: BalanceTableSectionProps) {
    const { t } = useTranslation()
    const formatNumber = useNumberFormatter(currency)
    const isPrint = useMediaQuery('print')

    return (
        <Stack spacing={isPrint ? 1 : 4} sx={{ pageBreakInside: 'avoid' }}>
            <Stack direction={'row'} justifyContent={'space-between'} pr={1}>
                <Typography variant={'h2'}>{t('pages-fund-reconciliation-report.balance')}</Typography>
                <FormControlLabel
                    sx={{ displayPrint: 'none' }}
                    label={t('pages-fund-reconciliation-report.showDetails')}
                    labelPlacement={'start'}
                    control={
                        <Switch
                            size={'small'}
                            checked={showFundPlacementRows}
                            onChange={(e) => setShowFundPlacementRows(e.target.checked)}
                        />
                    }
                />
            </Stack>
            <table className={styles.fundAccountingTable}>
                <BalanceHeader startDate={startDate} endDate={endDate} />
                <tbody>
                    {Object.entries(report.balanceReport.entries).map(([category, subReport]) => (
                        <Fragment key={category}>
                            <tr className={styles.subHeader}>
                                <td colSpan={5}>{t(`enum-SubReportCategory.${category}`)}</td>
                            </tr>
                            {Object.entries(subReport).map(([entryType, entry]) => (
                                <tr key={entryType}>
                                    <td>
                                        <Stack spacing={0.5}>
                                            <span>{t(`enum-ReportEntryType.${entryType}`)}</span>
                                            {showFundPlacementRows &&
                                                report.fundPlacementIds.map((id) => (
                                                    <FundNameStackItem
                                                        key={id}
                                                        fundName={
                                                            report.balanceReport.outgoingShareClassInfo[id].fullName
                                                        }
                                                    />
                                                ))}
                                        </Stack>
                                    </td>
                                    <td className={styles.noPrint}>
                                        <Stack spacing={0.5}>
                                            <span>&nbsp;</span>
                                            {showFundPlacementRows &&
                                                report.fundPlacementIds.map((id) => (
                                                    <FundNameStackItem
                                                        key={id}
                                                        fundName={report.balanceReport.outgoingShareClassInfo[id].isin}
                                                    />
                                                ))}
                                        </Stack>
                                    </td>
                                    <td>
                                        <Stack spacing={0.5}>
                                            <span>{formatNumber(entry.incoming)}</span>
                                            {showFundPlacementRows &&
                                                report.fundPlacementIds.map((id) => (
                                                    <ValueStackItem key={id}>
                                                        {formatNumber(entry.entries[id].incoming)}
                                                    </ValueStackItem>
                                                ))}
                                        </Stack>
                                    </td>
                                    <td>
                                        <Stack spacing={0.5}>
                                            <span>{formatNumber(entry.difference)}</span>
                                            {showFundPlacementRows &&
                                                report.fundPlacementIds.map((id) => (
                                                    <ValueStackItem key={id}>
                                                        {formatNumber(entry.entries[id].difference)}
                                                    </ValueStackItem>
                                                ))}
                                        </Stack>
                                    </td>
                                    <td>
                                        <Stack spacing={0.5}>
                                            <span>{formatNumber(entry.outgoing)}</span>
                                            {showFundPlacementRows &&
                                                report.fundPlacementIds.map((id) => (
                                                    <ValueStackItem key={id}>
                                                        {formatNumber(entry.entries[id].outgoing)}
                                                    </ValueStackItem>
                                                ))}
                                        </Stack>
                                    </td>
                                </tr>
                            ))}
                        </Fragment>
                    ))}
                    <tr className={styles.sumLine}>
                        <td>
                            <Stack spacing={0.5}>
                                <span>{t('pages-fund-reconciliation-report.sumBalanceMarketValue')}</span>
                                {showFundPlacementRows &&
                                    report.fundPlacementIds.map((id) => (
                                        <FundNameStackItem
                                            key={id}
                                            fundName={report.balanceReport.outgoingShareClassInfo[id].fullName}
                                        />
                                    ))}
                            </Stack>
                        </td>
                        <td className={styles.noPrint}>
                            <Stack spacing={0.5}>
                                <span>&nbsp;</span>
                                {showFundPlacementRows &&
                                    report.fundPlacementIds.map((id) => (
                                        <FundNameStackItem
                                            key={id}
                                            fundName={report.balanceReport.outgoingShareClassInfo[id].isin}
                                        />
                                    ))}
                            </Stack>
                        </td>
                        <td>
                            <Stack spacing={0.5}>
                                <span>{formatNumber(report.balanceReport.sumIncoming)}</span>
                                {showFundPlacementRows &&
                                    report.fundPlacementIds.map((id) => (
                                        <ValueStackItem key={id}>
                                            {formatNumber(report.balanceReport.sumEntries[id].incoming)}
                                        </ValueStackItem>
                                    ))}
                            </Stack>
                        </td>
                        <td>
                            <Stack spacing={0.5}>
                                <span>{formatNumber(report.balanceReport.sumDifference)}</span>
                                {showFundPlacementRows &&
                                    report.fundPlacementIds.map((id) => (
                                        <ValueStackItem key={id}>
                                            {formatNumber(report.balanceReport.sumEntries[id].difference)}
                                        </ValueStackItem>
                                    ))}
                            </Stack>
                        </td>
                        <td>
                            <Stack spacing={0.5}>
                                <span>{formatNumber(report.balanceReport.sumOutgoing)}</span>
                                {showFundPlacementRows &&
                                    report.fundPlacementIds.map((id) => (
                                        <ValueStackItem key={id}>
                                            {formatNumber(report.balanceReport.sumEntries[id].outgoing)}
                                        </ValueStackItem>
                                    ))}
                            </Stack>
                        </td>
                    </tr>
                </tbody>
            </table>
        </Stack>
    )
}

type BalanceHeaderProps = {
    startDate: string
    endDate: string
}
function BalanceHeader({ startDate, endDate }: BalanceHeaderProps) {
    const { t } = useTranslation()
    const isPrint = useMediaQuery('print')
    return (
        <Fragment>
            <colgroup>
                <col style={{ width: isPrint ? '37%' : '23%' }} />
                {!isPrint && <col style={{ width: '14%' }} />}
                <col style={{ width: '21%' }} />
                <col style={{ width: '21%' }} />
                <col style={{ width: '21%' }} />
            </colgroup>
            <thead>
                <tr>
                    <th>
                        <Stack>
                            <span>&nbsp;</span>
                            <span>{t('pages-fund-reconciliation-report.colName')}</span>
                        </Stack>
                    </th>
                    {!isPrint && (
                        <th>
                            <Stack>
                                <span>&nbsp;</span>
                                <span>&nbsp;</span>
                            </Stack>
                        </th>
                    )}
                    <th>
                        <Stack>
                            <span className={styles.date}>{DateOutput.formatDate(startDate)}</span>
                            <span style={{ whiteSpace: 'nowrap' }}>
                                {t('pages-fund-reconciliation-report.colIncomingBalance')}
                            </span>
                        </Stack>
                    </th>
                    <th>
                        <Stack>
                            <span>&nbsp;</span>
                            <span>{t('pages-fund-reconciliation-report.colChanges')}</span>
                        </Stack>
                    </th>
                    <th>
                        <Stack>
                            <span className={styles.date}>{DateOutput.formatDate(endDate)}</span>
                            <span style={{ whiteSpace: 'nowrap' }}>
                                {t('pages-fund-reconciliation-report.colClosingBalance')}
                            </span>
                        </Stack>
                    </th>
                </tr>
            </thead>
        </Fragment>
    )
}

type ResultTableSectionProps = {
    report: FundAccountingReport
    startDate: string
    endDate: string
    showFundPlacementRows: boolean
    setShowFundPlacementRows: (value: boolean) => void
    currency: Currency
}

function ResultTableSection({
    report,
    startDate,
    endDate,
    showFundPlacementRows,
    setShowFundPlacementRows,
    currency,
}: ResultTableSectionProps) {
    const { t } = useTranslation()
    const formatNumber = useNumberFormatter(currency)
    const startOfYear = startDate.substring(0, 4) + '-01-01'
    const isPrint = useMediaQuery('print')

    return (
        <Stack spacing={isPrint ? 1 : 4} sx={{ pageBreakInside: 'avoid' }}>
            <Stack direction={'row'} justifyContent={'space-between'}>
                <Typography variant={'h2'}>{t('pages-fund-reconciliation-report.result')}</Typography>
                <FormControlLabel
                    sx={{ displayPrint: 'none' }}
                    control={
                        <Switch
                            checked={showFundPlacementRows}
                            onChange={(e) => setShowFundPlacementRows(e.target.checked)}
                        />
                    }
                    label={t('pages-fund-reconciliation-report.showDetails')}
                    labelPlacement="start"
                />
            </Stack>
            <table className={styles.fundAccountingTable}>
                <ResultTableHeader startDate={startDate} endDate={endDate} startOfYear={startOfYear} />
                <tbody>
                    {Object.entries(report.resultReport.entries).map(([category, subReport]) => (
                        <Fragment key={category}>
                            <tr className={styles.subHeader}>
                                <td colSpan={5}>{t(`enum-SubReportCategory.${category}`)}</td>
                            </tr>
                            {Object.entries(subReport).map(([entryType, entry]) => (
                                <tr key={entryType}>
                                    <td>
                                        <Stack spacing={0.5}>
                                            <Typography>
                                                <span style={{ whiteSpace: 'nowrap' }}>
                                                    {t(`enum-ReportEntryType.${entryType}`)}
                                                </span>
                                                {entryType === 'RETROCESSION_CASH_VALUE' && (
                                                    <Tooltip
                                                        title={t(
                                                            'pages-fund-reconciliation-report.noCorrespondingBalanceEntry'
                                                        )}
                                                        className={styles.noPrint}
                                                    >
                                                        <i className="ri-information-line purple" />
                                                    </Tooltip>
                                                )}
                                            </Typography>
                                            {showFundPlacementRows &&
                                                report.fundPlacementIds.map((id) => (
                                                    <FundNameStackItem
                                                        key={id}
                                                        fundName={
                                                            report.resultReport.outgoingShareClassInfo[id].fullName
                                                        }
                                                    />
                                                ))}
                                        </Stack>
                                    </td>
                                    <td className={styles.noPrint}>
                                        <Stack spacing={0.5}>
                                            <span>&nbsp;</span>
                                            {showFundPlacementRows &&
                                                report.fundPlacementIds.map((id) => (
                                                    <FundNameStackItem
                                                        key={id}
                                                        fundName={report.resultReport.outgoingShareClassInfo[id].isin}
                                                    />
                                                ))}
                                        </Stack>
                                    </td>
                                    <td>
                                        <Stack spacing={0.5}>
                                            <span>{formatNumber(entry.difference)}</span>
                                            {showFundPlacementRows &&
                                                report.fundPlacementIds.map((id) => (
                                                    <ValueStackItem key={id}>
                                                        {formatNumber(entry.entries[id].difference)}
                                                    </ValueStackItem>
                                                ))}
                                        </Stack>
                                    </td>
                                    <td>
                                        {!startDate.endsWith('01-01') && (
                                            <Stack spacing={0.5}>
                                                <span>{formatNumber(entry.differenceThisYear)}</span>
                                                {showFundPlacementRows &&
                                                    report.fundPlacementIds.map((id) => (
                                                        <ValueStackItem key={id}>
                                                            {formatNumber(entry.entries[id].differenceThisYear)}
                                                        </ValueStackItem>
                                                    ))}
                                            </Stack>
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </Fragment>
                    ))}
                    <tr className={styles.sumLine}>
                        <td>
                            <Stack spacing={0.5}>
                                <span>{t('pages-fund-reconciliation-report.sumResult')}</span>
                                {showFundPlacementRows &&
                                    report.fundPlacementIds.map((id) => (
                                        <FundNameStackItem
                                            key={id}
                                            fundName={report.resultReport.outgoingShareClassInfo[id].fullName}
                                        />
                                    ))}
                            </Stack>
                        </td>
                        <td className={styles.noPrint}>
                            <Stack spacing={0.5}>
                                <span>&nbsp;</span>
                                {showFundPlacementRows &&
                                    report.fundPlacementIds.map((id) => (
                                        <FundNameStackItem
                                            key={id}
                                            fundName={report.resultReport.outgoingShareClassInfo[id].isin}
                                        />
                                    ))}
                            </Stack>
                        </td>
                        <td>
                            <Stack spacing={0.5}>
                                <span>{formatNumber(report.resultReport.sumDifference)}</span>
                                {showFundPlacementRows &&
                                    report.fundPlacementIds.map((id) => (
                                        <ValueStackItem key={id}>
                                            {formatNumber(report.resultReport.sumEntries[id].difference)}
                                        </ValueStackItem>
                                    ))}
                            </Stack>
                        </td>
                        <td>
                            {!startDate.endsWith('01-01') && (
                                <Stack spacing={0.5}>
                                    <span>{formatNumber(report.resultReport.sumDifferenceThisYear)}</span>
                                    {showFundPlacementRows &&
                                        report.fundPlacementIds.map((id) => (
                                            <ValueStackItem key={id}>
                                                {formatNumber(report.resultReport.sumEntries[id].differenceThisYear)}
                                            </ValueStackItem>
                                        ))}
                                </Stack>
                            )}
                        </td>
                    </tr>
                </tbody>
            </table>
        </Stack>
    )
}

type ResultTableHeaderProps = {
    startDate: string
    endDate: string
    startOfYear: string
}

function ResultTableHeader({ startDate, endDate, startOfYear }: ResultTableHeaderProps) {
    const { t } = useTranslation()
    const isPrint = useMediaQuery('print')
    return (
        <Fragment>
            <colgroup>
                <col style={{ width: isPrint ? '37%' : '23%' }} />
                {!isPrint && <col style={{ width: '14%' }} />}
                <col style={{ width: '42%' }} />
                <col style={{ width: '21%' }} />
            </colgroup>
            <thead>
                <tr>
                    <th>
                        <Stack>
                            <span>&nbsp;</span>
                            <span>{t('pages-fund-reconciliation-report.colName')}</span>
                        </Stack>
                    </th>
                    {!isPrint && (
                        <th>
                            <Stack>
                                <span>&nbsp;</span>
                                <span>&nbsp;</span>
                            </Stack>
                        </th>
                    )}
                    <th>
                        <Stack>
                            <span className={styles.date}>
                                {DateOutput.formatDate(startDate)} - {DateOutput.formatDate(endDate)}
                            </span>
                            <span style={{ whiteSpace: 'nowrap' }}>
                                {t('pages-fund-reconciliation-report.colChangesThisPeriod')}
                            </span>
                        </Stack>
                    </th>
                    <th>
                        {!startDate.endsWith('01-01') && (
                            <Stack>
                                <span className={styles.date}>
                                    {DateOutput.formatDate(startOfYear)} - {DateOutput.formatDate(endDate)}
                                </span>
                                <span style={{ whiteSpace: 'nowrap' }}>
                                    {t('pages-fund-reconciliation-report.colChangesThisYear')}
                                </span>
                            </Stack>
                        )}
                    </th>
                </tr>
            </thead>
        </Fragment>
    )
}

function FundNameStackItem({ fundName }: { fundName: string | undefined }) {
    return (
        <Tooltip title={fundName ?? ''} placement={'top'} arrow>
            <Box
                component={'span'}
                sx={{
                    pl: 1,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    color: SILVER_GRAY[400],
                }}
            >
                {fundName}
            </Box>
        </Tooltip>
    )
}

function ValueStackItem({ children }: { children?: React.ReactNode }) {
    return (
        <Box component={'span'} sx={{ color: SILVER_GRAY[400] }}>
            {children}
        </Box>
    )
}

const useNumberFormatter = (currency: Currency) => {
    const { t } = useTranslation()
    const Currency = useCurrencyOutputWithCurrency(currency)
    // eslint-disable-next-line react/display-name
    return (value: number) => {
        if (value === 0) {
            return (
                <Tooltip title={t('pages-fund-reports.valueIsZero')}>
                    <span>-</span>
                </Tooltip>
            )
        } else if (!value) {
            return (
                <Tooltip title={t('pages-fund-reports.valueNotAvailable')}>
                    <span>{'\u2026'}</span>
                </Tooltip>
            )
        } else {
            return Currency(value)
        }
    }
}
