import {MenuItem, Select, Stack, Typography} from "@mui/material";
import {SILVER_GRAY} from "#app/colors/colors";
import parse from "date-fns/parse";
import {useTranslation} from "react-i18next";
import {DateOutput} from "#components";
import { useFundReportVersions } from '#pages/portfolio-depositor/Reports/FundReports/useFundReports'
import LoadingSpinner from '#components/LoadingSpinner'

type Props = {
    reportType: "ACCOUNTING" | "INVENTORY"
    depositorId: string,
    portfolioId: string,
    period: string,
    currentVersion: string,
    currentTimestamp: string,
    changeVersion: (newVersion: string) => void
    isPrint?: boolean
}

export function ReportVersionSelect(
    {
        reportType,
        depositorId,
        portfolioId,
        period,
        currentVersion,
        currentTimestamp,
        changeVersion,
        isPrint
    }: Props) {
    if (isPrint) {
        return <SingleReportVersion version={currentVersion} updated={currentTimestamp}/>
    }
    return ReportVersionSelectWithHook({ reportType, depositorId, portfolioId, period, currentVersion, currentTimestamp, changeVersion })
}

function ReportVersionSelectWithHook(
    {
        reportType,
        depositorId,
        portfolioId,
        period,
        currentVersion,
        currentTimestamp,
        changeVersion
    }: Props) {
    const {t} = useTranslation()
    const {availableVersions, isSuccess, isLoading, isError} = useFundReportVersions(reportType, depositorId, portfolioId, period, currentVersion)
    if (isLoading) {
        return <LoadingSpinner/>
    }
    if (isError || !isSuccess || !availableVersions || Object.keys(availableVersions).length < 2) {
        return <SingleReportVersion version={currentVersion} updated={currentTimestamp}/>
    }
    if (!Object.keys(availableVersions).includes(currentVersion)) {
        availableVersions[currentVersion] = currentTimestamp
    }
    return (
        <Select
            size={'small'}
            value={currentVersion || ''} onChange={(e) => {changeVersion(e.target.value as string)}}>

            {Object.entries(availableVersions)
                .sort(([a], [b]) => parseInt(b) - parseInt(a))
                .map(([v, updated]) => (
                    <MenuItem
                        key={v}
                        value={v}
                    >
                        <Stack>
                            <Typography fontSize={14} fontWeight={600}>{t('pages-fund-reports.version', {version: v})}</Typography>
                            <Typography variant={'caption'} color={SILVER_GRAY[500]}>{DateOutput.formatDateTime(parse(updated, 'yyyy-MM-dd HH:mm', new Date()))}</Typography>
                        </Stack>
                    </MenuItem>))}
        </Select>
    )
}

function SingleReportVersion({version, updated}: {version: string, updated: string}) {
    const {t} = useTranslation()
    return (
        <Stack>
            <Typography variant={'h4'}>{t('pages-fund-reports.version', {version})}</Typography>
            <Typography variant={'caption'} color={SILVER_GRAY[500]}>{DateOutput.formatDateTime(parse(updated, 'yyyy-MM-dd HH:mm', new Date()))}</Typography>
        </Stack>
    )
}

