import { PURPLE } from '#app/colors/colors'
import FxDialog from '#app/components/FxDialog/FxDialog'
import FxDialogActions from '#app/components/FxDialog/FxDialogActions'
import FxDialogContent from '#app/components/FxDialog/FxDialogContent'
import FxDialogTitle from '#app/components/FxDialog/FxDialogTitle'
import { useCommand } from '#app/services/beta'
import { personIsAccountCreator, userIsAccountCreator } from '#app/utilities/accountCreatorUtils'
import { DepositorDto, OrganisationalRole, SecurityRole } from '@fixrate/fixrate-query'
import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Fade,
    FormControlLabel,
    Grid,
    IconButton,
    Stack,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
} from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import BadgeList from '../../components/BadgeList'
import NameOutput from '../../components/NameOutput'
import POASigners, { Procuration } from './POASigners'
import { Proxy } from './PowerOfAttorney'
import { Signatories } from './Signatories'

export default function NeedsAuthorization({ depositor }: { depositor: DepositorDto }) {
    const { t } = useTranslation()
    const [selectedOpenRole, setSelectedOpenRole] = useState<SecurityRole | OrganisationalRole | null>(null)
    const [proxyLoading, setProxyLoading] = useState('')
    const [deletePowerOfAttorneyModal, setDeletePowerOfAttorneyModal] = useState(false)
    const [selectedProxy, setSelectedProxy] = useState<Proxy>(null)
    const navigate = useNavigate()
    const { setPersonSecurityRoles } = useCommand()
    const nonSelectableRoles = ['DEPOSITOR_PARTNER_SUPPORT']
    const selectablePeople = depositor.people.filter((person) =>
        person.securityRoles.every((role) => !nonSelectableRoles.includes(role))
    )

    const peopleProxies: Proxy[] = depositor?.people
        .filter((person) => personIsAccountCreator(person))
        .map((person) => {
            if (person.associatedUserId) {
                const user = depositor.users.find((u) => u.id === person.associatedUserId)
                return {
                    ...user,
                    personId: person.personId,
                    isUser: true,
                    isInvitee: false,
                    isPerson: false,
                }
            } else if (person.associatedInviteId) {
                const invitee = depositor.userInvites.find((u) => u.id === person.associatedInviteId)
                return {
                    ...invitee,
                    personId: person.personId,
                    isUser: false,
                    isInvitee: true,
                    isPerson: false,
                }
            } else {
                return {
                    id: person.personId,
                    personId: person.personId,
                    firstName: person.firstName,
                    lastName: person.lastName,
                    roles: person.securityRoles,
                    authorizationDocumentId: undefined,
                    isUser: false,
                    isInvitee: false,
                    isPerson: true,
                }
            }
        })
        .filter((proxy) => !!proxy)

    const partnerProxies: Proxy[] = depositor?.partnerRelations
        .filter((partner) => partner.partnerCategory !== 'SUPPORT')
        .flatMap((partner) => partner.advisors)
        .map((advisor) => advisor.userId)
        .map((userId) => depositor.users.find((user) => user.id === userId))
        .filter((user) => !!user && userIsAccountCreator(user))
        .map((user) => {
            return {
                ...user,
                personId: undefined,
                isUser: true,
                isInvitee: false,
                isPerson: false,
            }
        })

    const proxies = [...peopleProxies, ...partnerProxies]

    async function toggleNeedsAuthorization(proxy: Proxy) {
        setProxyLoading(proxy.id)
        if (!proxy.roles.includes('DEPOSITOR_ACCOUNT_HOLDER_WITHOUT_AUTHORIZATION') && proxy.authorizationDocumentId) {
            setSelectedProxy(proxy)
            setDeletePowerOfAttorneyModal(true)
        } else {
            let newRoles
            if (proxy.roles.includes('DEPOSITOR_ACCOUNT_HOLDER_WITHOUT_AUTHORIZATION')) {
                newRoles = proxy.roles.filter((role) => role !== 'DEPOSITOR_ACCOUNT_HOLDER_WITHOUT_AUTHORIZATION')
            } else {
                newRoles = [...proxy.roles, 'DEPOSITOR_ACCOUNT_HOLDER_WITHOUT_AUTHORIZATION']
            }
            await setPersonSecurityRoles(depositor.id, proxy.personId, newRoles)
        }
        setTimeout(() => setProxyLoading(''), 2000)
    }

    const closeDeletePowerOfAttorneyModal = () => {
        setDeletePowerOfAttorneyModal(false)
        setSelectedProxy(null)
    }

    return (
        <Box>
            <FxDialog open={deletePowerOfAttorneyModal}>
                <FxDialogTitle onClose={closeDeletePowerOfAttorneyModal}>
                    {t('pages-organizations.powerOfAttorneyExistsHeader')}
                </FxDialogTitle>
                <FxDialogContent>
                    <Box pb={2}>
                        <Typography>
                            {t('pages-organizations.powerOfAttorneyExists', {
                                name: selectedProxy?.firstName + ' ' + selectedProxy?.lastName,
                            })}
                        </Typography>
                    </Box>
                </FxDialogContent>
                <FxDialogActions>
                    <Button variant={'outlined'} onClick={() => setDeletePowerOfAttorneyModal(false)}>
                        {t('common.cancel')}
                    </Button>
                    <Button
                        variant={'contained'}
                        onClick={() =>
                            navigate(
                                selectedProxy.isUser
                                    ? `/organizations/${depositor.id}/users/authorization/${selectedProxy.id}`
                                    : `/organizations/${depositor.id}/users/authorizationforinvite/${selectedProxy.id}`
                            )
                        }
                    >
                        {t('pages-organizations.goToPowerOfAttorney')}
                    </Button>
                </FxDialogActions>
            </FxDialog>
            <Typography>{t('pages-organizations.signingRightsDescription')}</Typography>
            <Table sx={{ my: 2 }}>
                <TableHead>
                    <TableRow>
                        <TableCell>{t('pages-marketplace.accountHolder')}</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {proxies.map((proxy) => (
                        <TableRow key={proxy.id}>
                            <TableCell>
                                <Typography>{NameOutput.withBirthYearOnDuplicates(proxy, depositor)}</Typography>
                            </TableCell>
                            <TableCell>
                                <Stack direction={'row'} alignItems={'center'}>
                                    <FormControlLabel
                                        data-cy="needsAuthorization"
                                        disabled={
                                            proxyLoading === proxy.id ||
                                            proxy.roles.includes('DEPOSITOR_PARTNER_ACCOUNTANT')
                                        }
                                        control={
                                            <Checkbox
                                                checked={
                                                    !proxy.roles.includes(
                                                        'DEPOSITOR_ACCOUNT_HOLDER_WITHOUT_AUTHORIZATION'
                                                    )
                                                }
                                                onChange={() => toggleNeedsAuthorization(proxy)}
                                            />
                                        }
                                        label={
                                            <Stack direction="row" spacing={2} alignItems="center">
                                                <Typography fontSize={'1.4rem'} fontWeight={600}>
                                                    {t('pages-organizations.poaNeedsPoa')}
                                                </Typography>
                                                {!proxy.roles.includes('DEPOSITOR_PARTNER_ACCOUNTANT') && (
                                                    <Box width="2rem">
                                                        {proxyLoading === proxy.id && <CircularProgress size={20} />}
                                                    </Box>
                                                )}
                                            </Stack>
                                        }
                                    />
                                    {proxy.roles.includes('DEPOSITOR_PARTNER_ACCOUNTANT') && (
                                        <Tooltip title={t('pages-organizations.accountantsNeedsPoa')} color="primary">
                                            <IconButton>
                                                <i
                                                    className="ri-information-line"
                                                    style={{ fontSize: '1.8rem', color: PURPLE[500] }}
                                                />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                </Stack>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Signatories nationalIdentity={depositor.nationalIdentity} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Procuration depositor={depositor} />
                </Grid>
            </Grid>
            {proxies?.some(
                (p) =>
                    p.roles.includes('DEPOSITOR_ACCOUNT_HOLDER') &&
                    !p.roles.includes('DEPOSITOR_ACCOUNT_HOLDER_WITHOUT_AUTHORIZATION')
            ) && (
                <Fade in={true} timeout={1000}>
                    <Stack
                        mt={3}
                        spacing={1}
                        p={3}
                        sx={{
                            //backgroundColor: PURPLE[25],
                            borderWidth: '1px',
                            borderStyle: 'solid',
                            borderColor: PURPLE[100],
                            borderRadius: '0.6rem',
                        }}
                    >
                        <Typography variant="h2" color={PURPLE[900]}>
                            {proxies?.filter(
                                (p) =>
                                    p.roles.includes('DEPOSITOR_ACCOUNT_HOLDER') &&
                                    !p.roles.includes('DEPOSITOR_ACCOUNT_HOLDER_WITHOUT_AUTHORIZATION')
                            ).length > 1
                                ? t('pages-organizations.poaShouldSignPlural')
                                : t('pages-organizations.poaShouldSignSingular')}
                        </Typography>
                        <BadgeList
                            selectedOpenRole={selectedOpenRole}
                            setSelectedOpenRole={setSelectedOpenRole}
                            depositor={depositor}
                            people={selectablePeople}
                            securityRole={'DEPOSITOR_SIGN_AUTHORIZATION'}
                            organisationalRole={null}
                            showPersonalWarnings={false}
                            namesWithRoles={true}
                            row
                        />
                    </Stack>
                </Fade>
            )}
        </Box>
    )
}
