import { DepositorDto, DepositorUserDto, OrganisationalPersonDto, SecurityRole } from '@fixrate/fixrate-query'

type AccountCreatorRolesType = Extract<SecurityRole, 'DEPOSITOR_ACCOUNT_HOLDER' | 'DEPOSITOR_PARTNER_ACCOUNTANT'>

const accountCreatorRoles: AccountCreatorRolesType[] = ['DEPOSITOR_ACCOUNT_HOLDER', 'DEPOSITOR_PARTNER_ACCOUNTANT']

const userIsAccountCreator = (user: DepositorUserDto) => {
    const userRoles = user?.roles.map((role) => role)
    return accountCreatorRoles.some((role) => userRoles?.includes(role))
}

const personIsAccountCreator = (person: OrganisationalPersonDto) => {
    const personRoles = person?.securityRoles.map((role) => role)
    return accountCreatorRoles.some((role) => personRoles?.includes(role))
}

const accountCreatorUserList = (depositor: DepositorDto) => {
    return depositor?.users.filter((user) => userIsAccountCreator(user))
}

export {
    userIsAccountCreator,
    personIsAccountCreator,
    accountCreatorUserList,
    AccountCreatorRolesType,
    accountCreatorRoles,
}
