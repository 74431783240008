import { useSelector } from '#state/useSelector'
import en from 'date-fns/locale/en-US'
import nb from 'date-fns/locale/nb'
import sv from 'date-fns/locale/sv'

export function useSessionLanguage(): string | null {
    return useSelector((state) => state?.session?.language)
}

const locales = {
    en: en,
    nb: nb,
    sv: sv,
}
export function useSessionDateFnsLocale(): LocaleAliased | null {
    const language = useSessionLanguage()
    if (!language) {
        return null
    }
    return locales[language] || null
}
