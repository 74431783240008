import { Avatar, Button, Divider, ListItemIcon, ListItemText, Menu, MenuItem, useMediaQuery } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { OrganisationSelectorOption } from '../PageHeader'
import {
    listItemIconStyle,
    listItemTextStyle,
    OrganisationSearch,
    OrganizationMenuItem,
    selectButtonStyles,
} from './MenuComponents'

export const PartnerSelectCustomer = ({
    organisationOptions,
}: {
    organisationOptions: OrganisationSelectorOption[]
}) => {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const [selectCustomerAnchorEl, setSelectCustomerAnchorEl] = useState<null | HTMLElement>(null)
    const customerSelectionMenuOpen = Boolean(selectCustomerAnchorEl)
    const isMobile = useMediaQuery('(max-width: 800px)')

    const handleCustomerMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setSelectCustomerAnchorEl(event.currentTarget)
    }

    const handleCustomerMenuClose = () => {
        setSelectCustomerAnchorEl(null)
    }

    return (
        <>
            <Button
                id="partner-depositor-button"
                data-cy="partner-depositor-button"
                aria-controls={'partner-depositor-menu'}
                aria-haspopup="true"
                aria-expanded={'true'}
                onClick={handleCustomerMenuClick}
                color="darkMode"
                startIcon={
                    <Avatar
                        sx={{
                            fontSize: '1.4rem!important',
                            height: '2.8rem',
                            width: '2.8rem',
                            fontWeight: '600',
                            marginRight: isMobile ? '0' : null,
                        }}
                    >
                        <i className="ri-building-line" />
                    </Avatar>
                }
                sx={{ ...selectButtonStyles, borderLeftColor: 'rgba(250,250,250,0.3)' }}
            >
                {!isMobile && <span>{t('pages-customer.selectCustomer')}</span>}
            </Button>
            <Menu
                id="partner-depositor-menu"
                data-cy="partner-depositor-menu"
                anchorEl={selectCustomerAnchorEl}
                open={customerSelectionMenuOpen}
                onClose={handleCustomerMenuClose}
                MenuListProps={{
                    'aria-labelledby': 'partner-depositor-button',
                }}
            >
                {organisationOptions?.filter(
                    (org) => org.organisationType === 'DEPOSITOR' || org.organisationType === 'BANK'
                ).length > 10 && <OrganisationSearch organisationOptions={organisationOptions} />}
                {organisationOptions?.length > 1 &&
                    organisationOptions
                        ?.filter((org) => org.organisationType === 'DEPOSITOR' || org.organisationType === 'BANK')
                        .map((org, index) => <OrganizationMenuItem key={org?.key} org={org} />)}
                <Divider />
                <MenuItem
                    sx={{
                        minWidth: '30rem',
                    }}
                    color="primary"
                    onClick={() => {
                        handleCustomerMenuClose()
                        navigate('/customer/register')
                    }}
                >
                    <ListItemIcon sx={listItemIconStyle}>
                        <i className="ri-add-line" />
                    </ListItemIcon>
                    <ListItemText sx={listItemTextStyle}>{t('pages-customer.addNewCustomer')}</ListItemText>
                </MenuItem>
            </Menu>
        </>
    )
}
