import { Fragment, useState } from 'react'
import CustomerDeclarationDocumentSection from './CustomerDeclarationDocumentSection'
import CustomerDeclarationWizardSection from './CustomerDeclarationWizardSection'
import { PersonValidator } from '#services/PersonValidator'
import useCurrentDepositor from '#app/services/useCurrentDepositor'

type Props = {
    inline?: boolean
}

export default function CustomerDeclarationInfo({ inline = false }: Props) {
    const depositor = useCurrentDepositor()

    const [forceShowWizard, setForceShowWizard] = useState(false)

    const wizardIsVisible =
        forceShowWizard ||
        PersonValidator.hasCustomerDeclarationPersonGalleryChanged(depositor) ||
        depositor?.customerDeclarationDraftState !== 'NO_DRAFT'

    const showDocumentSection = (!wizardIsVisible && inline) || !inline

    return (
        <Fragment>
            {wizardIsVisible && <CustomerDeclarationWizardSection inline={inline} />}
            {showDocumentSection && (
                <CustomerDeclarationDocumentSection
                    wizardIsVisible={wizardIsVisible}
                    setForceShowWizard={setForceShowWizard}
                    inline={inline}
                />
            )}
        </Fragment>
    )
}
