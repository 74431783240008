import { Paper } from '#app/components'
import useCurrentCountryCode from '#app/services/useCurrentCountryCode'
import useCurrentDepositor from '#app/services/useCurrentDepositor'
import { useCommand } from '#command'
import { useAuthorization } from '#services/authorization'
import { formatAccount, formatIban } from '#services/formatnumber'
import * as selectors from '#state/selectors'
import { useSelector } from '#state/useSelector'
import { SettlementAccountDto } from '@fixrate/fixrate-query'
import {
    Alert,
    Box,
    Button,
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Tooltip,
    Typography,
    useTheme,
} from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import { isFuture } from 'date-fns'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import styles from './Accounts.module.scss'
import NewAccount from './NewAccount'
import UploadAccountCertificate from './UploadAccountCertificate'

type Props = {
    allowMultipleAccounts?: boolean
    inline?: boolean
}

const AccountsInnerElement = ({ allowMultipleAccounts }: { allowMultipleAccounts: boolean }) => {
    const { t } = useTranslation()
    const location = useLocation()
    const navigate = useNavigate()
    const depositor = useCurrentDepositor()
    const organisationCountry = useCurrentCountryCode()
    const regulatoryRegion = useSelector((state) => state.depositor?.regulatoryRegion)
    const deposits = useSelector((state) => state.deposits)
    const auth = useAuthorization(depositor.id)
    const accounts = useSelector(
        (state) => selectors.depositorSettlementAccounts(state)[depositor.id] || ([] as SettlementAccountDto[])
    )
    const currencies = depositor?.portfolios?.map((p) => p.currency)
    const multipleCurrencies = currencies?.length > 1
    const showCurrencyOnAccount = currencies?.length > 1 || (currencies?.[0] !== 'NOK' && currencies?.[0] !== null)

    const missingCurrencies = currencies?.filter(
        (c) => !accounts?.find((a) => a.currency === c || (c === 'NOK' && a.currency === null))
    )

    const theme = useTheme()
    const desktop = useMediaQuery(theme.breakpoints.up('md'))
    const mobile = useMediaQuery(theme.breakpoints.down('md'))

    const documents = useSelector((state) => state?.documents)

    const { removeSettlementAccount } = useCommand()

    async function onRemove(accountId: string) {
        const { waitForCommand } = await removeSettlementAccount(depositor.id, accountId)
        await waitForCommand()
    }

    const canRemoveAccount = auth.depositor.hasAdminRole || auth.depositor.hasPartnerAccountantRole

    const removeButtonVisible = (account: SettlementAccountDto) => {
        return (
            canRemoveAccount &&
            account.fundPortfolioClientAccount === false &&
            deposits
                ?.filter((dep) => isFuture(new Date(dep.terminationDate)))
                ?.find((d) => d.settlementAccountId === account.id) === undefined
        )
    }

    const canNotDeleteReason = (account: SettlementAccountDto) => {
        if (account.fundPortfolioClientAccount) {
            return t('pages-organizations.fundPortfolioClientAccount')
        }

        if (
            deposits
                ?.filter((dep) => isFuture(new Date(dep.terminationDate)))
                ?.find((d) => d.settlementAccountId === account.id) !== undefined
        ) {
            return t('pages-organizations.accountHasDeposits')
        }

        if (!canRemoveAccount) {
            return t('pages-organizations.noPermissionToRemoveAccount')
        }

        return ''
    }
    return (
        <>
            <Typography>{t('pages-organizations.settlementAccountMessage')}</Typography>
            <List>
                {accounts?.map?.((account) => {
                    let accountNumber, accountBankName
                    if (account.account) {
                        accountNumber = formatAccount(account.account, organisationCountry)
                        accountBankName = account.bankName
                    } else {
                        accountNumber = formatIban(account.iban)
                        accountBankName = 'BIC (SWIFT): ' + account.bic
                    }

                    return (
                        <Fragment key={account.id}>
                            <ListItem
                                sx={{
                                    flexDirection: mobile ? 'column' : null,
                                    border: mobile ? '1px solid rgba(0,0,0,0.1)' : null,
                                    padding: mobile ? '2rem 0' : null,
                                }}
                            >
                                <ListItemIcon sx={{ marginRight: mobile ? '0' : null }}>
                                    <i className="ri-bank-line" />
                                </ListItemIcon>
                                <ListItemText
                                    sx={{ textAlign: mobile ? 'center' : null }}
                                    //Handling the case where the account currency is null – aka existing account NOK
                                    primary={
                                        account.name +
                                        (showCurrencyOnAccount
                                            ? ' (' + (account.currency !== null ? account.currency : 'NOK') + ')'
                                            : '')
                                    }
                                    data-cy="accountName"
                                    secondary={
                                        <span style={{ display: 'flex', flexDirection: 'column' }}>
                                            <span data-cy="accountNumber" style={{ marginBottom: 0 }}>
                                                {accountNumber}
                                            </span>
                                            <span data-cy="accountBankName">{accountBankName}</span>
                                        </span>
                                    }
                                />
                                {organisationCountry === 'SE' && (
                                    <Box mx={1}>
                                        <UploadAccountCertificate
                                            depositorId={depositor?.id}
                                            settlementAccountId={account?.id}
                                            currentDocument={documents[account?.bankAccountCertificateId]}
                                        />
                                    </Box>
                                )}
                                {removeButtonVisible(account) ? (
                                    <Button
                                        sx={{
                                            marginTop: { xs: '1rem', md: '0' },
                                            textDecoration: 'none',
                                            ':hover': {
                                                textDecoration: 'none',
                                            },
                                        }}
                                        startIcon={<i className="ri-delete-bin-line" />}
                                        size="small"
                                        color={'error'}
                                        variant="text"
                                        onClick={async () => await onRemove(account.id)}
                                    >
                                        <Box>{t('pages-organizations.removeAccount')}</Box>
                                    </Button>
                                ) : (
                                    <Tooltip sx={{ textAlign: 'center' }} title={canNotDeleteReason(account)}>
                                        <IconButton>
                                            <i className="ri-lock-line" />
                                        </IconButton>
                                    </Tooltip>
                                )}
                            </ListItem>
                            {account.fundPortfolioClientAccount && (
                                <Alert severity={'info'} style={{ marginLeft: 'auto', marginBottom: '1em' }}>
                                    {t('pages-organizations.fundPortfolioClientAccount')}
                                </Alert>
                            )}
                            {regulatoryRegion === 'SE' && !documents[account?.bankAccountCertificateId] && (
                                <Alert severity={'warning'}>{t('pages-organizations.missingAccountCertificate')}</Alert>
                            )}
                            {desktop && <Divider component="li" />}
                        </Fragment>
                    )
                })}
            </List>
            {missingCurrencies.length > 0 &&
                multipleCurrencies &&
                missingCurrencies.map((c) => (
                    <Alert severity="info" key={c}>
                        {t('pages-organizations.missingCurrencyAccount', { currency: c })}
                    </Alert>
                ))}
            {accounts.length === 0 && (
                <Alert severity="error">
                    {auth.depositor.hasAdminRole || auth.depositor.hasPartnerAccountantRole
                        ? t('pages-organizations.settlementAccountMissing')
                        : t('pages-organizations.settlementAccountMissingNotAdmin')}
                </Alert>
            )}
            {(auth.depositor.hasAdminRole || auth.depositor.hasPartnerAccountantRole) && allowMultipleAccounts && (
                <div className={styles.addNew}>
                    <Button
                        id="addNewAccountButton"
                        variant={accounts.length === 0 ? 'contained' : 'outlined'}
                        onClick={() => {
                            navigate(`/organizations/${depositor.id}/accounts/new-account`)
                        }}
                    >
                        {accounts.length === 0
                            ? t('pages-organizations.addAccount')
                            : t('pages-organizations.addMoreAccounts')}
                    </Button>
                </div>
            )}
            {location.pathname === `/organizations/${depositor.id}/accounts/new-account` && (
                <NewAccount depositorId={depositor.id} />
            )}
        </>
    )
}

export default function Accounts({ allowMultipleAccounts = true, inline = false }: Props) {
    const { t } = useTranslation()

    return inline ? (
        <AccountsInnerElement allowMultipleAccounts={allowMultipleAccounts} />
    ) : (
        <Paper
            id="accounts"
            title={t('pages-organizations.settlementAccountHeading')}
            supportCommonName="settlementAccounts"
        >
            <AccountsInnerElement allowMultipleAccounts={allowMultipleAccounts} />
        </Paper>
    )
}
