import config from '../config'
import * as Sentry from '@sentry/browser'
import { Extras, SeverityLevel } from '@sentry/types'
import { BrowserTracing } from '@sentry/tracing'

const enableSentry = !!config().sentryUrl

export function installSentry() {
    if (Sentry && enableSentry) {
        console.log('Installing Sentry', config().sentryUrl, config().marketplaceVersion)
        Sentry.init({
            dsn: config().sentryUrl,
            release: config().marketplaceVersion,
            environment: config().sentryEnv,
            integrations: [new BrowserTracing(), Sentry.captureConsoleIntegration({ levels: ['error'] })],
            tracesSampleRate: 0.2,
            beforeSend(event) {
                if (event.exception) {
                    const error = event.exception.values[0]
                    if (error && error.type === 'TypeError' && error.value.includes('Failed to fetch')) {
                        // This is likely a network error, so don't report it
                        return null
                    }
                }

                // Report other types of errors
                return event
            },
        })
    }
}

export function setSentryUser(sessionId) {
    if (Sentry && enableSentry) {
        Sentry.setUser({ id: sessionId })
    }
}

export function clearSentryUser() {
    if (Sentry && enableSentry) {
        Sentry.setUser({ id: undefined })
    }
}

export function sendExceptionToSentry(error, info: Extras) {
    console.log('Error', error, info)
    if (Sentry && enableSentry) {
        Sentry.withScope((scope) => {
            scope.setExtras(info)
            Sentry.captureException(error)
        })
    }
}

export function sendSentryMessage(message: string, info: Extras, level: SeverityLevel) {
    if (!level) level = 'error'
    if (Sentry && enableSentry) {
        Sentry.withScope((scope) => {
            scope.setExtras(info)
            Sentry.captureMessage(message, level)
        })
    }
}
