import { useState } from 'react'
import { useTracking as useReactTracking } from 'react-tracking'
import { useSelector } from '#state/useSelector'

interface TrackingProps {
    page: string
}

interface TrackingEvent {
    event: string
    [key: string]: unknown
}

export const useTracking = ({ page }: TrackingProps) => {
    const session = useSelector((state) => state.session)
    const { trackEvent } = useReactTracking({ page, session })

    const [eventsTracked, setEventsTracked] = useState<{ [key: string]: boolean }>({})

    const trackEventOnce = (newEvent: TrackingEvent) => {
        if (newEvent && !eventsTracked[newEvent.event]) {
            trackEvent(newEvent)
            setEventsTracked({ ...eventsTracked, [newEvent.event]: true })
        }
    }

    return {
        trackEvent: (event: TrackingEvent) => trackEvent(event), // Type wrapper
        trackEventOnce,
    }
}
