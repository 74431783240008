import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
    name: 'session',
    initialState: {
        unknownState: true,
        authenticated: false,
    },
    reducers: {
        setSession(state, action) {
            return action.payload
        },
    },
})

const { actions, reducer } = slice
export const { setSession } = actions
export default reducer
