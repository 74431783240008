import { PURPLE, SUNSET_ORANGE } from '#app/colors/colors'
import { Accordion, AccordionDetails, AccordionSummary, Alert, Stack, Typography } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
    header: string
    infoVisible: boolean
    hasPeopleChanges: boolean
    hasNonExactMatch: boolean
    children: React.ReactNode
}

export default function Brregbox({ header, infoVisible = true, hasPeopleChanges, hasNonExactMatch, children }: Props) {
    const { t } = useTranslation()
    const [expand, setExpand] = useState<boolean>(infoVisible)
    return (
        <Accordion expanded={expand} onChange={(e, expanded) => setExpand(expanded)} defaultExpanded={infoVisible}>
            <AccordionSummary
                expandIcon={<i className="ri-arrow-down-s-line" style={{ color: PURPLE[900], fontSize: '2rem' }} />}
                aria-controls="registerinfo-content"
                id="registerinfo-header"
                sx={{
                    backgroundColor: PURPLE[25],
                    border: '0.1rem solid ' + PURPLE[100],
                    borderTopRightRadius: '0.6rem',
                    borderTopLeftRadius: '0.6rem',
                    borderBottomLeftRadius: expand ? '0' : '0.6rem',
                    borderBottomRightRadius: expand ? '0' : '0.6rem',
                }}
            >
                <Stack direction="row" spacing={1} maxWidth="90%" alignItems="center" sx={{ color: PURPLE[500] }}>
                    <i className="ri-archive-drawer-line" />
                    <Typography fontSize={'1.4rem'} fontWeight={600}>
                        {header}
                    </Typography>
                </Stack>
                {hasPeopleChanges && hasNonExactMatch && (
                    <Alert
                        variant="filled"
                        data-cy="brreg-changes"
                        icon={<i className="ri-information-line" />}
                        sx={{
                            py: 0,
                            backgroundColor: SUNSET_ORANGE[50],
                            color: SUNSET_ORANGE[900],
                            marginLeft: 'auto',
                            marginRight: '1rem',
                        }}
                    >
                        {t('pages-organizations.personGalleryMismatch')}
                    </Alert>
                )}
            </AccordionSummary>
            <AccordionDetails
                sx={{
                    borderBottomLeftRadius: '0.6rem',
                    borderBottomRightRadius: '0.6rem',
                    py: 2,
                    border: '0.1rem solid ' + PURPLE[100],
                    borderTop: '0',
                }}
            >
                {children}
            </AccordionDetails>
        </Accordion>
    )
}
