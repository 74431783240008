import styles from './BrregBox.module.scss'
import { DepositorDto } from '@fixrate/fixrate-query'
import { useTranslation } from '#components/i18n'
import { useSelector } from '#state/useSelector'
import { DateOutput } from '#components'
import { Box } from '@mui/material'

interface Props {
    depositor: DepositorDto
}

export default function BrregOwners({ depositor }: Props) {
    const { t } = useTranslation('pages-organizations')
    const companyPublicInformation = useSelector((state) =>
        state.companyPublicInformation.find((c) => c.nationalIdentity === depositor.nationalIdentity)
    )
    if (!companyPublicInformation) return null

    const owners = companyPublicInformation.people
        .filter((person) => person.controlShare && person.controlShare > 0)
        .map((person) => `${person.name} (${DateOutput.formatDate(person.birthDate)}): ${person.controlShare}%`)

    return (
        <div className={styles.data}>
            <div className={styles.section}>
                {owners && owners.length > 0 && (
                    <Box component={'ul'} mb={0}>
                        {owners.map((person) => (
                            <li key={person}>{person}</li>
                        ))}
                    </Box>
                )}

                {(!owners || owners.length === 0) && (
                    <Box component={'ul'} mb={0}>
                        <li>{t('brregNoData')}</li>
                    </Box>
                )}
            </div>
        </div>
    )
}
