import { HelpIcon } from '#app/components'
import { useCommand } from '#app/services/beta'
import { UpdatePersonPep } from '#app/services/beta/command/useCommand.types'
import useCurrentDepositor from '#app/services/useCurrentDepositor'
import { useFieldState } from '@fixrate/fieldstate'
import { OrganisationalPersonDto } from '@fixrate/fixrate-query'
import {
    Box,
    Checkbox,
    FormControlLabel,
    FormGroup,
    FormLabel,
    InputLabel,
    Radio,
    RadioGroup,
    Stack,
    TextField,
    Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'

export default function PEP({
    person,
    setUpdating,
}: {
    person: OrganisationalPersonDto
    setUpdating: (value: boolean) => void
}) {
    const { t } = useTranslation()
    const depositor = useCurrentDepositor()
    const { updatePersonPep } = useCommand()

    const pepSelf = useFieldState(person.politicallyExposedPerson.pepSelf, null, { validateInitially: true }, (value) =>
        updatePEP({ pepSelf: value })
    )

    const familyMemberIsPep = useFieldState(
        person.politicallyExposedPerson.familyMemberOfPep,
        null,
        { validateInitially: true },
        (value) => updatePEP({ familyMemberIsPep: value })
    )

    const colleagueIsPep = useFieldState(
        person.politicallyExposedPerson.colleagueOfPep,
        null,
        { validateInitially: true },
        (value) => updatePEP({ colleagueIsPep: value })
    )

    const pep = useFieldState(
        person.pep,
        ({ value, isEditing, isSet }) => {
            if (!isSet) {
                return t('pages-organizations.personFormMustBeGivenForCustomerDeclaration')
            }
        },
        { validateInitially: true },
        (value) => updatePEP({ pep: value })
    )

    const pepPosition = useFieldState(
        person.pepPosition,
        ({ value, isEditing, isSet }) => {
            if (!isSet && pep.value === true) {
                return t('pages-organizations.personFormMustBeGivenForCustomerDeclaration')
            }
        },
        { validateInitially: true },
        (value) => updatePEP({ pepPosition: value })
    )

    const pepInvestingPrivateFunds = useFieldState(
        person.pepInvestingPrivateFunds,
        ({ value, isEditing, isSet }) => {
            if (!isSet && pep.value === true) {
                return t('pages-organizations.personFormMustBeGivenForCustomerDeclaration')
            }
        },
        { validateInitially: true },
        (value) => updatePEP({ pepInvestingPrivateFunds: value })
    )

    async function updatePEP(updatedFields?: Partial<UpdatePersonPep>) {
        setUpdating(true)
        const { waitForCommand } = await updatePersonPep({
            depositorId: depositor.id,
            personId: person.personId,
            pep: pep.value,
            pepSelf: !!pepSelf.value,
            familyMemberIsPep: !!familyMemberIsPep.value,
            colleagueIsPep: !!colleagueIsPep.value,
            pepPosition: pepPosition.value,
            pepInvestingPrivateFunds: pepInvestingPrivateFunds.value,
            ...updatedFields,
        })
        await waitForCommand()
        setUpdating(false)
    }

    return (
        <Box>
            <FormLabel sx={{ display: 'flex', flexDirection: 'column' }} id="pep-yes-no">
                <InputLabel sx={{ mb: 0.5 }}>{t('pages-organizations.personInfoPep')} (PEP)</InputLabel>
                <Typography variant="caption" maxWidth={'75rem'}>
                    <span>{t('pages-organizations.isPepDescription')} </span>
                    <span>{t('pages-organizations.personInfoPepDescription')}</span>
                    <span> </span>
                    <HelpIcon inline sx={{}} supportCommonName="pep" />
                </Typography>
            </FormLabel>
            <RadioGroup
                aria-labelledby="pep-yes-no"
                name="pep"
                row
                value={pep.value}
                onChange={(e) => {
                    pep.submitValue(e.target.value === 'true')
                }}
            >
                <FormControlLabel data-cy="pep-yes" value={true} control={<Radio />} label={t('common.yes')} />
                <FormControlLabel data-cy="pep-no" value={false} control={<Radio />} label={t('common.no')} />
            </RadioGroup>
            <p className="field-error-message">{pep.errorMessage}</p>
            {pep.value === true && (
                <Stack spacing={2} mt={2}>
                    <Box>
                        <InputLabel sx={{ mb: 0 }}>{t('pages-organizations.pepReason')}</InputLabel>
                        <Typography variant="caption">{t('pages-organizations.pepReasonDescription')}</Typography>
                        <FormGroup>
                            <FormControlLabel
                                label={t('pages-organizations.pep-isSelf')}
                                control={
                                    <Checkbox
                                        name="IS_PEP_SELF"
                                        data-cy="pepSelf"
                                        onChange={(e, val) => pepSelf.submitValue(val)}
                                        checked={!!pepSelf.value}
                                    />
                                }
                            />
                            <FormControlLabel
                                label={t('pages-organizations.pep-familyMemberIsPep')}
                                control={
                                    <Checkbox
                                        name="FAMILY_MEMBER_IS_PEP"
                                        data-cy="pepFamily"
                                        onChange={(e, val) => familyMemberIsPep.submitValue(val)}
                                        checked={!!familyMemberIsPep.value}
                                    />
                                }
                            />
                            <FormControlLabel
                                label={t('pages-organizations.pep-coWorkerIsPep')}
                                control={
                                    <Checkbox
                                        name="CO_WORKER_IS_PEP"
                                        data-cy="pepCoWorker"
                                        onChange={(e, val) => colleagueIsPep.submitValue(val)}
                                        checked={!!colleagueIsPep.value}
                                    />
                                }
                            />
                        </FormGroup>
                        {!pepSelf.value && !familyMemberIsPep.value && !colleagueIsPep.value && (
                            <p className="field-error-message">
                                {t('pages-organizations.personFormMustBeGivenForCustomerDeclaration')}
                            </p>
                        )}
                    </Box>
                    <Box>
                        <InputLabel sx={{ mb: 0 }}>
                            <span>{t('pages-organizations.personInfoPepPositionHeading')}</span>
                        </InputLabel>
                        <Typography variant="caption">{t('pages-organizations.personInfoPepPosition')}</Typography>
                        <TextField
                            sx={{ mt: 1 }}
                            multiline
                            fullWidth
                            onBlur={() => updatePEP()}
                            minRows={4}
                            maxRows={10}
                            data-cy="pepReason"
                            value={pepPosition.value || ''}
                            onChange={(e) => {
                                pepPosition.setValue(e.target.value)
                            }}
                        />
                        <p className="field-error-message">{pepPosition.errorMessage}</p>
                    </Box>
                    <Box>
                        <FormLabel id="privateFunds">
                            {t('pages-organizations.personInfoPepInvestingPrivateFunds')}
                        </FormLabel>
                        <RadioGroup
                            aria-labelledby="privateFunds"
                            name="pepInvestingPrivateFunds"
                            row
                            value={pepInvestingPrivateFunds.value}
                            onChange={(e) => {
                                pepInvestingPrivateFunds.submitValue(e.target.value === 'true')
                            }}
                        >
                            <FormControlLabel
                                value={true}
                                data-cy="private-funds-yes"
                                control={<Radio />}
                                label={t('common.yes')}
                            />
                            <FormControlLabel
                                value={false}
                                data-cy="private-funds-no"
                                control={<Radio />}
                                label={t('common.no')}
                            />
                        </RadioGroup>
                        <p className="field-error-message">{pepInvestingPrivateFunds.errorMessage}</p>
                    </Box>
                </Stack>
            )}
        </Box>
    )
}
