import { OrganisationalPersonDto } from '@fixrate/fixrate-query'
import PersonalInformation from './PersonalInformation/PersonalInformation'

interface Props {
    person: OrganisationalPersonDto
    onClose: () => void
    open?: boolean
    hideInvitationAlert?: boolean
    wizardMode?: boolean
}

export default function EditPersonModal({ person, onClose, open = true, hideInvitationAlert, wizardMode }: Props) {
    return (
        <PersonalInformation
            person={person}
            onClose={onClose}
            open={open}
            wizardMode={wizardMode}
            hideInvitationAlert={hideInvitationAlert}
        />
    )
}
