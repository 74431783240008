import CountryMultiSelect from '#app/components/CountrySelect/CountryMultiSelect'
import { useCommand } from '#app/services/beta'
import { UpdatePersonNationality } from '#app/services/beta/command/useCommand.types'
import useCurrentCountryCode from '#app/services/useCurrentCountryCode'
import useCurrentDepositor from '#app/services/useCurrentDepositor'
import { useFieldState } from '@fixrate/fieldstate'
import { CountryCode, OrganisationalPersonDto, RegulatoryRegion } from '@fixrate/fixrate-query'
import { Box, InputLabel, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import TaxResidencies from './TaxResidencies/TaxResidencies'

export default function Nationality({
    region,
    person,
    setUpdating,
}: {
    region: RegulatoryRegion
    person: OrganisationalPersonDto
    setUpdating: (value: boolean) => void
}) {
    const { t } = useTranslation()
    const depositor = useCurrentDepositor()
    const regulatoryRegion = useCurrentCountryCode()
    const { updatePersonNationality } = useCommand()

    const citizenships = useFieldState(
        person.citizenships,
        ({ value, isEditing, isSet }) => {
            if (!isSet) {
                return t('pages-organizations.personFormCitizenshipMissing')
            }
        },
        { validateInitially: true },
        (val) => updateNationality({ citizenships: val })
    )

    const taxResidencies = useFieldState(
        person.taxResidencies?.length > 0 ? person.taxResidencies : [{ nationality: regulatoryRegion, value: '' }],
        ({ value, isEditing, isSet }) => {
            if (isSet && value.some((val) => val.nationality?.length < 1)) {
                return t('pages-organizations.personFormMustSelectNationality')
            }
            if (isSet && value.some((val) => val.value?.length < 1)) {
                return t('pages-organizations.personFormMustProvideNationalId')
            }
        },
        { validateInitially: true },
        (val) => updateNationality({ taxResidencies: val })
    )

    // updated fields needs to be one or more the fields typed in UpdatePersonNationality type
    async function updateNationality(updatedFields: Partial<UpdatePersonNationality>) {
        const newFields = {
            taxResidencies: taxResidencies.value,
            citizenships: citizenships.value,
            ...updatedFields,
        }

        const fieldsHaveChanged = () => {
            const taxResidenciesFieldsHaveChanged = !newFields.taxResidencies.every((tr) =>
                person.taxResidencies.find((oldTr) => oldTr.nationality === tr.nationality && oldTr.value === tr.value)
            )
            const taxResidenciesChanged =
                taxResidenciesFieldsHaveChanged || newFields.taxResidencies.length !== person.taxResidencies.length
            const citizenshipsChanged =
                newFields.citizenships.find((c) => person.citizenships.includes(c)) === undefined ||
                newFields.citizenships.length !== person.citizenships.length

            if (taxResidenciesChanged || citizenshipsChanged) {
                return true
            }
            return false
        }

        if (!fieldsHaveChanged()) {
            return
        }

        setUpdating(true)
        const newValues = {
            depositorId: depositor.id,
            personId: person.personId,
            taxResidencies: taxResidencies.value,
            citizenships: citizenships.value,
            ...updatedFields,
        }
        const validatedNewValues = {
            ...newValues,
            taxResidencies: newValues.taxResidencies.filter(
                (field) => !(field.nationality === '' && field.value === '')
            ),
        }
        const { waitForCommand } = await updatePersonNationality(validatedNewValues)
        await waitForCommand()
        setUpdating(false)
    }

    function updateCitizenShips(countries: CountryCode[]) {
        if (countries.includes('US') && !taxResidencies.value.some((tr) => tr.nationality === 'US')) {
            addTaxResidency('US')
        }
        citizenships.submitValue(countries)
    }

    function addTaxResidency(nationality?: CountryCode) {
        taxResidencies.setValue([...taxResidencies.value, { nationality: nationality ?? '', value: '' }])
    }

    return (
        <>
            <Box mb={3}>
                <Stack mb={1}>
                    <InputLabel sx={{ mb: 0 }}>{t('pages-organizations.citizenship')}</InputLabel>
                    <Typography component="span" variant="caption">
                        {t('pages-organizations.citizensDescription')}
                    </Typography>
                </Stack>
                <CountryMultiSelect
                    dataCy="selectCitizenship"
                    selectedCountries={citizenships.value}
                    setSelectedCountries={(countries: CountryCode[]) => updateCitizenShips(countries)}
                />
                {citizenships?.value?.length === 0 && (
                    <p className="field-error-message" style={{ maxWidth: '30rem' }}>
                        {t('pages-organizations.personFormMustSelectCitizenship')}
                    </p>
                )}
            </Box>
            <TaxResidencies taxResidencies={taxResidencies} citizenships={citizenships.value} />
        </>
    )
}
