import { AvailableLanguage, languageFlags, supportedLanguages as defaultSupportedLanguages } from '#app/services/i18n'
import { Box, MenuItem, Select, Stack } from '@mui/material'
import 'flag-icons/css/flag-icons.min.css'

const LanguageSelect = ({
    language,
    setLanguage,
    dataCy,
    supportedLanguages = defaultSupportedLanguages,
}: {
    language: AvailableLanguage
    setLanguage: (val: AvailableLanguage) => void
    dataCy?: string
    supportedLanguages?: Partial<Record<AvailableLanguage, string>>
}) => {
    return (
        <Select data-cy={dataCy} value={language} onChange={(e) => setLanguage(e.target.value as AvailableLanguage)}>
            {Object.keys(supportedLanguages).map((lang) => (
                <MenuItem value={lang} key={lang} sx={{ py: 1 }}>
                    <Stack direction={'row'} alignItems={'center'}>
                        <Box width={'2rem'} height={'2rem'} className={languageFlags[lang]} mr={1} />
                        {supportedLanguages[lang]}
                    </Stack>
                </MenuItem>
            ))}
        </Select>
    )
}

export { LanguageSelect }
