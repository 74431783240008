import config from '#app/config'
import { DepositorDto } from '@fixrate/fixrate-query'
import { DateOutput } from '#components'
import { useTranslation } from '#components/i18n'
import * as selectors from '#state/selectors'
import { useSelector } from '#state/useSelector'
import classNames from 'classnames'
import styles from './CustomerDeclarationInfo.module.scss'

interface CustomerDeclarationDocumentLinkProps {
    className?: string
    linkText?: string
    children?: React.ReactNode
    document?: string
    depositor: DepositorDto
}

const API_BASE_URL = config().apiUrl

const CustomerDeclarationDocumentLink = function ({
    className,
    linkText = '',
    children,
    document: propsDocument = null,
    depositor,
}: CustomerDeclarationDocumentLinkProps) {
    const { t } = useTranslation('pages-organizations')
    const documents = useSelector(({ documents }) => documents)

    let document
    if (propsDocument === Object(propsDocument)) {
        document = propsDocument
    } else if (typeof propsDocument === 'string') {
        document = documents?.[propsDocument]
    }

    const isCheckingSignatureStatus = useSelector((state) => selectors.lookupSignatureStatus(state)(document?.id))

    if (!depositor || !document) return null

    const expired = document?.id === depositor.customerDeclarationId && depositor.customerDeclarationExpired
    const signed = document?.signedByAny && document?.signedByAll

    function onClick() {
        window.open(API_BASE_URL + document.link, '_blank')
    }

    return (
        <div
            data-cy="documentLink"
            className={classNames(styles.document, className, expired && styles.expired)}
            onClick={onClick}
        >
            <div className={styles.documentName}>
                {expired && <i className="ri-file-warning-line" />}
                {!expired && <i className="ri-file-line" />}

                <span>{children || linkText || document?.name}</span>
            </div>

            {isCheckingSignatureStatus && (
                <div className={styles.statusText}>
                    <p data-cy="signedStatus" className="loading">
                        {t('checkingStatus')}
                    </p>
                </div>
            )}

            {expired && !isCheckingSignatureStatus && (
                <div data-cy="expiredStamp">
                    <i className="ri-information-line red" />
                    {t('expired')}
                </div>
            )}

            {signed && !isCheckingSignatureStatus && !expired && (
                <div className={styles.statusText} data-cy="signedStamp">
                    <i className="ri-shield-check-fill green" />
                    <span>
                        {t('signed')} <DateOutput.Date date={document?.lastSignatureTime} />
                    </span>
                </div>
            )}

            {!signed && !isCheckingSignatureStatus && !expired && (
                <div className={styles.statusText}>
                    <i className="ri-error-warning-line orange" />
                    <span>{t('notSigned')}</span>
                </div>
            )}
        </div>
    )
}

export default CustomerDeclarationDocumentLink
