import { SUCCESS_GREEN, SUNSET_ORANGE } from '#app/colors/colors'
import { HelpIcon } from '#app/components'
import AsyncButton from '#app/components/Button/AsyncButton'
import FxDialog from '#app/components/FxDialog/FxDialog'
import FxDialogActions from '#app/components/FxDialog/FxDialogActions'
import FxDialogContent from '#app/components/FxDialog/FxDialogContent'
import FxDialogTitle from '#app/components/FxDialog/FxDialogTitle'
import RequestAuthorization from '#app/pages/organizations/Authorization/RequestAuthorization/RequestAuthorization'
import SignAuthorization from '#app/pages/organizations/Authorization/SignAuthorization/SignAuthorization'
import { useCommand } from '#app/services/beta'
import { useSelector } from '#app/state/useSelector'
import { personIsAccountCreator, userIsAccountCreator } from '#app/utilities/accountCreatorUtils'
import { DepositorDto, OrganisationalRole, SecurityRole } from '@fixrate/fixrate-query'
import {
    Alert,
    AlertProps,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CircularProgress,
    Divider,
    FormControlLabel,
    Icon,
    Stack,
    Switch,
    Typography,
} from '@mui/material'
import { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import InviteModal from '../../components/InviteModal'
import NameOutput from '../../components/NameOutput'
import { Proxy } from './PowerOfAttorney'

type Action = {
    icon: JSX.Element
    text: string
    onClick: () => void
}

export default function POAList({
    depositor,
    inline,
    hideWarnings,
}: {
    depositor: DepositorDto
    inline?: boolean
    hideWarnings?: boolean
}) {
    const { t } = useTranslation()
    const documents = useSelector(({ documents }) => documents)
    const [selectedOpenRole, setSelectedOpenRole] = useState<SecurityRole | OrganisationalRole | null>(null)
    const [inviteModalPersonId, setInviteModalPersonId] = useState<string>(null)
    const [proxyLoading, setProxyLoading] = useState('')
    const [deletePowerOfAttorneyModal, setDeletePowerOfAttorneyModal] = useState(false)
    const [selectedProxy, setSelectedProxy] = useState<Proxy>(null)
    const [proxyToDelete, setProxyToDelete] = useState<Proxy>(null)
    const [principalPersonIds, setPrincipalPersonIds] = useState([])
    const [requestAuthorizationModalOpen, setRequestAuthorizationModalOpen] = useState(false)
    const [signDocumentModalOpen, setSignDocumentModalOpen] = useState(false)
    const { startElectronicSignature } = useCommand()
    const navigate = useNavigate()
    const { deleteAuthorizationDocument, setPersonSecurityRoles } = useCommand()

    const closeOpenRoleOnClickOutside = () => {
        if (selectedOpenRole != null) {
            setSelectedOpenRole(null)
        }
    }

    const peopleProxies: Proxy[] = depositor?.people
        .filter((person) => personIsAccountCreator(person))
        .map((person) => {
            if (person.associatedUserId) {
                const user = depositor.users.find((u) => u.id === person.associatedUserId)
                return {
                    ...user,
                    personId: person.personId,
                    isUser: true,
                    isInvitee: false,
                    isPerson: false,
                }
            } else if (person.associatedInviteId) {
                const invitee = depositor.userInvites.find((u) => u.id === person.associatedInviteId)
                return {
                    ...invitee,
                    personId: person.personId,
                    isUser: false,
                    isInvitee: true,
                    isPerson: false,
                }
            } else {
                return {
                    id: person.personId,
                    personId: person.personId,
                    firstName: person.firstName,
                    lastName: person.lastName,
                    roles: person.securityRoles,
                    authorizationDocumentId: undefined,
                    isUser: false,
                    isInvitee: false,
                    isPerson: true,
                }
            }
        })
        .filter((proxy) => !!proxy)

    const partnerProxies: Proxy[] = depositor?.partnerRelations
        .filter((partner) => partner.partnerCategory !== 'SUPPORT')
        .flatMap((partner) => partner.advisors)
        .map((advisor) => advisor.userId)
        .map((userId) => depositor.users.find((user) => user.id === userId))
        .filter((user) => !!user && userIsAccountCreator(user))
        .map((user) => {
            return {
                ...user,
                personId: undefined,
                isUser: true,
                isInvitee: false,
                isPerson: false,
            }
        })

    const proxies = [...peopleProxies, ...partnerProxies]

    async function deleteAuthorization(proxy: Proxy) {
        const userId = proxy.isUser ? proxy.id : null
        const inviteId = proxy.isInvitee ? proxy.id : null
        try {
            const { waitForCommand } = await deleteAuthorizationDocument(userId, inviteId, depositor.id)
            await waitForCommand()
            setProxyToDelete(null)
        } catch (err) {
            console.error(err)
        }
    }

    async function toggleNeedsAuthorization(proxy: Proxy) {
        setProxyLoading(proxy.id)
        if (!proxy.roles.includes('DEPOSITOR_ACCOUNT_HOLDER_WITHOUT_AUTHORIZATION') && proxy.authorizationDocumentId) {
            setSelectedProxy(proxy)
            setDeletePowerOfAttorneyModal(true)
        } else {
            let newRoles
            if (proxy.roles.includes('DEPOSITOR_ACCOUNT_HOLDER_WITHOUT_AUTHORIZATION')) {
                newRoles = proxy.roles.filter((role) => role !== 'DEPOSITOR_ACCOUNT_HOLDER_WITHOUT_AUTHORIZATION')
            } else {
                newRoles = [...proxy.roles, 'DEPOSITOR_ACCOUNT_HOLDER_WITHOUT_AUTHORIZATION']
            }
            await setPersonSecurityRoles(depositor.id, proxy.personId, newRoles)
        }
        setTimeout(() => setProxyLoading(''), 500)
    }

    const closeDeletePowerOfAttorneyModal = () => {
        setDeletePowerOfAttorneyModal(false)
        setSelectedProxy(null)
    }

    const getStatus = (proxy: Proxy): { alertType: AlertProps['severity']; message: string } => {
        const authorizationDocument = proxy.authorizationDocumentId ? documents[proxy.authorizationDocumentId] : null

        const renderStatus = (alertType: AlertProps['severity'], message: string) => {
            return {
                alertType: alertType,
                message: message,
            }
        }

        if (
            !proxy.roles.includes('DEPOSITOR_ACCOUNT_HOLDER') &&
            !proxy.roles.includes('DEPOSITOR_PARTNER_ACCOUNTANT')
        ) {
            return renderStatus('info', t('pages-organizations.poaInactive'))
        }

        if (proxy.roles.includes('DEPOSITOR_ACCOUNT_HOLDER_WITHOUT_AUTHORIZATION')) {
            return renderStatus('success', t('pages-organizations.poaNoNeed'))
        }

        if (proxy.isPerson) {
            return renderStatus('warning', t('pages-organizations.inviteNotSent'))
        }

        if (authorizationDocument?.userCanSign && !authorizationDocument?.signedByUser) {
            return renderStatus('warning', t('pages-organizations.missingYourSignature'))
        }

        if (authorizationDocument) {
            if (hideWarnings) {
                return renderStatus('success', t('pages-organizations.poaSignedByYou'))
            }
            return authorizationDocument.signedByAll
                ? renderStatus('success', t('pages-organizations.poaSigned'))
                : renderStatus('warning', t('pages-organizations.poaMissingSign'))
        }

        return renderStatus('warning', t('pages-organizations.poaMissing'))
    }

    const openRequestAuthorizationModal = (proxy: Proxy) => {
        setSelectedProxy(proxy)
        setRequestAuthorizationModalOpen(true)
    }

    const getAction = (proxy: Proxy): Action => {
        const authorizationDocument = proxy.authorizationDocumentId ? documents[proxy.authorizationDocumentId] : null
        const authorizationPageLink = proxy.isUser
            ? `/organizations/${depositor.id}/users/authorization/${proxy.id}`
            : `/organizations/${depositor.id}/users/authorizationforinvite/${proxy.id}`
        if (proxy.isPerson) {
            return {
                icon: <i className="ri-send-line" />,
                text: t('pages-organizations.sendInviteHeader'),
                onClick: () => setInviteModalPersonId(proxy.personId),
            }
        }
        if (authorizationDocument && authorizationDocument.userCanSign) {
            return {
                icon: <i className="ri-file-shield-2-line" />,
                text: t('pages-organizations.signPOA'),
                onClick: () => {
                    setSelectedProxy(proxy)
                    setSignDocumentModalOpen(true)
                },
            }
        }
        return {
            icon: authorizationDocument ? <i className="ri-file-shield-line" /> : <i className="ri-add-box-line" />,
            text: authorizationDocument
                ? t('pages-organizations.goToAuthorization')
                : t('pages-organizations.poaCreate'),
            onClick: () => {
                inline && !authorizationDocument
                    ? openRequestAuthorizationModal(proxy)
                    : navigate(authorizationPageLink)
            },
        }
    }

    if (proxies.length === 0) {
        return (
            <Typography variant="emptyState" mt={3} fontSize="2rem">
                {t('pages-organizations.poaNoUsers')}
            </Typography>
        )
    }

    return (
        <Box onClick={closeOpenRoleOnClickOutside}>
            {inviteModalPersonId && (
                <InviteModal personId={inviteModalPersonId} close={() => setInviteModalPersonId(null)} />
            )}
            <FxDialog open={!!proxyToDelete} onClose={() => setProxyToDelete(null)}>
                <FxDialogTitle onClose={() => setProxyToDelete(null)}>
                    {t('pages-organizations.powerOfAttorneyConfirmDeleteHeader')}
                </FxDialogTitle>
                <FxDialogContent>
                    <Box pb={2}>
                        <Typography>{t('pages-organizations.powerOfAttorneyConfirmDeleteDescription')}</Typography>
                    </Box>
                </FxDialogContent>
                <FxDialogActions>
                    <AsyncButton
                        variant={'outlined'}
                        color={'error'}
                        onClick={() => deleteAuthorization(proxyToDelete)}
                    >
                        {t('pages-organizations.deletePOA')}
                    </AsyncButton>
                    <Button variant={'outlined'} onClick={() => setProxyToDelete(null)}>
                        {t('common.cancel')}
                    </Button>
                </FxDialogActions>
            </FxDialog>

            <FxDialog open={deletePowerOfAttorneyModal}>
                <FxDialogTitle onClose={closeDeletePowerOfAttorneyModal}>
                    {t('pages-organizations.powerOfAttorneyExistsHeader')}
                </FxDialogTitle>
                <FxDialogContent>
                    <Box pb={2}>
                        <Typography>
                            {t('pages-organizations.powerOfAttorneyExists', {
                                name: selectedProxy?.firstName + ' ' + selectedProxy?.lastName,
                            })}
                        </Typography>
                    </Box>
                </FxDialogContent>
                <FxDialogActions>
                    <Button variant={'outlined'} onClick={() => setDeletePowerOfAttorneyModal(false)}>
                        {t('common.cancel')}
                    </Button>
                    <Button
                        variant={'contained'}
                        onClick={() =>
                            navigate(
                                selectedProxy.isUser
                                    ? `/organizations/${depositor.id}/users/authorization/${selectedProxy.id}`
                                    : `/organizations/${depositor.id}/users/authorizationforinvite/${selectedProxy.id}`
                            )
                        }
                    >
                        {t('pages-organizations.goToPowerOfAttorney')}
                    </Button>
                </FxDialogActions>
            </FxDialog>
            {requestAuthorizationModalOpen && (
                <RequestAuthorization
                    onClose={() => {
                        setRequestAuthorizationModalOpen(false)
                        setSelectedProxy(null)
                    }}
                    inline={inline}
                    principalPersonIds={principalPersonIds}
                    setPrincipalPersonIds={setPrincipalPersonIds}
                    attorney={selectedProxy}
                />
            )}
            {signDocumentModalOpen && (
                <SignAuthorization
                    authorizationDocument={documents[selectedProxy?.authorizationDocumentId]}
                    inline={true}
                    depositorId={depositor.id}
                    forUser={selectedProxy}
                    startElectronicSignature={startElectronicSignature}
                    onClose={() => setSignDocumentModalOpen(false)}
                    contextUrl={
                        inline
                            ? `/organizations/onboarding/${depositor.id}/powerOfAttorney`
                            : `/organizations/${depositor.id}/users/power-of-attorney`
                    }
                />
            )}
            <Stack spacing={2}>
                <Stack>
                    {!inline && (
                        <Stack direction={'row'} spacing={1} alignItems="center">
                            <Typography variant="h2" mb={0}>
                                {t('pages-organizations.poaTitle')}
                            </Typography>
                            <HelpIcon supportCommonName="authorizations" />
                        </Stack>
                    )}
                    <Typography>{t('pages-organizations.poaDescription')}</Typography>
                    {hideWarnings && (
                        <Typography>{t('pages-organizations.poaAccountantOnboardingDescription')}</Typography>
                    )}
                </Stack>
                <Stack direction={'row'} flexWrap={'wrap'} spacing={2} mt={2}>
                    {proxies.map((proxy) => (
                        <Card
                            data-cy="userNeedingAuthorization"
                            key={proxy.id}
                            sx={{ width: '100%', maxWidth: '38rem' }}
                        >
                            <CardContent>
                                <Stack direction="row" spacing={1} alignItems="center">
                                    <Icon
                                        sx={{
                                            color:
                                                getStatus(proxy).alertType === 'success'
                                                    ? SUCCESS_GREEN[800]
                                                    : SUNSET_ORANGE[800],
                                        }}
                                    >
                                        {getStatus(proxy).alertType === 'success' ? (
                                            <i className="ri-file-shield-2-line" />
                                        ) : (
                                            <i className="ri-file-warning-line" />
                                        )}
                                    </Icon>
                                    <Typography variant="h3">
                                        {NameOutput.withBirthYearOnDuplicates(proxy, depositor)}
                                    </Typography>
                                </Stack>
                            </CardContent>
                            <Divider />
                            <Alert
                                severity={getStatus(proxy).alertType}
                                icon={false}
                                sx={{ px: '3rem', boxShadow: 'none' }}
                            >
                                <Stack>
                                    <Typography fontSize={'1.4rem'}>Status</Typography>
                                    <Typography fontSize={'1.4rem'} fontWeight={'600'}>
                                        {getStatus(proxy).message}
                                    </Typography>
                                </Stack>
                            </Alert>
                            <Divider />
                            {(!proxy.roles.includes('DEPOSITOR_PARTNER_ACCOUNTANT') ||
                                (proxy.roles.includes('DEPOSITOR_PARTNER_ACCOUNTANT') &&
                                    proxy.roles.includes('DEPOSITOR_ACCOUNT_HOLDER_WITHOUT_AUTHORIZATION'))) && (
                                <Fragment>
                                    <CardContent sx={{ py: 1 }}>
                                        <FormControlLabel
                                            data-cy="needsAuthorization"
                                            disabled={proxyLoading === proxy.id}
                                            control={
                                                <Switch
                                                    checked={
                                                        !proxy.roles.includes(
                                                            'DEPOSITOR_ACCOUNT_HOLDER_WITHOUT_AUTHORIZATION'
                                                        )
                                                    }
                                                    onChange={() => toggleNeedsAuthorization(proxy)}
                                                />
                                            }
                                            label={
                                                proxyLoading === proxy.id ? (
                                                    <CircularProgress size={20} />
                                                ) : (
                                                    <Typography fontSize={'1.4rem'} fontWeight={600}>
                                                        {t('pages-organizations.poaNeedsPoa')}
                                                    </Typography>
                                                )
                                            }
                                        />
                                    </CardContent>
                                    <Divider />
                                </Fragment>
                            )}
                            <CardActions sx={{ p: 0 }} disableSpacing>
                                {!proxy.roles.includes('DEPOSITOR_ACCOUNT_HOLDER_WITHOUT_AUTHORIZATION') && (
                                    <Button
                                        sx={{
                                            textDecoration: 'none',
                                            '&:hover': { textDecoration: 'none' },
                                            py: 1.5,
                                            textAlign: 'left',
                                        }}
                                        fullWidth
                                        startIcon={getAction(proxy).icon}
                                        data-cy={
                                            documents[proxy.authorizationDocumentId]
                                                ? 'signAuthorization'
                                                : 'createAuthorization'
                                        }
                                        onClick={() => getAction(proxy).onClick()}
                                    >
                                        {getAction(proxy).text}
                                    </Button>
                                )}
                                {proxy.roles.includes('DEPOSITOR_ACCOUNT_HOLDER_WITHOUT_AUTHORIZATION') &&
                                    proxy.isPerson && (
                                        <Button
                                            sx={{
                                                textDecoration: 'none',
                                                '&:hover': { textDecoration: 'none' },
                                                py: 1.5,
                                                textAlign: 'left',
                                            }}
                                            fullWidth
                                            startIcon={getAction(proxy).icon}
                                            data-cy={'createAuthorization'}
                                            onClick={() => getAction(proxy).onClick()}
                                        >
                                            {getAction(proxy).text}
                                        </Button>
                                    )}
                                {documents[proxy.authorizationDocumentId] && (
                                    <Divider orientation="vertical" sx={{ m: 0, height: '4rem' }} />
                                )}
                                {documents[proxy.authorizationDocumentId] && (
                                    <Button
                                        sx={{
                                            textDecoration: 'none',
                                            '&:hover': { textDecoration: 'none' },
                                            py: 1.5,
                                            textAlign: 'left',
                                        }}
                                        fullWidth
                                        color="error"
                                        variant="text"
                                        startIcon={<i className="ri-delete-bin-line" />}
                                        data-cy={'deleteAuthorization'}
                                        onClick={() => setProxyToDelete(proxy)}
                                    >
                                        {t('common.delete')}
                                    </Button>
                                )}
                            </CardActions>
                        </Card>
                    ))}
                </Stack>
            </Stack>
        </Box>
    )
}
