import { Box, Button, Paper, Stack, SxProps, Typography } from '@mui/material'
import FixrateIllustration, { IllustrationColor, IllustrationName } from '../FixrateIllustration/FixrateIllustration'
import { SILVER_GRAY } from '#app/colors/colors'

type EmptyStateProps = {
    illustration: {
        name: IllustrationName
        color: IllustrationColor
    }
    variant?: 'default' | 'centered-on-background' | 'left-on-background'
    backgroundColor?: string
    title?: string | null
    description?: {
        dataCy?: string
        text: string
    } | null
    children?: React.ReactNode
    button?: {
        dataCy?: string
        text: string
        onClick: () => void
    } | null
    link?: {
        dataCy?: string
        text: string
        onClick: () => void
    } | null
    sx?: SxProps
}

//Component that renders the correct illustration based on the props (color and name) passed to it
export default function EmptyState({
    illustration,
    variant,
    sx,
    backgroundColor = '#fff',
    title = null,
    description = null,
    button = null,
    link = null,
    children = null,
}: EmptyStateProps) {
    const stack = (
        <Box>
            {title && (
                <Typography
                    variant="emptyState"
                    sx={{
                        ...(variant === 'left-on-background' && {
                            display: 'block', // Allows it to wrap
                            flexGrow: 1, // Allows it to grow within the available space
                            maxWidth: '100%', // Prevents it from overflowing
                            textAlign: 'left', // Keeps the text aligned
                            minWidth: 0, // Allows shrinking if necessary
                        }),
                    }}
                >
                    {title}
                </Typography>
            )}
            {description && (
                <Typography color={SILVER_GRAY[500]} data-cy={description?.dataCy}>
                    {description?.text}
                </Typography>
            )}
            {button && (
                <Button
                    variant="contained"
                    data-cy={button?.dataCy}
                    onClick={button?.onClick}
                    sx={{
                        mt: 2,
                        alignSelf: 'flex-start', // to prevent button from stretching
                        width: 'auto',
                        minWidth: 0,
                    }}
                >
                    {button?.text}
                </Button>
            )}
            {link && (
                <Button
                    data-cy={link?.dataCy}
                    sx={{ fontSize: '1.2rem', textDecoration: 'underline' }}
                    onClick={link?.onClick}
                >
                    {link?.text}
                </Button>
            )}
            {children}
        </Box>
    )

    if (variant === 'centered-on-background') {
        return (
            <Stack alignItems="center" maxWidth="120rem">
                <Stack sx={{ ...sx }} direction="column" alignItems="center" justifyContent="center" maxWidth="60rem">
                    <Box maxWidth="50rem">
                        <FixrateIllustration name={illustration.name} color={illustration.color} />
                    </Box>
                    <Stack
                        sx={{
                            textAlign: 'center',
                            px: 3,
                        }}
                        spacing={3}
                        alignItems={{ xs: 'center' }}
                    >
                        {stack}
                    </Stack>
                </Stack>
            </Stack>
        )
    }

    if (variant === 'left-on-background') {
        return (
            <Stack alignItems="left">
                <Stack sx={{ ...sx }} direction="row" alignItems="center" justifyContent="space-between">
                    <Box maxWidth="30rem">
                        <FixrateIllustration name={illustration.name} color={illustration.color} />
                    </Box>
                    <Stack
                        sx={{
                            textAlign: 'left',
                            px: 3,
                            flexGrow: 1, // Allows it to take available space
                            minWidth: 0, // Fixes shrinking issues
                            maxWidth: '100%',
                        }}
                        spacing={3}
                        alignItems={{ xs: 'left' }}
                    >
                        {stack}
                    </Stack>
                </Stack>
            </Stack>
        )
    }

    return (
        <Paper
            sx={{
                backgroundColor: backgroundColor,
                maxWidth: '100rem',
                boxShadow: backgroundColor !== '#fff' && 'none',
                ...sx,
            }}
        >
            <Stack direction={{ md: 'row' }} alignItems="center" justifyContent={'space-between'}>
                <Stack
                    order={{ xs: 2, md: 1 }}
                    sx={{
                        textAlign: { xs: 'center', md: 'left' },
                        pt: { xs: 0, md: 4 },
                        pb: { xs: 6, md: 4 },
                        pl: { xs: 4, md: 6 },
                        pr: { xs: 4, md: 0 },
                    }}
                    maxWidth={'50rem'}
                    spacing={3}
                    alignItems={{ xs: 'center', md: 'flex-start' }}
                >
                    {stack}
                </Stack>
                <Box order={{ xs: 1, md: 2 }} maxWidth={{ xs: '50rem', md: '100%' }} maxHeight="40rem" display={'flex'}>
                    <FixrateIllustration name={illustration.name} color={illustration.color} />
                </Box>
            </Stack>
        </Paper>
    )
}
