import { v4 as uuidv4 } from 'uuid'
import { addPing, clearAllPings } from '#state/reducers/ping'
import config from '../config'
import pingCommand from './command/pingcommand'
import { syncSessionState } from '#services/thunks/session'

const pingFrequency = config().pingFrequencey

// The timer that runs at a specified interval and updates the status flags
let timer

//
// Starts a job that sends a ping message to the server every minute
//
export const startPingThread = (dispatch, getState) => {
    const { ping } = getState()
    if (ping) {
        dispatch(clearAllPings())
    }

    if (timer) {
        clearInterval(timer)
    }

    timer = setInterval(() => {
        const session = getState().session
        if (session && session.authenticated) {
            const pingId = uuidv4()
            dispatch(addPing(pingId))

            pingCommand(dispatch)
                .send(pingId)
                .then((status) => {
                    if (status === 'LOGGED_OUT') {
                        dispatch(syncSessionState())
                    }
                })
        }
    }, pingFrequency)
}

export const stopPingThread = () => {
    if (timer) {
        clearInterval(timer)
    }
}
