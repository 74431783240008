import React from 'react'
import { useTranslation } from 'react-i18next'
import { Alert } from '@mui/material'
import { BURNT_ORANGE, SPRING_GREEN, SUNSET_ORANGE } from '#app/colors/colors'
import { getRoleMatch, MatchType, RolesMatchType } from '../../views/BrregBoard'
import { personMatchTypeTMap } from '#app/services/enumTranslationKeyMapping'
import { PersonMatchDto } from '@fixrate/fixrate-query'

interface BrregComparisonProps {
    person: PersonMatchDto
    proffRole: string
    fixrateRole: string
}

const BrregComparisonLabel: React.FC<BrregComparisonProps> = ({ person, proffRole, fixrateRole }) => {
    const { t } = useTranslation()
    function getStatusLabel(matchType) {
        switch (matchType as string) {
            case 'EXACT': // navn og fødselsdato matcher
                return {
                    label: t(personMatchTypeTMap[matchType]),
                    bgcolor: SPRING_GREEN[50],
                    color: SPRING_GREEN[900],
                    icon: <i className="ri-check-line" />,
                }
            case 'LIKELY': // fødselsdato matcher, navn tilsier at det antakelig er samme person
                return {
                    label: t(personMatchTypeTMap[matchType]),
                    bgcolor: SUNSET_ORANGE[50],
                    color: SUNSET_ORANGE[900],
                    icon: <i className="ri-question-mark" />,
                }
            case 'POSSIBLE': // antakelig har det skjedd et navnebytte, samme person
                return {
                    label: t(personMatchTypeTMap[matchType]),
                    bgcolor: SUNSET_ORANGE[50],
                    color: SUNSET_ORANGE[900],
                    icon: <i className="ri-question-mark" />,
                }
            case 'NONE': // ingen match, enten ny person fra proff eller person i fixrate er fjernet fra proff
                return {
                    label: t(personMatchTypeTMap[matchType]),
                    bgcolor: BURNT_ORANGE[50],
                    color: BURNT_ORANGE[900],
                    icon: <i className="ri-information-line" />,
                }
            case 'ROLES_DIFFER': // roller matcher ikke
                return {
                    label: t(personMatchTypeTMap[matchType]),
                    bgcolor: BURNT_ORANGE[50],
                    color: BURNT_ORANGE[900],
                    icon: <i className="ri-information-line" />,
                }
            case 'MISSING_IN_FIXRATE': // person mangler styrerolle i fixrate
                return {
                    label: t(personMatchTypeTMap[matchType]),
                    bgcolor: BURNT_ORANGE[50],
                    color: BURNT_ORANGE[900],
                    icon: <i className="ri-information-line" />,
                }
            case 'MISSING_IN_PROFF': // person er ikke registrert i proff med styrerolle
                return {
                    label: t(personMatchTypeTMap[matchType]),
                    bgcolor: BURNT_ORANGE[50],
                    color: BURNT_ORANGE[900],
                    icon: <i className="ri-information-line" />,
                }
            default:
                return { label: '-', color: 'default', icon: <i className="ri-information-line" /> }
        }
    }

    const personMatchType = person.matchType
    const rolesMatch: RolesMatchType = getRoleMatch(proffRole, fixrateRole) as RolesMatchType

    let matchType: string = personMatchType.toString()
    if (personMatchType === MatchType.EXACT || personMatchType === MatchType.NONE) {
        matchType = rolesMatch !== RolesMatchType.EXACT ? rolesMatch : personMatchType
    }

    const { label, color, bgcolor, icon } = getStatusLabel(matchType)

    return (
        <Alert
            icon={icon}
            variant="filled"
            sx={{
                p: '0rem 0.5rem',
                backgroundColor: bgcolor,
                color: color,
                width: '100%',
                maxWidth: '300px',
            }}
        >
            {label}
        </Alert>
    )
}

export default BrregComparisonLabel
