type Props = {
    color: string
    dashed?: boolean
}

export default function LegendSquare({ color, dashed = false }: Props) {
    return (
        <div
            style={{
                height: '1.4rem',
                width: '4.2rem',
            }}
        >
            {dashed && (
                <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
                    <rect
                        fill="none"
                        width="calc(100% - 5px)"
                        height="calc(100% - 5px)"
                        stroke={color}
                        strokeWidth="2.5"
                        rx={'0.5rem'}
                        ry={'0.5rem'}
                        x={'2.5'}
                        y={'2.5'}
                        strokeDasharray="4"
                        strokeDashoffset="0"
                        strokeLinecap={'round'}
                    />
                </svg>
            )}
            {!dashed && (
                <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
                    <rect
                        fill={color}
                        width="calc(100% - 5px)"
                        height="calc(100% - 5px)"
                        stroke={color}
                        strokeWidth="2.5"
                        rx={'0.5rem'}
                        ry={'0.5rem'}
                        x={'2.5'}
                        y={'2.5'}
                        strokeLinecap={'round'}
                    />
                </svg>
            )}
        </div>
    )
}

export function LegendCircle({ color }: Props) {
    return (
        <div
            style={{
                backgroundColor: color,
                marginTop: 5,
                width: '0.8rem',
                height: '0.8rem',
                printColorAdjust: 'exact',
                WebkitPrintColorAdjust: 'exact',
                borderRadius: '50%',
            }}
        />
    )
}
