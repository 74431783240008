import { useTranslation } from 'react-i18next'
import AsyncButton from '../Button/AsyncButton'
import { Avatar, Button, Stack, SxProps } from '@mui/material'
import { PURPLE } from '#app/colors/colors'
import { DateOutput } from '#components'

type Props = {
    id?: string
    link: string
    name: string
    onRemove?: () => void
    signatureTime?: string
    sx?: SxProps
    icon?: string
}

export default function DocumentLink({ id, link, name, onRemove, signatureTime, sx, icon }: Props) {
    const { t } = useTranslation()

    return (
        <Stack direction={'row'}>
            <Button
                sx={{
                    textAlign: 'left',
                    textDecoration: 'none',
                    '&:hover': { backgroundColor: 'transparent', textDecoration: 'none' },
                    ...sx,
                }}
                startIcon={
                    <Avatar sx={{ width: '3rem', height: '3rem', backgroundColor: PURPLE[50], color: PURPLE[500] }}>
                        <i className={icon || 'ri-file-text-line'} />
                    </Avatar>
                }
                id={id}
                href={link}
                target="_blank"
                rel="noopener noreferrer"
            >
                <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '100%' }}>
                    {name}
                    {signatureTime && (
                        <span style={{ fontSize: '1.2rem', marginLeft: '0.8rem', color: 'gray' }}>
                            {t('common.signed')} {DateOutput.formatDateTime(signatureTime)}
                        </span>
                    )}
                </div>
            </Button>
            {onRemove && (
                <AsyncButton onClick={onRemove} variant={'text'} color="error">
                    {t('common.remove')}
                </AsyncButton>
            )}
        </Stack>
    )
}
