import { getOrganisationSelectorOptions, OrganisationSelectorOption } from '#app/components/PageHeader/PageHeader'
import { SwitchOrgAnimation, SWITCH_ORG_DURATION } from '#app/components/SwitchOrgAnimation/SwitchOrgAnimation'
import { switchOrganisation } from '#app/services/thunks/session'
import { useSelector } from '#app/state/useSelector'
import { personIsAccountCreator } from '#app/utilities/accountCreatorUtils'
import {
    DepositorDto,
    DepositorWarningType,
    OrganisationUserInviteDto,
    PartnerCustomerDto,
} from '@fixrate/fixrate-query'
import { Button, Paper, Stack, Typography } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Tada from '../illustrations/tada.svg'
import { personHasWarning } from './MissingInformationStep'

export const invitationWarnings: DepositorWarningType[] = [
    'PERSON_NEEDS_INVITE',
    'AUTHORIZATION_SIGNER_NEEDS_INVITE',
    'INVITE_IS_NOT_SENT',
]

export const peopleWithTasks = (depositor: DepositorDto | PartnerCustomerDto) => {
    return depositor?.people.filter(
        (person) =>
            person.securityRoles.includes('DEPOSITOR_SIGN_AUTHORIZATION') ||
            personIsAccountCreator(person) ||
            person.securityRoles.includes('DEPOSITOR_ORDER') ||
            person.securityRoles.includes('DEPOSITOR_PAYMENT')
    )
}

export const hasInvitationWarnings = (depositor: DepositorDto) => {
    const invitationPeople = peopleWithTasks(depositor)
    return invitationPeople.some((person) =>
        personHasWarning({
            type: invitationWarnings,
            depositor: depositor,
            person: person,
        })
    )
}

export async function copyUserInviteLink(
    userInvite: OrganisationUserInviteDto,
    setConfirmCopyModalInviteId: (id: string) => void
) {
    const inviteLink = window.location.href.split(window.location.pathname)[0] + '/invite/' + userInvite?.id
    await navigator.clipboard.writeText(inviteLink)
    setConfirmCopyModalInviteId(userInvite.id)
    setTimeout(() => {
        setConfirmCopyModalInviteId(null)
    }, 2000)
}

export default function PartnerSendOffer({ depositor }: { depositor: DepositorDto }) {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const association = useSelector((state) => state.session?.association)
    const associations = useSelector((state) => state.session?.associations)
    const organisationSelectorOptions = getOrganisationSelectorOptions(associations, association)
    const [partnerSwitchAnimation, setPartnerSwitchAnimation] = useState(false)
    const [prevOrg, setPrevOrg] = useState<OrganisationSelectorOption | null>(null)
    const [newOrg, setNewOrg] = useState<OrganisationSelectorOption | null>()

    // TODO: Unduplicate this code with the one in the org wizard and page header
    function navigateToPartner(url: string) {
        setPartnerSwitchAnimation(true)
        setNewOrg(organisationSelectorOptions.find((o) => o.organisationType === 'PARTNER'))
        setPrevOrg(organisationSelectorOptions.find((o) => o.selected))
        setTimeout(() => {
            const orgId = organisationSelectorOptions.find((o) => o.organisationType === 'PARTNER')?.organisationId
            dispatch(switchOrganisation('PARTNER', orgId, () => navigate(url)))
            setPartnerSwitchAnimation(false)
            setPrevOrg(null)
            setNewOrg(null)
        }, SWITCH_ORG_DURATION)
    }

    return (
        <>
            <Stack py={6} alignItems="center" justifyContent="center" spacing={3}>
                <img src={Tada} alt="" style={{ maxWidth: '20rem' }} />
                <Stack alignItems="center" justifyContent="center" spacing={1}>
                    <Typography variant="h1">{t('pages-OrganizationWizard.allDone')}!</Typography>
                    <Typography>{t('pages-OrganizationWizard.accountantReadyMessage')}</Typography>
                </Stack>
                <Button
                    variant="contained"
                    onClick={() => navigateToPartner('/marketplace/category/deposits/under-20')}
                >
                    {t('pages-OrganizationWizard.findOffers')}
                </Button>
            </Stack>
            {partnerSwitchAnimation && <SwitchOrgAnimation prevOrg={prevOrg} newOrg={newOrg} />}
        </>
    )
}
