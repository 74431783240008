import config from '#app/config'
import { Autocomplete, InputAdornment, TextField } from '@mui/material'
import { useEffect, useState, ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { CompanyInfo, CompanyType } from '#components/CompanySelect/CompanySelect'

const API_BASE_URL = config().apiUrl

interface OrganisasjonsInfo {
    organisasjonsform: { beskrivelse: string; kode: string; utgaatt: string }
    naeringskode1: {
        kode: string
        beskrivelse: string
    }
}

interface BrregCompany {
    navn: string
    organisasjonsnummer: string
    organisasjonsinfo?: OrganisasjonsInfo
}

type Props = {
    onChange: (value: CompanyInfo | null) => void
    setErrorMessage: (error: string) => void
    autoFocus?: boolean
    placeholder?: string
}

export default function CompanySelectBrreg({ onChange, setErrorMessage, autoFocus, placeholder }: Props) {
    const { t } = useTranslation()

    const [value, setValue] = useState<BrregCompany>()
    const [inputValue, setInputValue] = useState('')
    const [options, setOptions] = useState<BrregCompany[]>([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        let active = true // Flag to prevent updating state after unmounting

        if (inputValue.length < 3) {
            return
        }

        setLoading(true)
        ;(async () => {
            const brregData = await fetchSuggestionsFromBrreg(inputValue)
            if (active) {
                if (brregData) {
                    setErrorMessage('')
                    setOptions(brregData)
                } else {
                    setErrorMessage(t('components-CompanySelect.connectionError'))
                    setOptions([])
                }
                setLoading(false)
            }
        })()

        return () => {
            active = false
        }
    }, [inputValue, setErrorMessage, t])

    async function _onChange(_: unknown, newValue: BrregCompany | null) {
        const orgForm = async () => {
            return await fetchOrgFormFromBrreg(newValue.organisasjonsnummer)
        }
        if (newValue) {
            const organisationForm = await orgForm()
            onChange({
                name: newValue.navn,
                nationalIdentity: newValue.organisasjonsnummer,
                nationality: 'NO',
                companyType: companyType(organisationForm),
            })
            setValue(newValue)
            setInputValue(formatLabel(newValue))
        } else {
            onChange(null)
            setValue(null)
            setInputValue('')
            setOptions([])
        }
    }

    function _onInput(e: ChangeEvent<HTMLInputElement>) {
        setInputValue(e.target.value)
        const matchingOption: BrregCompany | undefined = options.find((c) => formatLabel(c) === e.target.value)
        if (matchingOption) {
            onChange({
                name: matchingOption.navn,
                nationalIdentity: matchingOption.organisasjonsnummer,
                nationality: 'NO',
                companyType: companyType(matchingOption.organisasjonsinfo),
            })
        } else {
            onChange(null)
        }
    }

    return (
        <Autocomplete
            freeSolo
            data-cy="companySearchSelect"
            value={inputValue}
            options={options}
            loading={loading}
            isOptionEqualToValue={(option, value) => formatLabel(option) === formatLabel(value)}
            getOptionLabel={formatLabel}
            noOptionsText={t('components-CompanySelect.typeCompanyName')}
            onChange={_onChange}
            onInput={_onInput}
            renderInput={(params) => (
                <TextField
                    {...params}
                    autoFocus={autoFocus}
                    placeholder={placeholder}
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                            <InputAdornment
                                position="end"
                                style={{
                                    transition: '0.2s ease-in-out',
                                    transformOrigin: 'left',
                                }}
                            >
                                {value ? <i className="ri-check-line green" /> : <i className="ri-building-line" />}
                            </InputAdornment>
                        ),
                    }}
                />
            )}
        />
    )
}

function companyType(organisasjonsInfo: OrganisasjonsInfo | null): CompanyType {
    if (!organisasjonsInfo) {
        return null
    }
    if (organisasjonsInfo.organisasjonsform.kode === 'ENK') {
        return 'SOLE_PROP'
    }
    // Naeringskode 64.190 is brreg's code for "Bankvirksomhet ellers"
    if (organisasjonsInfo.naeringskode1.kode === '64.190') {
        return 'BANK'
    }
    return 'OTHER'
}

function formatLabel(company: BrregCompany | string | null): string {
    if (typeof company === 'string') {
        return company
    }
    return company ? `${company.navn} (${company.organisasjonsnummer})` : ''
}

async function fetchSuggestionsFromBrreg(query: string): Promise<BrregCompany[] | null> {
    const url = `${API_BASE_URL}/api/brreg?q=${query}`
    const response = await fetch(url).catch((_) => null)
    if (response && response.ok) {
        const data = await response?.json()
        if (Array.isArray(data)) {
            return data
        } else {
            return []
        }
    }
    return null
}

async function fetchOrgFormFromBrreg(orgNumber: string): Promise<OrganisasjonsInfo> {
    const url = `${API_BASE_URL}/api/brreg/${orgNumber}`
    const response = await fetch(url).catch((_) => null)
    if (response && response.ok) {
        const data = await response?.json()
        if (data.organisasjonsform) {
            return { organisasjonsform: data.organisasjonsform, naeringskode1: data.naeringskode1 }
        }
    }
    return null
}
