import update from 'immutability-helper'
import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
    name: 'deposits',
    initialState: [],
    reducers: {
        updateDeposits(state, action) {
            return action.payload
        },
        updateDepositTag(state, action) {
            const key = state.findIndex((deposit) => deposit.id === action.payload.id)
            const newDeposit = {
                ...state[key],
                tag: action.payload.tag,
                tagColor: action.payload.tagColor,
            }
            return update(state, { [key]: { $set: newDeposit } })
        },
    },
})

const { actions, reducer } = slice
export const { updateDeposits, updateDepositTag } = actions
export default reducer
