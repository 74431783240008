import { useCommand } from '#app/services/beta'
import { useSelector } from '#app/state/useSelector'
import { InvestmentCategory } from '@fixrate/fixrate-query'
import {
    Alert,
    Chip,
    CircularProgress,
    Divider,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Stack,
    Switch,
    Typography,
} from '@mui/material'
import { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import usePortfolio from '#services/usePortfolio'

type PlacementOption = {
    label: string
    description: string
    risk: 'LOWEST_RISK' | 'LOW_RISK' | 'MEDIUM_RISK' | 'HIGH_RISK'
    disabled: boolean
    investmentCategory: InvestmentCategory
}
export default function MandateChanger() {
    const { t } = useTranslation()
    const { changeMandatePolicy } = useCommand()
    const depositor = useSelector((state) => state.depositor)
    const portfolio = usePortfolio()
    const mandatePolicies = depositor?.mandates[portfolio.id]?.policies
    const [loadingMandateChange, setLoadingMandateChange] = useState<InvestmentCategory | null>(null)
    const permissions = useSelector((state) => state.session?.association?.permissions)
    const canChangeMandate = permissions?.includes('GENERAL__ORGANISATION__CONFIG')

    const placementOptions: PlacementOption[] = [
        {
            label: t('enum-InvestmentCategory.BANK_DEPOSITS'),
            description: t('common.investmentCategoryInformation.NO.BANK_DEPOSITS'),
            risk: 'LOWEST_RISK',
            disabled: true,
            investmentCategory: 'BANK_DEPOSITS',
        },
        {
            label: t('enum-InvestmentCategory.MONEY_MARKET_FUNDS'),
            description: t('common.investmentCategoryInformation.NO.MONEY_MARKET_FUNDS'),
            risk: 'LOW_RISK',
            disabled: false,
            investmentCategory: 'MONEY_MARKET_FUNDS',
        },
        {
            label: t('enum-InvestmentCategory.BOND_FUNDS'),
            description: t('common.investmentCategoryInformation.NO.BOND_FUNDS'),
            risk: 'MEDIUM_RISK',
            disabled: false,
            investmentCategory: 'BOND_FUNDS',
        },
    ]

    const riskColor = (risk: 'LOWEST_RISK' | 'LOW_RISK' | 'MEDIUM_RISK' | 'HIGH_RISK') => {
        switch (risk) {
            case 'LOWEST_RISK':
                return 'success'
            case 'LOW_RISK':
                return 'success'
            case 'MEDIUM_RISK':
                return 'warning'
            case 'HIGH_RISK':
                return 'error'
        }
    }

    async function changeMandatePolicyForInvestmentCategory(investmentCategory: InvestmentCategory, enabled: boolean) {
        setLoadingMandateChange(investmentCategory)
        const { waitForCommand } = await changeMandatePolicy({
            depositorId: depositor?.id,
            portfolioId: portfolio?.id,
            investmentCategory: investmentCategory,
            enabled: enabled,
        })
        const success = await waitForCommand()

        if (success) {
            setTimeout(() => {
                setLoadingMandateChange(null)
            }, 1000)
        }
    }
    return (
        <Stack spacing={2}>
            {mandatePolicies && (
                <List>
                    {placementOptions.map((placementOption) => (
                        <Fragment key={placementOption.label}>
                            <ListItem sx={{ py: 1.5, pr: 8 }}>
                                <ListItemText
                                    primary={
                                        <Stack direction="row" spacing={1} mb={0.7}>
                                            <Typography variant="h3">{placementOption.label}</Typography>
                                            <Chip
                                                size="small"
                                                sx={{ borderRadius: 1 }}
                                                color={riskColor(placementOption.risk)}
                                                label={t(`enum-RiskLevel.${placementOption.risk}`)}
                                            />
                                        </Stack>
                                    }
                                    secondary={placementOption.description}
                                ></ListItemText>
                                <ListItemSecondaryAction>
                                    {loadingMandateChange === placementOption.investmentCategory ? (
                                        <CircularProgress size={20} />
                                    ) : (
                                        <Switch
                                            onChange={(e, val) =>
                                                changeMandatePolicyForInvestmentCategory(
                                                    placementOption.investmentCategory,
                                                    val
                                                )
                                            }
                                            checked={!!mandatePolicies[placementOption.investmentCategory].enabled}
                                            disabled={placementOption.disabled || !canChangeMandate}
                                        />
                                    )}
                                </ListItemSecondaryAction>
                            </ListItem>
                            <Divider />
                        </Fragment>
                    ))}
                </List>
            )}
            {!canChangeMandate && (
                <Alert severity="warning" icon={null}>
                    {t('block-Mandate.canNotChangeMandate')}
                </Alert>
            )}
        </Stack>
    )
}
