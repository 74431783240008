import { Avatar, Backdrop, CircularProgress, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { OrganisationSelectorOption } from '../PageHeader/PageHeader'

const SWITCH_ORG_DURATION = 3500

const SwitchOrgAnimation = ({
    prevOrg,
    newOrg,
}: {
    prevOrg: OrganisationSelectorOption
    newOrg: OrganisationSelectorOption
}) => {
    const { t } = useTranslation()
    const isPartner = newOrg.organisationType === 'PARTNER'
    return (
        <Backdrop open={true} sx={{ zIndex: 2, backgroundColor: 'rgba(0, 0, 0, 0.9)' }}>
            <Stack alignItems="center">
                <Stack direction="row" spacing={2} mb={8} alignItems="center">
                    <CircularProgress color="primary" />
                    <Typography variant="h2" color="white">
                        {isPartner
                            ? t('pages-organizations.switchingPartner')
                            : t('pages-organizations.switchingCustomer')}
                    </Typography>
                </Stack>
                <Avatar
                    sx={{
                        height: '10rem',
                        width: '10rem',
                        fontSize: '3rem',
                        backgroundColor: prevOrg?.iconColor,
                        transform: 'scale(1.4) translate(0, -3rem)',
                        border: '0.3rem solid white',
                        boxShadow: '0 0 1rem 0.5rem rgba(0, 0, 0, 0.2)',
                        '@keyframes go-down': {
                            '0%': {
                                transform: 'scale(1.4) translate(0, -3rem)',
                            },
                            '100%': {
                                transform: 'scale(1) translate(0, 10rem)',
                            },
                        },
                        animation: 'go-down 2s forwards',
                        animationDelay: '0.5s',
                    }}
                >
                    {prevOrg?.iconName}
                </Avatar>
                <Avatar
                    sx={{
                        height: '10rem',
                        width: '10rem',
                        fontSize: '3rem',
                        backgroundColor: newOrg?.iconColor,
                        transform: 'scale(1) translate(0, 0)',
                        border: '0.3rem solid white',
                        boxShadow: '0 0 1rem 0.5rem rgba(0, 0, 0, 0.2)',
                        '@keyframes go-up': {
                            '0%': {
                                transform: 'scale(1) translate(0, 0)',
                            },
                            '100%': {
                                transform: 'scale(1.4) translate(0, -10rem)',
                            },
                        },
                        animation: 'go-up 2s forwards',
                        animationDelay: '0.5s',
                    }}
                >
                    {newOrg?.iconName}
                </Avatar>
            </Stack>
        </Backdrop>
    )
}

export { SwitchOrgAnimation, SWITCH_ORG_DURATION }
