import CustomerDeclarationDocumentLink from './CustomerDeclarationDocumentLink'
import styles from './CustomerDeclarationInfo.module.scss'
import { If, Paper, Title } from '#components'
import { useTranslation } from 'react-i18next'
import { useAuthorization } from '#services/authorization'
import useCurrentDepositor from '#app/services/useCurrentDepositor'
import { Button, Stack, Typography } from '@mui/material'
import useOrganizationWarnings from '#app/services/useOrganizationWarnings'

type Props = {
    wizardIsVisible: boolean
    setForceShowWizard: (value: boolean) => void
    inline?: boolean
}

export default function CustomerDeclarationDocumentSection({
    wizardIsVisible,
    setForceShowWizard,
    inline = false,
}: Props) {
    const { t } = useTranslation()
    const depositor = useCurrentDepositor()
    const organisationWarnings = useOrganizationWarnings(depositor)

    const auth = useAuthorization(depositor.id)
    const hasCustomerDeclarationPermission =
        auth.depositor.hasAdminRole ||
        auth.depositor.hasOrderRole ||
        auth.depositor.hasAccountHolderRole ||
        auth.depositor.hasPartnerRole

    const hasCustomerDeclarationWarning = organisationWarnings.customerDeclaration.length > 0

    if (!depositor) {
        return null
    }

    if (inline) {
        return (
            <Stack spacing={2}>
                <Typography>{t('pages-organizations.customerDeclarationMessagePart1')}</Typography>
                <Typography>{t('pages-organizations.customerDeclarationMessagePart2')}</Typography>
                <CustomerDeclarationDocumentLink document={depositor.customerDeclarationId} depositor={depositor} />
                <Typography>{t('pages-organizations.customerDeclarationUpdateMessage')}</Typography>
                <If condition={!wizardIsVisible && hasCustomerDeclarationPermission}>
                    <Stack alignItems={'flex-start'}>
                        <Button
                            variant={hasCustomerDeclarationWarning ? 'contained' : 'outlined'}
                            id="createNewCustomerDeclarationButton"
                            onClick={() => setForceShowWizard(true)}
                        >
                            {t('pages-organizations.createNewCustomerDeclaration')}
                        </Button>
                    </Stack>
                </If>
            </Stack>
        )
    }

    return (
        <Paper supportCommonName="customerDeclaration">
            <Title>{t('pages-organizations.customerDeclarationHeading')}</Title>
            <p className={styles.paragraph}>{t('pages-organizations.customerDeclarationMessagePart1')}</p>
            <p className={styles.paragraph}>{t('pages-organizations.customerDeclarationMessagePart2')}</p>
            <CustomerDeclarationDocumentLink document={depositor.customerDeclarationId} depositor={depositor} />
            <p>{t('pages-organizations.customerDeclarationUpdateMessage')}</p>
            <If condition={!wizardIsVisible && hasCustomerDeclarationPermission}>
                <Stack alignItems={'flex-start'}>
                    <Button
                        id="createNewCustomerDeclarationButton"
                        variant={hasCustomerDeclarationWarning ? 'contained' : 'outlined'}
                        onClick={() => setForceShowWizard(true)}
                    >
                        {t('pages-organizations.createNewCustomerDeclaration')}
                    </Button>
                </Stack>
            </If>
        </Paper>
    )
}
