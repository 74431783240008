import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
    name: 'invoiceReports',
    initialState: [],
    reducers: {
        setInvoiceReports(state, action) {
            return action.payload
        },
    },
})

const { actions, reducer } = slice
export const { setInvoiceReports } = actions
export default reducer
