import { DateOutput } from '#components'
import { CircularProgress, Stack, SxProps, Typography } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

export default function UpdateStatus({
    loading,
    lastUpdated,
    sx,
}: {
    loading: boolean
    lastUpdated?: Date
    sx?: SxProps
}) {
    const { t } = useTranslation()
    const timeoutRef = useRef(null)
    const [updating, setUpdating] = useState(false)

    // Adding a bit of delay to make sure the user sees the updating status
    useEffect(() => {
        if (loading) {
            setUpdating(true)
        }
        timeoutRef.current = setTimeout(() => {
            setUpdating(false)
        }, 1000)
    }, [loading])

    return (
        <Stack mt={2} direction={'row'} alignItems="center" height={'4rem'} spacing={1} sx={sx}>
            {(lastUpdated || updating) && (
                <Stack>
                    {updating ? (
                        <CircularProgress size={20} />
                    ) : (
                        <i className="ri-check-line green" style={{ fontSize: '1.6rem' }} />
                    )}
                </Stack>
            )}
            {(lastUpdated || updating) && (
                <Typography variant="caption">
                    {updating
                        ? t('common.updatingInformation')
                        : t('common.updated') + ': ' + DateOutput.formatTime(lastUpdated)}
                </Typography>
            )}
        </Stack>
    )
}
