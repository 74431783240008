import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import styles from './PowerOfAttorney.module.scss'
import { useSelector } from '#app/state/useSelector'
import Brregbox from '../BrregBox'
import { Alert } from '@mui/material'

interface Props {
    nationalIdentity: string
}

export function Signatories({ nationalIdentity }: Readonly<Props>) {
    const { t } = useTranslation()
    const signatories = useSelector(
        (state) => state.companyPublicInformation.find((c) => c.nationalIdentity === nationalIdentity)?.signatories
    )

    return (
        <Brregbox
            hasNonExactMatch={false}
            hasPeopleChanges={false}
            infoVisible={signatories ? true : false}
            header={t('pages-organizations.poaRuleDescription')}
        >
            {signatories && (
                <div className={classNames(styles.data, styles.signatureConfiguration)}>
                    <pre className={styles.signatories}>{signatories}</pre>
                </div>
            )}
            {!signatories && (
                <Alert severity="warning" icon={<i className={'ri-error-warning-line'} />}>
                    {t('pages-organizations.poaRuleNA')}
                </Alert>
            )}
        </Brregbox>
    )
}
