import * as React from 'react'
import ArrowRight from '#pages/common/icons/ArrowRight'
import { InterestOutput } from '#components'
import { Box, SxProps } from '@mui/material'
import { BURNT_ORANGE, SILVER_GRAY, SUCCESS_GREEN } from '#app/colors/colors'

export type Props = {
    interest: number | null | undefined
    newInterest?: number | null
    sx?: SxProps
}

export function InterestBadge({ interest, newInterest, sx }: Props) {
    const interestStyle: SxProps = {
        padding: '0.6rem 0.8rem',
        fontWeight: 700,
        minWidth: '5.8rem',
        display: 'inline-block',
        textAlign: 'center',
        fontVariantNumeric: 'tabular-nums',
        backgroundColor: interest == null ? 'transparent' : interest < 0 ? BURNT_ORANGE[50] : SUCCESS_GREEN[50],
        color: interest == null ? SILVER_GRAY[500] : interest < 0 ? BURNT_ORANGE[600] : SUCCESS_GREEN[600],
    }

    const arrowRightStyle: React.CSSProperties = {
        width: '1.4rem',
        height: '1.4rem',
        fill: SUCCESS_GREEN[500],
        display: 'inline-block',
        margin: '0 0.4rem',
    }

    return (
        <Box component="span" sx={{ ...interestStyle, ...sx }}>
            {interest == null ? '–' : InterestOutput.formatWithSign(interest)}
            {newInterest != null && (
                <React.Fragment>
                    <ArrowRight style={arrowRightStyle} />
                    {InterestOutput.formatWithSign(newInterest)}
                </React.Fragment>
            )}
        </Box>
    )
}
