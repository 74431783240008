import { PURPLE } from '#app/colors/colors'
import { Paper } from '#app/components'
import useCurrentDepositor from '#app/services/useCurrentDepositor'
import { Avatar, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

const SupportSection = () => {
    const depositor = useCurrentDepositor()
    const { t } = useTranslation()

    const primaryPartnerRelation = depositor.partnerRelations
        .filter((pr) => pr.accepted)
        .filter((pr) => pr.partnerCategory === 'SUPPORT')[0]
    const primaryAdvisor = depositor.users.find((user) => user.id === primaryPartnerRelation?.primaryAdvisor)

    const formatPhoneNumber = (number) => {
        let formattedNumber = number

        //Check for country code and remove it from number
        if (number.indexOf('+47') !== -1) {
            formattedNumber = formattedNumber.replace('+47', '')
        }

        formattedNumber = formattedNumber.match(/.{1,2}/g).join(' ')

        return '+47 ' + formattedNumber
    }

    if (!primaryPartnerRelation) {
        return null
    }

    return (
        <Paper
            key={primaryPartnerRelation.partnerId}
            title={t('pages-organizations.supportUsersHeading', {
                partnerName: primaryPartnerRelation.name,
            })}
        >
            <Stack spacing={2}>
                <Typography sx={{ color: PURPLE[800] }}>
                    {t('pages-organizations.supportUsersMessagePart1', {
                        partnerName: primaryPartnerRelation.name,
                    })}
                </Typography>
                <Typography sx={{ color: PURPLE[800] }}>{t('pages-organizations.supportUsersMessagePart2')}</Typography>

                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <Typography variant="h4" sx={{ color: PURPLE[800] }}>
                                    {t('pages-organizations.supportUsersMessagePart3', {
                                        partnerName: primaryPartnerRelation.name,
                                    })}
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="h4" sx={{ color: PURPLE[800] }}>
                                    {t('pages-organizations.status')}
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell sx={{ width: '60%', paddingTop: '2rem' }}>
                                <Stack spacing={1}>
                                    <Typography variant="h3" sx={{ color: PURPLE[800] }}>
                                        {primaryAdvisor?.firstName} {primaryAdvisor?.lastName}
                                    </Typography>
                                    <Typography sx={{ color: PURPLE[800] }}>
                                        <i className="ri-mail-line" />
                                        {primaryAdvisor?.email}
                                    </Typography>
                                    <Typography sx={{ color: PURPLE[800] }}>
                                        <i className="ri-smartphone-line" />
                                        {formatPhoneNumber(primaryAdvisor?.phone)}
                                    </Typography>
                                </Stack>
                            </TableCell>
                            <TableCell sx={{ width: '40%', verticalAlign: 'top', paddingTop: '2rem' }}>
                                <Typography variant="h4" sx={{ color: PURPLE[800] }}>
                                    <Stack direction={'row'} spacing={'1rem'} alignItems="center">
                                        <Avatar sx={{ backgroundColor: PURPLE[50], color: PURPLE[500] }}>
                                            <i className="ri-customer-service-2-line" style={{ padding: '0rem' }} />
                                        </Avatar>
                                        {t('pages-organizations.primary')}
                                    </Stack>
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Stack>
        </Paper>
    )
}

export default SupportSection
