import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
    name: 'postalCodeSearchData',
    initialState: null,
    reducers: {
        setPostalCodeSearchData(_, action) {
            return action.payload
        },
        removePostalCodeSearchData() {
            return null
        },
    },
})

const { actions, reducer } = slice
export const { setPostalCodeSearchData, removePostalCodeSearchData } = actions
export default reducer
