let offset: number

const ServerTimeStamp = () => 0

ServerTimeStamp.init = (serverTimeStamp: number) => {
    offset = Date.now() - serverTimeStamp
}

ServerTimeStamp.now = (): number => {
    return Date.now() - offset
}

ServerTimeStamp.localTime2serverTime = (localTimestamp: number): number => {
    return localTimestamp - offset
}

ServerTimeStamp.serverTime2localTime = (serverTimestamp: number): number => {
    return serverTimestamp + offset
}

export default ServerTimeStamp
