import ServerTimeStamp from './ServerTimeStamp'
import { sendWsHeartbeats } from '#services/network/websocket-connection'

/*
    IdleDetector provides a service that detects when user is idle and a session is about to timeout.
 */

// When the user was last active in this application
let lastActivity: number = Date.now()

// Identity of timers
let reportToServerIntervalHandlerId: number
let syncLocalStorageIntervalHandlerId: number

// These functions are initialized by IdleDetector.init()
let _setTimeoutCountdown: (counter: number) => void
let _syncLastSeen: (lastSeen: number) => Promise<any>
let _onSessionTimeout: () => void

const storeLastActivityTimeStamp = (timestamp: number) => {
    lastActivity = timestamp
    window.localStorage.setItem('lastActivityTimestamp', timestamp.toString())
}

const getLastActivityTimeStamp = () => {
    const item = window.localStorage.getItem('lastActivityTimestamp')
    if (item != null) {
        return parseInt(item) || 0
    } else {
        return 0
    }
}

const getLocalSessionTimeStamp = () => {
    const item = window.localStorage.getItem('sessionTimeoutTimestamp')
    if (item != null) {
        return parseInt(item) || 0
    } else {
        return 0
    }
}

const storeLocalSessionTimeoutTimestamp = (timestamp: number) => {
    window.localStorage.setItem('sessionTimeoutTimestamp', timestamp.toString())
}

function warningShouldBeVisible() {
    return getLocalSessionTimeStamp() - Date.now() < 60 * 1000
}

function onActivity() {
    const now = Date.now()

    if (now - lastActivity > 1000) {
        // Sync localStore every second
        storeLastActivityTimeStamp(now)

        // If less than 60 seconds to timeout,
        // inform server about the activity immediately to hide logout warning
        if (warningShouldBeVisible()) {
            synchronizeWithServer()
        }
    }
}

function synchronizeWithServer() {
    // This function will
    //  1. Send an empty websocket message to the server to check if the connection is still alive
    //  2. Report lastActivityTimeStamp to server and retrieve sessionTimeoutTimestamp from server
    //  3. Start an aggressive background job when it is 90 seconds to session timeout

    sendWsHeartbeats()

    _syncLastSeen(ServerTimeStamp.localTime2serverTime(getLastActivityTimeStamp()))
        .then((serverSessionTimeoutTimestamp) => {
            const localSessionTimeoutTimeStamp = ServerTimeStamp.serverTime2localTime(serverSessionTimeoutTimestamp)
            storeLocalSessionTimeoutTimestamp(localSessionTimeoutTimeStamp)
        })
        .catch(() => {
            //Network error or something bad happened.
            window.clearInterval(syncLocalStorageIntervalHandlerId)
        })
        .finally(() => {
            // Removes any existing sync job that runs in the background
            window.clearInterval(syncLocalStorageIntervalHandlerId)

            if (warningShouldBeVisible()) {
                console.log('Session will timeout in less than 90 seconds')

                // Starts aggressive sync job that runs in the background every second and
                //  1. Updates localStore with latest sessionTimeoutTimestamp from server
                //  2. Shows warning to user if session is about to timeout
                //  3. Runs onSessionTimeout when/if sessionTimeoutTimestamp is reached
                syncLocalStorageIntervalHandlerId = window.setInterval(() => {
                    const localSessionTimeoutTimeStamp = getLocalSessionTimeStamp()

                    if (localSessionTimeoutTimeStamp - Date.now() <= 0) {
                        // Session is timed out, logout
                        window.clearInterval(syncLocalStorageIntervalHandlerId)
                        IdleDetector.cleanup()
                        _onSessionTimeout()
                    } else if (localSessionTimeoutTimeStamp - Date.now() < 60 * 1000) {
                        // If it's less than 60 seconds, show warning
                        _setTimeoutCountdown(localSessionTimeoutTimeStamp)
                    } else {
                        _setTimeoutCountdown(0)
                    }
                }, 1_000)
            } else {
                // (Re)sets the warning timeout to 0
                _setTimeoutCountdown(0)
            }
        })
}

type IdleDetectorType = {
    init: (
        setTimeoutCountdown: (counter: number) => void,
        syncLastSeen: (lastSeen: number) => Promise<any>,
        onSessionTimeout: () => void
    ) => void
    cleanup: () => void
}

const IdleDetector: IdleDetectorType = () => 0

IdleDetector.init = (setTimeoutCountdown, syncLastSeen, onSessionTimeout) => {
    console.log('Starting IdleDetector')

    IdleDetector.cleanup() // cleanup possible existing event listeners and report intervals.
    _syncLastSeen = syncLastSeen
    _onSessionTimeout = onSessionTimeout
    _setTimeoutCountdown = setTimeoutCountdown

    storeLastActivityTimeStamp(Date.now()) // initialize

    const events = ['load', 'mousemove', 'mousedown', 'click', 'scroll', 'keypress']
    for (const i in events) {
        window.addEventListener(events[i], onActivity)
    }
    reportToServerIntervalHandlerId = window.setInterval(synchronizeWithServer, 30_000)
}

IdleDetector.cleanup = () => {
    const events = ['load', 'mousemove', 'mousedown', 'click', 'scroll', 'keypress']
    for (const i in events) {
        window.removeEventListener(events[i], onActivity)
    }
    window.clearInterval(reportToServerIntervalHandlerId)
}

export default IdleDetector
