import { addInfoMessage } from '#state/reducers/infoMessages'
import { translate } from '#services/i18n'

export async function handleStatusCodeErrors(dispatch, response) {
    async function reportError(message: string, header: string) {
        if (response) {
            console.error('Network Response', response.statusText, message)
        }

        return dispatch(addInfoMessage({ type: 'error', message: header, detail: message }))
    }

    if (response && !response.ok) {
        switch (response.status) {
            case 401:
            case 403:
            case 413:
            case 500: {
                return reportError(
                    translate('error.status.' + response.status + '.message'),
                    translate('error.status.' + response.status + '.header')
                )
            }

            default: {
                try {
                    if (response.headers.get('content-type') === 'application/json') {
                        const json = await response.clone().json()

                        if (json.messageKey) {
                            return reportError(translate('command.error.' + json.messageKey), translate('error.header'))
                        } else if (json.message) {
                            return reportError(json.message, translate('error.header'))
                        } else {
                            return reportError(translate('error.default'), translate('error.header'))
                        }
                    } else {
                        const text = await response.clone().text()
                        return reportError(
                            text && text.length < 300 ? text : translate('error.default'),
                            translate('error.header')
                        )
                    }
                } catch (err) {
                    console.log('Error finding error message: {}', err)
                    return reportError(translate('error.default'), translate('error.header'))
                }
            }
        }
    }
}

export const handleNetworkErrors = function (dispatch) {
    dispatch(
        addInfoMessage({
            type: 'error',
            message: translate('error.header'),
            detail: translate('error.network'),
        })
    )
}
