import { Paper } from '#app/components'
import { SignableDocumentList } from '#app/components/SignableDocument/SignableDocumentList'
import config from '#app/config'
import useCurrentDepositor from '#app/services/useCurrentDepositor'
import useFundStatus from '#app/services/useFundStatus'
import { useSelector } from '#app/state/useSelector'
import { useCommand } from '#command'
import DocumentLink from '#components/DocumentLink'
import { hasFundAccessSelector } from '#state/selectors'
import { DocumentDto } from '@fixrate/fixrate-query'
import { Alert, Box, Button, Divider, Stack, Typography } from '@mui/material'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { useSessionLanguage } from '#services/useSessionLanguage'

const API_BASE_URL = config().apiUrl
const FC_DOC_URL = config().fixrateFrontDocumentUrl + '/fixrate-capital-dokumenter'

const FundAgreementSection = ({ inline }: { inline?: boolean }) => {
    const depositor = useCurrentDepositor()
    const { startElectronicSignature } = useCommand()
    const { t } = useTranslation()
    const navigate = useNavigate()
    const location = useLocation()
    const { setDepositorFundEnabled } = useCommand()
    const fundAvailable = useSelector(hasFundAccessSelector)
    const permissions = useSelector((state) => state.session?.association?.permissions)
    const fundStatus = useFundStatus()
    const canEnableFund =
        permissions?.includes('GENERAL__ORGANISATION__CONFIG') ||
        permissions?.includes('DEPOSITOR__ACCOUNT_AGREEMENT__SIGN')
    const fundContractDocumentId = useSelector(
        (state) => state.depositor.contracts.filter((c) => c.documentType === 'FUND_CONTRACT')?.[0]?.documentId
    )
    const fundContractAddendumDocumentId = useSelector(
        (state) => state.depositor.contracts.filter((c) => c.documentType === 'FUND_CONTRACT_ADDENDUM')?.[0]?.documentId
    )
    const fundGeneralTermsDocumentId = useSelector(
        (state) =>
            state.depositor.contracts.filter((c) => c.documentType === 'FUND_GENERAL_TERMS_AND_CONDITIONS')?.[0]
                ?.documentId
    )

    const fundContract = useSelector((state) => state.documents[fundContractDocumentId])
    const fundContractAddendum = useSelector((state) => state.documents[fundContractAddendumDocumentId])
    const fundGeneralTerms = useSelector((state) => state.documents[fundGeneralTermsDocumentId])
    const lang = useSessionLanguage() ?? 'nb'

    const fundDocuments = [fundContract, fundGeneralTerms, fundContractAddendum].filter((d) => !!d)
    const fundContractAddendumPriceListUrl = `${API_BASE_URL}/document/depositor/${depositor?.id}/fund/platformfee?lang=${lang}`
    const fundInfoAboutFinancialInstrumentsUrl = `${FC_DOC_URL}/Informasjon+om+egenskaper+og+risiko+knyttet+til+finansielle+instrumenter.pdf`
    const fundGuidelinesForHandlingConflictsOfInterestUrl = `${FC_DOC_URL}/Retningslinjer+for+h%C3%A5ndtering+av+interessekonflikter.pdf`
    const fundGuidelinesForCustomerComplaintsUrl = `${FC_DOC_URL}/Retningslinjer+for+kundeklager.pdf`
    const fundStockTransferFormUrl = `${FC_DOC_URL}/Stock+Transfer+Form.docx`

    async function onStartSignature(documents: DocumentDto[]) {
        if (documents.length < 0) {
            return
        }

        const onlyOne = documents.length === 1
        const processType = onlyOne ? 'SINGLE_DOCUMENT' : 'MULTIPLE_DOCUMENTS'
        const identity = onlyOne ? documents.at(0).id : documents.map((d) => d.id).join(',')

        const processId = uuidv4()
        const { waitForCommand } = await startElectronicSignature(processId, processType, identity)
        const success = await waitForCommand()
        if (success) {
            const contextUrl = inline ? location.pathname : `/organizations/${depositor.id}/terms/fund`
            navigate(`/signature/${processId}?context=${contextUrl}`)
        }
    }

    const additionalDocuments = [
        {
            name: t('pages-organizations.fundInfoAboutFinancialInstrumentsLink'),
            link: fundInfoAboutFinancialInstrumentsUrl,
        },
        {
            name: t('pages-organizations.fundGuidelinesForHandlingConflictsOfInterestLink'),
            link: fundGuidelinesForHandlingConflictsOfInterestUrl,
        },
        {
            name: t('pages-organizations.fundGuidelinesForCustomerComplaintsLink'),
            link: fundGuidelinesForCustomerComplaintsUrl,
        },
        {
            name: t('pages-organizations.fundContractAddendumPriceListLink'),
            link: fundContractAddendumPriceListUrl,
        },
        {
            name: t('pages-organizations.fundStockTransferFormLink'),
            link: fundStockTransferFormUrl,
        },
    ]

    const enableFund = async () => {
        const { waitForCommand } = await setDepositorFundEnabled(depositor.id, true, false)
        await waitForCommand()
    }

    const Wrapper = inline ? Box : Paper

    if (!fundAvailable) {
        return (
            <Wrapper
                component={'div'}
                id="enableFund"
                title={inline ? undefined : t('pages-organizations.fundActivationHeading').toString()}
            >
                <p>{t('pages-organizations.fundNotAvailable')}</p>
                <p>{t('pages-organizations.fundNotAvailableMessage')}</p>
                {canEnableFund && (
                    <Button variant={'contained'} onClick={enableFund}>
                        {t('pages-organizations.enableFund')}
                    </Button>
                )}
                {!canEnableFund && <Alert severity="info">{t('pages-organizations.enableFundNoPermission')}</Alert>}
            </Wrapper>
        )
    }

    return (
        <Stack direction={'row'} flexWrap={'wrap'} spacing={3}>
            <Wrapper
                component="div"
                id="acceptterms"
                title={t('pages-organizations.fundTermsOfServiceHeading').toString()}
                supportCommonName="fundTermsAndConditions"
            >
                <Box>
                    <p>{t('pages-organizations.fundTermsOfServiceMessage')}</p>
                    {!fundStatus?.inFundOnboardingQueue && fundStatus?.allFundDocumentsSigned && (
                        <Alert severity="success">
                            {t('pages-organizations.fundDocumentsSigned', {
                                name: depositor?.name,
                            })}
                        </Alert>
                    )}
                    {fundStatus?.inFundOnboardingQueue && (
                        <Alert severity="success">
                            {t('pages-organizations.fundDocumentsInQueue', {
                                name: depositor?.name,
                            })}
                        </Alert>
                    )}
                    <SignableDocumentList documents={fundDocuments} onStartSignature={onStartSignature} />
                </Box>
                <Box sx={{ mt: 3 }}>
                    <Typography variant="h3" mb={1}>
                        <span>{t('pages-organizations.fundOtherDocuments')}</span>
                    </Typography>
                </Box>
                {additionalDocuments.map((doc, index) => (
                    <Fragment key={index}>
                        <DocumentLink sx={{ fontSize: '1.2rem' }} link={doc.link} name={doc.name} />
                        <Divider />
                    </Fragment>
                ))}
            </Wrapper>
        </Stack>
    )
}

export default FundAgreementSection
