import { DepositorDto, OnboardingStep } from '@fixrate/fixrate-query'
import { useTranslation } from 'react-i18next'
import { useCommand } from '#command'
import { useState } from 'react'
import useOrganizationWarnings from '#services/useOrganizationWarnings'
import { Alert, AlertTitle, Box, Button, Checkbox, FormControlLabel, Stack, Typography } from '@mui/material'
import { Fragment } from 'react/jsx-runtime'
import { PURPLE } from '#app/colors/colors'

type TextKeyTypes = 'SUCCESS_TITLE' | 'ACTION_TITLE' | 'ACTION_DESCRIPTION' | 'CHECKBOX_LABEL'
const TEXT_KEYS: {
    [step in OnboardingStep]: {
        [key in TextKeyTypes]: string
    }
} = {
    ORGANISATIONAL_ROLES: {
        SUCCESS_TITLE: 'pages-OrganizationWizard.organisationalRolesConfirmed',
        ACTION_TITLE: 'pages-OrganizationWizard.confirmOrganisationalRolesTitle',
        ACTION_DESCRIPTION: 'pages-OrganizationWizard.confirmOrganisationalRolesDescription',
        CHECKBOX_LABEL: 'pages-OrganizationWizard.confirmOrganisationalRolesCheckboxLabel',
    },
    SECURITY_ROLES: {
        SUCCESS_TITLE: 'pages-OrganizationWizard.securityRolesConfirmed',
        ACTION_TITLE: 'pages-OrganizationWizard.confirmSecurityRolesTitle',
        ACTION_DESCRIPTION: 'pages-OrganizationWizard.confirmSecurityRolesDescription',
        CHECKBOX_LABEL: 'pages-OrganizationWizard.confirmSecurityRolesCheckboxLabel',
    },
    BENEFICIAL_OWNERS: {
        SUCCESS_TITLE: 'pages-OrganizationWizard.beneficialOwnersConfirmed',
        ACTION_TITLE: 'pages-OrganizationWizard.confirmBeneficialOwnersTitle',
        ACTION_DESCRIPTION: 'pages-OrganizationWizard.confirmBeneficialOwnersDescription',
        CHECKBOX_LABEL: 'pages-OrganizationWizard.confirmBeneficialOwnersCheckboxLabel',
    },
}

export const ConfirmOnboardingStep = ({ depositor, step }: { depositor: DepositorDto; step: OnboardingStep }) => {
    const { t } = useTranslation()
    const { confirmOnboardingStep } = useCommand()
    const [checked, setChecked] = useState(false)
    const organisationWarnings = useOrganizationWarnings(depositor)

    const canConfirm = () => {
        switch (step) {
            case 'ORGANISATIONAL_ROLES':
                return !organisationWarnings.organisationalRoles.some((w) => w.type === 'MISSING_ORGANISATIONAL_ROLES')
            case 'SECURITY_ROLES':
                return !organisationWarnings.organisationalRoles.some((w) => w.type === 'MISSING_SECURITY_ROLES')
            case 'BENEFICIAL_OWNERS':
                return !organisationWarnings.users.some((w) => w.type === 'MISSING_BENEFICIAL_OWNERS')
        }
    }

    const isConfirmed = () => {
        switch (step) {
            case 'ORGANISATIONAL_ROLES':
                return !organisationWarnings.onboarding.some((w) => w.type === 'ORGANISATIONAL_ROLES_NOT_CONFIRMED')
            case 'SECURITY_ROLES':
                return !organisationWarnings.onboarding.some((w) => w.type === 'SECURITY_ROLES_NOT_CONFIRMED')
            case 'BENEFICIAL_OWNERS':
                return !organisationWarnings.onboarding.some((w) => w.type === 'BENEFICIAL_OWNERS_NOT_CONFIRMED')
            default:
                return false
        }
    }

    const handleConfirmStep = async () => {
        const { waitForCommand } = await confirmOnboardingStep(depositor.id, step)
        const success = await waitForCommand()
        if (success) {
            console.log('success')
        }
    }

    return (
        <Fragment>
            {isConfirmed() ? (
                <Alert
                    severity="success"
                    elevation={-1}
                    sx={{ py: 2, pl: 2, '& .MuiSvgIcon-root': { width: '3rem', height: '3rem' } }}
                >
                    <AlertTitle>{t(TEXT_KEYS[step]['SUCCESS_TITLE'])}</AlertTitle>
                    {t('pages-OrganizationWizard.youMayProceed')}
                </Alert>
            ) : (
                <Stack
                    spacing={2}
                    sx={{ backgroundColor: PURPLE[25], border: `0.1rem solid ${PURPLE[100]}` }}
                    p={4}
                    borderRadius={0.6}
                    alignItems="flex-start"
                >
                    <Box>
                        <Typography variant="h2">{t(TEXT_KEYS[step]['ACTION_TITLE'])}</Typography>
                        <Typography>{t(TEXT_KEYS[step]['ACTION_DESCRIPTION'])}</Typography>
                    </Box>
                    <FormControlLabel
                        control={
                            <Checkbox checked={checked} onChange={(e, v) => setChecked(v)} disabled={!canConfirm()} />
                        }
                        data-cy="confirm-step-checkbox"
                        sx={{
                            ml: 0,
                            py: 1,
                            pr: 2,
                            pl: 1,
                            border: `0.1rem solid ${PURPLE[100]}`,
                            borderRadius: 0.6,
                            '& .MuiFormControlLabel-label': {
                                fontWeight: 600,
                            },
                        }}
                        label={t(TEXT_KEYS[step]['CHECKBOX_LABEL'])}
                    />
                    <Button
                        variant="contained"
                        onClick={handleConfirmStep}
                        disabled={!checked || !canConfirm()}
                        data-cy="confirm-step-button"
                    >
                        {t('common.confirm')}
                    </Button>
                </Stack>
            )}
        </Fragment>
    )
}
