import restEndpoint from '#app/services/rest/rest-endpoint'
import { Currency } from '@fixrate/fixrate-security'
import { setSession } from '#state/reducers/session'
import { Button, CircularProgress, ListItemText, Menu, MenuItem, useMediaQuery, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { listItemTextStyle, selectButtonStyles } from '../MenuComponents'
import { sendExceptionToSentry } from '#app/services/sentry'
export const CurrencySelection = ({
    currencies,
    selectedCurrency,
    switchCurrency,
}: {
    currencies: Currency[]
    selectedCurrency: Currency
    switchCurrency: (currency: Currency) => void
}) => {
    const location = useLocation()
    const dispatch = useDispatch()

    const [currencyAnchorEl, setCurrencyAnchorEl] = useState<null | HTMLElement>(null)
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

    // Portfolio is now only relevant for multiple currencies – if it is being used for other grouping in the future this will need to be updated
    const hasMultipleCurrencies = currencies.length > 1

    const updateSession = () => {
        setLoading(true)
        restEndpoint(dispatch)
            .getSession()
            .then((session) => {
                dispatch(setSession(session))
            })
            .catch((err) => {
                sendExceptionToSentry(err, { message: 'Failed to update the session' })
            })
            .finally(() => {
                // avoid flickering between empty currency text and loading spinner
                setTimeout(() => {
                    setLoading(false)
                }, 1000)
            })
    }

    const handleCurrencyMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setCurrencyAnchorEl(event.currentTarget)
        setOpen(true)
    }

    const handleCurrencyMenuClose = () => {
        setCurrencyAnchorEl(null)
        setOpen(false)
    }

    const selectCurrency = async (currency) => {
        setLoading(true)
        await switchCurrency(currency)
        setOpen(false)
        await updateSession()
    }

    useEffect(() => {
        setCurrencyAnchorEl(null)
    }, [location])

    return hasMultipleCurrencies ? (
        <>
            <Button
                id="currency-menu-button"
                aria-controls={open ? 'currency-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleCurrencyMenuClick}
                color="secondary"
                data-cy="currency-menu-button"
                sx={{ ...selectButtonStyles, minWidth: { xs: '8rem', md: '12rem' } }}
                startIcon={!loading && <i className="ri-coin-line purple" />}
                endIcon={
                    !isMobile && (
                        <i
                            style={{ fontSize: '1.6rem', fontWeight: 'normal' }}
                            className={open ? 'ri-arrow-up-s-line' : 'ri-arrow-down-s-line'}
                        />
                    )
                }
            >
                {loading ? <CircularProgress size={20} /> : selectedCurrency}
            </Button>
            <Menu
                id="currency-menu"
                data-cy="currency-menu"
                anchorEl={currencyAnchorEl}
                open={open}
                onClose={handleCurrencyMenuClose}
                sx={{ width: '100%' }}
                MenuListProps={{
                    'aria-labelledby': 'currency-menu-button',
                }}
            >
                {currencies
                    .filter((curr) => curr !== selectedCurrency)
                    .map((c) => (
                        <MenuItem
                            data-cy="currency-menu-item"
                            sx={{ minWidth: { xs: '8rem', md: '12rem' } }}
                            color="primary"
                            key={c}
                            onClick={() => selectCurrency(c)}
                        >
                            <ListItemText sx={{ ...listItemTextStyle, textAlign: 'center' }}>{c}</ListItemText>
                        </MenuItem>
                    ))}
            </Menu>
        </>
    ) : null
}
