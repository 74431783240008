import MandateChanger from '#app/blocks/MandateChanger/MandateChanger'
import useCurrentDepositor from '#app/services/useCurrentDepositor'
import { Paper } from '#components'
import { Box, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import DpaAgreement from './Sections/DpaAgreement'
import FundAgreementSection from './Sections/FundAgreement'
import GeneralTerms from './Sections/GeneralTerms'
import PartnerAgreements from './Sections/PartnerRelations'
import SupportSection from './Sections/Support'
import { useFeatures } from '#services/useFeatures'
import FundStatus from '../FundStatus'

type Props = {
    inline?: boolean
}

type Params = {
    activeTab?: TabValues
}

type TabValues = 'deposit' | 'fund' | 'partners' | 'mandate'

export default function AcceptTerms({ inline }: Props) {
    const { t } = useTranslation()
    const depositor = useCurrentDepositor()
    const navigate = useNavigate()
    const features = useFeatures()
    const { activeTab } = useParams<Params>()

    const setActiveTab = (tab: TabValues) => {
        navigate(`/organizations/${depositor.id}/terms/${tab}`)
    }

    if (!depositor) {
        return null
    }

    if (inline) {
        return (
            <Fragment>
                <GeneralTerms />
            </Fragment>
        )
    }

    return (
        <Fragment>
            <ToggleButtonGroup value={activeTab} color="primary" sx={{ mb: 3 }}>
                <ToggleButton value={'deposit'} onClick={() => setActiveTab('deposit')}>
                    {t('pages-organizations.generalTerms')}
                </ToggleButton>
                <ToggleButton value={'mandate'} onClick={() => setActiveTab('mandate')}>
                    {t('pages-organizations.mandate')}
                </ToggleButton>
                {features.fund && (
                    <ToggleButton value={'fund'} onClick={() => setActiveTab('fund')}>
                        {t('pages-organizations.fund')}
                    </ToggleButton>
                )}
                <ToggleButton value={'partners'} onClick={() => setActiveTab('partners')}>
                    {t('pages-organizations.partnerAccess')}
                </ToggleButton>
            </ToggleButtonGroup>
            {activeTab === 'deposit' && (
                <>
                    <Paper
                        id="acceptterms"
                        title={t('pages-organizations.termsOfServiceHeading')}
                        supportCommonName="generalTerms"
                    >
                        <GeneralTerms />
                    </Paper>
                    <DpaAgreement />
                </>
            )}
            {activeTab === 'mandate' && (
                <Paper id="mandate" title={t('pages-organizations.mandate')}>
                    <MandateChanger />
                </Paper>
            )}

            {features.fund && activeTab === 'fund' && (
                <Fragment>
                    <FundAgreementSection />
                    <FundStatus />
                </Fragment>
            )}
            {activeTab === 'partners' &&
                (depositor.partnerRelations.length === 0 ? (
                    <Box mt={2}>
                        <Typography variant="emptyState">{t('pages-organizations.noPartnerRelations')}</Typography>
                    </Box>
                ) : (
                    <>
                        <PartnerAgreements />
                        <SupportSection />
                    </>
                ))}
        </Fragment>
    )
}
