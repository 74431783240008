import { getInputMask } from '#app/pages/organizations/OrganizationDetail/Accounts/swedishAccountValidator'
import useCurrentCountryCode from '#app/services/useCurrentCountryCode'
import { FieldState } from '@fixrate/fieldstate'
import { FormControl, FormLabel, TextField, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { PatternFormat } from 'react-number-format'

const AccountNumberInput = ({
    accountNumber,
    submitValueOnBlur,
}: {
    accountNumber: FieldState<string>
    submitValueOnBlur?: boolean
}) => {
    const { t } = useTranslation()
    const organisationCountry = useCurrentCountryCode()
    return (
        <FormControl>
            <FormLabel>
                {organisationCountry === 'SE'
                    ? t('pages-organizations.clearingAccountNumber')
                    : t('pages-organizations.accountNumber')}
            </FormLabel>
            {organisationCountry === 'NO' && (
                <PatternFormat
                    format="####.##.#####"
                    mask="_"
                    value={accountNumber.value}
                    data-cy="accountNumberInput"
                    autoComplete="off"
                    onValueChange={(values) => {
                        const { value } = values
                        accountNumber.setValue(value)
                    }}
                    onBlur={(e) => {
                        accountNumber.onBlur(e)
                        if (submitValueOnBlur) {
                            accountNumber.submitValue(e.target.value)
                        }
                    }}
                    customInput={TextField}
                />
            )}
            {organisationCountry === 'SE' && (
                <PatternFormat
                    format={getInputMask(accountNumber.value)}
                    mask="_"
                    value={accountNumber.value}
                    data-cy="accountNumberInput"
                    autoComplete="off"
                    onValueChange={(values) => {
                        const { value } = values
                        accountNumber.setValue(value)
                    }}
                    onBlur={(e) => {
                        accountNumber.onBlur(e)
                        if (submitValueOnBlur) {
                            accountNumber.submitValue(e.target.value)
                        }
                    }}
                    customInput={TextField}
                />
            )}
            <Typography variant="labelDescription" mt={1}>
                {organisationCountry === 'NO' && t('pages-organizations.accountNumberExampleNO')}
                {organisationCountry === 'SE' && t('pages-organizations.accountNumberExampleSE')}
            </Typography>
            <p className="field-error-message">{!accountNumber.valid && accountNumber.errorMessage}</p>
        </FormControl>
    )
}

export { AccountNumberInput }
