import { v4 as uuidv4 } from 'uuid'
import update from 'immutability-helper'
import { createSlice } from '@reduxjs/toolkit'

function findKeyForIdenticalMessage(state, msg) {
    const keys = Object.keys(state)
    return keys.find(
        (key) => msg.type === state[key].type && msg.message === state[key].text && msg.detail === state[key].detail
    )
}

const slice = createSlice({
    name: 'infoMessages',
    initialState: {},
    reducers: {
        addInfoMessage(state, action) {
            let id = action.payload.id
            if (!id) {
                id = uuidv4()
            }

            let type = action.payload.type
            if (!type) {
                type = 'info'
            }

            // Identical messages will only increase the counter on the existing message
            const existing = findKeyForIdenticalMessage(state, action.payload)

            if (existing) {
                return update(state, { [existing]: { count: { $set: state[existing].count + 1 } } })
            } else {
                const newMessage = {
                    id: id,
                    text: action.payload.message,
                    detail: action.payload.detail,
                    technicalDetail: action.payload.technicalDetail,
                    type: type,
                    count: 1,
                }
                return update(state, { [id]: { $set: newMessage } })
            }
        },
        removeInfoMessage(state, action) {
            const newMessages = { ...state }
            delete newMessages[action.payload]
            return newMessages
        },
        removeIdenticalInfoMessage(state, action) {
            const newMessages = { ...state }
            const key = findKeyForIdenticalMessage(state, action.payload)
            if (key) {
                delete newMessages[key]
            }
            return newMessages
        },
        clearInfoMessages(state, action) {
            return {}
        },
    },
})

const { actions, reducer } = slice
export const { addInfoMessage, removeInfoMessage, removeIdenticalInfoMessage, clearInfoMessages } = actions
export default reducer
