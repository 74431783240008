import DateInput from '#app/components/DateInput'
import { LanguageSelect } from '#app/components/LanguageSelect/LanguageSelect'
import { AvailableLanguage } from '#app/services/i18n'
import useCurrentDepositor from '#app/services/useCurrentDepositor'
import { useCommand } from '#command'
import Modal from '#components/Modal'
import { isAfter, isBefore } from '#services/dateandtime'
import { showConfirmationModal } from '#state/reducers/confirmationModal'
import { useFieldState } from '@fixrate/fieldstate'
import { Alert, InputLabel, Stack, TextField } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { validateEmailAddress } from '#services/validateFields'

type Props = {
    personId: string
    close: () => void
}

export default function InviteModal({ personId, close }: Props) {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const depositor = useCurrentDepositor()
    const person = depositor?.people?.find((p) => p.personId === personId)

    const [language, setLanguage] = useState(depositor.language as AvailableLanguage)

    const email = useFieldState(person.email || '', ({ value, isEditing }) => {
        if (isEditing) {
            return
        }
        if (!value) {
            return t('pages-organizations.sendInviteEmailMissing')
        }
        if (!validateEmailAddress(value)) {
            return t('blocks-UsersList.inviteEmailInvalid')
        }
    })

    const birthDate = useFieldState(person.birthDate ? new Date(person.birthDate) : null, ({ value, isEditing }) => {
        if (isEditing) {
            return
        }
        if (!value) {
            return t('pages-organizations.sendInviteBirthDateMissing')
        }

        if (isAfter(value, new Date())) {
            return t('components-DateInput.mustBeInPast')
        } else if (isBefore(value, new Date(1900, 0, 1))) {
            return t('components-DateInput.mustBeAfter1900')
        }
    })

    const { updatePerson, invitePerson } = useCommand()

    async function submit() {
        const isValid = email.validate() && birthDate.validate()
        if (!isValid) return
        const { waitForCommand } = await updatePerson(
            depositor.id,
            person.personId,
            person.firstName,
            person.lastName,
            birthDate.value,
            email.value,
            person.address,
            person.taxResidencies,
            person.americanCitizenship,
            person.pep,
            person.pepPosition,
            person.pepInvestingPrivateFunds,
            person.controlShare,
            person.keyPersonRole
        )
        const success = await waitForCommand()

        if (success) {
            const { waitForCommand } = await invitePerson(depositor.id, personId, language)
            const success = await waitForCommand()
            if (success) {
                closeModal()
                dispatch(
                    showConfirmationModal({
                        title: t('pages-organizations.inviteEmailSentHeader'),
                        text: t('pages-organizations.inviteEmailSentInfo'),
                        buttonText: t('common.itsOkay'),
                        illustrationName: 'emailSent',
                        illustrationColor: 'default',
                    })
                )
            }
        }
    }

    const closeModal = () => {
        close()
    }

    const autoCompleteBlocker = (field) => {
        const randomNumber = Math.floor(Math.random() * (1 - 1000 + 1)) + 1
        return field + '-' + randomNumber
    }

    return (
        <Modal
            header={t('pages-organizations.sendInviteHeader')}
            onSubmit={submit}
            onCancel={closeModal}
            submitButtonText={t('common.send')}
            submitButtonCy="sendButton"
            width="50rem"
        >
            <form autoComplete="off">
                <Stack spacing={1.5}>
                    <Stack>
                        <InputLabel htmlFor="emailField">{t('pages-organizations.sendInviteEmailLabel')}</InputLabel>
                        <TextField
                            id="emailField"
                            name={autoCompleteBlocker('fixrate-email')}
                            onBlur={email.onBlur}
                            onChange={(e) => email.setValue(e.target.value)}
                            type="email"
                            autoComplete="off"
                            value={email.value}
                        />
                        <p className="field-error-message">{email.errorMessage}</p>
                    </Stack>
                    <Stack>
                        <InputLabel htmlFor="birthDateDatePicker">
                            {t('pages-organizations.sendInviteBirthDateLabel')}
                        </InputLabel>
                        <DateInput
                            id="birthDateDatePicker"
                            value={birthDate.value}
                            onBlur={birthDate.onBlur}
                            onChange={birthDate.setValue}
                        />
                        <p className="field-error-message">{birthDate.errorMessage}</p>
                    </Stack>
                    <Stack>
                        <InputLabel>{t('pages-organizations.sendInviteLanguageLabel')}</InputLabel>
                        <LanguageSelect language={language} setLanguage={setLanguage} />
                        <Alert icon={<i className={'ri-info-line'} />} severity="warning" sx={{ mt: 3 }}>
                            {t('pages-organizations.inviteTipBankID')}
                        </Alert>
                    </Stack>
                </Stack>
            </form>
        </Modal>
    )
}
