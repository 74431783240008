import FxDialog from '#app/components/FxDialog/FxDialog'
import FxDialogContent from '#app/components/FxDialog/FxDialogContent'
import FxDialogTitle from '#app/components/FxDialog/FxDialogTitle'
import { useCommand } from '#command'
import FileUpload from '#components/FileUpload'
import { DocumentDto } from '@fixrate/fixrate-query'
import { Box, Button, Stack } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import config from '#app/config'

const API_BASE_URL = config().apiUrl

type Props = {
    depositorId: string
    settlementAccountId: string
    currentDocument: DocumentDto | undefined
}

export default function UploadAccountCertificate({ depositorId, settlementAccountId, currentDocument }: Props) {
    const { t } = useTranslation()
    const [showUpload, setShowUpload] = useState(false)
    const [isUploading, setIsUploading] = useState(false)
    const { uploadSettlementAccountCertificate } = useCommand()

    const onDrop = (files) => {
        setIsUploading(true)
        files.forEach((file) => {
            const reader = new FileReader()
            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = () => {
                uploadSettlementAccountCertificate({
                    depositorId: depositorId,
                    settlementAccountId: settlementAccountId,
                    content: new Blob([reader.result], { type: file.type }),
                    fileName: file?.path,
                })
            }
            reader.readAsArrayBuffer(file)
        })
        setIsUploading(false)
        setShowUpload(false)
    }

    return (
        <Stack>
            {currentDocument && (
                <Button
                    size="small"
                    href={API_BASE_URL + currentDocument.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    variant="outlined"
                    startIcon={<i className="ri-file-shield-2-line" />}
                    title={currentDocument?.filename}
                >
                    {t('pages-organizations.accountCertificate')}
                </Button>
            )}

            {!currentDocument && (
                <Button size="small" variant="outlined" onClick={() => setShowUpload(true)}>
                    {t('pages-organizations.uploadAccountCertificate')}
                </Button>
            )}

            <FxDialog maxWidth={'md'} open={showUpload} onClose={() => setShowUpload(false)}>
                <FxDialogTitle onClose={() => setShowUpload(false)}>
                    {t('pages-organizations.uploadAccountCertificate')}
                </FxDialogTitle>
                <FxDialogContent sx={{ width: '40rem' }}>
                    <Box>
                        <FileUpload uploadFiles={onDrop} isUploadingDocument={isUploading} multiple={false} />
                    </Box>
                </FxDialogContent>
            </FxDialog>
        </Stack>
    )
}
