type BankData = {
    bank: string
    ranges: number[][]
    length: 7 | 9 | 10 | 11
}

const bankData: BankData[] = [
    { bank: 'Avanza Bank', ranges: [[9550, 9569]], length: 7 },
    { bank: 'Bluestep Finans', ranges: [[9680, 9689]], length: 7 },
    { bank: 'BNP Paribas Fortis', ranges: [[9470, 9479]], length: 7 },
    {
        bank: 'Citibank International Plc, Sweden Branch',
        ranges: [[9040, 9049]],
        length: 7,
    },
    {
        bank: 'Danske Bank A/S, Sverige Filial',
        ranges: [
            [1200, 1399],
            [2400, 2499],
        ],
        length: 7,
    },
    { bank: 'Danske Bolån', ranges: [[1200, 1399]], length: 7 },
    { bank: 'DNB Bank ASA, Filial Sverige', ranges: [[9190, 9199]], length: 7 },
    { bank: 'Forex Bank AB', ranges: [[9400, 9449]], length: 7 },
    { bank: 'Handelsbanken', ranges: [[6000, 6999]], length: 9 },
    { bank: 'ICA Banken', ranges: [[9270, 9279]], length: 7 },
    { bank: 'IKANO Banken', ranges: [[9170, 9179]], length: 7 },
    {
        bank: 'Länsförsäkringar Bank',
        ranges: [
            [9020, 9029],
            [3400, 3409],
            [9060, 9069],
        ],
        length: 7,
    },
    { bank: 'Lån og Spar Bank Sverige', ranges: [[9630, 9639]], length: 7 },
    { bank: 'Marginalen Bank', ranges: [[9230, 9239]], length: 7 },
    { bank: 'Nordax Bank AB', ranges: [[9640, 9649]], length: 7 },
    {
        bank: 'Nordea',
        ranges: [
            [1100, 1199],
            [1401, 2099],
            [3000, 3399],
            [3410, 4999],
        ],
        length: 7,
    },
    {
        bank: 'Nordea - PlusGirot',
        ranges: [
            [9500, 9549],
            [9960, 9969],
        ],
        length: 10,
    },
    { bank: 'Nordnet Bank', ranges: [[9100, 9109]], length: 7 },
    { bank: 'Resurs Bank AB', ranges: [[9280, 9289]], length: 7 },
    { bank: 'Royal Bank of Scotland', ranges: [[9090, 9099]], length: 7 },
    { bank: 'Santander Consumer Bank AS', ranges: [[9460, 9469]], length: 7 },
    { bank: 'SBAB', ranges: [[9250, 9259]], length: 7 },
    {
        bank: 'SEB',
        ranges: [
            [5000, 5999],
            [9120, 9124],
            [9130, 9149],
        ],
        length: 7,
    },
    { bank: 'Skandiabanken AB', ranges: [[9150, 9169]], length: 7 },
    { bank: 'Sparbanken Syd', ranges: [[9570, 9579]], length: 10 },
    { bank: 'Sparbanken Öresund', ranges: [[9300, 9349]], length: 10 },
    {
        bank: 'Swedbank',
        ranges: [[7000, 7999]],
        length: 7,
    },
    { bank: 'Swedbank', ranges: [[8000, 8999]], length: 11 },
    { bank: 'Svea Bank', ranges: [[9660, 9669]], length: 7 },
    {
        bank: 'Ålandsbanken Abp (Finland), svensk filial',
        ranges: [[2300, 2399]],
        length: 7,
    },
]

function validateSwedishAccountNumber(accountNumber: string): boolean {
    const clearingNumber = parseInt(accountNumber.slice(0, 4), 10)
    const accountNumberBody = accountNumber.slice(4)
    for (const bank of bankData) {
        for (const range of bank.ranges) {
            if (clearingNumber >= range[0] && clearingNumber <= range[1]) {
                if (accountNumberBody.length === bank.length) {
                    return true
                }
            }
        }
    }
    return false
}

function getBankName(accountNumber: string): string | null {
    const clearingNumber = parseInt(accountNumber.slice(0, 4), 10)
    for (const bank of bankData) {
        for (const range of bank.ranges) {
            if (clearingNumber >= range[0] && clearingNumber <= range[1]) {
                return bank.bank
            }
        }
    }
    return null
}

function getInputMask(accountNumber: string) {
    const repeatString = (str, num) => Array(num).fill(str).join('')
    const clearingNumber = parseInt(accountNumber.slice(0, 4), 10)
    for (const bank of bankData) {
        for (const range of bank.ranges) {
            if (clearingNumber >= range[0] && clearingNumber <= range[1]) {
                const accountNumberArray = repeatString('#', bank.length)
                return '####-' + accountNumberArray
            }
        }
    }

    return '####-#########'
}

export { validateSwedishAccountNumber, getBankName, getInputMask }
