import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
    name: 'funds',
    initialState: [],
    reducers: {
        setFunds(state, action) {
            return action.payload
        },
    },
})

const { actions, reducer } = slice
export const { setFunds } = actions
export default reducer
