import config from '../../config'
import ReconnectingWebSocket from 'reconnecting-websocket'
import { sendSentryMessage } from '../sentry'

/*
    Functions for handling websocket connections
*/

const WEBSOCKET_BASE_URL = config().websocketBaseUrl

export interface WebsocketConnection {
    path: string
    ws?: ReconnectingWebSocket
    queuedMessages?: object[]
}

// Definition of websockets that can be opened
export const websockets: { [connectionName: string]: WebsocketConnection } = {
    MARKETPLACE_DATA: {
        path: '/marketplace-data',
    },
    OPEN_DATA: {
        path: '/open-data',
    },
}

export function openWebsocket(connection: WebsocketConnection, handleWebsocketMessage: (message: any) => void) {
    if (!connection.ws || connection.ws.readyState !== connection.ws.OPEN) {
        const url = WEBSOCKET_BASE_URL + connection.path

        console.log('Opening websocket to ' + url)
        let connectionStartTime = new Date().getTime()

        connection.ws = new ReconnectingWebSocket(url)

        connection.ws.addEventListener('open', () => {
            if (connectionStartTime > 0) {
                const startupTime = new Date().getTime() - connectionStartTime
                console.log('onopen of', url, 'in', startupTime, 'ms')
                if (startupTime > 2500) {
                    sendSentryMessage('Websocket connection took too long', { startupTime, url }, 'error')
                    connectionStartTime = 0
                }
            }
            sendQueuedMessages(connection)
        })

        connection.ws.addEventListener('message', (msg: MessageEvent<any>) => {
            const message = JSON.parse(msg.data)
            console.log('Websocket message from ' + connection.path, Object.assign({}, message))
            handleWebsocketMessage(message)
        })

        connection.ws.addEventListener('error', (err: ErrorEvent) => {
            // We get this error without any other information quite often. It is usually connected to a close event.
            // The close event will give more information.
            if (err.type === 'error' && !err.message) {
                console.warn(
                    'Websocket error (' + connection.path + ')',
                    JSON.stringify({
                        type: err.type,
                        message: err.message,
                    })
                )
                return
            }
            // We get this error without any other information quite often. It is connected to a close event.
            // The close event will give more information.
            if (err.type === 'error' && err.message === 'TIMEOUT') {
                console.warn(
                    'Websocket error (' + connection.path + ')',
                    JSON.stringify({
                        type: err.type,
                        message: err.message,
                    })
                )
                return
            }
            console.error(
                'Websocket error (' + connection.path + ')',
                JSON.stringify({
                    type: err.type,
                    message: err.message,
                    filename: err.filename,
                    lineno: err.lineno,
                    colno: err.colno,
                    error: err.error,
                })
            )
        })

        connection.ws.addEventListener('close', (event) => {
            if (event.code === 1000) {
                if (event.reason === 'Session is not authenticated') {
                    console.log(
                        'Websocket closed (' +
                            connection.path +
                            ') Websocket session is not authenticated (' +
                            connection.path +
                            '). Will now stop reconnecting.'
                    )
                    connection.ws.close() // Explicitly close to force the reconnecting websocket to stop trying to reconnect
                } else {
                    console.log('Websocket closed (' + connection.path + ')') // Normal close
                }
            } else if (event.code === 1001) {
                console.log('Websocket closed. Client is leaving. (' + connection.path + ')') // Normal close
            } else if (event.code === 1006) {
                console.warn('Websocket closed abruptly. No close code frame received.  (' + connection.path + ')') // Abnormal close
            } else {
                console.error(
                    'Websocket closed (' + connection.path + ')',
                    JSON.stringify({
                        code: event.code,
                        reason: event.reason,
                        wasClean: event.wasClean,
                    })
                )
            }
        })
    }
}

export function closeWebsocket(websocket: WebsocketConnection) {
    if (websocket.ws) {
        console.log('Closing websocket ' + websocket.path, websocket.ws)
        websocket.ws.close(1000, '')
    }
}

export function sendMessage(websocket: WebsocketConnection, message: object) {
    if (websocket.ws) {
        console.log('Sending websocket message to ' + websocket.path, message)
        websocket.ws.send(JSON.stringify(message))
    } else {
        // Queue up this message, if the websocket is not open yet
        websocket.queuedMessages.push(message)
    }
}

const sendQueuedMessages = (websocket: WebsocketConnection) => {
    if (websocket.queuedMessages) {
        websocket.queuedMessages.forEach((message) => {
            console.log('Sending queued websocket message to ' + websocket.path, message)
            websocket.ws.send(JSON.stringify(message))
        })
        delete websocket.queuedMessages
    }
}

export function sendWsHeartbeats() {
    Object.values(websockets).forEach((connection) => connection?.ws.send('heartbeat'))
}
