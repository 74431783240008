import { useEffect, useRef, useState } from 'react'
import styles from './Button.module.scss'
import classNames from 'classnames'

/**
 * /**
 * @deprecated Use Button from MUI or AsyncButton instead.
 * @typedef {object} Props
 * @property {function} onClick
 * @property {function} [onDisabledClick]
 * @property {function} [onError]
 * @property [children]
 * @property [style]
 * @property [option]
 * @property [className]
 * @property {boolean} [loading]
 * @property {boolean} [disabled]
 * @property {'primary'|'secondary'|'tertiary'|'transparent'|'cancel'} [variant]
 * @property {'normal'|'small'} [size]
 *
 * @param {Props & any} props
 * @return {JSX.Element}
 */
const Button = function ({
    onClick: propsOnClick = () => {},
    onDisabledClick: propsOnDisabledClick = () => {},
    onError: propsOnError = (err) => {
        console.error('Failed in Button onClick handler', err)
    },
    children = null,
    style: propsStyle = {},
    option = '',
    className: propsClassName = '',
    loading = false,
    disabled = false,
    variant = 'primary',
    size = 'normal',
    ...props
}) {
    const [isWorking, setWorking] = useState(false)

    const isMounted = useRef(false)
    useEffect(() => {
        isMounted.current = true
        return () => {
            isMounted.current = false
        }
    }, [])

    const className = classNames(
        styles.button,
        variant && styles?.[variant],
        propsClassName,
        size === 'small' && styles.small
    )

    async function handleClick() {
        try {
            setWorking(true)
            await propsOnClick()
        } catch (err) {
            propsOnError(err)
        } finally {
            if (isMounted.current && setWorking) setWorking(false)
        }
    }

    async function handleKeys(e) {
        const key = e?.keyCode || e?.which
        if (key === 32 && e?.key?.toLowerCase?.() === 'spacebar') {
            e?.target?.click?.()
        }
    }

    return (
        <button
            {...props}
            onClick={handleClick}
            onKeyDown={handleKeys}
            aria-disabled={disabled || null}
            aria-busy={isWorking || loading}
            disabled={isWorking || disabled || null}
            className={className}
        >
            {children}
        </button>
    )
}

export default Button
