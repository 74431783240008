import { Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

export const NewBankAdButton = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    return (
        <Button
            startIcon={<i className={'ri-add-circle-line'} />}
            sx={{ px: 2 }}
            variant={'text'}
            id={'newAdButton'}
            onClick={() => navigate('/ads/new-ad')}
        >
            {t('pages-BankAds.newAd')}
        </Button>
    )
}
