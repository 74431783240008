import AsyncButton from '#app/components/Button/AsyncButton'
import useCurrentDepositor from '#app/services/useCurrentDepositor'
import { useCommand } from '#command'
import { ButtonRow } from '#components'
import CompanySelect from '#components/CompanySelect'
import { CompanyInfo, useWarningMessageOnRegisterCompany } from '#components/CompanySelect/CompanySelect'
import { switchOrganisation, syncSessionState } from '#services/thunks/session'
import { DepositorDto } from '@fixrate/fixrate-security'
import { showConfirmationModal } from '#state/reducers/confirmationModal'
import { useSelector } from '#state/useSelector'
import { Alert, Button } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useTracking } from 'react-tracking'
import styles from './AddNewOrganization.module.scss'

export function ChooseOrganization({
    cancel,
    orgnum,
    setOrgnum,
}: {
    cancel: () => void
    orgnum: string
    setOrgnum: (orgnum: string) => void
}) {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const session = useSelector((state) => state.session)
    const { trackEvent } = useTracking({ page: 'ChooseOrganization', session })
    const { createDepositor } = useCommand()
    const currentDepositor = useCurrentDepositor()
    const currentDepositorId = currentDepositor?.id
    const navigate = useNavigate()
    const associations = useSelector(({ session }) => session.associations)

    const [error, setError] = useState(null)
    const [errorMessage, setErrorMessage] = useState('')
    const [companyInfo, setCompanyInfo] = useState<CompanyInfo | null>(null)

    // The id of the newly created depositor, after the user has registered a new organisation
    const newlyCreatedDepositorId = associations
        .filter((association) => association.organisationType === 'DEPOSITOR')
        .find((association) => (association.organisation as DepositorDto).nationalIdentity === orgnum)?.organisation.id

    useEffect(() => {
        // Switch to newly created depositor
        if (newlyCreatedDepositorId) {
            dispatch(switchOrganisation('DEPOSITOR', newlyCreatedDepositorId))
            if (currentDepositorId) {
                navigate(window.location.pathname.replace(currentDepositorId, newlyCreatedDepositorId))
            }
        }
    }, [newlyCreatedDepositorId, currentDepositorId, dispatch, navigate])

    function setCompany(company: CompanyInfo | null) {
        setCompanyInfo(company)
        setOrgnum(company?.nationalIdentity || '')
        if (errorMessage.length > 0) setErrorMessage('')
    }

    async function submit() {
        const { waitForCommand } = await createDepositor(companyInfo.nationalIdentity, companyInfo.nationality)
        const success = await waitForCommand()
        if (success) {
            trackEvent({ event: 'registerNewOrganization' })
            if (associations.length === 0) {
                trackEvent({ event: 'registerFirstOrganization' })
                dispatch(
                    showConfirmationModal({
                        title: t('pages-organizations.organizationRegisteredHeading'),
                        text: t('pages-organizations.organizationRegisteredMessage'),
                        buttonText: t('common.ok'),
                    })
                )
            }
            dispatch(syncSessionState())
        } else {
            setErrorMessage(t('command.error.organisationRegistrationFailed'))
        }
    }

    const getWarningMessage = useWarningMessageOnRegisterCompany(companyInfo, session)

    return (
        <>
            <p>{t('pages-organizations.searchForOrganizationMessage')}</p>
            <div className={styles.companySelect}>
                <CompanySelect onChange={setCompany} setErrorMessage={setError} placeholder={t('common.search')} />
                {getWarningMessage && (
                    <Alert sx={{ mt: 1 }} severity="warning">
                        {getWarningMessage}
                    </Alert>
                )}
            </div>
            <ButtonRow className={styles.buttonRow}>
                <Button disabled={false} onClick={cancel} variant={'outlined'}>
                    {t('common.cancel')}
                </Button>
                <AsyncButton
                    disabled={error || !orgnum || getWarningMessage !== null}
                    id="modalContinueButton"
                    onClick={submit}
                    variant="contained"
                >
                    {t('pages-organizations.registerOrganization')}
                </AsyncButton>
            </ButtonRow>
            {errorMessage.length > 0 && <p className="field-error-message">{errorMessage}</p>}
        </>
    )
}
