import { SILVER_GRAY } from '#app/colors/colors'
import { HelpIcon } from '#components'
import BadgeList from '#pages/organizations/OrganizationDetail/UsersAndRoles/components/BadgeList'
import { DepositorDto, OrganisationalRole, PartnerRelationDto, SecurityRole } from '@fixrate/fixrate-query'
import { Divider, Grid, Hidden, Stack, Tooltip, Typography } from '@mui/material'
import classNames from 'classnames'
import * as React from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './OrderProcess.module.scss'
import PartnerAdvisorBadge from '#pages/organizations/OrganizationDetail/UsersAndRoles/components/PartnerAdvisorBadge'
import { ConfirmOnboardingStep } from '#pages/organizations/AddNewOrganization/OrganizationWizard/components/ConfirmOnboardingStep'

type UserRoles = 'orderProcess' | 'admin' | 'readOnly' | 'accounting'

interface Props {
    depositor: DepositorDto
    visibleItems?: Array<UserRoles>
    inline?: boolean
}

const PartnerAdvisorBadgeList = ({ partnerAccountant }: { partnerAccountant: PartnerRelationDto }) => {
    return (
        <Stack alignItems="center">
            {partnerAccountant.advisors.map((advisor) => (
                <PartnerAdvisorBadge
                    key={advisor.userId}
                    sx={{
                        maxWidth: '90%',
                        '& .MuiButtonGroup-root': { maxWidth: '100%' },
                        '& .MuiButton-root .MuiStack-root': {
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            width: '100%',
                            '& span': {
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                                width: '100%',
                            },
                        },
                    }}
                    firstName={advisor.firstName}
                    lastName={advisor.lastName}
                    securityRole={advisor.roles.find((role) => role.startsWith('DEPOSITOR_PARTNER_'))}
                />
            ))}
        </Stack>
    )
}
export default function OrderProcess({ depositor, visibleItems, inline }: Props) {
    const { t } = useTranslation()

    // Only a single AddUserButton can be open at the same time in this context
    const [selectedOpenRole, setSelectedOpenRole] = useState<SecurityRole | OrganisationalRole | null>(null)
    const hasVisibleItems = visibleItems && visibleItems.length > 0

    const closeOpenRoleOnClickOutside = () => {
        if (selectedOpenRole != null) {
            setSelectedOpenRole(null)
        }
    }

    const roleVisible = (role: UserRoles) => !hasVisibleItems || visibleItems.includes(role)

    const viewOnlyUsersAndInvites = depositor.people.filter((u) =>
        u.securityRoles.every((role) => role === 'DEPOSITOR_VIEW' || role === 'DEPOSITOR_SIGN_AUTHORIZATION')
    )

    const nonSelectableRoles = ['DEPOSITOR_PARTNER_SUPPORT', 'DEPOSITOR_PARTNER_ACCOUNTANT']

    const selectablePeople = depositor.people.filter((person) =>
        person.securityRoles.every((role) => !nonSelectableRoles.includes(role))
    )
    const partnerAccountant = depositor.partnerRelations.find((relation) => relation.partnerCategory === 'ACCOUNTANT')

    return (
        <Stack spacing={4} onClick={closeOpenRoleOnClickOutside}>
            {roleVisible('orderProcess') && (
                <Stack>
                    {!inline && (
                        <Stack direction={'row'} spacing={1} paddingBottom={1}>
                            <Typography variant="h2">
                                <i className="ri-hand-coin-line" />
                                <span>{t('pages-organizations.orderProcess')}</span>
                            </Typography>
                        </Stack>
                    )}
                    <p>
                        {t('pages-organizations.orderProcessDescription')}{' '}
                        <HelpIcon supportCommonName="rolesDepositor" inline />
                    </p>
                    <ul className={styles.orderProcess}>
                        <Step title={t('pages-organizations.orderStepOrderTitle')} icon="ri-shopping-cart-line">
                            <BadgeList
                                securityRole={'DEPOSITOR_ORDER'}
                                selectedOpenRole={selectedOpenRole}
                                setSelectedOpenRole={setSelectedOpenRole}
                                depositor={depositor}
                                people={selectablePeople}
                                namesWithRoles={false}
                                missingRoleFeedback={
                                    partnerAccountant ? '' : t('pages-organizations.orderStepOrderMissingFeedback')
                                }
                                onlyInvitesCoverRoleFeedback={t('pages-organizations.waitingInviteFeedback')}
                                showPersonalWarnings={false}
                                centered
                            />
                        </Step>
                        <Step title={t('pages-organizations.orderStepSignTitle')} icon="ri-quill-pen-line">
                            <BadgeList
                                securityRole={'DEPOSITOR_ACCOUNT_HOLDER'}
                                selectedOpenRole={selectedOpenRole}
                                setSelectedOpenRole={setSelectedOpenRole}
                                depositor={depositor}
                                people={selectablePeople}
                                namesWithRoles={false}
                                missingRoleFeedback={
                                    partnerAccountant ? '' : t('pages-organizations.orderStepSignMissingFeedback')
                                }
                                onlyInvitesCoverRoleFeedback={t('pages-organizations.waitingInviteFeedback')}
                                showPersonalWarnings={false}
                                centered
                            />
                        </Step>
                        <Step title={t('pages-organizations.orderStepMakePaymentTitle')} icon="ri-coin-line">
                            <BadgeList
                                securityRole={'DEPOSITOR_PAYMENT'}
                                selectedOpenRole={selectedOpenRole}
                                setSelectedOpenRole={setSelectedOpenRole}
                                depositor={depositor}
                                people={selectablePeople}
                                namesWithRoles={false}
                                missingRoleFeedback={
                                    partnerAccountant
                                        ? ''
                                        : t('pages-organizations.orderStepMakePaymentMissingFeedback')
                                }
                                onlyInvitesCoverRoleFeedback={t('pages-organizations.waitingInviteFeedback')}
                                showPersonalWarnings={false}
                                centered
                            />
                        </Step>
                    </ul>
                    {partnerAccountant && (
                        <Stack
                            sx={{ backgroundColor: SILVER_GRAY[100], py: 2 }}
                            spacing={2}
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Hidden lgUp>
                                <Stack direction="row" spacing={1} alignItems="center" flexWrap="wrap">
                                    <Typography variant="caption">
                                        {t('pages-organizations.orderStepOrderTitle')}
                                    </Typography>
                                    <i className="ri-arrow-right-s-line" />
                                    <Typography variant="caption">
                                        {t('pages-organizations.orderStepSignTitle')}
                                    </Typography>
                                    <i className="ri-arrow-right-s-line" />
                                    <Typography variant="caption">
                                        {t('pages-organizations.orderStepMakePaymentTitle')}
                                    </Typography>
                                </Stack>
                            </Hidden>
                            <Grid container>
                                <Grid item xs={12} lg={4}>
                                    <PartnerAdvisorBadgeList partnerAccountant={partnerAccountant} />
                                </Grid>
                                <Hidden lgDown>
                                    <Grid item xs={12} lg={4}>
                                        <PartnerAdvisorBadgeList partnerAccountant={partnerAccountant} />
                                    </Grid>
                                </Hidden>
                                <Hidden lgDown>
                                    <Grid item xs={12} lg={4}>
                                        <PartnerAdvisorBadgeList partnerAccountant={partnerAccountant} />
                                    </Grid>
                                </Hidden>
                            </Grid>
                            <Divider sx={{ width: '100%' }} />
                            <Stack direction="row" alignItems="center" spacing={1}>
                                <Typography m={0}>{partnerAccountant.name}</Typography>
                                <Tooltip
                                    title={t('pages-organizations.accountantOrderInformation', {
                                        name: partnerAccountant.name,
                                    })}
                                >
                                    <i style={{ fontSize: '1.8rem' }} className="ri-information-line purple" />
                                </Tooltip>
                            </Stack>
                        </Stack>
                    )}
                </Stack>
            )}
            {roleVisible('admin') && (
                <Stack>
                    <Stack direction={'row'} spacing={1} paddingBottom={1}>
                        <Typography variant="h2">
                            <i className="ri-admin-line" />
                            <span>{t('pages-organizations.roleAdministratorTitle')}</span>
                        </Typography>
                    </Stack>
                    <p>
                        {t('pages-organizations.roleAdministratorDescription')}{' '}
                        <HelpIcon supportCommonName="rolesDepositor" inline />
                    </p>
                    <BadgeList
                        selectedOpenRole={selectedOpenRole}
                        setSelectedOpenRole={setSelectedOpenRole}
                        depositor={depositor}
                        people={selectablePeople}
                        securityRole={'DEPOSITOR_ADMIN'}
                        row
                    />
                </Stack>
            )}
            {roleVisible('readOnly') && (
                <Stack>
                    <Stack direction={'row'} spacing={1} paddingBottom={1}>
                        <Typography variant="h2">
                            <i className="ri-search-eye-line" />
                            <span>{t('pages-organizations.roleReadOnlyTitle')}</span>
                        </Typography>
                    </Stack>
                    <p>
                        {t('pages-organizations.roleReadOnlyDescription')}{' '}
                        <HelpIcon supportCommonName="rolesDepositor" inline />
                    </p>
                    <BadgeList
                        selectedOpenRole={selectedOpenRole}
                        setSelectedOpenRole={setSelectedOpenRole}
                        depositor={depositor}
                        people={viewOnlyUsersAndInvites}
                        securityRole="DEPOSITOR_VIEW"
                        row
                    />
                </Stack>
            )}
            {roleVisible('accounting') && (
                <Stack>
                    <Stack direction={'row'} spacing={1} paddingBottom={1}>
                        <Typography variant="h2">
                            <i className="ri-file-chart-line" />
                            <span>{t('pages-organizations.roleAccountantTitle')}</span>
                        </Typography>
                    </Stack>
                    <p>
                        {t('pages-organizations.roleAccountantDescription')}{' '}
                        <HelpIcon supportCommonName="rolesEmail" inline />
                    </p>
                    <BadgeList
                        selectedOpenRole={selectedOpenRole}
                        setSelectedOpenRole={setSelectedOpenRole}
                        depositor={depositor}
                        people={selectablePeople}
                        securityRole="DEPOSITOR_ACCOUNTANT"
                        row
                    />
                </Stack>
            )}
            {inline && <ConfirmOnboardingStep step={'SECURITY_ROLES'} depositor={depositor} />}
        </Stack>
    )
}

interface StepProps {
    title: string
    icon: string
    children: React.ReactNode
}

const Step = ({ title, icon, children }: StepProps) => (
    <li className={styles.step}>
        <div className={styles.stepHeader}>
            <h3 className={styles.title}>
                <i className={icon} />
                <span>{title}</span>
            </h3>
            <i className={classNames(styles.nextStepIcon, 'ri-arrow-right-s-line')} />
        </div>
        {children}
    </li>
)
