import { DialogActions, DialogActionsProps } from '@mui/material'

type Props = DialogActionsProps

export default function FxDialogActions(props: Props) {
    return (
        <DialogActions {...props} sx={{ ...props.sx, borderTop: '1px solid rgba(0, 0, 0, 0.12)' }}>
            {props.children}
        </DialogActions>
    )
}
