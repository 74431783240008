import useCurrentDepositor from '#app/services/useCurrentDepositor'
import { OrganisationalPersonDto, PersonMatchDto } from '@fixrate/fixrate-query'
import { useCommand } from '#command'
import Modal from '#components/Modal'
import { useFieldState } from '@fixrate/fieldstate'
import { Box, Button, FormLabel, Stack, TextField, Typography } from '@mui/material'
import DateInput from '#components/DateInput'
import { isAfter, isBefore, format } from 'date-fns'
import { useTranslation } from 'react-i18next'

type Props = {
    close: () => void
    person: PersonMatchDto
    fixratePerson: OrganisationalPersonDto
}

function formatDate(dateString: string): string {
    const date = new Date(dateString)
    return date.toLocaleDateString('no-NB', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
    })
}

const BrregModifyPerson = function ({ close, person, fixratePerson }: Props) {
    const { t } = useTranslation()
    const depositor = useCurrentDepositor()
    const { updatePersonInfo } = useCommand()

    const firstName = useFieldState(fixratePerson.firstName.trim(), ({ value }) => {
        if (!value) {
            return t('pages-organizations.newPersonFirstNameMissing')
        }
    })

    const lastName = useFieldState(fixratePerson.lastName.trim(), ({ value }) => {
        if (!value) {
            return t('pages-organizations.newPersonLastNameMissing')
        }
    })

    const birthDate = useFieldState(
        fixratePerson.birthDate ? new Date(fixratePerson.birthDate) : null,
        ({ value, isEditing }) => {
            if (!isEditing) {
                if (isAfter(value, new Date())) {
                    return t('components-DateInput.mustBeInPast')
                } else if (isBefore(value, new Date(1900, 0, 1))) {
                    return t('components-DateInput.mustBeAfter1900')
                }
            }
        },
        { validateInitially: true }
    )

    async function submit() {
        const isValid = firstName.validate() && lastName.validate()

        if (!isValid) return

        try {
            const birthDateFormatted = birthDate.value ? format(birthDate.value, 'yyyy-MM-dd') : null
            const { waitForCommand, rejected } = await updatePersonInfo({
                depositorId: depositor.id,
                personId: person.personId,
                firstName: firstName.value.trim(),
                lastName: lastName.value.trim(),
                birthDate: birthDateFormatted,
                email: fixratePerson.email,
                address: fixratePerson.address,
                keyPersonRole: fixratePerson.keyPersonRole,
            })

            if (rejected) {
                closeModal()
            } else {
                await waitForCommand()
                closeModal()
            }
        } catch (err) {
            console.error(err)
        }
    }

    const closeModal = () => {
        close()
    }

    const autoCompleteBlocker = (field) => {
        const randomNumber = Math.floor(Math.random() * (1 - 1000 + 1)) + 1
        return field + '-' + randomNumber
    }

    const formattedBirthDate = formatDate(person.birthDate)

    function updateName(): void {
        const nameParts = person.proffName.split(' ')
        const initialLastName = nameParts.pop().trim()
        const initialFirstName = nameParts.join(' ').trim()
        const personBirthDate = new Date(person.birthDate)
        firstName.setValue(initialFirstName)
        lastName.setValue(initialLastName)
        birthDate.setValue(personBirthDate)
    }

    return (
        <Modal
            header={t('pages-organizations.editPersonInfo')}
            onSubmit={submit}
            submitButtonId={'editPersonNameConfirm'}
            onCancel={closeModal}
            width="50rem"
            submitButtonText={t('common.confirm')}
        >
            <form autoComplete="off">
                <Stack spacing={1.5}>
                    <Box>
                        <FormLabel htmlFor="firstNameField">{t('pages-organizations.newPersonFirstName')}</FormLabel>

                        <TextField
                            id="firstNameField"
                            name={autoCompleteBlocker('fixrate-fname')}
                            onBlur={firstName.onBlur}
                            onChange={(e) => firstName.setValue(e.target.value)}
                            autoComplete="off"
                            value={firstName.value}
                        />
                        <p className="field-error-message">{firstName.errorMessage}</p>
                    </Box>
                    <Box>
                        <FormLabel htmlFor="lastNameField">{t('pages-organizations.newPersonLastName')}</FormLabel>
                        <TextField
                            id="lastNameField"
                            name={autoCompleteBlocker('fixrate-lname')}
                            onBlur={lastName.onBlur}
                            onChange={(e) => lastName.setValue(e.target.value)}
                            type="text"
                            autoComplete="off"
                            value={lastName.value}
                        />
                        <p className="field-error-message">{lastName.errorMessage}</p>
                    </Box>
                    <Box>
                        <FormLabel htmlFor="birthDateField">{t('pages-organizations.personBirthDate')}</FormLabel>
                        <DateInput
                            id="birthDateDatePicker"
                            value={birthDate.value}
                            onChange={(date) => birthDate.setValue(date)}
                            onBlur={() => {
                                birthDate.validate()
                                if (birthDate.valid) {
                                    birthDate.onBlur()
                                }
                            }}
                        />
                        <p className="field-error-message">{birthDate.errorMessage}</p>
                    </Box>
                    <Box paddingTop={'2rem'}>
                        <Button variant="outlined" onClick={updateName} data-cy="updatePerson">
                            {t('pages-organizations.updatePersonName')}
                        </Button>
                        <Typography variant="body2">{t('pages-organizations.updatePersonNameTooltip')}:</Typography>
                        <Typography variant="body1">
                            {person.proffName}, {formattedBirthDate}
                        </Typography>
                    </Box>
                </Stack>
            </form>
        </Modal>
    )
}

export default BrregModifyPerson
