import useCurrentDepositor from '#app/services/useCurrentDepositor'
import { OrganisationalRole } from '@fixrate/fixrate-query'
import { SecurityRole } from '@fixrate/fixrate-security'
import { useCommand } from '#command'
import Modal from '#components/Modal'
import { useFieldState } from '@fixrate/fieldstate'
import { Box, Checkbox, FormControlLabel, FormLabel, Stack, TextField } from '@mui/material'
import { isAfter, isBefore } from 'date-fns'
import { ChangeEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
    close: () => void
    securityRoles?: SecurityRole[]
    organisationalRoles?: OrganisationalRole[]
}

const NewPerson = function ({ close, securityRoles = [], organisationalRoles = [] }: Props) {
    const { t } = useTranslation()
    const depositor = useCurrentDepositor()
    const { addPerson } = useCommand()
    const [showAllowSimilarNamesCheckbox, setShowAllowSimilarNamesCheckbox] = useState(false)
    const [allowSimilarNames, setAllowSimilarNames] = useState(false)
    const sendInvitation = false

    const firstName = useFieldState('', ({ value }) => {
        if (!value || value.trim() === '') {
            return t('pages-organizations.newPersonFirstNameMissing')
        }
    })

    const lastName = useFieldState('', ({ value }) => {
        if (!value || value.trim() === '') {
            return t('pages-organizations.newPersonLastNameMissing')
        }
    })

    const email = useFieldState('', ({ value }) => {
        if (!value) {
            return t('pages-organizations.sendInviteEmailMissing')
        }
    })

    const birthDate = useFieldState(null, ({ value, isEditing }) => {
        if (isEditing) {
            return
        }
        if (!value) {
            return t('pages-organizations.sendInviteBirthDateMissing')
        }

        if (isAfter(value, new Date())) {
            return t('components-DateInput.mustBeInPast')
        } else if (isBefore(value, new Date(1900, 0, 1))) {
            return t('components-DateInput.mustBeAfter1900')
        }
    })

    async function submit() {
        const isValid = firstName.validate() && lastName.validate()
        const sendInvitationIsvalid = sendInvitation ? email.validate() && birthDate.validate() : true

        if (!isValid || !sendInvitationIsvalid) return

        try {
            const { waitForCommand, rejected } = await addPerson(
                depositor.id,
                firstName.value?.trim(),
                lastName.value?.trim(),
                securityRoles,
                organisationalRoles,
                allowSimilarNames,
                null,
                null
            )

            if (rejected) {
                setShowAllowSimilarNamesCheckbox(true)
            } else {
                // handle error, see trello
                await waitForCommand()
                closeModal()
            }
        } catch (err) {
            console.error(err)
        }
    }

    const closeModal = () => {
        close()
    }

    const autoCompleteBlocker = (field) => {
        const randomNumber = Math.floor(Math.random() * (1 - 1000 + 1)) + 1
        return field + '-' + randomNumber
    }

    return (
        <Modal header={t('pages-organizations.newPersonHeading')} onSubmit={submit} onCancel={closeModal} width="50rem">
            <form autoComplete="off">
                <Stack spacing={1.5}>
                    <Box>
                        <FormLabel htmlFor="lastNameField">{t('pages-organizations.newPersonFirstName')}</FormLabel>
                        <TextField
                            id="firstNameField"
                            name={autoCompleteBlocker('fixrate-lname')}
                            onBlur={firstName.onBlur}
                            onChange={(e) => firstName.setValue(e.target.value)}
                            autoComplete="off"
                            value={firstName.value}
                        />
                        <p className="field-error-message">{firstName.errorMessage}</p>
                    </Box>
                    <Box>
                        <FormLabel htmlFor="lastNameField">{t('pages-organizations.newPersonLastName')}</FormLabel>
                        <TextField
                            id="lastNameField"
                            name={autoCompleteBlocker('fixrate-lname')}
                            onBlur={lastName.onBlur}
                            onChange={(e) => lastName.setValue(e.target.value)}
                            type="text"
                            autoComplete="off"
                            value={lastName.value}
                        />
                        <p className="field-error-message">{lastName.errorMessage}</p>
                    </Box>
                </Stack>

                {showAllowSimilarNamesCheckbox && (
                    <FormControlLabel
                        control={
                            <Checkbox
                                id="allowSimilarNamesCheckbox"
                                data-cy="allowSimilarNamesCheckbox"
                                value={allowSimilarNames}
                                checked={allowSimilarNames}
                                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                                    setAllowSimilarNames(event.target.checked)
                                }
                            />
                        }
                        label={t('pages-organizations.allowSimilarNames')}
                    />
                )}
            </form>
        </Modal>
    )
}

export default NewPerson
