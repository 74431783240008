import DateOutput from '#components/DateOutput'
import { TextField } from '@mui/material'
import nb from 'date-fns/locale/nb'
import ReactDatePicker, { registerLocale } from 'react-datepicker'
import { useTranslation } from 'react-i18next'
import { PatternFormat } from 'react-number-format'
import './react-datepicker.css'

registerLocale('nb', nb)

interface Props {
    id?: string
    inline?: boolean
    className?: string
    selected?: Date | null
    onChange: (Date) => void
    onBlur?: (Date) => void
    openToDate?: Date
    showTimeSelect?: boolean
    disabled?: boolean
    filterDate?: (Date) => boolean
    minDate?: Date
    maxDate?: Date
    portalId?: string
    inputProps?: {
        label?: string
        helperText?: string
        error?: boolean
    }
    'data-cy'?: string
}

/**
 * /**
 * @deprecated Use FxDateInput instead.
 *
 */

export default function DatePicker({
    id,
    'data-cy': dataCy,
    inline = false,
    className,
    selected,
    onChange,
    onBlur,
    openToDate,
    showTimeSelect,
    disabled,
    filterDate,
    minDate,
    maxDate,
    portalId = 'datepicker-portal',
    inputProps,
}: Props) {
    const { t, i18n } = useTranslation()

    const dateFormatString = showTimeSelect ? DateOutput.dateTimeFormat : DateOutput.dateFormat

    const autoCompleteBlocker = (field) => {
        const randomNumber = Math.floor(Math.random() * (1 - 1000 + 1)) + 1
        return field + '-' + randomNumber
    }

    return (
        <ReactDatePicker
            data-cy={dataCy}
            id={id}
            className={className}
            inline={inline}
            customInput={
                <PatternFormat
                    format={'##.##.####'}
                    type={'text'}
                    mask={'_'}
                    customInput={TextField}
                    error={inputProps?.error}
                    label={inputProps?.label}
                    helperText={inputProps?.helperText}
                />
            }
            selected={selected}
            onChange={onChange}
            onBlur={onBlur}
            openToDate={openToDate}
            showWeekNumbers
            showTimeSelect={showTimeSelect}
            dateFormat={dateFormatString}
            disabled={disabled}
            showYearDropdown
            filterDate={filterDate}
            minDate={minDate}
            maxDate={maxDate}
            dropdownMode={'scroll'}
            timeFormat={DateOutput.timeFormat}
            timeCaption={t('components-DatePicker.time')}
            locale={i18n.language}
            portalId={portalId}
            name={autoCompleteBlocker('birthDate')}
            autoComplete="off"
        />
    )
}
