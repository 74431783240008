import { nameWithRoles } from '#app/utilities/nameWithRoles'
import { DepositorDto, OrganisationalPersonDto } from '@fixrate/fixrate-query'
import { TFunction } from 'react-i18next'

// Subset of OrganisationalPersonDto and Proxy (in PowerOfAttorney)
type PersonBasics = {
    personId: string
    firstName: string
    lastName: string
}

const NameOutput = () => undefined

NameOutput.withBirthYearOnDuplicates = (person: PersonBasics, depositor: DepositorDto) => {
    const otherPersonWithSameName = depositor.people.some(
        (p) => p.personId !== person.personId && p.firstName === person.firstName && p.lastName === person.lastName
    )
    const birthYear = depositor.people.find((p) => p.personId === person.personId)?.birthDate?.substring(0, 4)
    return person.firstName + ' ' + person.lastName + (otherPersonWithSameName && birthYear ? ` (${birthYear})` : '')
}

NameOutput.withAllRoles = (person: OrganisationalPersonDto, depositor: DepositorDto, t: TFunction) => {
    return nameWithRoles(person, depositor, t).nameWithAllRoles
}

NameOutput.withOrgRoles = (person: OrganisationalPersonDto, depositor: DepositorDto, t: TFunction) => {
    return nameWithRoles(person, depositor, t).nameWithOrgRoles
}

NameOutput.withFixrateRoles = (person: OrganisationalPersonDto, depositor: DepositorDto, t: TFunction) => {
    return nameWithRoles(person, depositor, t).nameWithFixrateRoles
}

export default NameOutput
