import useCurrentCountryCode from './useCurrentCountryCode'

type FeaturesType = {
    fund: boolean
}

/**
 * The features that may be enabled or not, based on the site configuration or other static properties
 */
export function useFeatures(): FeaturesType {
    const organisationCountry = useCurrentCountryCode()

    return {
        fund: organisationCountry === 'NO',
    }
}
