export const SuspenseLoader = (
    <div
        style={{
            display: 'flex',
            justifyItems: 'center',
            alignItems: 'center',
            background: 'white',
            position: 'fixed',
            width: '100%',
            height: '100%',
        }}
    >
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyItems: 'center',
                margin: 'auto',
                backfaceVisibility: 'hidden',
                transform: 'translateZ(0)',
                willChange: 'contents',
                alignItems: 'center',
            }}
        >
            <img
                style={{
                    maxWidth: '18rem',
                    marginBottom: '2rem',
                }}
                src="/images/fixrate-logo.svg"
                alt={''}
            />
            <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44" stroke="#4D2A98">
                <g fill="none" fillRule="evenodd" strokeWidth="2">
                    <circle cx="22" cy="22" r="1">
                        <animate
                            attributeName="r"
                            begin="0s"
                            dur="1.8s"
                            values="1; 20"
                            calcMode="spline"
                            keyTimes="0; 1"
                            keySplines="0.165, 0.84, 0.44, 1"
                            repeatCount="indefinite"
                        />
                        <animate
                            attributeName="stroke-opacity"
                            begin="0s"
                            dur="1.8s"
                            values="1; 0"
                            calcMode="spline"
                            keyTimes="0; 1"
                            keySplines="0.3, 0.61, 0.355, 1"
                            repeatCount="indefinite"
                        />
                    </circle>
                    <circle cx="22" cy="22" r="1">
                        <animate
                            attributeName="r"
                            begin="-0.9s"
                            dur="1.8s"
                            values="1; 20"
                            calcMode="spline"
                            keyTimes="0; 1"
                            keySplines="0.165, 0.84, 0.44, 1"
                            repeatCount="indefinite"
                        />
                        <animate
                            attributeName="stroke-opacity"
                            begin="-0.9s"
                            dur="1.8s"
                            values="1; 0"
                            calcMode="spline"
                            keyTimes="0; 1"
                            keySplines="0.3, 0.61, 0.355, 1"
                            repeatCount="indefinite"
                        />
                    </circle>
                </g>
            </svg>
        </div>
    </div>
)
