import { Subject } from 'rxjs'
import { timeout } from 'rxjs/operators'

const commands = new Map()
const promises = new Map()
const completed = new Map()

export const resolveCommand = function (commandId) {
    if (commands.has(commandId)) {
        commands.get(commandId).next(commandId)
    } else {
        completed.set(commandId, Promise.resolve(true))
    }
}

const defaultTimeoutTime = 10_000

export const waitForCommandResponse = function (commandId, timeoutTime = defaultTimeoutTime) {
    if (commandId && typeof commandId === 'string') {
        if (promises.has(commandId)) {
            return promises.get(commandId)
        }
        if (completed.has(commandId)) {
            const ret = completed.get(commandId)
            completed.delete(commandId)
            return ret
        }
        console.log('Waiting for command to complete', commandId)
        promises.set(
            commandId,
            new Promise((resolve) => {
                const subject = new Subject()
                subject.pipe(timeout(timeoutTime)).subscribe(
                    (id) => {
                        //console.log('next', id)
                        if (id && id === commandId) {
                            subject.complete()
                        }
                    },
                    () => {
                        //console.log('error')
                        if (promises.has(commandId)) {
                            promises.delete(commandId)
                        }
                        if (commands.has(commandId)) {
                            commands.delete(commandId)
                        }
                        resolve(false)
                    },
                    () => {
                        //console.log('complete')
                        if (promises.has(commandId)) {
                            promises.delete(commandId)
                        }
                        if (commands.has(commandId)) {
                            commands.delete(commandId)
                        }
                        resolve(true)
                    }
                )

                subject.next()

                commands.set(commandId, subject)
            })
        )
        return promises.get(commandId)
    } else {
        console.error('waitForCommandResonse called without a commandId')
        return Promise.resolve(false)
    }
}
