import { useTranslation } from 'react-i18next'
import ResendUserInvite, { ResendUserInviteProps } from './ResendUserInvite'

//This is just a wrapper but is added to avoid confusion around resending vs sending a new invite
export default function SendUserInvite({ inviteEmail, inviteId, returnTo, depositorId, close }: ResendUserInviteProps) {
    const { t } = useTranslation()
    return (
        <ResendUserInvite
            title={t('pages-organizations.sendInviteHeader')}
            close={close}
            inviteEmail={inviteEmail}
            inviteId={inviteId}
            returnTo={returnTo}
            depositorId={depositorId}
        />
    )
}
