import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
    name: 'companyPublicInformation',
    initialState: [],
    reducers: {
        setCompanyPublicInformation(state, action) {
            return action.payload
        },
    },
})

const { actions, reducer } = slice
export const { setCompanyPublicInformation } = actions
export default reducer
