import { useSelector } from '#state/useSelector'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import { useCommand } from '#command'
import { setUiSetting } from '#state/reducers/uiSettings'
import { useDispatch } from 'react-redux'
import {
    FundCreditDurationCategory,
    FundEsgCategory,
    FundMorningstarCategory,
    FundVffCategory,
    MessageCategory,
} from '@fixrate/fixrate-query'
import { DepositFilterOption } from '#app/pages/marketplace/MarketplaceFilter/MarketplaceFilter'

type UiSettingKey =
    | 'FundCustomerList-rowsPerPage'
    | 'fundCategoriesFilter'
    | 'fundVffCategoriesFilter'
    | 'fundOmfFilter'
    | 'fundSubordinateLoanFilter'
    | 'fundMinimumAumFilter'
    | 'fundCreditDurationCategoryFilter'
    | 'esgFilter'
    | 'inbox.filter.selectedCategory'
    | 'annualStatementsMessage'
    | 'hideSuccessModalForEver'
    | 'freeFundCakeMessage'
    | 'hideNibor'
    | 'hideFolio'
    | 'fundreport-reconciliation-type'
    | 'fundreport-reconciliation-type'
    | 'fundreport-inventory-type'
    | '10MRDCakeMessage'
    | 'onboardingWizardStep'
    | 'selectedDepositFilter'
    | '20MRDCakeMessage'
    | 'hasOrdered20MRDCake'
    | 'simplifiedOnboarding'

// Add a specific type for each key, to enforce the correct value type
export default function useUiSetting(
    key: 'FundCustomerList-rowsPerPage',
    defaultValue?: number
): [number, (value: number) => void]
export default function useUiSetting(
    key: 'fundCategoriesFilter',
    defaultValue?: FundMorningstarCategory[]
): [FundMorningstarCategory[], (value: FundMorningstarCategory[]) => void]
export default function useUiSetting(
    key: 'fundVffCategoriesFilter',
    defaultValue?: FundVffCategory[]
): [FundVffCategory[], (value: FundVffCategory[]) => void]
export default function useUiSetting(key: 'fundOmfFilter', defaultValue?: boolean): [boolean, (value: boolean) => void]
export default function useUiSetting(
    key: 'fundSubordinateLoanFilter',
    defaultValue?: boolean
): [boolean, (value: boolean) => void]
export default function useUiSetting(
    key: 'fundMinimumAumFilter',
    defaultValue?: number,
    debounceTime?: number
): [number, (value: number) => void]
export default function useUiSetting(
    key: 'fundCreditDurationCategoryFilter',
    defaultValue?: FundCreditDurationCategory | ''
): [FundCreditDurationCategory | '', (value: FundCreditDurationCategory | '') => void]
export default function useUiSetting(
    key: 'esgFilter',
    defaultValue?: { [category in FundEsgCategory]?: boolean }
): [{ [category in FundEsgCategory]?: boolean }, (value: { [category in FundEsgCategory]?: boolean }) => void]
export default function useUiSetting(
    key: 'inbox.filter.selectedCategory',
    defaultValue?: MessageCategory | 'ALL'
): [MessageCategory | 'ALL', (value: MessageCategory | 'ALL') => void]
export default function useUiSetting(
    key: 'annualStatementsMessage',
    defaultValue?: boolean
): [boolean, (value: boolean) => void]
export default function useUiSetting(
    key: 'hideSuccessModalForEver',
    defaultValue?: boolean
): [boolean, (value: boolean) => void]
export default function useUiSetting(
    key: 'freeFundCakeMessage',
    defaultValue?: boolean
): [boolean, (value: boolean) => void]
export default function useUiSetting(
    key: '10MRDCakeMessage',
    defaultValue?: boolean
): [boolean, (value: boolean) => void]
export default function useUiSetting(
    key: '20MRDCakeMessage',
    defaultValue?: boolean
): [boolean, (value: boolean) => void]
export default function useUiSetting(
    key: 'hasOrdered20MRDCake',
    defaultValue?: boolean
): [boolean, (value: boolean) => void]
export default function useUiSetting(key: 'hideNibor', defaultValue?: boolean): [boolean, (value: boolean) => void]
export default function useUiSetting(key: 'hideFolio', defaultValue?: boolean): [boolean, (value: boolean) => void]
export default function useUiSetting(
    key: 'simplifiedOnboarding',
    defaultValue?: boolean
): [boolean, (value: boolean) => void]
export default function useUiSetting(
    key: 'fundreport-reconciliation-type',
    defaultValue?: string
): [string, (value: string) => void]
export default function useUiSetting(
    key: 'fundreport-inventory-type',
    defaultValue?: string
): [string, (value: string) => void]
export default function useUiSetting(
    key: 'onboardingWizardStep',
    defaultValue?: { [depositorId: string]: number }
): [{ [depositorId: string]: number }, (value: { [depositorId: string]: number }) => void]
export default function useUiSetting(
    key: 'selectedDepositFilter',
    defaultValue?: { [depositorId: string]: DepositFilterOption }
): [{ [depositorId: string]: DepositFilterOption }, (value: { [depositorId: string]: DepositFilterOption }) => void]
/**
 * Hook to get and set a UI setting.
 * <p/>
 * NB! The defaultValue is stored in a ref, so it will not be updated if the component is re-rendered with a new defaultValue.
 */
export default function useUiSetting<Type>(
    key: UiSettingKey,
    defaultValue?: Type,
    debounceTime = 0
): [Type, (value: Type) => void] {
    const settings = useSelector((state) => state.uiSettings)
    const dispatch = useDispatch()
    const { storeUiSetting } = useCommand()
    const userIdPrefix = useSelector((state) => state.session?.id?.slice(0, 5))
    const localStorageKey = userIdPrefix ? `${key}${userIdPrefix}` : key
    const debounceTimeout = useRef<ReturnType<typeof setTimeout> | null>(null)

    // Removes the value from localStorage and store it in redux and on backend
    useEffect(() => {
        const localStorageValue = window.localStorage.getItem(localStorageKey)
        if (settings[key] === undefined && localStorageValue != null) {
            try {
                const parsedValue = JSON.parse(localStorageValue) as Type
                dispatch(setUiSetting({ key, parsedValue }))
                storeUiSetting(key, JSON.stringify(parsedValue))
            } catch (e) {
                console.error(`Error parsing value from localStorage as JSON for key ${key}`, e)
            }
        }
        window.localStorage.removeItem(localStorageKey)
    }, [dispatch, key, localStorageKey, settings, storeUiSetting])

    const setValue = useCallback(
        (value: Type) => {
            dispatch(setUiSetting({ key, value }))
            clearTimeout(debounceTimeout.current)
            debounceTimeout.current = setTimeout(() => {
                storeUiSetting(key, JSON.stringify(value))
            }, debounceTime)
        },
        [dispatch, key, debounceTime, storeUiSetting]
    )

    const fixedDefaultValue = useRef(defaultValue).current

    return useMemo(
        () => [settings[key] !== undefined ? (settings[key] as Type) : fixedDefaultValue, setValue],
        [key, setValue, settings, fixedDefaultValue]
    )
}
