import {
    BURNT_ORANGE,
    FOREST_GREEN,
    PURPLE,
    SILVER_GRAY,
    SPRING_GREEN,
    SUCCESS_GREEN,
    SUNSET_ORANGE,
} from '#app/colors/colors'
import { createTheme, PaletteColor, PaletteColorOptions, Shadows, ThemeProvider } from '@mui/material'
import { LinkProps } from '@mui/material/Link'
import { fontWeight } from 'html2canvas/dist/types/css/property-descriptors/font-weight'
import * as React from 'react'
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom'

declare module '@mui/material/styles' {
    interface TypographyVariants {
        interestBig: React.CSSProperties
        emptyState: React.CSSProperties
        labelDescription: React.CSSProperties
        filterLabel: React.CSSProperties
    }

    interface ButtonVariants {
        underline: React.CSSProperties
    }

    interface TypographyVariantsOptions {
        interestBig?: React.CSSProperties
        emptyState?: React.CSSProperties
        labelDescription?: React.CSSProperties
        filterLabel?: React.CSSProperties
    }

    interface Palette {
        springGreen: PaletteColor
        backgroundWhite: PaletteColor
        darkMode: PaletteColor
    }

    interface PaletteOptions {
        springGreen?: {
            main: string
            light: string
            dark: string
            contrastText: string
        }
        darkMode?: {
            main: string
            light: string
            dark: string
            contrastText: string
        }
        backgroundWhite: PaletteColorOptions
    }
}

declare module '@mui/material/Button' {
    interface ButtonPropsVariantOverrides {
        underline: true
    }
}
declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        interestBig: true
        emptyState: true
        labelDescription: true
        filterLabel: true
    }
}

declare module '@mui/material/Button' {
    interface ButtonPropsColorOverrides {
        springGreen: true
        backgroundWhite: true
        darkMode: true
    }
}
declare module '@mui/material/ButtonGroup' {
    interface ButtonGroupPropsColorOverrides {
        springGreen: true
        backgroundWhite: true
        darkMode: true
    }
}

declare module '@mui/material/Tabs' {
    interface TabsPropsIndicatorColorOverrides {
        darkMode: true
        inherit: true
    }
}

const LinkBehavior = React.forwardRef<HTMLAnchorElement, Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }>(
    (props, ref) => {
        const { href, ...other } = props
        // Map href (MUI) -> to (react-router)
        return <RouterLink ref={ref} to={href} {...other} />
    }
)

LinkBehavior.displayName = 'LinkBehavior'

function create() {
    const theme = createTheme() // Used to access breakpoints
    return createTheme({
        palette: {
            springGreen: {
                main: SPRING_GREEN[500],
                light: SPRING_GREEN[400],
                dark: SPRING_GREEN[600],
                contrastText: PURPLE[900],
            },
            darkMode: {
                main: PURPLE[25],
                light: PURPLE[50],
                dark: PURPLE[900],
                contrastText: PURPLE[25],
            },
            primary: {
                main: '#4d2a98',
            },
            secondary: {
                main: '#21362C',
            },
            info: {
                main: PURPLE[500],
            },
            error: {
                main: BURNT_ORANGE[500],
            },
            warning: {
                main: SUNSET_ORANGE[500],
            },
            success: {
                main: '#40a251',
            },
            backgroundWhite: {
                main: '#ffffff',
                contrastText: PURPLE[900],
            },
        },
        shape: {
            borderRadius: 3,
        },
        shadows: Array(25).fill('1px 1px 3px rgba(0, 0, 0, 0.2)') as Shadows,
        typography: {
            h1: {
                color: FOREST_GREEN[500],
                fontSize: '3.2rem',
                fontWeight: 700,
                [theme.breakpoints.down('md')]: {
                    fontSize: '2.8rem',
                },
            },
            h2: {
                color: FOREST_GREEN[500],
                fontSize: '2.2rem',
                fontWeight: 700,
                marginBottom: '1rem',
                [theme.breakpoints.down('md')]: {
                    fontSize: '2rem',
                },
                '& i': {
                    fontWeight: 500,
                    marginRight: '0.4rem',
                },
            },
            h3: {
                color: FOREST_GREEN[500],
                fontSize: '1.8rem',
                fontWeight: 700,
                display: 'flex',
                alignItems: 'center',
                '& i': {
                    marginRight: '0.4rem',
                    fontWeight: 500,
                    fontSize: '2rem',
                },
                [theme.breakpoints.down('md')]: {
                    fontSize: '1.6rem',
                },
            },
            h4: {
                color: FOREST_GREEN[500],
                fontSize: '1.6rem',
                fontWeight: 600,
                [theme.breakpoints.down('md')]: {
                    fontSize: '1.4rem',
                },
            },
            subtitle1: {
                color: FOREST_GREEN[500],
                fontWeight: 500,
                fontSize: '1.6rem',
                [theme.breakpoints.down('md')]: {
                    fontSize: '1.4rem',
                },
            },
            subtitle2: {
                color: SILVER_GRAY[500],
                fontWeight: 500,
                fontSize: '1.6rem',
                [theme.breakpoints.down('md')]: {
                    fontSize: '1.4rem',
                },
            },
            body2: {
                color: SILVER_GRAY[500],
            },
            emptyState: {
                fontWeight: 500,
                fontSize: '2.6rem',
                color: SILVER_GRAY[500],
                [theme.breakpoints.down('md')]: {
                    fontSize: '2.4rem',
                },
            },
            interestBig: {
                fontWeight: 800,
                fontSize: '3.6rem',
                color: PURPLE[500],
            },
            button: {
                textTransform: 'none',
                fontSize: '1.6rem',
                fontWeight: '600',
                lineHeight: '1.875',
            },
            labelDescription: {
                fontSize: '1.2rem',
                color: SILVER_GRAY[500],
                marginBottom: '1rem',
                marginTop: '0.2rem',
                fontWeight: 500,
            },
            filterLabel: {
                fontWeight: 600,
                fontSize: '1.4rem',
                marginBottom: '0.6rem',
            },
            htmlFontSize: 10,
            fontWeightRegular: 500,
            fontFamily: [
                "'Montserrat'",
                '-apple-system',
                'BlinkMacSystemFont',
                '"Segoe UI"',
                'Roboto',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
            ].join(','),
        },
        spacing: (factor) => `${factor}rem`,
        components: {
            MuiContainer: {
                styleOverrides: {
                    root: {
                        marginRight: 'auto',
                        marginLeft: '0',
                    },
                },
            },
            MuiTypography: {
                styleOverrides: {
                    root: {
                        '& i': {
                            paddingRight: '0.4rem',
                            verticalAlign: 'bottom',
                        },
                    },
                },
                defaultProps: {
                    variantMapping: {
                        filterLabel: 'p',
                        emptyState: 'p',
                    },
                },
            },
            MuiAlert: {
                styleOverrides: {
                    root: {
                        alignItems: 'center',
                    },
                    standardWarning: {
                        '& .MuiAlert-icon': {
                            color: SUNSET_ORANGE[700],
                        },
                    },
                },
            },
            MuiAlertTitle: {
                styleOverrides: {
                    root: {
                        fontWeight: '700',
                        marginBottom: '0.1rem',
                    },
                },
            },
            MuiToggleButton: {
                styleOverrides: {
                    root: {
                        '& i': {
                            marginRight: '0.6rem',
                            fontWeight: 'normal',
                        },
                    },
                    sizeSmall: {
                        paddingLeft: 20,
                        paddingRight: 20,
                    },
                    sizeMedium: {
                        fontSize: '1.4rem',
                    },
                },
            },
            MuiButton: {
                defaultProps: {
                    LinkComponent: LinkBehavior,
                },
                variants: [
                    {
                        props: {
                            variant: 'underline',
                        },
                        style: {
                            textDecoration: 'underline',
                            textDecorationThickness: '0.1rem',
                            '&:hover': {
                                textDecoration: 'underline',
                                textDecorationThickness: '0.1rem',
                            },
                        },
                    },
                    {
                        props: {
                            color: 'darkMode',
                        },
                        style: {
                            borderColor: 'rgba(250,250,250,0.2)',
                        },
                    },
                ],
                styleOverrides: {
                    // Name of the slot
                    root: {
                        paddingTop: '1rem',
                        paddingBottom: '1rem',
                        whiteSpace: 'nowrap',
                    },
                    contained: {
                        boxShadow: '0rem 0.1rem 0.1rem rgba(0, 0, 0, 0.4)',
                        ':disabled': {
                            color: SILVER_GRAY[500],
                            backgroundColor: SILVER_GRAY[50],
                        },
                    },
                    sizeSmall: {
                        fontSize: '1.4rem',
                        paddingTop: '0.4rem',
                        paddingBottom: '0.4rem',
                        '& .MuiButton-startIcon': {
                            lineHeight: 1,
                        },
                        '& .MuiButton-endIcon': {
                            lineHeight: 1,
                            marginRight: '0.4rem',
                        },
                    },
                    sizeLarge: {
                        fontSize: '1.8rem',
                    },
                },
            },
            MuiAutocomplete: {
                styleOverrides: {
                    root: {
                        width: '30rem',
                    },
                    inputRoot: {
                        padding: '0.6rem 1rem',
                    },
                },
            },
            MuiDialogActions: {
                styleOverrides: {
                    root: {
                        padding: '2rem 2.4rem',
                    },
                },
            },
            MuiTooltip: {
                styleOverrides: {
                    tooltip: {
                        padding: '1rem',
                        fontSize: '1.2rem',
                        maxWidth: '25rem',
                        backgroundColor: 'rgba(0,0,0,0.8)',
                        boxShadow: '1px 1px 2px rgba(0,0,0,0.2)',
                    },
                    arrow: {
                        '&:before': {
                            color: 'rgba(0,0,0,0.8)',
                        },
                    },
                },
            },
            MuiDialogTitle: {
                styleOverrides: {
                    root: {
                        fontSize: '2rem',
                        fontWeight: '700',
                        paddingLeft: '3rem',
                        paddingRight: '3rem',
                        position: 'relative',
                        zIndex: 1,
                        '& .MuiIconButton-root i': {
                            paddingRight: '0',
                        },
                    },
                },
            },
            MuiSlider: {
                styleOverrides: {
                    valueLabel: {
                        backgroundColor: PURPLE[900],
                    },
                    markLabelActive: {
                        fontWeight: 600,
                    },
                },
            },
            MuiSnackbar: {
                variants: [
                    {
                        props: { color: 'error' },
                        style: {
                            '& .MuiSnackbarContent-root': {
                                backgroundColor: BURNT_ORANGE[50],
                                color: BURNT_ORANGE[900],
                            },
                            '& .MuiSnackbarContent-message': {
                                [theme.breakpoints.down('md')]: {
                                    fontSize: '1.2rem',
                                },
                            },
                        },
                    },
                ],
            },
            MuiDialogContent: {
                styleOverrides: {
                    root: {
                        paddingLeft: '3rem',
                        paddingRight: '3rem',
                        paddingBottom: '2.4rem',
                        paddingTop: '2.4rem',
                    },
                },
            },
            MuiIconButton: {
                styleOverrides: {
                    // Name of the slot
                    root: {
                        // Some CSS
                        fontSize: '2rem',
                    },
                },
            },
            MuiCardHeader: {
                styleOverrides: {
                    root: {
                        padding: '2rem 3rem',
                    },
                    title: {
                        fontSize: '2rem',
                        fontWeight: '700',
                        color: FOREST_GREEN[500],
                    },
                },
            },
            MuiCardContent: {
                styleOverrides: {
                    root: {
                        padding: '2rem 3rem',
                    },
                },
            },
            MuiCardActions: {
                styleOverrides: {
                    root: {
                        padding: '2rem 3rem',
                    },
                },
            },
            MuiLink: {
                styleOverrides: {
                    root: {
                        cursor: 'pointer',
                        fontWeight: '600',
                        display: 'flex',
                        alignItems: 'center',
                        '& i': {
                            marginLeft: '0.4rem',
                            marginRight: '0.4rem',
                        },
                    },
                },
                defaultProps: {
                    component: LinkBehavior,
                } as LinkProps,
            },
            MuiButtonBase: {
                defaultProps: {
                    LinkComponent: LinkBehavior,
                },
            },
            MuiLinearProgress: {
                variants: [
                    {
                        props: { variant: 'determinate', color: 'primary' },
                        style: {
                            backgroundColor: '#F3F1F9',
                        },
                    },
                ],
            },
            MuiTabs: {
                variants: [
                    {
                        props: { indicatorColor: 'darkMode' },
                        style: {
                            '& .MuiTabs-indicator': {
                                backgroundColor: PURPLE[200],
                            },
                        },
                    },
                ],
            },
            MuiChip: {
                styleOverrides: {
                    icon: {
                        fontSize: '2rem',
                        paddingLeft: '0.4rem',
                    },
                },
                variants: [
                    {
                        props: { variant: 'outlined', color: 'warning' },
                        style: {
                            borderColor: SUNSET_ORANGE[700],
                            color: SUNSET_ORANGE[700],
                        },
                    },
                    {
                        props: { variant: 'filled', color: 'warning' },
                        style: {
                            backgroundColor: SUNSET_ORANGE[100],
                            color: SUNSET_ORANGE[900],
                        },
                    },
                    {
                        props: { variant: 'filled', color: 'success' },
                        style: {
                            backgroundColor: SUCCESS_GREEN[50],
                            color: SUCCESS_GREEN[900],
                        },
                    },
                    {
                        props: { variant: 'filled', color: 'error' },
                        style: {
                            backgroundColor: BURNT_ORANGE[50],
                            color: BURNT_ORANGE[900],
                        },
                    },
                ],
            },
            MuiSelect: {
                styleOverrides: {
                    select: {
                        width: '30rem',
                        backgroundColor: '#fff',
                        fontSize: '1.4rem',
                        '& .MuiListItemText-root': {
                            marginTop: '0',
                            marginBottom: '0',
                        },
                        '&.MuiInputBase-inputSizeSmall': {
                            fontSize: '1.3rem',
                            width: '20rem',
                            paddingTop: '0.8rem',
                            paddingBottom: '0.8rem',
                        },
                        '& .MuiListItemIcon-root': {
                            marginTop: '0',
                            marginBottom: '0',
                            height: '1.8rem',
                        },
                    },
                },
            },
            MuiAccordion: {
                styleOverrides: {
                    root: {
                        boxShadow:
                            '0px 2px 1px -1px rgba(0, 0, 0, 0.05), 0px 1px 1px 0px rgba(0, 0, 0, 0.05), 0px 1px 3px 0px rgba(0, 0, 0, 0.05)',
                        '&.Mui-expanded': {
                            margin: '0.1rem 0',
                        },
                    },
                },
            },
            MuiMenuItem: {
                styleOverrides: {
                    root: {
                        fontSize: '1.4rem',
                    },
                },
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    root: {
                        maxWidth: '30rem',
                        '&.MuiInputBase-fullWidth': { maxWidth: 'unset' },
                    },
                    input: {
                        boxSizing: 'border-box',
                        height: 'auto',
                        '&:focus': {
                            border: '0.1rem',
                        },
                        padding: '1.35rem 1.4rem',
                    },
                    multiline: {
                        padding: '0',
                    },
                },
            },
            MuiFilledInput: {
                styleOverrides: {
                    root: {
                        maxWidth: '30rem',
                        '&.MuiInputBase-fullWidth': { maxWidth: 'unset' },
                        '&.Mui-disabled': {
                            backgroundColor: 'rgba(0, 0, 0, 0.05)',
                            '& input': {
                                border: '0',
                            },
                        },
                    },
                    input: {
                        boxSizing: 'border-box',
                        height: 'auto',
                        padding: '1.35rem 1.4rem',
                    },
                },
            },
            MuiSwitch: {
                styleOverrides: {
                    root: {},
                },
            },
            MuiStack: {
                defaultProps: {
                    useFlexGap: true,
                },
            },
            MuiTablePagination: {
                styleOverrides: {
                    displayedRows: {
                        margin: '0',
                    },
                    selectLabel: {
                        margin: '0',
                    },
                },
            },
            MuiTableSortLabel: {
                styleOverrides: {
                    root: {
                        fontWeight: '600',
                    },
                },
            },
            MuiTable: {
                styleOverrides: {
                    root: {
                        borderCollapse: 'collapse',
                        fontSize: '1.4rem',
                    },
                },
            },
            MuiTableFooter: {
                styleOverrides: {
                    root: {
                        fontWeight: '700',
                        '& .MuiTableCell-root': {
                            fontWeight: 'inherit',
                        },
                    },
                },
            },
            MuiTableRow: {
                styleOverrides: {
                    root: {
                        '&.MuiTableRow-hover': {
                            cursor: 'pointer',
                        },
                    },
                },
            },
            MuiTableCell: {
                styleOverrides: {
                    root: {
                        color: 'inherit',
                        fontSize: '1.4rem',
                        paddingLeft: 0,
                        paddingRight: '3.0rem',
                        '&:last-of-type': {
                            paddingRight: 0,
                        },
                    },
                    head: {
                        lineHeight: 'inherit',
                        fontWeight: 600,
                        paddingTop: '0.8rem',
                        paddingBottom: '0.8rem',
                        alignItems: 'baseline',
                    },
                },
                variants: [
                    {
                        props: { size: 'small' },
                        style: {
                            fontSize: '1.2rem',
                        },
                    },
                ],
            },
            MuiToggleButtonGroup: {
                styleOverrides: {
                    root: {
                        border: `1px solid ${theme.palette.divider}`,
                        borderRadius: theme.shape.borderRadius,
                        backgroundColor: theme.palette.background.paper,
                    },
                    grouped: {
                        margin: theme.spacing(0.5),
                        border: 'none',
                        '&:not(:first-of-type)': {
                            borderRadius: theme.shape.borderRadius,
                        },
                        '&:first-of-type': {
                            borderRadius: theme.shape.borderRadius,
                        },
                    },
                },
            },
            MuiBottomNavigation: {
                styleOverrides: {
                    root: {
                        width: '100%',
                        position: 'fixed',
                        bottom: 0,
                        left: 0,
                        zIndex: 1001,
                        boxShadow: '0px -1px 6px rgba(0,0,0,0.15)',
                    },
                },
            },
            MuiTab: {
                styleOverrides: {
                    root: {
                        minHeight: '5rem',
                        fontSize: '1.4rem',
                    },
                    iconWrapper: {
                        fontSize: '1.8rem',
                    },
                    labelIcon: {
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: 4,
                        '& .MuiTab-iconWrapper': {
                            marginBottom: 0,
                            fontWeight: 'normal',
                        },
                    },
                },
            },
            MuiListItem: {
                styleOverrides: {
                    root: {
                        marginBottom: '0',
                    },
                },
            },
            MuiListItemText: {
                styleOverrides: {
                    primary: {
                        fontWeight: '600',
                    },
                },
            },
            MuiListItemIcon: {
                styleOverrides: {
                    root: {
                        minHeight: '5rem',
                        minWidth: '5rem',
                        backgroundColor: '#f2f2f2',
                        borderRadius: '10rem',
                        marginRight: '1rem',
                        fontSize: '2rem',
                        alignItems: 'center',
                        justifyContent: 'center',
                    },
                },
            },
            MuiBadge: {
                styleOverrides: {
                    badge: {
                        fontWeight: '700',
                        height: '1.8rem',
                        minWidth: '1.8rem',
                    },
                    root: {
                        '& i': {
                            fontSize: '2.2rem',
                            lineHeight: '0.8',
                        },
                    },
                    dot: {
                        borderRadius: '10rem',
                        height: '1rem',
                        width: '1rem',
                        minWidth: '0',
                    },
                },
            },
            MuiBottomNavigationAction: {
                styleOverrides: {
                    root: {
                        gap: '0.4rem',
                        '& i': {
                            fontSize: '2.2rem',
                            fontWeight: '500',
                        },
                        '&.Mui-selected .MuiBottomNavigationAction-label': {
                            fontSize: '1.2rem',
                        },
                    },
                },
            },
            MuiFormLabel: {
                styleOverrides: {
                    root: {
                        display: 'flex',
                        fontWeight: 600,
                        color: FOREST_GREEN[500],
                        '&.MUI-focused': {
                            color: FOREST_GREEN[500],
                        },
                    },
                },
            },
            MuiFormControlLabel: {
                styleOverrides: {
                    root: {
                        marginBottom: '0',
                    },
                },
            },
        },
    })
}

export default function Theme({ children }: { children: React.ReactNode }) {
    return <ThemeProvider theme={create()}>{children}</ThemeProvider>
}
