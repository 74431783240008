import CountrySelect from '#app/components/CountrySelect/CountrySelect'
import useCurrentCountryCode from '#app/services/useCurrentCountryCode'
import { validateNationalIdentity } from '#app/services/validateFields'
import { FieldState } from '@fixrate/fieldstate'
import { CountryCode, NationalIdentityDto } from '@fixrate/fixrate-query'
import {
    Box,
    Button,
    InputLabel,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'

interface NationalIdentity {
    nationality: string
    value: string
}

export default function TaxResidencies({
    taxResidencies,
    citizenships,
}: {
    taxResidencies: FieldState<NationalIdentityDto[]>
    citizenships: string[]
}) {
    const { t } = useTranslation()
    const organisationCountry = useCurrentCountryCode()

    function getTRCountryCode(): CountryCode | '' {
        if (taxResidencies.value.length === 0) {
            return citizenships.length > 0 ? (citizenships[0] as CountryCode) : organisationCountry
        }

        return ''
    }

    function addTaxResidency(nationality?: string) {
        taxResidencies.setValue([...taxResidencies.value, { nationality: nationality ?? '', value: '' }])
    }

    function deleteTaxResidency(index: number) {
        const newTaxResidencies = [...taxResidencies.value]
        newTaxResidencies.splice(index, 1)
        taxResidencies.submitValue(newTaxResidencies)
        taxResidencies.touched = true
    }

    function updateTaxResidency(index: number, taxResidency: NationalIdentity) {
        const newTaxResidencies = [...taxResidencies.value]
        newTaxResidencies[index] = taxResidency
        taxResidencies.setValue(newTaxResidencies)
    }

    function submitTaxResidency(index: number, taxResidency: NationalIdentity) {
        const newTaxResidencies = [...taxResidencies.value]
        newTaxResidencies[index] = taxResidency

        taxResidencies.submitValue(newTaxResidencies)
    }

    function nationalIdentityNumberErrorMessage(nationalIdentity: NationalIdentityDto) {
        let errorMessage

        if (!nationalIdentity.value) {
            errorMessage = t('pages-organizations.personFormMustProvideNationalId')
        } else if (!validateNationalIdentity(nationalIdentity.nationality, nationalIdentity.value)) {
            errorMessage = t('pages-organizations.personFormInvalidNationalId')
        }

        return errorMessage ? <p className="field-error-message">{errorMessage}</p> : null
    }

    return (
        <Box>
            <InputLabel>{t('pages-organizations.personInfoTaxResidencies')}</InputLabel>
            {taxResidencies.value.length > 0 && (
                <Table sx={{ mb: 1 }}>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <Stack>
                                    <span>{t('pages-organizations.personInfoCountry')}</span>
                                    <Typography component="span" variant="caption">
                                        {t('pages-organizations.personInfoTaxResidenciesDescription')}
                                    </Typography>
                                </Stack>
                            </TableCell>
                            <TableCell>
                                <Stack>
                                    <span>{t('pages-organizations.personFormNationalId')}</span>
                                    <Typography component="span" variant="caption">
                                        {t('pages-organizations.personFormNationalIdAdditionalInfo')}.
                                    </Typography>
                                </Stack>
                            </TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody
                        sx={{
                            '& tr:last-of-type .MuiTableCell-root': {
                                borderBottom: '0',
                            },
                        }}
                    >
                        {taxResidencies.value.map((nationalIdentity, index) => (
                            <TableRow key={nationalIdentity.nationality + index} sx={{ verticalAlign: 'top' }}>
                                <TableCell>
                                    <CountrySelect
                                        dataCy={`taxResidencySelect-${nationalIdentity.nationality && nationalIdentity.value ? nationalIdentity.nationality : 'empty'}`}
                                        disabled={
                                            !!(
                                                nationalIdentity.nationality === 'US' &&
                                                citizenships.find((c) => c === 'US')
                                            )
                                        }
                                        selectedCountry={nationalIdentity.nationality as CountryCode | null}
                                        setSelectedCountry={(country) =>
                                            submitTaxResidency(index, {
                                                ...nationalIdentity,
                                                nationality: country,
                                            })
                                        }
                                    />
                                    {!nationalIdentity.nationality && (
                                        <p className="field-error-message">
                                            {t('pages-organizations.personFormMustSelectNationality')}
                                        </p>
                                    )}
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        required
                                        autoComplete="off"
                                        data-cy={`nationalityInput-${nationalIdentity.nationality ?? 'empty'}`}
                                        value={nationalIdentity.value}
                                        onChange={(e) =>
                                            updateTaxResidency(index, {
                                                ...nationalIdentity,
                                                value: e.target.value,
                                            })
                                        }
                                        onBlur={(e) =>
                                            submitTaxResidency(index, {
                                                ...nationalIdentity,
                                                value: e.target.value,
                                            })
                                        }
                                    />
                                    {nationalIdentityNumberErrorMessage(nationalIdentity)}
                                </TableCell>
                                <TableCell>
                                    {!(
                                        nationalIdentity.nationality === 'US' && citizenships.find((c) => c === 'US')
                                    ) && (
                                        <Button color="error" onClick={() => deleteTaxResidency(index)}>
                                            {t('common.remove')}
                                        </Button>
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            )}
            {taxResidencies.value.length === 0 && (
                <p className="field-error-message">{t('pages-organizations.personFormAtLeastOneTaxResidency')}</p>
            )}
            <Button
                variant="outlined"
                data-cy="addTaxResidencyButton"
                onClick={() => addTaxResidency(getTRCountryCode())}
            >
                {t('pages-organizations.personFormAddTaxResidency')}
            </Button>
        </Box>
    )
}
