import CompanySelectBrreg from '#components/CompanySelect/CompanySelectBrreg'
import CompanySelectBasic from '#components/CompanySelect/CompanySelectBasic'
import { SessionDto } from '@fixrate/fixrate-security'
import { useTranslation } from 'react-i18next'
import { useSelector } from '#state/useSelector'

export type CompanyType = 'CORPORATION' | 'SOLE_PROP' | 'BANK' | 'NON_PROFIT' | 'GOVERNMENT' | 'OTHER' | 'UNKNOWN'

export interface CompanyInfo {
    name: string
    nationalIdentity: string
    nationality: string
    companyType: CompanyType
}

type Props = {
    onChange: (value: CompanyInfo | null) => void
    setErrorMessage: (error: string) => void
    autoFocus?: boolean
    placeholder?: string
    regulatoryRegion?: 'NO' | 'SE'
}

export default function CompanySelect({ onChange, setErrorMessage, autoFocus, placeholder, regulatoryRegion }: Props) {
    const primaryDomain = useSelector((state) => state.session.primaryDomain)
    let variant
    if (regulatoryRegion) {
        variant = regulatoryRegion
    } else {
        switch (primaryDomain) {
            case 'fixrate.no':
                variant = 'NO'
                break
            case 'fixrate.se':
                variant = 'SE'
                break
        }
    }

    switch (variant) {
        case 'NO':
            return (
                <CompanySelectBrreg
                    onChange={onChange}
                    setErrorMessage={setErrorMessage}
                    autoFocus={autoFocus}
                    placeholder={placeholder}
                />
            )
        case 'SE':
            return (
                <CompanySelectBasic
                    onChange={onChange}
                    setErrorMessage={setErrorMessage}
                    autoFocus={autoFocus}
                    placeholder={placeholder}
                    regulatoryRegion={variant}
                />
            )
    }
}

export const useWarningMessageOnRegisterCompany = (company: CompanyInfo, session: SessionDto): string | null => {
    const { t } = useTranslation()
    if (!company) return null
    if (company.companyType === 'SOLE_PROP') {
        return t('pages-organizations.organisationFormENKMessage')
    }
    // If loggedin user is a fixrate employee, don't show warning
    if (session.email.includes('@fixrate.no')) return null
    if (company.companyType === 'BANK') {
        return t('pages-organizations.organisationFormBankMessage')
    }
    return null
}
