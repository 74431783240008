import { createSlice } from '@reduxjs/toolkit'
import { DashboardData } from '@fixrate/fixrate-query'

const slice = createSlice({
    name: 'dashboard',
    initialState: {},
    reducers: {
        updateDashboard(state: DashboardData, action) {
            return {
                ...state,
                ...action.payload,
                potentialMonthlyInterest: action.payload.potentialMonthlyInterest || state.potentialMonthlyInterest,
            }
        },
        setDashboardPotential(state: DashboardData, action) {
            return {
                ...state,
                ...action.payload,
                potentialMonthlyInterest: action.payload.potentialMonthlyInterest || state.potentialMonthlyInterest,
            }
        },
        setDashboardPotentialInterest(state, action) {
            return {
                ...state,
                potentialMonthlyInterest: action.payload,
            }
        },
    },
})

const { actions, reducer } = slice
export const { updateDashboard, setDashboardPotential, setDashboardPotentialInterest } = actions
export default reducer
