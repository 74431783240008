import { SupportCategoryNameType } from './supportNameMappings'

type URLMap = {
    [key: string]: {
        commonName: SupportCategoryNameType
    }
}

const SUPPORT_PAGE_FOR_URL: URLMap = {
    '/marketplace': {
        commonName: 'depositorAds',
    },
    '/marketplace/category/deposits/over-20': {
        commonName: 'depositorAds',
    },
    '/marketplace/category/deposits/under-20': {
        commonName: 'depositorAds',
    },
    '/reports/deposits/interest-balance': {
        commonName: 'depositReports',
    },
    '/reports/deposits/collected-statement': {
        commonName: 'depositReports',
    },
    '/reports/deposits/annual-statements': {
        commonName: 'depositReports',
    },
    '/organizations/:uuid/users/owners': {
        commonName: 'ownershipKeyPeople',
    },
    '/organizations/:uuid/users/authorization/:uuid': {
        commonName: 'authorizations',
    },
    '/organizations/:uuid/customerdecl': {
        commonName: 'customerDeclaration',
    },
    '/organizations/:uuid/customerdeclaration': {
        commonName: 'customerDeclaration',
    },
    '/organizations/:uuid/accounts': {
        commonName: 'settlementAccounts',
    },
    '/organizations/:uuid/terms': {
        commonName: 'generalTerms',
    },
    '/orders/waiting': {
        commonName: 'termsBankAcceptingDeposits',
    },
    '/bank-dashboard': {
        commonName: 'depositTermsOverview',
    },
    '/ads': {
        commonName: 'depositTermsOverview',
    },
    '/interestratechange-bank': {
        commonName: 'interestRateChange',
    },
    '/portfolio/overview': {
        commonName: 'portfolio',
    },
    '/portfolio/overview/deposits': {
        commonName: 'portfolio',
    },
    '/portfolio-bank': {
        commonName: 'portfolio',
    },
}

export function getSupportArticleForPathname(pathname: string): SupportCategoryNameType | null {
    const formattedUrl = pathname.replaceAll(
        /\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b/gi,
        ':uuid'
    )
    const commonName = SUPPORT_PAGE_FOR_URL[formattedUrl]?.commonName
    return commonName || null
}
