import { PURPLE } from '#app/colors/colors'
import { logout } from '#app/services/thunks/session'
import { userNeedsIdDoc } from '#app/state/selectors'
import { useSelector } from '#app/state/useSelector'
import { Avatar, Button, Divider, ListItemIcon, ListItemText, Menu, MenuItem, useMediaQuery } from '@mui/material'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { MenuLink } from '../PageHeader'
import { listItemIconStyle, listItemTextStyle, MenuItemLink, selectButtonStyles } from './MenuComponents'

export const ProfileMenu = () => {
    const userHasIdDocOption = useSelector((state) => userNeedsIdDoc(state))
    const userName = useSelector((state) => state.session.firstName)
    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [profileAnchorEl, setProfileAnchorEl] = useState<null | HTMLElement>(null)
    const profileMenuOpen = Boolean(profileAnchorEl)
    const isMobile = useMediaQuery('(max-width: 800px)')
    const darkMode = useSelector((state) => state?.session?.organisationType === 'PARTNER')

    const handleProfileMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setProfileAnchorEl(event.currentTarget)
    }

    const handleProfileMenuClose = () => {
        setProfileAnchorEl(null)
    }

    useEffect(() => {
        setProfileAnchorEl(null)
    }, [location])

    const profileMenuList: MenuLink[] = [
        {
            icon: 'ri-shield-check-line',
            text: 'pages-profile.identificationDocument',
            link: '/profile#id-document',
            visible: userHasIdDocOption,
        },
        {
            icon: 'ri-phone-line',
            text: 'pages-profile.contactInformation',
            link: '/profile#contact',
            visible: true,
        },
        {
            icon: 'ri-earth-line',
            text: 'pages-profile.languageSettings',
            link: '/profile#language',
            visible: true,
        },
        {
            icon: 'ri-mail-unread-line',
            text: 'pages-profile.emailNotifications',
            link: '/profile#email-notifications',
            visible: true,
        },
    ]

    return (
        <React.Fragment>
            <Button
                id="profile-button"
                data-cy="profile-button"
                aria-controls={profileMenuOpen ? 'profile-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={profileMenuOpen ? 'true' : undefined}
                onClick={handleProfileMenuClick}
                color={darkMode ? 'darkMode' : 'primary'}
                startIcon={
                    <Avatar
                        sx={{
                            fontSize: '1.4rem!important',
                            height: '2.8rem',
                            width: '2.8rem',
                            fontWeight: '600',
                            backgroundColor: PURPLE[50],
                            color: PURPLE[500],
                        }}
                    >
                        <i className="ri-user-3-line" />
                    </Avatar>
                }
                sx={{ ...selectButtonStyles, borderLeftColor: darkMode ? 'rgba(250,250,250,0.3)' : undefined }}
            >
                {!isMobile && userName}
            </Button>
            <Menu
                id="profile-menu"
                data-cy="profile-menu"
                anchorEl={profileAnchorEl}
                open={profileMenuOpen}
                onClose={handleProfileMenuClose}
                MenuListProps={{
                    'aria-labelledby': 'profile-button',
                }}
            >
                {profileMenuList
                    .filter((listItem) => listItem.visible)
                    .map((item) => (
                        <MenuItemLink key={item.link} item={item} link={item.link} />
                    ))}
                <Divider />
                <MenuItem id="logoutItem" onClick={() => dispatch(logout(navigate))}>
                    <ListItemIcon sx={listItemIconStyle}>
                        <i className="ri-logout-box-line" />
                    </ListItemIcon>
                    <ListItemText sx={listItemTextStyle}>Logg ut</ListItemText>
                </MenuItem>
            </Menu>
        </React.Fragment>
    )
}
