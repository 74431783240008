import update from 'immutability-helper'
import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
    name: 'orders',
    initialState: [],
    reducers: {
        updateOrders(state, action) {
            return action.payload
        },
        updateOrder(state, action) {
            const index = state.findIndex((order) => order.id === action.payload.id)
            if (index !== -1) {
                return update(state, { $splice: [[index, 1, action.payload]] })
            } else {
                return update(state, { $push: [action.payload] })
            }
        },
        updateOrderTag(state, action) {
            const index = state.findIndex((order) => order.id === action.payload.id)
            const newOrder = {
                ...state[index],
                tag: action.payload.tag,
                tagColor: action.payload.tagColor,
            }
            return update(state, { [index]: { $set: newOrder } })
        },
    },
})

const { actions, reducer } = slice
export const { updateOrders, updateOrder, updateOrderTag } = actions
export default reducer
