import { PURPLE } from '#app/colors/colors'
import InterestOutput from '#app/components/InterestOutput'
import AddNewOrganization from '#app/pages/organizations/AddNewOrganization/AddNewOrganization'
import { useAuthorization } from '#app/services/authorization'
import useCurrentDepositor from '#app/services/useCurrentDepositor'
import { usePlacementProfileAccess } from '#app/services/usePlacementProfileAccess'
import { useSelector } from '#app/state/useSelector'
import { useOnboardingWizardStatus } from '#app/utilities/useOnboardingWizardStatus'
import useOrganizationWarnings from '#services/useOrganizationWarnings'
import {
    Avatar,
    Box,
    Button,
    Divider,
    LinearProgress,
    ListItem,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Stack,
    Switch,
    Typography,
    useMediaQuery,
} from '@mui/material'
import { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { MenuLink, OrganisationSelectorOption } from '../PageHeader'
import {
    listItemIconStyle,
    listItemTextStyle,
    MenuItemLink,
    OrganizationMenuItem,
    selectButtonStyles,
} from './MenuComponents'
import { asOrganisationTypeTMap } from '#app/services/enumTranslationKeyMapping'
import AvatarContent from '#app/layers/ChooseDepositor/AvatarContent'
import useUiSetting from '#app/services/useUiSetting'

const bankMenuList: MenuLink[] = [
    {
        icon: 'ri-group-line',
        text: 'pages-organizations.menuItemUsers',
        link: '/my-bank#users',
        adminOnly: false,
    },
    {
        icon: 'ri-mail-unread-line',
        text: 'pages-profile.emailNotifications',
        link: '/my-bank#email-settings',
        adminOnly: true,
    },
    {
        icon: 'ri-mail-settings-line',
        text: 'pages-profile.emailDomain',
        link: '/my-bank#email-domain',
        adminOnly: true,
    },
    {
        icon: 'ri-file-line',
        text: 'pages-organizations.menuItemAgreementWithFixrate',
        link: '/my-bank#contract',
        adminOnly: false,
    },
    {
        icon: 'ri-pie-chart-line',
        text: 'pages-mybank.keyInfoTitle',
        link: '/my-bank#key-info',
        adminOnly: false,
    },
]

export const OrganizationSelection = ({
    selectedOrganisation,
    organisationOptions,
}: {
    selectedOrganisation: OrganisationSelectorOption
    organisationOptions: OrganisationSelectorOption[]
}) => {
    const { t } = useTranslation()
    const location = useLocation()
    const currentDepositor = useCurrentDepositor()
    const associations = useSelector((state) => state.session.associations)
    const auth = useAuthorization()
    const navigate = useNavigate()
    const hasPlacementProfileAccess = usePlacementProfileAccess()
    const sessionId = useSelector((state) => state.session.id)
    const [organizationAnchorEl, setOrganizationAnchorEl] = useState<null | HTMLElement>(null)
    const onboardingWizardStatus = useOnboardingWizardStatus(currentDepositor)
    const [simplifiedOnboardingState, setSimplifiedOnboardingState] = useState(
        onboardingWizardStatus?.simplifiedOnboarding
    )
    const [registerOrganizationVisible, setRegisterOrganizationVisible] = useState(false)
    const organizationMenuOpen = Boolean(organizationAnchorEl)
    const isPartner = organisationOptions?.some((org) => org.organisationType === 'PARTNER')
    const isPartnerAccountant = currentDepositor?.users
        ?.find((u) => u.id === sessionId)
        ?.roles?.find((r) => r === 'DEPOSITOR_PARTNER_ACCOUNTANT')
    const isMobile = useMediaQuery('(max-width: 800px)')
    const selectedOrg = organisationOptions.find((org) => org.selected)
    const canViewDepositorMenu = associations
        .find((a) => a.organisation?.id === selectedOrg?.organisationId)
        ?.roles.includes('DEPOSITOR_VIEW')
    const hasManyOrgTypes = organisationOptions?.some((org) => org.organisationType !== selectedOrg?.organisationType)

    const organizationWarnings = useOrganizationWarnings(currentDepositor)

    // This is done to avoid instant reload on click, letting the switch animation finish and then reload
    const toggleSimplifiedOnboarding = (value: boolean) => {
        setSimplifiedOnboardingState(value)
        setTimeout(() => {
            onboardingWizardStatus?.setSimplifiedOnboarding(value)
            setSimplifiedOnboardingState(value)
        }, 500)
    }

    const depositorMenuList: MenuLink[] = [
        {
            icon: 'ri-group-line',
            text: 'pages-organizations.menuItemUsers',
            link: '/users/',
            warning: organizationWarnings.users.length > 0 || organizationWarnings.organisationalRoles.length > 0,
        },
        {
            icon: 'ri-file-list-2-line',
            text: 'pages-organizations.menuItemCustomerDeclaration',
            link: '/customerdecl',
            warning: organizationWarnings.customerDeclaration.length > 0,
        },
        {
            icon: 'ri-bank-line',
            text: 'pages-organizations.settlementAccountHeading',
            link: '/accounts',
            warning: organizationWarnings.accounts.length > 0,
        },
        {
            icon: 'ri-pencil-line',
            text: 'pages-organizations.menuItemAgreementWithFixrate',
            link: '/terms',
            warning: organizationWarnings.terms.length > 0,
        },
    ]
    if (auth.depositor.hasAdminRole) {
        depositorMenuList.push({
            icon: 'ri-puzzle-line',
            text: 'pages-organizations.menuItemIntegrations',
            link: '/integrations',
            warning: organizationWarnings.integrations.length > 0,
        })
    }

    if (hasPlacementProfileAccess) {
        depositorMenuList.push({
            icon: 'ri-list-settings-line',
            text: 'pages-organizations.mandate',
            link: '/placement-profile',
        })
    }

    const handleOrganisationMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setOrganizationAnchorEl(event.currentTarget)
    }

    const handleOrganizationMenuClose = () => {
        setOrganizationAnchorEl(null)
    }

    const openRegistrationDialog = () => {
        setRegisterOrganizationVisible(true)
        setOrganizationAnchorEl(null)
    }

    useEffect(() => {
        setOrganizationAnchorEl(null)
    }, [location])

    return (
        organisationOptions?.length > 0 && (
            <>
                <Button
                    id="organization-menu-button"
                    aria-controls={organizationMenuOpen ? 'organization-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={organizationMenuOpen ? 'true' : undefined}
                    onClick={handleOrganisationMenuClick}
                    color="secondary"
                    data-cy="organization-menu-button"
                    data-fx={selectedOrganisation?.organisationId}
                    startIcon={
                        hasManyOrgTypes && selectedOrganisation ? (
                            <AvatarContent
                                hasDifferentOrgTypes={hasManyOrgTypes}
                                hasPartner={isPartner}
                                name={selectedOrganisation?.name}
                                type={selectedOrganisation?.organisationType}
                                iconColor={selectedOrganisation?.iconColor}
                                isList={true}
                            />
                        ) : (
                            <Avatar
                                sx={{
                                    backgroundColor: selectedOrganisation?.iconColor,
                                    fontSize: '1.4rem!important',
                                    height: '2.8rem',
                                    width: '2.8rem',
                                    fontWeight: '600',
                                    marginRight: isMobile ? '0' : null,
                                }}
                            >
                                {selectedOrganisation?.iconName}
                            </Avatar>
                        )
                    }
                    sx={{ ...selectButtonStyles, padding: hasManyOrgTypes ? '0.5rem 1rem' : '1rem' }}
                >
                    <Box display="flex" flexDirection="column" alignItems="flex-start">
                        {!isMobile && selectedOrganisation?.name}
                        {hasManyOrgTypes && !isPartner && (
                            <Typography color={PURPLE[900]} sx={{ fontWeight: '500', fontSize: '1.2rem' }}>
                                {t(asOrganisationTypeTMap[selectedOrganisation?.organisationType]).toUpperCase()}
                            </Typography>
                        )}
                    </Box>
                </Button>
                <Menu
                    id="organization-menu"
                    data-cy="organization-menu"
                    anchorEl={organizationAnchorEl}
                    open={organizationMenuOpen}
                    onClose={handleOrganizationMenuClose}
                    MenuListProps={{
                        'aria-labelledby': 'organization-menu-button',
                    }}
                >
                    {onboardingWizardStatus?.wizardActive && (
                        <MenuItem onClick={() => navigate('/organizations/onboarding/')}>
                            <Stack px={1} my={1} width="100%">
                                <Typography variant="h4" mb={0.5}>
                                    {onboardingWizardStatus?.progress < 100
                                        ? t('menu.finishRegistration')
                                        : t('menu.registrationFinished')}
                                </Typography>
                                <Typography variant="caption" fontWeight="700" color={PURPLE[500]}>
                                    {InterestOutput.formatWithDecimals(onboardingWizardStatus?.progress, 0)}{' '}
                                    {t('pages-OrganizationWizard.statusFinished')}
                                </Typography>
                                <LinearProgress
                                    sx={{ width: '100%', marginTop: '0.6rem', height: '0.8rem', borderRadius: '10rem' }}
                                    variant={'determinate'}
                                    value={onboardingWizardStatus?.progress}
                                />
                            </Stack>
                        </MenuItem>
                    )}
                    {!onboardingWizardStatus?.wizardActive &&
                        selectedOrganisation?.organisationType === 'DEPOSITOR' &&
                        canViewDepositorMenu &&
                        depositorMenuList.map((item) => (
                            <MenuItemLink
                                key={item.text}
                                data-cy={'organization-menu-item'}
                                item={item}
                                link={'/organizations/' + currentDepositor?.id + item.link}
                            />
                        ))}
                    {selectedOrganisation?.organisationType === 'BANK' &&
                        bankMenuList.map((item) =>
                            item.adminOnly && !auth?.bank?.hasAdminRole ? null : (
                                <MenuItemLink
                                    key={item.text}
                                    data-cy={'organization-menu-item'}
                                    item={item}
                                    link={item.link}
                                />
                            )
                        )}
                    {organisationOptions?.length > 1 && <Divider />}
                    {!isPartner &&
                        organisationOptions?.length > 1 &&
                        organisationOptions
                            ?.filter((d) => d.organisationId !== currentDepositor?.id)
                            .map((org) => (
                                <OrganizationMenuItem data-cy={'organization-menu-item'} key={org?.key} org={org} />
                            ))}
                    {isPartner &&
                        organisationOptions
                            ?.filter((o) => o.organisationType !== 'DEPOSITOR' && o.organisationType !== 'BANK')
                            .map((org) => <OrganizationMenuItem key={org?.key} org={org} />)}
                    {selectedOrganisation?.organisationType === 'DEPOSITOR' &&
                        !isPartnerAccountant &&
                        !onboardingWizardStatus?.fullyRegistered && (
                            <Box component="li">
                                <Box>
                                    <Divider sx={{ mb: 0 }} />
                                </Box>
                                <Stack direction={'row'} alignItems="center" px={1.5} py={1}>
                                    <Switch
                                        checked={simplifiedOnboardingState}
                                        onChange={(e) => toggleSimplifiedOnboarding(e.target.checked)}
                                        data-cy="simplifiedOnboardingSwitch"
                                    />
                                    <Typography fontSize={'1.2rem'} fontWeight="600">
                                        {t('pages-OrganizationWizard.simplifiedOnboarding')}
                                    </Typography>
                                </Stack>
                            </Box>
                        )}
                    {!isPartner && selectedOrganisation?.organisationType !== 'BANK' && <Divider />}
                    {!isPartner && selectedOrganisation?.organisationType !== 'BANK' && (
                        <MenuItem color="primary" onClick={openRegistrationDialog}>
                            <ListItemIcon sx={listItemIconStyle}>
                                <i className="ri-add-line" />
                            </ListItemIcon>
                            <ListItemText sx={listItemTextStyle}>{t('common.registerCompany')}</ListItemText>
                        </MenuItem>
                    )}
                </Menu>
                <AddNewOrganization
                    visible={registerOrganizationVisible}
                    setShowNewOrganization={() => setRegisterOrganizationVisible(false)}
                />
            </>
        )
    )
}
