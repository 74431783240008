import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
    name: 'partnerNames',
    initialState: [],
    reducers: {
        setPartnerNames(state, action) {
            return action.payload
        },
    },
})

const { actions, reducer } = slice
export const { setPartnerNames } = actions
export default reducer
