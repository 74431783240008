import { createSlice } from '@reduxjs/toolkit'
import { ConfirmationModal } from '#state/types'
import { IllustrationColor, IllustrationName } from '#components/FixrateIllustration/FixrateIllustration'
import { ConfirmationIconName } from '#app/layers/ConfirmationModal/ConfirmationIcon'

const DEFAULT_STATE: ConfirmationModal = {
    showModal: false,
    title: '',
    text: '',
    buttonText: '',
    sentIcon: false,
    illustrationName: null,
    illustrationColor: null,
    confirmationIconName: null,
}

interface actionPayload {
    title: string
    text: string
    buttonText: string
    sentIcon?: boolean
    illustrationName?: IllustrationName
    illustrationColor?: IllustrationColor
    confirmationIconName?: ConfirmationIconName
}

const slice = createSlice({
    name: 'confirmationModal',
    initialState: DEFAULT_STATE,
    reducers: {
        showConfirmationModal(state: ConfirmationModal, action: { payload: actionPayload }) {
            state.showModal = true
            state.title = action.payload.title
            state.text = action.payload.text
            state.buttonText = action.payload.buttonText
            state.sentIcon = action.payload.sentIcon
            state.illustrationName = action.payload.illustrationName
            state.illustrationColor = action.payload.illustrationColor
            state.confirmationIconName = action.payload.confirmationIconName
        },
        closeConfirmationModal(state, action) {
            return DEFAULT_STATE
        },
    },
})

const { actions, reducer } = slice
export const { showConfirmationModal, closeConfirmationModal } = actions
export default reducer
