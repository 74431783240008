import React from 'react'
import { Button, ButtonGroup, Typography } from '@mui/material'
import { BURNT_ORANGE, SUNSET_ORANGE } from '#app/colors/colors'
import { formatPerson, getRoleMatch, MatchType, RolesMatchType } from '../../views/BrregBoard'
import { useTranslation } from 'react-i18next'
import { OrganisationalPersonDto, PersonMatchDto } from '@fixrate/fixrate-query'

interface BrregFixerProps {
    fixratePerson: OrganisationalPersonDto | null
    personMatch: PersonMatchDto
    proffRole: string
    fixrateRole: string
    onAddPerson: () => void
    onRemovePerson: () => void
    onModifyPerson: () => void
    onSwitchRole: () => void
}

const BrregFixer: React.FC<BrregFixerProps> = ({
    fixratePerson,
    personMatch,
    proffRole,
    fixrateRole,
    onAddPerson,
    onRemovePerson,
    onModifyPerson,
    onSwitchRole,
}) => {
    const { t } = useTranslation()
    const anchorRef = React.useRef<HTMLDivElement>(null)
    const personMatchType = personMatch.matchType
    const rolesMatch: RolesMatchType = getRoleMatch(proffRole, fixrateRole) as RolesMatchType

    let matchType: string = personMatchType.toString()
    if (personMatchType === MatchType.EXACT || personMatchType === MatchType.NONE) {
        matchType = rolesMatch !== RolesMatchType.EXACT ? rolesMatch : personMatchType
    }

    const matchTypeString = matchType.toString()

    if (matchTypeString === 'EXACT') {
        return <>{formatPerson(personMatch.personName, personMatch.birthDate)}</>
    } else if (matchTypeString === 'NONE') {
        return <></>
    } else if (matchTypeString === 'MISSING_IN_FIXRATE') {
        return (
            <ButtonGroup
                variant="text"
                ref={anchorRef}
                aria-label="split button"
                sx={{ '& .MuiButtonGroup-firstButton': { borderColor: 'rgba(0,0,0,0.1)' } }}
            >
                <Button
                    sx={{
                        fontSize: '1.4rem',
                    }}
                    disabled
                    startIcon={<i className="ri-user-add-line" />}
                >
                    {formatPerson(personMatch.proffName, personMatch.birthDate)}
                </Button>
                <Button onClick={() => onAddPerson()}>
                    <Typography variant="overline" data-cy="addPerson">
                        {' '}
                        {t('common.add')}
                    </Typography>
                </Button>
            </ButtonGroup>
        )
    } else if (matchTypeString === 'MISSING_IN_PROFF') {
        return (
            <ButtonGroup
                variant="text"
                ref={anchorRef}
                aria-label="split button"
                sx={{ '& .MuiButtonGroup-firstButton': { borderColor: 'rgba(0,0,0,0.1)' } }}
            >
                <Button
                    sx={{
                        fontSize: '1.4rem',
                        '&.Mui-disabled': {
                            color: BURNT_ORANGE[900],
                            backgroundColor: SUNSET_ORANGE[50],
                        },
                    }}
                    disabled
                    startIcon={<i className="ri-user-unfollow-line" />}
                >
                    {formatPerson(personMatch.personName, personMatch.birthDate)}
                </Button>
                <Button
                    sx={{
                        color: BURNT_ORANGE[900],
                        backgroundColor: SUNSET_ORANGE[50],
                        '&.MuiButton-root:hover': {
                            color: BURNT_ORANGE[800],
                            backgroundColor: SUNSET_ORANGE[100],
                        },
                    }}
                    onClick={() => onRemovePerson()}
                >
                    <Typography variant="overline" sx={{ color: BURNT_ORANGE[900] }}>
                        {t('common.remove')}
                    </Typography>
                </Button>
            </ButtonGroup>
        )
    } else if (matchTypeString === 'LIKELY' || matchTypeString === 'POSSIBLE') {
        return (
            <ButtonGroup
                variant="text"
                ref={anchorRef}
                aria-label="split button"
                sx={{ '& .MuiButtonGroup-firstButton': { borderColor: 'rgba(0,0,0,0.1)' } }}
            >
                <Button
                    sx={{
                        fontSize: '1.4rem',
                    }}
                    disabled
                    startIcon={<i className="ri-user-settings-line" />}
                >
                    {formatPerson(personMatch.personName, fixratePerson?.birthDate ? fixratePerson.birthDate : '')}
                </Button>
                <Button onClick={() => onModifyPerson()}>
                    <Typography variant="overline" data-cy="editPerson">
                        {t('common.edit')}
                    </Typography>
                </Button>
            </ButtonGroup>
        )
    } else if (matchTypeString === 'ROLES_DIFFER') {
        return (
            <ButtonGroup
                variant="text"
                ref={anchorRef}
                aria-label="split button"
                sx={{ '& .MuiButtonGroup-firstButton': { borderColor: 'rgba(0,0,0,0.1)' } }}
            >
                <Button
                    sx={{
                        fontSize: '1.4rem',
                    }}
                    disabled
                    startIcon={<i className="ri-user-received-line" />}
                >
                    {formatPerson(personMatch.personName, personMatch.birthDate)}
                </Button>
                <Button onClick={() => onSwitchRole()}>
                    <Typography variant="overline">{t('common.switchRole')}</Typography>
                </Button>
            </ButtonGroup>
        )
    }
}

export default BrregFixer
