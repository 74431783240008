import { useState } from 'react'
import * as React from 'react'
import { Avatar, Box, Button, Menu, useMediaQuery } from '@mui/material'
import { MenuItemLink, OrganizationMenuItem, selectButtonStyles } from './MenuComponents'
import { MenuLink, OrganisationSelectorOption } from '../PageHeader'
import { PURPLE } from '#app/colors/colors'

const partnerMenuList: MenuLink[] = [
    {
        icon: 'ri-group-line',
        text: 'pages-organizations.menuItemUsers',
        link: '/partner/users',
        dataCy: 'partner-users-menu-item',
    },
    {
        icon: 'ri-file-paper-2-line',
        text: 'pages-mypartner.contract',
        link: '/partner/contract',
        dataCy: 'partner-contract-menu-item',
    },
]

const arrowStyle = {
    position: 'absolute',
    width: '1rem',
    height: '2rem',
    left: '-1rem',
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    '&::before': {
        content: '""',
        display: 'block',
        border: '1px solid rgba(250,250,250,0.2)',
        transform: 'rotate(45deg)',
        backgroundColor: PURPLE[900],
        width: '1rem',
        height: '1rem',
        position: 'absolute',
        right: '-0.6rem',
    },
}

export const PartnerSelectionMenu = ({
    selectedOrganisation,
    organisationOptions,
}: {
    selectedOrganisation: OrganisationSelectorOption
    organisationOptions: OrganisationSelectorOption[]
}) => {
    const isMobile = useMediaQuery('(max-width: 800px)')
    const [partnerSelectionAnchorEl, setPartnerSelectionAnchorEl] = useState<null | HTMLElement>(null)
    const partnerSelectionMenuOpen = Boolean(partnerSelectionAnchorEl)
    const partnerSelectionOptions = organisationOptions?.filter(
        (org) => org.organisationType !== 'DEPOSITOR' && org.organisationType !== 'BANK'
    )

    const handlePartnerSelectionMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setPartnerSelectionAnchorEl(event.currentTarget)
    }

    const handlePartnerSelectionMenuClose = () => {
        setPartnerSelectionAnchorEl(null)
    }

    return (
        <React.Fragment>
            <Button
                id="partner-selection-button"
                aria-controls={window.open ? 'partner-selection-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={window.open ? 'true' : undefined}
                onClick={handlePartnerSelectionMenuClick}
                data-cy={'partner-selection-button'}
                color="darkMode"
                startIcon={
                    <Avatar
                        sx={{
                            backgroundColor: selectedOrganisation?.iconColor,
                            fontSize: '1.4rem!important',
                            height: '2.8rem',
                            width: '2.8rem',
                            fontWeight: '600',
                        }}
                    >
                        {selectedOrganisation?.iconName}
                    </Avatar>
                }
                sx={{ ...selectButtonStyles, borderLeftColor: 'rgba(250,250,250,0.3)' }}
            >
                {!isMobile && selectedOrganisation?.name}
                <Box component="span" sx={{ ...arrowStyle }}></Box>
            </Button>
            <Menu
                id="partner-selection-menu"
                anchorEl={partnerSelectionAnchorEl}
                open={partnerSelectionMenuOpen}
                onClose={handlePartnerSelectionMenuClose}
                MenuListProps={{
                    'aria-labelledby': 'partner-selection-button',
                }}
            >
                {partnerMenuList.map((item) => (
                    <MenuItemLink key={item.text} data-cy={item.dataCy} item={item} link={item.link} />
                ))}
                {partnerSelectionOptions.length > 1 &&
                    partnerSelectionOptions.map((org, index) => <OrganizationMenuItem key={index} org={org} />)}
            </Menu>
        </React.Fragment>
    )
}
